export enum ENV_VARS {
    JIRA_URL = '___ENV-VAR-JIRA-URL___',
    ENABLE_JIRA_LINK = '___ENV-VAR-ENABLE-JIRA-LINK___',
    ENABLE_FLAGS = '___ENV-VAR-ENABLE-FLAGS___',
    PORTAL_CONFIGURATOR = '___ENV-VAR-PORTAL-CONFIGURATOR___',
}

export enum ENV_VAR_NAMES {
    JIRA_URL = 'JIRA_URL',
    ENABLE_JIRA_LINK = 'ENABLE_JIRA_LINK',
    ENABLE_FLAGS = 'ENABLE_FLAGS',
    PORTAL_CONFIGURATOR = 'PORTAL_CONFIGURATOR',
}

/**
 * Returns ENV var based on environment.
 * When developing on local computer, environment variable is provided by env.local file
 * @param env
 */
export const getEnvVar = (key: ENV_VAR_NAMES): ENV_VARS | string => {
    const localVar = process.env[`REACT_APP_${key}`]
    if (localVar) {
        return localVar
    }
    return ENV_VARS[key]
}

import isNull from 'lodash/isNull'
import { call, ForkEffect, select, StrictEffect, takeLatest } from 'redux-saga/effects'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Select } from '../../lib/toolBoxes/2D'
import { selectLengths, setHeight, setWidth } from '../../slices/geometry'

export function* modifySelectedItemLength(): Generator<
    StrictEffect,
    void,
    (PaperManager | null) & { width: number; height: number } & Select & boolean
> {
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (isNull(manager)) return

    const lengths: { width: number; height: number } = yield select(selectLengths)

    const selectTool: Select = yield call(manager.getTool, Select.NAME)

    yield call(selectTool.setLengthParametersOfSelectedItem, lengths)
}

export function* watchForLengthChanges(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest([setWidth.type, setHeight.type], modifySelectedItemLength)
}

import { createAction } from '@reduxjs/toolkit'
import { put, select, takeEvery } from 'redux-saga/effects'
import { selectDisableSelectTool, setDisableSelectTool } from '../../slices/tools'

export const toggleDisableSelectToolState = createAction('updateSelectToolState')

export function* handleToggleSelectToolState() {
    const disableSelectTool = yield select(selectDisableSelectTool)
    yield put(setDisableSelectTool(!disableSelectTool))
}

export function* watchForSetSelectToolState() {
    yield takeEvery(toggleDisableSelectToolState.type, handleToggleSelectToolState)
}

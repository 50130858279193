import { Coordinates2D, PaperToolConfig } from '../../../../../types'
import { PathTool } from '../path/Path.tool'

export class PolygonTool extends PathTool {
    static NAME = 'POLYGON'

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = PolygonTool.NAME
    }

    public createPolygon = (coordinates: Coordinates2D) => {
        const path = this.createPath(coordinates)
        path.closed = true
        return path
    }
}

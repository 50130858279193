import { select, SelectEffect } from 'redux-saga/effects'
import {
    ActiveGeometryGroup,
    GeometricDrawable,
    selectActiveGeometryGroup,
    selectDrawableGeometries,
} from '../../slices/geometry'

export type ResetDrawableHighlightStateType = Generator<
    SelectEffect | (GeometricDrawable | undefined),
    void,
    GeometricDrawable[] & ActiveGeometryGroup & (GeometricDrawable | undefined)
>

export function* resetDrawableHighlightState(path: paper.PathItem | paper.Item): ResetDrawableHighlightStateType {
    const allDrawables: GeometricDrawable[] = yield select(selectDrawableGeometries)
    const activeDrawableGroup: ActiveGeometryGroup = yield select(selectActiveGeometryGroup)

    // Check if there are currently any active drawables at all
    const currentDrawable: GeometricDrawable | undefined = yield allDrawables.find(
        (d) => d.id === path.data.drawable_id
    )

    if (activeDrawableGroup && currentDrawable) {
        // Filter down only the ones that have the current path's id
        const alpha = currentDrawable.isActive ? path.data.activeOpacityValue : path.data.inactiveOpacityValue
        if (path.fillColor) path.fillColor.alpha = alpha
        if (path.strokeColor && path.data.originalStrokeWidth !== 0) {
            const newStrokeColor = path.data.originalStrokeColor as paper.Color
            newStrokeColor.alpha = alpha
            path.strokeColor = newStrokeColor
        }
    } else {
        if (path.fillColor) path.fillColor.alpha = path.data.originalOpacityValue
        if (path.strokeColor && path.data?.originalStrokeColor && path.data?.originalOpacityValue) {
            const color: paper.Color = path.data.originalStrokeColor
            color.alpha = path.data.originalOpacityValue
            path.strokeColor = color
        }
    }
}

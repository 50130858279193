import { UiSchema } from '@rjsf/core'
import { FormProperties } from '../imup/types'
import { deckOrPorch } from '../utils/formSchema/constants'

export const uiSchema: UiSchema = {
    size: {
        'ui:title': 'Steel Beam Size',
        'ui:placeholder': 'Select Size',
    },
    size_other: {
        'ui:title': 'Size Other',
        'ui:placeholder': 'Enter Size',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
    bundle_location: {
        'ui:placeholder': 'Select Bundle Location',
    },
    bundle_floor_level: {
        'ui:placeholder': 'Select Bundle Floor Level',
    },
    bundle_other_floor_level: {
        'ui:placeholder': 'Enter Bundle Other Floor Level',
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    newSettings.size_other = formData.size === 'other' ? formData.size_other : ''

    if (!deckOrPorch.includes(formData.bundle_name)) {
        newSettings.bundle_floor_level = null
    }

    if (formData.bundle_floor_level !== 'other') {
        newSettings.bundle_other_floor_level = null
    }

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form

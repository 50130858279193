import { createAction, createSelector } from '@reduxjs/toolkit'
import { ForkEffect, all, call, select, takeEvery } from 'redux-saga/effects'
import { ActiveFloor } from '../../../models/activeFloor'
import { AIAutomatedObject, AISuggestion } from '../../../models/aiClassifications'
import { getDrawableActiveFloor } from '../../../reducers/drawable'
import { RootState } from '../../../stores'
import { parseAiDataIntoDrawableSuggestions } from '../../../utils/aiSuggestions/aiParser'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Color, Count, PathTool, PolygonTool, Workspace } from '../../lib/toolBoxes/2D'
import { getCurrentDrawableLocations, selectAiSuggestionsForPage } from '../../slices/2D'
import { ToolsState } from '../../slices/tools'
import { IMUP2DDrawableLocation } from '../../types'
import drawAiSuggestionsByType from './drawAiSuggestionsByType'
import { selectMarkupState } from './markupDocument'

export const drawAISuggestions = createAction('drawAISuggestions')

type DrawAISuggestionsTools = [Color, PathTool, Count, PolygonTool, Workspace]

interface DAISuggestionStoreState {
    activeFloor: ActiveFloor
    drawableLocations: IMUP2DDrawableLocation[]
    areaOpacity: ToolsState['areaOpacityValue']
    lineOpacity: ToolsState['lineOpacityValue']
}

export const selectAISuggestionState = createSelector(
    (state: RootState): DAISuggestionStoreState => ({
        activeFloor: getDrawableActiveFloor(state.drawable),
        drawableLocations: getCurrentDrawableLocations(state.IMUP),
        areaOpacity: state.IMUP.tools.areaOpacityValue,
        lineOpacity: state.IMUP.tools.lineOpacityValue,
    }),
    (state: DAISuggestionStoreState): DAISuggestionStoreState => state
)

export function* handleDrawAISuggestions() {
    const paperManager: PaperManager | null = yield call(managers.get2DManager)

    if (!paperManager) return

    // get markup state from redux store
    const { areaOpacity, lineOpacity, drawableLocations, activeFloor }: ReturnType<typeof selectMarkupState> =
        yield select(selectAISuggestionState)

    // get tools from manager
    const [colorTool, pathTool, pointTool, polygonTool, workspaceTool]: DrawAISuggestionsTools = yield call(
        paperManager.getTools,
        [Color.NAME, PathTool.NAME, Count.NAME, PolygonTool.NAME, Workspace.NAME]
    )

    //remove all already Drawn Ai Suggestions before
    const alreadyDrawnAiSuggestions: paper.Item[] = yield call(
        workspaceTool.getItemsWithCriteria,
        'data',
        (data) => data?.aiSuggestion?.id
    )

    alreadyDrawnAiSuggestions.forEach((item) => item.remove())

    // handle AI drawables suggestions
    const aiSuggestions: AIAutomatedObject[] = yield select(selectAiSuggestionsForPage, activeFloor.id)
    if (aiSuggestions?.length) {
        // Filter deleted & used suggestions
        const aiSuggestionsIdSet: Set<string> = new Set(drawableLocations.map((l) => l.ai_suggestion_id))
        const relevantSuggestions: AIAutomatedObject[] = aiSuggestions.filter(
            (aiDrawable) => !aiDrawable.isDeleted && !aiSuggestionsIdSet.has(aiDrawable.id)
        )
        const aiDrawables: AISuggestion[] = parseAiDataIntoDrawableSuggestions(relevantSuggestions)

        yield all(
            aiDrawables.map((drawable) =>
                call(
                    drawAiSuggestionsByType,
                    drawable,
                    colorTool,
                    pointTool,
                    pathTool,
                    polygonTool,
                    workspaceTool,
                    areaOpacity,
                    lineOpacity
                )
            )
        )
    }
}

export function* watchForDrawAISuggestions(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(drawAISuggestions.type, handleDrawAISuggestions)
}

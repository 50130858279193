import { call, select, StrictEffect } from 'redux-saga/effects'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Select, Workspace } from '../../lib/toolBoxes/2D'
import { ActiveGeometryGroup, GeometricDrawable, selectActiveGeometryGroup } from '../../slices/geometry'

export function* handleToggleActiveDrawable2D(
    drawableId: number | number[]
): Generator<
    StrictEffect,
    void,
    (PaperManager | null) & Workspace & Select & Array<GeometricDrawable> & paper.Path & (ActiveGeometryGroup | null)
> {
    const manager: PaperManager | null = yield call(managers.get2DManager)
    if (!manager) return
    const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)
    const selectTool: Select = yield call(manager.getTool, Select.NAME)

    const activeDrawableGroup: ActiveGeometryGroup | null = yield select(selectActiveGeometryGroup)

    if (!activeDrawableGroup) return

    const drawable: GeometricDrawable | undefined = activeDrawableGroup.openings.find(
        (opening) => opening.id === drawableId
    )

    if (!drawable) return

    const item: paper.Path | null = yield call(workspaceTool.getItemWithDrawableId, drawable.id)

    // It is possible that getItemWithDrawableId returns a non-path item (a group for example)
    // In this case, we do not want to make any styling changes
    if (item) {
        item.data.isActive = drawable.isActive
        if (!drawable.isActive) {
            // Otherwise, if it is not active we don't want to have it selected for editing when it is toggled
            yield call(selectTool.removeHandles)
        }

        // Either way, update the styling to reflect its toggled status
        yield call(formatItemWithSelectedStatus, workspaceTool, selectTool, drawable, item)
    }
}

export function* formatItemWithSelectedStatus(
    workspaceTool: Workspace,
    selectTool: Select,
    drawable: GeometricDrawable,
    drawableItem?: paper.Path | null
) {
    const item: paper.Path | null =
        drawableItem !== undefined ? drawableItem : yield call(workspaceTool.getItemWithDrawableId, drawable.id)

    if (!item) return

    if (!drawable.isActive) yield call(selectTool.removeHandles)

    const colorAlpha = drawable.isActive ? item.data.activeOpacityValue : item.data.inactiveOpacityValue
    if (item.fillColor) item.fillColor.alpha = colorAlpha
    if (item.strokeColor) item.strokeColor.alpha = colorAlpha
}

import isNull from 'lodash/isNull'
import { call, CallEffect } from 'redux-saga/effects'
import { setActiveDrawable } from '../../../actions/drawable'
import managers from '../../lib/managers'
import { Select } from '../../lib/toolBoxes/2D/tools'
import { ITool } from '../../types'
import { PaperManager } from './../../lib/managers/PaperManager'

export function* handleSetActiveDrawable2D(
    action: ReturnType<typeof setActiveDrawable>
): Generator<CallEffect<PaperManager | null> | CallEffect<ITool> | CallEffect<void>, void, PaperManager & Select> {
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (isNull(manager)) return

    if (isNull(action.payload.drawable)) {
        const select: Select = yield call(manager.getTool, Select.NAME)
        yield call(select.exitSelectMode)
    }
}

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import isNull from 'lodash/isNull'
import { ProjectStatus } from '../../models/project'
import { RootState } from '../../stores'
import { Cursors, ThreeDToTwoDRecord, VIEW_MODE } from '../types'
import { preparedDrawablesFailure } from './2D'
import { fetchModelStructureFailure } from './3D'
import { selectProjectWithId } from './project'

export type CommonState = {
    availableModes: Array<VIEW_MODE>
    activeMode: VIEW_MODE | null
    threeDToTwoDMappings: ThreeDToTwoDRecord | null
    error: string | null
    cursor: string
    tooltip: {
        title: string
        visible: boolean
        color: string
    }
    toolbarMsg: string | null
    projectStatuses: ProjectStatus[]
    isLoading: boolean
}

export const initialCommonState: CommonState = {
    availableModes: [],
    activeMode: null,
    error: null,
    cursor: Cursors.AUTO,
    threeDToTwoDMappings: null,
    tooltip: {
        title: '',
        visible: false,
        color: '#ffffff',
    },
    toolbarMsg: null,
    projectStatuses: [],
    isLoading: false,
}

export function handleAddMode(state: CommonState, action: PayloadAction<VIEW_MODE | null>): void {
    if (isNull(action.payload)) {
        state.activeMode = null
        return
    }

    if (!state.availableModes.includes(action.payload)) {
        state.availableModes.push(action.payload)
        state.availableModes.sort((modeA, modeB) => {
            return modeA > modeB ? 1 : -1
        })
    }

    if (!state.activeMode) {
        state.activeMode = action.payload
    }
}

export function handleRemoveMode(state: CommonState, action: PayloadAction<VIEW_MODE>): void {
    state.availableModes = state.availableModes.filter((mode) => mode !== action.payload)
}

export function handleEnableIMUP(state: CommonState, { payload: selectedMode }: PayloadAction<VIEW_MODE>): void {
    if (state.availableModes.includes(selectedMode)) {
        state.activeMode = selectedMode
    } else {
        state.error = `Something went wrong: ${selectedMode} experience not available.`
    }
}

function handleSetCursor(state: CommonState, action: PayloadAction<string>): void {
    state.cursor = action.payload
}

function handleUpdating3Dto2DMappings(state: CommonState, action: PayloadAction<ThreeDToTwoDRecord | null>): void {
    state.threeDToTwoDMappings = action.payload
}

function handleShowTooltip(state: CommonState, action: PayloadAction<{ color: string; title: string }>): void {
    const { title, color } = action.payload
    state.tooltip.visible = true
    state.tooltip.title = title
    state.tooltip.color = color
}

function handleHideTooltip(state: CommonState): void {
    state.tooltip.visible = false
    state.tooltip.title = ''
    state.tooltip.color = ''
}

function handleToolbarMsg(state: CommonState, action: PayloadAction<string | null>): void {
    state.toolbarMsg = action.payload
}

function handleSetLoading(state: CommonState, action: PayloadAction<boolean>): void {
    state.isLoading = action.payload
}

const commonSlice = createSlice({
    name: 'common',
    initialState: initialCommonState,
    reducers: {
        enableIMUP: handleEnableIMUP,
        availableMode: handleAddMode,
        disabledMode: handleRemoveMode,
        update3DTo2DMappings: handleUpdating3Dto2DMappings,
        setCursor: handleSetCursor,
        showTooltip: handleShowTooltip,
        hideTooltip: handleHideTooltip,
        updateToolbarMessage: handleToolbarMsg,
        setLoading: handleSetLoading,
    },
    extraReducers: (builder) => {
        builder.addCase(preparedDrawablesFailure, (state) => handleRemoveMode(state, disabledMode(VIEW_MODE.Markup2D)))
        builder.addCase(selectProjectWithId, (state) => {
            state.activeMode = null
            state.availableModes = []
        })
        builder.addCase(fetchModelStructureFailure, (state) =>
            handleRemoveMode(state, disabledMode(VIEW_MODE.Markup3D))
        )
    },
})

export const IMUPMappingsSelector = createSelector(
    (state: RootState) => {
        return state.IMUP.common.threeDToTwoDMappings
    },
    (mappings) => mappings
)

export const selectActiveMode = createSelector(
    (state: RootState) => state.IMUP.common.activeMode,
    (activeMode) => activeMode
)

export type TooltipStoreState = {
    visible: boolean
    title: string
    color: string
}

export const selectTooltipState = createSelector(
    ({ IMUP }: RootState) => IMUP.common.tooltip,
    (state) => state
)

export const selectIsLoading = createSelector(
    (state: RootState) => state.IMUP.common.isLoading,
    (isLoading) => isLoading
)

export const {
    enableIMUP,
    update3DTo2DMappings,
    availableMode,
    disabledMode,
    setCursor,
    showTooltip,
    hideTooltip,
    updateToolbarMessage,
    setLoading,
} = commonSlice.actions

export default commonSlice

import axios from 'axios'
import { getAuthorizationHeader, getToken } from '../authConfig'
import { AzureFeatureFlag } from '../models/azureFeatureFlags'

const getUserCompanyAndLocationAccess = async (): Promise<any> => {
    if (!process.env.REACT_APP_PORTAL_COMPANIESACCESSCONTROL_API) {
        return Promise.resolve(null)
    }

    const customerApi = process.env.REACT_APP_PORTAL_COMPANIESACCESSCONTROL_API

    const userCompanyLocationAccess = `${customerApi}/mine `
    const authorization: string = await getAuthorizationHeader()

    return fetch(userCompanyLocationAccess, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: authorization,
        },
    })
        .then((res) => res.json())
        .then((res) => {
            if (res.error) {
                throw res.error
            }
            return res
        })
        .catch((error) => {
            console.log(error)
        })
}

const getUserPermissions = async (): Promise<any> => {
    if (!process.env.REACT_APP_PORTAL_PERMISSIONS) {
        return Promise.resolve(null)
    }

    // get and set the token before we make this api call
    await getToken()

    const permissionsApi = process.env.REACT_APP_PORTAL_PERMISSIONS

    const authorization: string = await getAuthorizationHeader()

    return fetch(permissionsApi, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: authorization,
        },
    })
        .then((res) => res.json())
        .then((res) => {
            if (res.error) {
                throw res.error
            }
            return res
        })
        .catch((error) => {
            console.log(error)
        })
}

const getAzureFeatureFlags = async (): Promise<AzureFeatureFlag[] | []> => {
    return await axios.get(`get-feature-flags`)
}

export { getUserCompanyAndLocationAccess, getUserPermissions, getAzureFeatureFlags }

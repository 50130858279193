import throttle from 'lodash/throttle'
import { convertAbsoluteDistanceToFormattedString } from '../../../../../../components/markup/utils/helpers'
import { DRAWING_TYPES } from '../../../../../../shared/constants/drawable-types'
import { applyScaleFactorToPathLength } from '../../../../../../utils/calculations/scaleConversion/scaleConversion'
import { Coordinates2D, MouseButtonCodes, PaperToolConfig } from '../../../../../types'
import { SNAPPING_TOLERANCE } from '../../../../../utils/constants'
import { getSnapPoint, snap } from '../../../../utils/Snapping'
import { PathTool } from '../path/Path.tool'

export class Line extends PathTool {
    static NAME = 'LINE'

    private static readonly NUM_POINTS_IN_LINE = 2

    private lineAndCircleColor: paper.Color = new this.paper.Color('blue')
    private linePoints: Coordinates2D = []
    private temporaryPointObjects: paper.Group[] = []
    private temporaryPath: paper.Path | null = null

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Line.NAME
    }

    private cleanUpPoints = (): void => {
        this.temporaryPointObjects.forEach((path) => {
            path.remove()
        })
        this.temporaryPointObjects = []
        this.linePoints = []
    }

    /**
     * Determines what the new point should be based on:
     *  1. Shift key pressed: snap to a specified angle
     *  2. Snapping enabled: hit test against the current point to see if it should snap to another item
     * @param eventPoint
     * @param isShiftKeyPressed
     * @returns
     */
    private calculateNewPoint = (eventPoint: paper.Point, isShiftKeyPressed: boolean): paper.Point => {
        let newPoint = eventPoint

        newPoint = isShiftKeyPressed
            ? this.calculateFixedAnglePoint(this.temporaryPointObjects[0].position, eventPoint)
            : newPoint

        return newPoint
    }

    /**
     * on cancel, if there is
     * any stray single points
     * clear those points
     */
    cancel = (): void => {
        this.cleanUpPoints()
        this.temporaryPath?.remove() // remove the temporary drawing path
        this.setState('common', { cursor: this.cursor, tooltip: { title: '', visible: false, color: '#000000' } })
    }

    updateMouseToolTipThrottled = throttle(
        (tooltip: { title: string; color: string; visible: boolean }) =>
            this.mediator.mediate('common', {
                tooltip,
            }),
        15
    )

    /**
     * on mouse move draw a new line that represents what the created line would look like
     * this helps the user understand where the line is
     */
    public onMouseMove = (event: paper.ToolEvent): void => {
        if (this.linePoints.length) {
            // remove the previous line that is no longer relevant
            this.temporaryPath?.remove() // remove the temporary drawing path

            const items = this.paper.project.getItems({
                data: (data) => data?.drawable_id || data?.aiSuggestion?.id,
            })

            // get the point when shift is pressed or without it
            const fixedPoint = this.calculateNewPoint(event.point, event['event'].shiftKey)

            // get the point when snapping is enabled
            const newPoint = this.snappingEnabled ? getSnapPoint(this.paper, items, fixedPoint) : fixedPoint

            // update the second point to keep the label up to date
            this.linePoints[1] = [newPoint.x, newPoint.y]

            this.temporaryPath = new this.paper.Path([this.temporaryPointObjects[0].position, newPoint])
            this.temporaryPath.strokeColor = this.lineAndCircleColor
            this.temporaryPath.strokeWidth = this.strokeWidth
            this.updateMouseToolTipThrottled({
                title: `${convertAbsoluteDistanceToFormattedString(
                    applyScaleFactorToPathLength({
                        pxValue: this.temporaryPath.length,
                        scaleFactor: this.scaleFactor,
                        dpi: this.getActiveDocumentChunk()?.dpi ?? null,
                        xCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_x ?? 1,
                        yCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_y ?? 1,
                        coordinates: this.linePoints,
                        pdfScale: this.getActiveDocumentChunk()?.pdf_scale ?? 1,
                    })
                )}`,
                visible: true,
                color: '#000000',
            })
        }
    }

    /**
     * On mouse up change the cursor back to the default
     * of the tool
     */
    onMouseUp = () => {
        this.setState('common', { cursor: this.cursor, tooltip: { title: '', visible: false, color: '#000000' } })
    }

    /**
     * On mouse drag if it is right click
     * or middle click then pan
     * @param event the paper mouse event
     * @returns
     */
    onMouseDrag = (event) => {
        if (this.toolPanning(event)) return
    }

    /**
     * Draw points on mouse down and once two points are
     * drawn create a path drawable and trigger the event
     * that will create a new drawable location from that
     * line, after that event is fired cleanup the points
     * previously drawn
     * @param event
     */
    public onMouseDown = (event: paper.ToolEvent): void => {
        // indicate pan is active on right click
        const clickCode = event['event']['button']

        if (this.isPanningClick(event)) return

        if (clickCode === MouseButtonCodes.Left) {
            this.temporaryPointObjects.push(this.renderPoint(event.point))

            const fixedPoint = this.calculateNewPoint(event.point, event['event'].shiftKey)

            let newPoint: paper.Point = fixedPoint

            if (this.snappingEnabled) {
                const snapPoint = snap(this.paper, fixedPoint, { tolerance: SNAPPING_TOLERANCE })

                if (snapPoint) {
                    newPoint = snapPoint
                }
            }

            if (this.linePoints.length === 2) {
                this.linePoints[1] = [newPoint.x, newPoint.y]
            } else {
                this.linePoints.push([newPoint.x, newPoint.y])
            }

            if (this.linePoints.length === Line.NUM_POINTS_IN_LINE) {
                this.temporaryPath?.remove() // remove the temporary drawing path

                const newPath = this.createPath(this.linePoints)

                newPath.strokeColor = this.lineAndCircleColor
                newPath.strokeWidth = this.strokeWidth
                if (newPath.data) newPath.data.shapeType = DRAWING_TYPES.SECTION

                this.cleanUpPoints()

                this.setState('2D', { drawablesToCreate: [newPath.id] })
            } else {
                this.setScaleFromPointClick(newPoint)
            }
        }
    }
}

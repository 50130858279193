import { call, CallEffect, put, PutEffect, select, SelectEffect } from 'redux-saga/effects'
import { ActiveFloor } from '../../../models/activeFloor'
import { IToolObject } from '../../../models/tool'
import { DEFAULT_SCALE_FACTOR } from '../../../shared/constants/scales'
import { Color, PathTool, Workspace } from '../../lib/toolBoxes/2D'
import addSelectFunctionalityToToolObjects from '../../lib/utils/functionality-bindings/addSelectFunctionalityToToolObjects'
import { selectActiveMappingAsFloor } from '../../slices/mappings'
import { changeStrokeWidth } from '../../slices/tools'
import { ItemScale, REGION_ENUMS, TOOL_TYPE_ENUMS } from '../../types'
import addMetadataToPath from './data-prep/addMetadataToPath'

type DrawToolObjectShapeByTypeYield =
    | CallEffect
    | CallEffect<paper.Color | paper.Path | paper.Raster>
    | PutEffect
    | SelectEffect

type DrawToolObjectShapeByTypeNext = string & paper.Color & (paper.Raster | null) & paper.Path & (ActiveFloor | null)

export default function* drawToolByType(
    toolObject: IToolObject,
    colorTool: Color,
    pathTool: PathTool,
    workspaceTool: Workspace,
    lineOpacity: number
): Generator<DrawToolObjectShapeByTypeYield, paper.Path, DrawToolObjectShapeByTypeNext> {
    // create color with color tool
    const shapeColor: paper.Color = yield call(colorTool.createColor, toolObject.color)

    // determine if this drawable is inside a region so we can scale it appropriately
    const regionPaths = workspaceTool.getItemsWithCriteria(
        'data',
        (data) => data.shapeType === REGION_ENUMS.TYPE
    ) as paper.Path[]

    let thisRegionGroup: paper.Path | null = null
    for (const regionPath of regionPaths) {
        for (const c of toolObject.coordinates) {
            if (!regionPath.contains(workspaceTool.generatePoint(c))) {
                break
            }
            thisRegionGroup = regionPath
        }
    }

    const activeFloor: ActiveFloor | null = yield select(selectActiveMappingAsFloor)

    const scaleFactor = thisRegionGroup?.data?.scale
        ? thisRegionGroup.data.scale
        : activeFloor?.scale_factor ?? DEFAULT_SCALE_FACTOR

    // create a paper.Path based on the drawing type which is related to tool type - point, area, or section/default
    let path: paper.Path
    switch (toolObject.type) {
        //section
        case TOOL_TYPE_ENUMS.FLOOR_LEVEL_BREAK_LINE:
        default:
            path = yield call(pathTool.createPath, toolObject.coordinates)

            if (toolObject.document_chunk_id) {
                path.data.document_chunk_id = toolObject.document_chunk_id
            }

            shapeColor.alpha = lineOpacity

            const raster: paper.Raster | null = yield call(workspaceTool.getPlanRaster)
            let strokeWidthScale = 100
            if (raster) {
                // Scale the lines based on the size of the image we are working with
                strokeWidthScale = workspaceTool.calculateStrokeWidthBasedOnRaster(raster)
                yield put({ type: changeStrokeWidth.type, payload: strokeWidthScale * ItemScale.LINE })
            }

            path.strokeColor = shapeColor
            path.strokeWidth = strokeWidthScale * ItemScale.LINE
            break
    }

    yield call(addMetadataToPath, path, scaleFactor, undefined, undefined, toolObject)
    yield call(addSelectFunctionalityToToolObjects, path)
    return path
}

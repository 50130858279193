import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootSaga from '../imup/sagas'
import IMUPReducer from '../imup/slices'
import { projectReducer } from '../reducers'
import { drawableReducer } from '../reducers/drawable'
import { userReducer } from '../reducers/user'

export const rootReducer = combineReducers({
    mapping: projectReducer,
    drawable: drawableReducer,
    user: userReducer,
    IMUP: IMUPReducer,
})

const sagaMiddleware = createSagaMiddleware()

/**
 ** @reduxjs/toolkit configureStore method
 ** preserves typings for middleware and
 ** provides devtools. Includes thunks out of the box.
 */
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            // Disable ImmutableStateInvariantMiddleware
            serializableCheck: false,
            immutableCheck: process.env.REACT_APP_IMMUTABILITY_CHECK === 'true',
        }).concat(sagaMiddleware),
    devTools: process.env.REACT_APP_ENVIRONMENT !== 'production',
})

sagaMiddleware.run(rootSaga)

/**
 ** Types for Redux store state and dispatch method that is aware of
 ** middleware implementations
 */
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store

// Why is this not in the redux store?
export class ImageCoordinates {
    public static positionX = 0
    public static positionY = 0
}

import { FormValidation, UiSchema } from '@rjsf/core'
import { FormProperties } from '../imup/types'
import { validateGivenFieldsAreFractions } from './validation/fractionValidation'
import { generateFeetAndInchesString } from '../components/markup/utils/helpers'
import { deckOrPorch } from '../utils/formSchema/constants'

const defaultNumberWide = '1'
const labelOther = 'OTHER'
const interiorDoorFlag = process.env.REACT_APP_INTERIOR_DOOR_FLAG === 'true'

export const uiSchema: UiSchema = {
    number_wide: {
        'ui:widget': 'hidden',
    },
    room_location: {
        'ui:widget': 'hidden',
    },
    width_other: {
        'ui:title': 'Other Door Width',
        'ui:field': 'FeetAndInchesField',
    },
    height_other: {
        'ui:title': 'Other Door Height',
        'ui:field': 'FeetAndInchesField',
    },
    handling: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    type: {
        'ui:placeholder': 'Select Door Type',
    },
    width: {
        'ui:placeholder': 'Select Door Width',
    },
    height: {
        'ui:placeholder': 'Select Door Height',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
    bundle_location: {
        'ui:placeholder': 'Select Bundle Location',
    },
    bundle_floor_level: {
        'ui:placeholder': 'Select Bundle Floor Level',
    },
    bundle_other_floor_level: {
        'ui:placeholder': 'Enter Bundle Other Floor Level',
    },
    jamb_wall_depth: {
        'ui:widget': interiorDoorFlag ? 'select' : 'hidden',
        'ui:placeholder': 'Select Jamb Wall Depth',
    },
    lockset: {
        'ui:widget': interiorDoorFlag ? 'select' : 'hidden',
        'ui:placeholder': 'Select Lockset',
    },
    'ui:order': [
        'number_wide',
        'room_location',
        'type',
        'jamb_wall_depth',
        'lockset',
        'width',
        'width_other',
        'height',
        'height_other',
        'casing',
        'handling',
    ],
}

const onValidate = (formData: any, errors: FormValidation) => {
    if (formData.width === labelOther) validateGivenFieldsAreFractions(['width_other'], formData, errors)
    if (formData.height === labelOther) validateGivenFieldsAreFractions(['height_other'], formData, errors)
}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }
    const type = newSettings.type.toLowerCase()

    newSettings.number_wide = parseInt(defaultNumberWide, 10)

    if (newSettings.width === labelOther) {
        newSettings.width = generateFeetAndInchesString(newSettings.width_other)
    }

    newSettings.width_other = null

    if (newSettings.height === labelOther) {
        newSettings.height = generateFeetAndInchesString(newSettings.height_other)
    }

    newSettings.height_other = null

    if (type !== 'standard') {
        newSettings.handling = null
    }

    if (!deckOrPorch.includes(formData.bundle_name)) {
        newSettings.bundle_floor_level = null
    }

    if (formData.bundle_floor_level !== 'other') {
        newSettings.bundle_other_floor_level = null
    }

    if (!newSettings.type || newSettings.type === 'Cased Opening') {
        newSettings.jamb_wall_depth = null
        newSettings.lockset = null
    }

    if (
        newSettings.type === 'Barn Door' ||
        newSettings.type === 'Pocket Door' ||
        newSettings.type === 'Bypass' ||
        newSettings.type === 'Double Bypass'
    ) {
        newSettings.jamb_wall_depth = null
    }

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form

import { PayloadAction } from '@reduxjs/toolkit'
import { call, fork, put, SagaReturnType, select, StrictEffect, takeLatest } from 'redux-saga/effects'
import {
    FETCH_OPENING_GROUPS_PENDING,
    FETCH_OPENING_GROUPS_SUCCESS,
    FILTER_DRAWABLE_GROUPS,
    toggleAllPagesView,
} from '../../../../actions/drawable'
import {
    fetchAllProjectDocumentMappings,
    fetchProjectDocumentChunks,
    getAIsuggestions,
} from '../../../../api/projects-api'
import { fetchOpeningGroupsByProjectId, getAllFloors } from '../../../../api/takeoff-api'
import { OpeningGroupAPI } from '../../../../models/activeDrawable'
import { AIAutomatedObject } from '../../../../models/aiClassifications'
import { Project } from '../../../../models/project'
import { IToolObject } from '../../../../models/tool'
import { getProject } from '../../../../reducers/drawable'
import { PROJECT_TYPES_ENUM } from '../../../../shared/constants/project-type.constants'
import { RootState } from '../../../../stores'
import { preparedDrawablesFailure, preparedDrawablesSuccess, reset2DErrors, setAiSuggestions } from '../../../slices/2D'
import { availableMode } from '../../../slices/common'
import {
    initializeDocumentChunks,
    initializeProjectDocumentsFromChunks,
    selectDocumentChunks,
    selectDocumentMappings,
    updateDocumentMappings,
} from '../../../slices/documents'
import { requestCompleted, requestPending } from '../../../slices/loading'
import { setAllPossibleMappings } from '../../../slices/mappings'
import { selectProjectWithId } from '../../../slices/project'
import { updateRegions } from '../../../slices/region'
import { IMUP2DDrawableLocation, OpeningWithGroupId, VIEW_MODE } from '../../../types'
import { prepare3DModel } from '../../3D/data-prep/prepare3DModel'
import { convertOpeningGroupsToDrawableLocations } from './convertOpeningsToDrawableLocations'

type Prepare2DMarkupYield = StrictEffect | Array<number> | boolean

export function selectProject({ drawable }: RootState): Project {
    return getProject(drawable)
}

type Prepare2DMarkupNext = Array<OpeningWithGroupId> &
    Array<OpeningGroupAPI> &
    Array<IMUP2DDrawableLocation> &
    Array<number> &
    boolean &
    Project &
    AIAutomatedObject[] &
    IToolObject[]

export function* fetchDocumentChunksAndMappings(project: Project) {
    // If theres no project, we cant do anything
    if (!project.id) return

    yield put(requestPending())
    // Get and save document mappings for this project
    const mappings: SagaReturnType<typeof fetchAllProjectDocumentMappings> = yield call(
        fetchAllProjectDocumentMappings,
        project.id
    )
    yield put(updateDocumentMappings(mappings))
    yield put(requestCompleted())

    // Get and save document chunks for this project
    yield put(requestPending())
    const chunks: SagaReturnType<typeof fetchProjectDocumentChunks> = yield call(fetchProjectDocumentChunks, project.id)
    yield put(initializeDocumentChunks(chunks))
    yield put(initializeProjectDocumentsFromChunks(chunks))
    const regions = chunks.flatMap((chunk) => chunk.regions)
    yield put(updateRegions(regions))
    yield put(requestCompleted())

    // Get and save floors for this project
    yield put(requestPending())
    const floors: SagaReturnType<typeof getAllFloors> = yield call(getAllFloors)
    yield put(setAllPossibleMappings(floors))
    yield put(requestCompleted())
}

export function* prepareMarkupData(
    action: PayloadAction<number>
): Generator<Prepare2DMarkupYield, void, Prepare2DMarkupNext> {
    try {
        // Reset the error state
        yield put(reset2DErrors())

        const project: Project = yield select(selectProject)

        // Update loading/pending state of opening groups
        yield put({ type: FETCH_OPENING_GROUPS_PENDING })

        // Fetch opening groups from api
        const openingGroups: Array<OpeningGroupAPI> = yield call(
            fetchOpeningGroupsByProjectId,
            project.id,
            project.currentModelId
        )

        // Original IMUP: pass api response to original IMUP reducer
        yield put({ type: FETCH_OPENING_GROUPS_SUCCESS, payload: openingGroups })

        // Original IMUP: filter drawable groups based on active floor
        yield put({ type: FILTER_DRAWABLE_GROUPS })

        // Get the project and populate chunks and mappings if they are not already present
        const documentMappings = yield select(selectDocumentMappings)
        const documentChunks = yield select(selectDocumentChunks)

        if (project?.type === PROJECT_TYPES_ENUM.INTERACTIVE_MARKUP_ONLY && !documentMappings && !documentChunks) {
            yield fork(fetchDocumentChunksAndMappings, project)
        }

        // Convert opening groups to drawable locations with appropriate metadata
        const drawableLocations: Array<IMUP2DDrawableLocation> = yield call(
            convertOpeningGroupsToDrawableLocations,
            openingGroups
        )
        // Retrieve the unique set of document chunk IDs for all locations
        const documentChunkIds: Array<number> = yield [
            ...new Set<number>(drawableLocations.map((location) => location.document_chunk_id)),
        ]

        // Provide drawable locations and unique document chunk IDs to the store
        yield put(preparedDrawablesSuccess({ drawableLocations, documentChunkIds }))

        if (project.is3D) {
            // Hide all pages view
            yield put(toggleAllPagesView(false))
            // Start procedure to prepare 3D structure and geometries
            yield fork(prepare3DModel)
        } else {
            // 2D view mode active
            yield put(availableMode(VIEW_MODE.Markup2D))

            if (!project.id) return
            // Call AI suggestions api
            const aiSuggestions: AIAutomatedObject[] = yield call(getAIsuggestions, project.id)
            if (!aiSuggestions || !aiSuggestions.length) return
            yield put(setAiSuggestions(aiSuggestions))
        }
    } catch (error) {
        // Handle errors generated during procedure
        if ((error as any).actionToCall) {
            yield put((error as any).actionToCall((error as any).message))
        } else {
            // Capture general errors
            yield put(preparedDrawablesFailure((error as any).message))
        }
        yield put(availableMode(null))
    }
}

export function* watchForSelectedProject() {
    yield takeLatest(selectProjectWithId.type, prepareMarkupData)
}

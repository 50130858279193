import { createAction } from '@reduxjs/toolkit'
import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects'
import { FETCH_OPENING_GROUPS_SUCCESS } from '../../../actions/drawable'
import { updateDocumentChunkCalibrationFactor } from '../../../api/projects-api'
import { fetchOpeningGroupsByProjectId } from '../../../api/takeoff-api'
import { OpeningGroupAPI } from '../../../models/activeDrawable'
import { DocumentChunk } from '../../../models/documentChunk'
import { Region } from '../../../models/region'
import { isVertical } from '../../components/shared/CalibrationSetModal.component'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Select } from '../../lib/toolBoxes/2D'
import { preparedDrawablesSuccess, selectRegionId } from '../../slices/2D'
import { selectActiveDocumentChunk, updateSingleDocumentChunk } from '../../slices/documents'
import { selectProjectId } from '../../slices/project'
import { selectCalibrationLineCoords, setCalibrationLineLength, setCalibrationRatio } from '../../slices/tools'
import { IMUP2DDrawableLocation } from '../../types'
import { convertOpeningGroupsToDrawableLocations } from './data-prep/convertOpeningsToDrawableLocations'

export const saveUpdatedCalibrationFactorAction = createAction<{
    ratio: number
    totalExpectedInches: number
    lengthInPixels: number
}>('saveUpdatedCalibrationFactor')

export type UpdateCalibrationInput = {
    xCalibrationLength?: number
    yCalibrationLength?: number
    xPixelLength?: number
    yPixelLength?: number
    xCalibrationFactor?: number
    yCalibrationFactor?: number
}

export function* saveUpdatedCalibrationFactorEffect({
    payload,
}: ReturnType<typeof saveUpdatedCalibrationFactorAction>): Generator<
    StrictEffect,
    void,
    PaperManager &
        DocumentChunk &
        Array<OpeningGroupAPI> &
        number &
        Array<IMUP2DDrawableLocation> &
        Region['id'] &
        number[][]
> {
    try {
        const manager: PaperManager = yield call(managers.get2DManager)

        if (!manager) return

        const activeDocumentChunk = yield select(selectActiveDocumentChunk)
        const currentRegionId: Region['id'] | null = yield select(selectRegionId)
        const calibrationCoords: number[][] = yield select(selectCalibrationLineCoords)

        const isCalibrationVertical = isVertical(calibrationCoords[0][0], calibrationCoords[1][0])

        const apiInput: UpdateCalibrationInput = {
            xCalibrationFactor: isCalibrationVertical ? undefined : payload.ratio,
            yCalibrationFactor: isCalibrationVertical ? payload.ratio : undefined,
            xCalibrationLength: isCalibrationVertical ? undefined : payload.totalExpectedInches,
            yCalibrationLength: isCalibrationVertical ? payload.totalExpectedInches : undefined,
            xPixelLength: isCalibrationVertical ? undefined : payload.lengthInPixels,
            yPixelLength: isCalibrationVertical ? payload.lengthInPixels : undefined,
        }

        // Update the chunk in the database
        const updatedDocumentChunkResponse: DocumentChunk = yield call(
            updateDocumentChunkCalibrationFactor,
            activeDocumentChunk.id,
            currentRegionId,
            apiInput
        )

        if (!updatedDocumentChunkResponse) {
            throw new Error('Error: Error updating document chunk calibration factor')
        }

        const projectId: number = yield select(selectProjectId)

        if (!projectId) return

        // Fetch opening groups from api
        const openingGroups: Array<OpeningGroupAPI> = yield call(fetchOpeningGroupsByProjectId, projectId)

        // Original IMUP: pass api response to original IMUP reducer
        yield put({ type: FETCH_OPENING_GROUPS_SUCCESS, payload: openingGroups })

        // Convert opening groups to drawable locations with appropriate metadata
        const drawableLocations: Array<IMUP2DDrawableLocation> = yield call(
            convertOpeningGroupsToDrawableLocations,
            openingGroups
        )

        // Retrieve the unique set of document chunk IDs for all locations
        const documentChunkIds: Array<number> = [
            ...new Set(drawableLocations.map((location) => location.document_chunk_id)),
        ]

        // Provide drawable locations and unique document chunk IDs to the store
        yield put(preparedDrawablesSuccess({ drawableLocations, documentChunkIds }))

        // Update the chunk in the store
        yield put(updateSingleDocumentChunk(updatedDocumentChunkResponse))

        yield put(setCalibrationRatio(payload.ratio))
        yield put(setCalibrationLineLength(null))

        yield call(manager.useTool, Select.NAME)
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}

export function* watchForSaveUpdatedCalibrationFactor() {
    yield takeEvery(saveUpdatedCalibrationFactorAction.type, saveUpdatedCalibrationFactorEffect)
}

import { Mesh, VertexData } from '@babylonjs/core'
import { BabylonToolConfig } from '../../../../../types'
import BabylonTool from '../babylonTool/BabylonTool'

/**
 * Tool that takes in a mesh geometry and manages adding
 * it to the root mesh of the scene
 */
export class CustomMesh extends BabylonTool {
    static NAME = 'CUSTOM_MESH'
    static REDUCED_VISIBILITY = 0.4

    constructor(config: BabylonToolConfig) {
        super(config)
        this.name = CustomMesh.NAME
        this.registerActivationFunctions([])
    }

    private createVertexData = (vertices: number[], indices: number[], uvs?: number[]): VertexData => {
        const vertexData = new VertexData()
        vertexData.positions = vertices
        vertexData.indices = indices

        if (uvs && uvs.length) {
            vertexData.uvs = uvs
        }

        const normals: number[] = []
        VertexData.ComputeNormals(vertexData.positions, vertexData.indices, normals)
        vertexData.normals = normals

        return vertexData
    }

    /**
     * Creates a custom mesh from the geometry
     * and attach the associated mesh options
     * @param {Object} meshData mesh data object
     * @param {number[]} meshData.vertices the mesh vertex data
     * @param {number[]} meshData.indices the indices of mesh to create
     * @param meshOptions (optional) mesh options to attach to the mesh
     * @returns
     */
    public create = (
        { vertices, indices }: { vertices: number[]; indices: number[] },
        meshOptions?: Partial<Mesh>
    ): Mesh => {
        const vertexData = this.createVertexData(vertices, indices)

        const mesh = new Mesh('mesh', this.scene)
        vertexData.applyToMesh(mesh, CustomMesh.DO_NOT_FLAG_VERTEX_DATA_AS_UPDATABLE)

        if (meshOptions) {
            Object.assign(mesh, meshOptions)
        }

        mesh.freezeWorldMatrix()
        mesh.doNotSyncBoundingInfo = true

        return mesh
    }
}

export default CustomMesh

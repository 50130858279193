import { Cursors, PaperToolConfig } from '../../../../../types'
import Measure from '../measure/Measure.tool'

/**
 * Calibrate.tool.tsx
 * Used to set a calibration factor between 2 points on a plan
 */
export class Calibrate extends Measure {
    static NAME = 'CALIBRATE'
    static CURSOR = Cursors.CROSSHAIR

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Calibrate.NAME
        this.cursor = Calibrate.CURSOR
    }

    onMouseUp = (): void => {
        if (this.path && this.path.length > 0) {
            const coordinates = this.path.segments.map((segment) => [segment.point.x, segment.point.y])

            this.mediator.mediate('tools', {
                calibrationLineLength: this.path.length,
                calibrationLineCoords: coordinates,
            })
        }
    }
}

export default Calibrate

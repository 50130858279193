import { RootState } from '../../../stores'
import { call } from 'redux-saga/effects'
import { isNonAreaJoistLine } from '../../../utils/project/project-helper-functions'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Workspace } from '../../lib/toolBoxes/2D'
import { getHiddenAndVisibleGroups } from '../utils/getHiddenAndVisibleGroups'
import { selectDrawableGroupsGeometries } from '../../slices/geometry'

export function selectDrawableGroups(state: RootState) {
    return selectDrawableGroupsGeometries(state)
}

export function* handleToggleDrawableGroups2D() {
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (!manager) return

    const { hiddenDrawableGroupIds } = yield call(getHiddenAndVisibleGroups)

    const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)

    const visibilityTest = (item: paper.Item): boolean =>
        !(hiddenDrawableGroupIds.includes(item.data.opening_group_id) || isNonAreaJoistLine(item))
    yield call(workspaceTool.hideDrawablesWithCondition, visibilityTest)
}

import { PayloadAction } from '@reduxjs/toolkit'
import { fork, select, takeEvery } from 'redux-saga/effects'
import { toggleActiveDrawableGroup, TOGGLE_ACTIVE_DRAWABLE_GROUP } from '../../../actions/drawable'
import { selectActiveMode } from '../../slices/common'
import { sceneRendered } from '../../slices/loading'
import { VIEW_MODE } from '../../types'
import { handleToggleActiveDrawableGroups2D } from '../2D/handleToggleActiveDrawableGroups'
import { handleToggleActiveDrawableGroups3D } from '../3D/handleToggleActiveDrawableGroups'

export function* handleToggleActiveDrawableGroup(
    action: ReturnType<typeof toggleActiveDrawableGroup> | PayloadAction<undefined>
) {
    const activeMode = yield select(selectActiveMode)
    switch (activeMode) {
        case VIEW_MODE.Markup2D:
            yield fork(handleToggleActiveDrawableGroups2D)
            break
        case VIEW_MODE.Markup3D:
            if (action.payload) yield fork(handleToggleActiveDrawableGroups3D, action)
            break
        default:
            break
    }
}

export function* watchForToggleActiveDrawableGroup() {
    yield takeEvery([TOGGLE_ACTIVE_DRAWABLE_GROUP, sceneRendered.type], handleToggleActiveDrawableGroup)
}

import React from 'react'
import ModalDialogComponent from '../../shared/components/modal-dialog/modal-dialog'

// Error boundaries are handling run-time errors
export function ErrorFallback({ error, resetErrorBoundary }) {
    const code = error?.response?.status
    // Display run-time or back-end massage
    const msg = code ? error.response?.data?.errors?.map((err) => <div>{err.message}</div>) : error.message

    const body = (
        <>
            <div>{code ? <b>Code: {code}</b> : ''}</div>
            <br></br>
            <div>{msg ? <b>Message: {msg}</b> : ''}</div>
        </>
    )

    return <ModalDialogComponent title="Error" body={body} closeButton handleClose={resetErrorBoundary} />
}

import { FormValidation, UiSchema } from '@rjsf/core'
import { FormProperties } from '../imup/types'
import { eaveGableLengthFormSubmitCleanUp } from '../shared/services/form-schema/eave-gable-length'

const uiSchema: UiSchema = {
    label: {
        'ui:title': 'Label',
        'ui:widget': 'hidden',
    },
    name: {
        'ui:readonly': true,
        'ui:widget': 'hidden',
    },
    cornice_crown_molding: {
        'ui:title': 'Has Cornice Crown Molding?',
        'ui:field': 'SwitchField',
        'ui:options': {
            inline: true,
        },
    },
    non_vented_eave: {
        'ui:title': 'Has Non-Vented Eave?',
        'ui:field': 'SwitchField',
        'ui:options': {
            inline: true,
        },
    },
    folder: {
        'ui:readonly': true,
        'ui:widget': 'hidden',
    },
    width: {
        'ui:placeholder': 'Select Eave Width (In)',
    },
    width_other: {
        'ui:placeholder': 'Other Eave Width (In)',
    },
    fascia_type: {
        'ui:placeholder': 'Select Fascia Type 1',
    },
    fascia_type_2: {
        'ui:placeholder': 'Select Fascia Type 2',
    },
    fascia_thickness_1: {
        'ui:placeholder': 'Select Fascia Thickness 1',
    },
    fascia_thickness_2: {
        'ui:placeholder': 'Select Fascia Thickness 2',
    },
    frieze_type: {
        'ui:placeholder': 'Select Frieze Type 1',
    },
    frieze_type_2: {
        'ui:placeholder': 'Select Frieze Type 2',
    },
    frieze_thickness_1: {
        'ui:placeholder': 'Select Frieze Thickness 1',
    },
    frieze_thickness_2: {
        'ui:placeholder': 'Select Frieze Thickness 2',
    },
    fc_texture: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    fascia_texture: {
        'ui:placeholder': 'None',
    },
    fascia_texture_2: {
        'ui:placeholder': 'None',
    },
    frieze_texture: {
        'ui:placeholder': 'None',
    },
    frieze_texture_2: {
        'ui:placeholder': 'None',
    },
    fascia_width_1: {
        'ui:placeholder': 'Select Width 1',
    },
    fascia_width_2: {
        'ui:placeholder': 'Select Width 2',
    },
    frieze_width_1: {
        'ui:placeholder': 'Select Width 1',
    },
    frieze_width_2: {
        'ui:placeholder': 'Select Width 2',
    },
    selection: {
        'ui:placeholder': 'Select Roofing',
    },
    rafter_tail: {
        'ui:placeholder': 'Select Rafter Tail',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
    soffit_type: {
        'ui:placeholder': 'Select Soffit Type',
    },
    soffit_vent_type: {
        'ui:placeholder': 'Select Soffit Vent Type',
    },
    soffit_tg_width: {
        'ui:placeholder': 'Select Soffit TG Width',
    },
    'ui:order': [
        'folder',
        'name',
        'label',
        'selection',
        'soffit_type',
        'soffit_vent_type',
        'soffit_tg_width',
        'location',
        'rafter_tail',
        'width',
        'width_other',
        'fascia_type',
        'fascia_texture',
        'fascia_thickness_1',
        'fascia_width_1',
        'fascia_type_2',
        'fascia_texture_2',
        'fascia_thickness_2',
        'fascia_width_2',
        'frieze_type',
        'frieze_texture',
        'frieze_thickness_1',
        'frieze_width_1',
        'frieze_type_2',
        'frieze_texture_2',
        'frieze_thickness_2',
        'frieze_width_2',
        'cornice_crown_molding',
        'non_vented_eave',
    ],
}

const onValidate = (formData: any, errors: FormValidation) => {}

const onSubmit = (settings, formData) => {
    const newSettings = eaveGableLengthFormSubmitCleanUp({ ...settings, ...formData })

    if (!formData.rafter_tail) {
        newSettings.rafter_tail = null
    }

    if (!formData.selection) {
        newSettings.selection = null
    }

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form

import { call, ForkEffect, select, StrictEffect, takeEvery } from 'redux-saga/effects'
import { updateDocumentMapping } from '../../../api/takeoff-api'
import { DocumentMapping } from '../../../models/documentMapping'
import { Project } from '../../../models/project'
import { moveChunkToBuilding, selectDocumentMappings } from '../../slices/documents'
import { selectProject } from '../2D/data-prep/prepareMarkupData'

export function* handleMoveChunkToBuilding(
    action: ReturnType<typeof moveChunkToBuilding>
): Generator<StrictEffect, void, Project & DocumentMapping[]> {
    try {
        const project = yield select(selectProject)
        const mappings = yield select(selectDocumentMappings)

        const updatedMapping = mappings.find((mapping) => mapping.document_chunk_id === action.payload.documentChunk.id)
        if (updatedMapping) {
            yield call(updateDocumentMapping, project.id, updatedMapping)
        }
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}

export function* watchForMoveChunkToBuilding(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(moveChunkToBuilding.type, handleMoveChunkToBuilding)
}

import { DRAWABLE_TYPES, DRAWING_TYPES } from '../../shared/constants/drawable-types'

/**
 * Function defines the type of opening_location based on drawable/material type,
 * and paper js item data.
 * Logic is used, when coordinate location is added to the existing opening
 * @param item paper.Item
 * @param drawableType DRAWABLE_TYPES
 * @returns DRAWABLE_TYPES
 */
export const defineLocationType = (item: paper.Item, drawableType: DRAWABLE_TYPES): DRAWABLE_TYPES => {
    switch (drawableType) {
        case DRAWABLE_TYPES.WALL:
            if (item.data.shapeType === DRAWING_TYPES.POINT) {
                return DRAWABLE_TYPES.KING_STUD
            }
            return DRAWABLE_TYPES.SHEARWALL

        default:
            return drawableType
    }
}

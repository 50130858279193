import { createSelector } from '@reduxjs/toolkit'
import isNull from 'lodash/isNull'
import { all, call, put, select } from 'redux-saga/effects'
import { updateOpeningGroupsSuccess } from '../../../actions/drawable'
import { OpeningAPI } from '../../../models/activeDrawable'
import { ActiveFloor } from '../../../models/activeFloor'
import { getActiveFloor } from '../../../reducers/drawable'
import { DRAWING_TYPES } from '../../../shared/constants/drawable-types'
import { RootState } from '../../../stores'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Color, Count, Label, PathTool, PolygonTool, RadiusLine, Select, Workspace } from '../../lib/toolBoxes/2D'
import addTooltipsToPath from '../../lib/utils/functionality-bindings/addTooltipsToPath'
import { setDrawableLocations } from '../../slices/2D'
import { GeometryGroup, selectDrawableGroupsGeometries } from '../../slices/geometry'
import { ToolsState } from '../../slices/tools'
import { IMUP2DDrawableLocation } from '../../types'
import { calculateNewDrawableLocations } from './calculateDrawableLocationsFromNewGroups'
import drawShapeByType from './drawShapeByType'

export interface RedrawShapeStoreState {
    areaOpacity: ToolsState['areaOpacityValue']
    lineOpacity: ToolsState['lineOpacityValue']
}

export const selectRedrawState = createSelector(
    ({ IMUP }: RootState): RedrawShapeStoreState => ({
        areaOpacity: IMUP.tools.areaOpacityValue,
        lineOpacity: IMUP.tools.lineOpacityValue,
    }),
    (state: RedrawShapeStoreState): RedrawShapeStoreState => state
)

export type RedrawShapeTools = [Color, PathTool, Count, PolygonTool, RadiusLine, Workspace, Label, Select]

export function* handleUpdateOpeningGroupsSuccess2D(action: ReturnType<typeof updateOpeningGroupsSuccess>) {
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (isNull(manager)) return

    const [colorTool, pathTool, countTool, polygonTool, radiusLineTool, workspaceTool, labelTool]: RedrawShapeTools =
        yield call(manager.getTools, [
            Color.NAME,
            PathTool.NAME,
            Count.NAME,
            PolygonTool.NAME,
            RadiusLine.NAME,
            Workspace.NAME,
            Label.NAME,
        ])

    const newGroupOpenings: Array<OpeningAPI> = action.payload.openingGroups.newGroup.openings

    // Remove the original paper items for the changed openings
    for (const opening of newGroupOpenings) {
        const items: paper.Item[] | null = yield call(workspaceTool.getItemsWithDrawableId, opening.id)

        if (items?.length) {
            yield all(items.map((item) => call([item, 'remove'])))
        }
    }

    // Now we have to get and redraw the new openings on the canvas
    const openingGroups: Array<GeometryGroup> = yield select(selectDrawableGroupsGeometries, true)
    const filteredOpeningGroups: Array<GeometryGroup> = yield openingGroups.filter(
        (group) => group.id === action.payload.openingGroups.newGroup.id
    )

    // get opening groups with updated openings and drawables, calculateNewDrawableLocations should receive only
    // new openings and redraw only them
    const filteredOpeningGroupsWithUpdatedOpenings: Array<GeometryGroup> = yield filteredOpeningGroups.map(
        (openingGroup) => {
            if (openingGroup.id === action.payload.openingGroups.newGroup.id) {
                return {
                    ...openingGroup,
                    drawablesPerTab: newGroupOpenings,
                    openings: newGroupOpenings,
                }
            }

            return openingGroup
        }
    )

    const {
        additionalDrawableLocations,
        newDrawableLocations,
    }: { additionalDrawableLocations: IMUP2DDrawableLocation[]; newDrawableLocations: IMUP2DDrawableLocation[] } =
        yield call(calculateNewDrawableLocations, filteredOpeningGroupsWithUpdatedOpenings)

    // We've updated drawable locations -- we need to save them in the store's 2D slice.
    yield put(setDrawableLocations(newDrawableLocations))

    const { areaOpacity, lineOpacity }: RedrawShapeStoreState = yield select(selectRedrawState)

    const activeFloor: ActiveFloor = yield select(getActiveFloor)
    for (const location of additionalDrawableLocations) {
        if (location.document_chunk_id === activeFloor.document_chunk.id) {
            const shape: paper.Path = yield call(
                drawShapeByType,
                location,
                colorTool,
                countTool,
                pathTool,
                polygonTool,
                radiusLineTool,
                workspaceTool,
                labelTool,
                areaOpacity,
                lineOpacity,
                activeFloor
            )

            yield call(addTooltipsToPath, shape, workspaceTool)

            // if we draw the area we should send it to back just before the PlanRaster that other element will be on the top of it
            if (location.shapeType === DRAWING_TYPES.AREA) {
                yield call(workspaceTool.sendItemtoBackBeforeRaster, shape)
            }
        }
    }
}

import { IGeometry } from '../../../types'

const NUMBER_OF_LINE = 4
const Z_AXIS_ROW_INDEX = 2

/**
 * This function takes a geometry and geometry matrix to calculate new vertices.
 *
 * @param geometry the geometry being transformed
 * @param geometryMatrix The geometry matrix being applied to the given geometry
 */
export const transformGeometryVertices = (geometry: IGeometry, geometryMatrix: number[]): number[] => {
    const geoMatrix = convertGeometryMatrixListToMatrix(
        flipListValues(geometryMatrix, Z_AXIS_ROW_INDEX, NUMBER_OF_LINE)
    )
    const newVertices = new Array<number>(geometry.vertices.length)
    for (let j = 0; j < geometry.vertices.length; j += 3) {
        const xIndex = j
        const yIndex = j + 1
        const zIndex = j + 2
        const verticesMatrix = convertPointToMatrix4By1(
            geometry.vertices[xIndex],
            geometry.vertices[yIndex],
            geometry.vertices[zIndex]
        )
        const verticesMultipliedGeoMatrix = multiplyMatrix(geoMatrix, verticesMatrix)
        newVertices[xIndex] = verticesMultipliedGeoMatrix[0][0]
        newVertices[yIndex] = verticesMultipliedGeoMatrix[1][0]
        newVertices[zIndex] = verticesMultipliedGeoMatrix[2][0]
    }

    return newVertices
}

/**
 * Takes 3 points and turns into a 4x1 matrix of x, y, z, 1
 * @param x
 * @param y
 * @param z
 * @returns a 4x1 matrix with the given x, y, z, and then a 1
 */
const convertPointToMatrix4By1 = (x: number, y: number, z: number): number[][] => {
    const retArray = [[x], [y], [z], [1]]
    return retArray
}

/**
 * Multiplies two given matricies and returns the resulting matrix
 *
 * @param matrixA
 * @param matrixB
 * @returns resulting multiplied matrix
 */
const multiplyMatrix = (matrixA: number[][], matrixB: number[][]): number[][] => {
    const aNumRows = matrixA.length
    const aNumCols = matrixA[0].length
    const bNumCols = matrixB[0].length
    const retMatrix = new Array(aNumRows)
    if (aNumCols === matrixB.length) {
        for (let r = 0; r < aNumRows; ++r) {
            retMatrix[r] = new Array(bNumCols) // initialize the current row of return matrix

            for (let c = 0; c < bNumCols; ++c) {
                retMatrix[r][c] = 0 // initialize the current cell of return matrix

                // add the row/column multiplication of every row/column
                for (let i = 0; i < aNumCols; ++i) {
                    retMatrix[r][c] += matrixA[r][i] * matrixB[i][c]
                }
            }
        }
    }
    return retMatrix
}

/**
 * Takes given array and creates matrix implying the format of the list is [x1, y1, z1, x2, y2, z2...]
 *
 * @param vertices the list of vertices to put into a matrix. Assuming the format [x1, y1, z1, x2, y2, z2...]
 * @return The resulting matrix
 */
const convertGeometryMatrixListToMatrix = (vertices: number[]): number[][] => {
    const matrix: number[][] = []
    for (let x = 0; x < NUMBER_OF_LINE; x++) {
        const currRow: number[] = []
        for (let y = 0; y < NUMBER_OF_LINE; y++) {
            currRow.push(vertices[y * NUMBER_OF_LINE + x])
        }
        matrix[x] = currRow
    }
    return matrix
}

/**
 * Takes a list of values with a step value and start
 * and flips the sign of each step number
 * @param  {number[]} list
 * @param  {number} start
 * @param  {number} step
 * @returns number
 */
const flipListValues = (list: number[], start: number, step: number): number[] => {
    if (list && start) {
        const newList = [...list]
        for (let x = start; x < list.length; x += step) {
            newList[x] = -newList[x]
        }
        return newList
    } else {
        return list
    }
}

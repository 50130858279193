import { UiSchema } from '@rjsf/core'
import { FormProperties } from '../imup/types'

export const uiSchema: UiSchema = {
    item_number: {
        'ui:title': 'Fypon Bracket Type',
    },
    item_number_other: {
        'ui:title': 'Fypon Bracket Other',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    return {
        ...settings,
        ...formData,
        item_number_other: formData.item_number === 'OTHER' ? formData.item_number_other : '',
    }
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form

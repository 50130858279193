import { AISuggestion } from '../../../../models/aiClassifications'
import store from '../../../../stores'
import {
    createDrawableLocation,
    removeSelectedAiSuggestionIds,
    selectedPaperItem,
    setSelectedAiSuggestionIds,
    updateSelectedCoordinates,
    updateSelectedMaterialType,
} from '../../../slices/2D'
import { IMUP2DCoordinatesToUpdate } from '../../../types'

function saveAiSuggestion(): (coordinatesToUpdate: IMUP2DCoordinatesToUpdate) => void {
    return function saveDrawable(this: paper.Path, coordinatesToUpdate) {
        store.dispatch(updateSelectedCoordinates(coordinatesToUpdate))
    }
}

function selectAiSuggestion(): () => void {
    return function selectDrawable(this: paper.Path) {
        const drawable = this.data.aiSuggestion as AISuggestion
        store.dispatch(updateSelectedMaterialType(drawable.type))
        store.dispatch(createDrawableLocation(this.id))

        // add a new ai suggestion id to remove
        store.dispatch(setSelectedAiSuggestionIds(this.data.aiSuggestion.id))
    }
}

function deselectAiSuggestion(): () => void {
    return function deselectDrawable(this: paper.Path) {
        store.dispatch(
            selectedPaperItem({
                selectedItem: null,
                activeDrawableId: null,
                activeOpeningLocationId: null,
                openingGroupId: null,
            })
        )

        // on click outside ai suggestion, unset array of ids
        store.dispatch(removeSelectedAiSuggestionIds())
    }
}

export default function addSelectFunctionalityToAiSuggestion(drawable: paper.PathItem): void {
    drawable.data.onSave = saveAiSuggestion().bind(drawable)
    drawable.data.onSelect = selectAiSuggestion().bind(drawable)
    drawable.data.onDeselect = deselectAiSuggestion().bind(drawable)
}

import { call, fork, StrictEffect, takeEvery } from 'redux-saga/effects'
import { RESET_DRAWABLE_GROUPS, SET_GROUP_STATUS } from '../../../actions/drawable'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Workspace } from '../../lib/toolBoxes/2D'
import { handleToggleDrawableGroups2D } from './handleToggleDrawableGroups'

export function* resetDrawables(): Generator<StrictEffect, void, PaperManager & Workspace> {
    try {
        const manager: PaperManager = yield call(managers.get2DManager)

        if (!manager) return

        const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)

        // Re-show everything when we reset the selector panel
        yield call(workspaceTool.showAllDrawables)
        yield fork(handleToggleDrawableGroups2D)
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}

export function* watchForDrawableReset() {
    yield takeEvery([RESET_DRAWABLE_GROUPS, SET_GROUP_STATUS], resetDrawables)
}

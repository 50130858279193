import { ActionReducerMapBuilder, createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FETCH_USER_SETTINGS_SUCCESS, UPDATE_SCROLL_STEP } from '../../actions/user'
import { UserSettings } from '../../models/user'

export type UserSettingsState = { settings: UserSettings }

export const initialUserSettingsState: UserSettingsState = {
    settings: { scrollStep: 20 },
}

export const gotUserSettings = createAction<{ userSettings: UserSettings }>(FETCH_USER_SETTINGS_SUCCESS)
export const updateScrollStep = createAction<{ scrollStep: number }>(UPDATE_SCROLL_STEP)

function handleUserSettings(state: UserSettingsState, { payload }: PayloadAction<{ userSettings: UserSettings }>) {
    state.settings = { ...payload.userSettings }
}

function handleUpdateZoomStep(state: UserSettingsState, { payload }: PayloadAction<{ scrollStep: number }>) {
    state.settings.scrollStep = payload.scrollStep
}

const extraReducers = (builder: ActionReducerMapBuilder<UserSettingsState>): void => {
    builder.addCase(gotUserSettings, handleUserSettings)
    builder.addCase(updateScrollStep, handleUpdateZoomStep)
}

const userSettingsSlice = createSlice({
    name: 'userSettings',
    initialState: initialUserSettingsState,
    reducers: {},
    extraReducers,
})

export default userSettingsSlice

import { OpeningAPI } from '../../../models/activeDrawable'
import { IMUP2DDrawableLocation } from '../../types'
import { OpeningGroupAPI } from '../../../models/activeDrawable'
import { call, select } from 'redux-saga/effects'
import { selectDrawableLocations } from '../effects/deleteTemporaryDrawableGroups'
import { convertOpeningGroupsToDrawableLocations } from './data-prep/convertOpeningsToDrawableLocations'
import { GeometricDrawable, GeometryGroup } from '../../slices/geometry'

export function* calculateNewDrawableLocations(newGroups: OpeningGroupAPI[] | GeometryGroup[]) {
    const groupOpeningIds: number[] = yield newGroups.flatMap((group: OpeningGroupAPI | GeometryGroup) => {
        return group.openings.map((op: OpeningAPI | GeometricDrawable) => op.id)
    })

    const currentDrawableLocations: IMUP2DDrawableLocation[] = yield select(selectDrawableLocations)

    const unAffectedDrawableLocations: Array<IMUP2DDrawableLocation> = yield currentDrawableLocations.filter(
        (loc) => !groupOpeningIds.includes(loc.drawable_id)
    )

    const newDrawableLocations: Array<IMUP2DDrawableLocation> = yield call(
        convertOpeningGroupsToDrawableLocations,
        newGroups
    )

    return {
        additionalDrawableLocations: newDrawableLocations,
        newDrawableLocations: [...newDrawableLocations, ...unAffectedDrawableLocations],
    }
}

import { PayloadAction } from '@reduxjs/toolkit'
import isNull from 'lodash/isNull'
import { call, put, select } from 'redux-saga/effects'
import { OpeningGroup } from '../../../models/activeDrawable'
import managers from '../../lib/managers'
import { BabylonManager } from '../../lib/managers/BabylonManager'
import {
    joistLinesDataSelector,
    openingToMeshIdMappingSelector,
    selectedOpeningGroupIdSelector,
    selectVisibleAndHiddenDrawableGroupIds,
    updateSelectedOpeningGroup,
} from '../../slices/3D'
import { IBabylonLineWithMetadata } from '../../types'
import { calculate3DMeshIdsFromActiveDrawableGroup } from './calculate3DMeshIdsFromActiveDrawableGroup'
import { createAndLabelJoistLines } from './handleUpdateJoistLinesUpdate'
import { selectAndHighlightRequiredMeshes, toggleSceneAmbientColor } from './selectMesh'
import { Workspace } from '../../lib/toolBoxes/3D'
import { DRAWABLE_TYPES } from '../../../shared/constants/drawable-types'
import { handleCameraAndGroupsVisibility } from './handleCameraAndGroupsVisibility'

export function* handleSetActiveDrawableGroup3D(
    action: PayloadAction<{ activeDrawableGroup: OpeningGroup; activeDrawableId: number | undefined }>
) {
    const manager: BabylonManager | null = yield call(managers.get3DManager)

    if (isNull(manager)) return

    const { activeDrawableGroup, activeDrawableId } = action.payload

    const workspace: Workspace = yield call(manager.getTool, Workspace.NAME)

    const joistLinesData: Record<string, IBabylonLineWithMetadata> | null = yield select(joistLinesDataSelector)
    const openingGroupSelectedID = yield select(selectedOpeningGroupIdSelector)
    const openingIdsToMeshIdsMap = yield select(openingToMeshIdMappingSelector)
    // If and only If we see no actively selected group do we do
    // anything this is because the 3D IMUP sagas use this action to
    // active the side panel on selecting a mesh and if there is no
    // opening group selected id it means that it is the side panel that
    // triggered this action and we need to do something about it
    if (!openingGroupSelectedID && activeDrawableGroup && activeDrawableGroup.openings) {
        yield call(toggleSceneAmbientColor, true)

        // if the user clicked on a single drawable in the list
        // and activated the group only main highlight the clicked
        // drawable, else select entire group
        if (action.payload.activeDrawableId) {
            const nonMainMeshIds: string[] = yield call(
                calculate3DMeshIdsFromActiveDrawableGroup,
                {
                    ...activeDrawableGroup,
                    openings: activeDrawableGroup.openings.filter((opening) => opening.id !== activeDrawableId),
                },
                openingIdsToMeshIdsMap
            )

            const mainMeshIds: string[] = yield call(
                calculate3DMeshIdsFromActiveDrawableGroup,
                {
                    ...activeDrawableGroup,
                    openings: activeDrawableGroup.openings.filter((opening) => opening.id === activeDrawableId),
                },
                openingIdsToMeshIdsMap
            )

            yield put(updateSelectedOpeningGroup({ id: activeDrawableGroup.id }))

            const shouldUseMainHighlight = true
            yield call(selectAndHighlightRequiredMeshes, mainMeshIds, shouldUseMainHighlight, manager)

            const shouldNotUseMainHighlight = false
            yield call(selectAndHighlightRequiredMeshes, nonMainMeshIds, shouldNotUseMainHighlight, manager)
        } else {
            const meshIds: string[] = yield call(
                calculate3DMeshIdsFromActiveDrawableGroup,
                action.payload.activeDrawableGroup,
                openingIdsToMeshIdsMap
            )

            yield put(updateSelectedOpeningGroup({ id: activeDrawableGroup.id }))

            const shouldUseMainHighlight = true
            yield call(selectAndHighlightRequiredMeshes, meshIds, shouldUseMainHighlight, manager)
        }

        if (
            activeDrawableGroup.type === DRAWABLE_TYPES.ROOF_SYSTEM ||
            activeDrawableGroup.type === DRAWABLE_TYPES.FLOOR_SYSTEM
        ) {
            yield call(handleCameraAndGroupsVisibility, activeDrawableGroup)
        }

        if (joistLinesData) {
            const filteredJoistLines = Object.keys(joistLinesData).reduce((prev, cur) => {
                if (cur.includes(`${activeDrawableGroup.id}`)) {
                    prev[cur] = joistLinesData[cur]
                }
                return prev
            }, {} as Record<string, IBabylonLineWithMetadata>)
            yield call(createAndLabelJoistLines, filteredJoistLines, manager)
        }
    } else {
        const { visibleDrawableGroupIds, hiddenDrawableGroupIds } = yield select(selectVisibleAndHiddenDrawableGroupIds)

        if (!!visibleDrawableGroupIds.length) {
            yield call(workspace.modifyNodeVisibility, visibleDrawableGroupIds, true)
        }

        if (!!hiddenDrawableGroupIds.length) {
            yield call(workspace.modifyNodeVisibility, hiddenDrawableGroupIds, false)
        }
    }
}

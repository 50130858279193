import { ActiveDrawable, DrawableLocation } from '../../models/activeDrawable'
import { AIAutomatedObject, AISuggestion } from '../../models/aiClassifications'
import { DRAWING_TYPES } from '../../shared/constants/drawable-types'
import determineDrawingType from '../../shared/services/drawable-type-service'

export const parseAiDataIntoDrawableSuggestions = (aiSuggestion: AIAutomatedObject[]): AISuggestion[] => {
    const drawableSuggestions: AISuggestion[] = aiSuggestion.map((object) => {
        return {
            ...object,
            shape_type:
                determineDrawingType({
                    drawable: { type: object.type, settings: object.settings } as unknown as ActiveDrawable,
                    planView: {
                        coordinates: object.coordinates,
                        additional_data: {},
                    } as unknown as DrawableLocation,
                }) ?? DRAWING_TYPES.SECTION,
        }
    })

    return drawableSuggestions
}

export const markAiSuggestionsDeleted = (aiData: AIAutomatedObject[], deletedIds: string[]): AIAutomatedObject[] => {
    return aiData.map((object) => {
        if (deletedIds.includes(object.id)) {
            return {
                ...object,
                isDeleted: true,
            }
        }
        return object
    })
}

export const markAiSuggestionsModified = (aiData: AIAutomatedObject[], modifiedIds: string[]): AIAutomatedObject[] => {
    return aiData.map((object) => {
        if (modifiedIds.includes(object.id)) {
            return {
                ...object,
                isModified: true,
            }
        }
        return object
    })
}

import { GeneralDrawableSettings } from '../../models/activeDrawableSettings'
import { DRAWABLE_TYPES } from './drawable-types'

export const solidBeam = 'Solid Beam'
export const dimensionalLumber = 'DIMENSIONAL LUMBER'
export const beamMaterialWithHeightAndWidth = ['GL', 'LSL', 'LVL', 'PSL']

export const solidBeamTypes: DRAWABLE_TYPES[] = [
    DRAWABLE_TYPES.DROPPED_BEAM,
    DRAWABLE_TYPES.RIDGE_BEAM,
    DRAWABLE_TYPES.HIP_AND_VALLEY_BEAM,
    DRAWABLE_TYPES.HEADER,
]

export const solidBeamUIOrder: string[] = [
    'beam_type',
    'beam_cut',
    'lumber_grade',
    'beam_width',
    'beam_height',
    'bearing',
]

export const solidBeamUiSchema = {
    beam_type: {
        'ui:title': 'Beam Type',
        'ui:placeholder': 'Select Beam Type',
    },
    beam_cut: {
        'ui:title': 'Beam Cut',
        'ui:placeholder': 'Select Beam Cut',
    },
    lumber_grade: {
        'ui:title': 'Lumber Grade',
        'ui:placeholder': 'Select Lumber Grade',
    },
    beam_width: {
        'ui:title': 'Beam Width',
        'ui:placeholder': 'Enter Beam Width',
    },
    beam_height: {
        'ui:title': 'Beam Height',
        'ui:placeholder': 'Enter Beam Height',
    },
    bearing: {
        'ui:field': 'DropdownField',
    },
}

export const handleSolidBeamCleanUp = (
    formData: GeneralDrawableSettings,
    settings: GeneralDrawableSettings
): GeneralDrawableSettings => {
    if (formData.material === solidBeam) {
        settings.dimension = null
        settings.plies = null
    } else {
        if (formData.material !== dimensionalLumber) {
            settings.dimension = null
        }

        if (!(formData.material && beamMaterialWithHeightAndWidth.includes(formData.material))) {
            settings.height = null
            settings.width = null
        }

        if (formData.plies === 'other') {
            settings.plies = settings.plies_other
        }

        // ignore solid beam material fields
        settings.beam_type = null
        settings.beam_cut = null
        settings.lumber_grade = null
        settings.beam_width = null
        settings.beam_height = null
    }

    // plies_other are always null, because we reassign them
    settings.plies_other = null

    return settings
}

import { FormValidation, UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'
import { validateGivenFieldsAreFractions } from './validation/fractionValidation'

const otherValue = 'other'

export const uiSchema: UiSchema = {
    schedule_code: { 'ui:title': 'Schedule Code' },
    swing: { 'ui:title': 'Swing', 'ui:field': 'RadioButtons', 'ui:options': { inline: true } },
    transom: { 'ui:title': 'Has Mulled Transom?', 'ui:field': 'RadioButtons', 'ui:options': { inline: true } },
    transom_comment: { 'ui:title': 'Transom Comment' },
    transom_height: { 'ui:title': 'Transom Height' },
    manual_width: { 'ui:title': 'Width' },
    manual_height: { 'ui:title': 'Height' },
    wall_depth_other: { 'ui:title': 'Other Wall Depth' },
    discrepancy: { 'ui:title': 'Discrepancy Exists?', 'ui:field': 'RadioButtons', 'ui:options': { inline: true } },
    discrepancy_comment: { 'ui:title': 'Discrepancy Comment' },
    remarks: { 'ui:title': 'Remarks' },
    'ui:order': [
        'schedule_code',
        'swing',
        'transom',
        'transom_comment',
        'transom_height',
        'manual_width',
        'manual_height',
        'wall_depth',
        'wall_depth_other',
        'discrepancy',
        'discrepancy_comment',
        'remarks',
    ],
}

const onValidate = (formData: any, errors: FormValidation) => {
    validateGivenFieldsAreFractions(['manual_width', 'manual_height'], formData, errors)
    if (formData.wall_depth === otherValue) validateGivenFieldsAreFractions(['wall_depth_other'], formData, errors)
}

const onSubmit = (settings, formData) => {
    return {
        ...settings,
        ...formData,
        discrepancy_comment: formData.discrepancy === 'false' ? null : formData.discrepancy_comment,
        transom_comment: formData.transom === 'false' ? null : formData.transom_comment,
        wall_depth_other: formData.wall_depth !== otherValue ? null : formData.wall_depth_other,
    }
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form

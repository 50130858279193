import { ActiveChunk } from '../../models/activeChunk'
import { ActiveDrawable, DrawableLocation, NewDrawable } from '../../models/activeDrawable'
import { ActiveFloor } from '../../models/activeFloor'
import { UserData } from '../../models/user'
import { DRAWABLE_TYPES, IMUP_WINDOW_AND_DOOR_TYPES } from '../constants/drawable-types'
import IndexableObject from '../constants/general-enums/indexableObject'
import { USER_PERMISSIONS_NAMES } from '../constants/user-permissions'

export const isDrawableAnOpening = (drawable: ActiveDrawable | NewDrawable): boolean => {
    return (
        drawable &&
        (drawable.type === DRAWABLE_TYPES.ENTRY_DOOR ||
            drawable.type === DRAWABLE_TYPES.FLOOR_LINE ||
            drawable.type === DRAWABLE_TYPES.INTERIOR_DOOR ||
            drawable.type === DRAWABLE_TYPES.PATIO ||
            drawable.type === DRAWABLE_TYPES.NEW ||
            drawable.type === DRAWABLE_TYPES.VENT ||
            drawable.type === DRAWABLE_TYPES.WINDOW)
    )
}

export const isDrawableFloorLine = (drawable: ActiveDrawable | NewDrawable): boolean => {
    return drawable.type === DRAWABLE_TYPES.FLOOR_LINE
}

// TODO: activeChunk is not used, should be deleted and removed from all places where is used
export const isDrawableLocationPartOfActiveChunk = (
    drawable_location: DrawableLocation,
    activeFloor: ActiveFloor,
    activeChunk: ActiveChunk
): boolean => {
    return (
        drawable_location.floor_hash === activeFloor.hash &&
        drawable_location.document_chunk_id === activeFloor.document_chunk.id
    )
}

export const groupObjectByKey = (list: object[], groupBy: string): {} => {
    return list.reduce((a, b) => {
        ;(a[b[groupBy]] = a[b[groupBy]] || []).push(b)
        return a
    }, {})
}

export const replacePageName = (pageName: string): string => {
    return pageName ? pageName.replace(',', '|').replace('| ', '|') : ''
}

/**
 * Update the drawables selections based of group settings
 * @param openings
 * @param values
 */
export const updateDrawableSelections = (
    openings: ActiveDrawable[],
    values: {
        configuration: string
        remarks: string
        settings: IndexableObject
    }
): ActiveDrawable[] => {
    return openings.map((drawable) => {
        let drawableCopy = { ...drawable }
        // copy from group to each opening (remarks and configuration are outside the settings)
        if (IMUP_WINDOW_AND_DOOR_TYPES.includes(drawableCopy.type)) {
            drawableCopy = {
                ...drawableCopy,
                remarks: values.remarks,
                configuration: values.configuration,
            }
        }

        Object.keys(values.settings).forEach((keyName) => {
            // prevent add empty string, null and undefined as value, but keep false (Yes/No options)
            if (
                !values.settings[keyName] &&
                values.settings[keyName] !== false &&
                drawable.settings[keyName] === values.settings[keyName]
            ) {
                return
            }

            // Don't override drawable's data, BE handle it
            if (
                keyName === 'rcm_id' ||
                keyName === 'name' ||
                keyName === 'label' ||
                keyName === 'rcm_linked_opening_id' ||
                keyName === 'quantity' ||
                keyName === 'linear_total' ||
                keyName === 'area'
            ) {
                return
            }

            drawableCopy = {
                ...drawableCopy,
                settings: {
                    ...drawableCopy.settings,
                    [keyName]: values.settings[keyName],
                },
            }
        })

        return drawableCopy
    })
}

/**
 * Function which handle display of drawable
 * @param drawableType
 * @param userPermissions
 */
export const checkDrawablePermission = (
    drawableType: DRAWABLE_TYPES,
    userPermissions: UserData['permissions']
): boolean => {
    if (userPermissions?.length) {
        switch (drawableType) {
            case DRAWABLE_TYPES.WALL:
            case DRAWABLE_TYPES.SHEARWALL:
                return !!userPermissions.find(
                    (permission: { name: string }) => permission.name === USER_PERMISSIONS_NAMES.UPDATE_WALL_FORM
                )
            case DRAWABLE_TYPES.STAIRS:
                return !!userPermissions.find(
                    (permission: { name: string }) => permission.name === USER_PERMISSIONS_NAMES.UPDATE_STAIRS
                )
            case DRAWABLE_TYPES.STEEL_BEAM_PLATE:
                return !!userPermissions.find(
                    (permission: { name: string }) => permission.name === USER_PERMISSIONS_NAMES.UPDATE_STEEL_BEAM_PLATE
                )
            case DRAWABLE_TYPES.LF_RAILING:
                return !!userPermissions.find(
                    (permission: { name: string }) => permission.name === USER_PERMISSIONS_NAMES.UPDATE_LF_RAILING
                )
            case DRAWABLE_TYPES.HARDWARE:
                return !!userPermissions.find(
                    (permission: { name: string }) => permission.name === USER_PERMISSIONS_NAMES.UPDATE_HARDWARE
                )
            case DRAWABLE_TYPES.LE_JOIST:
            case DRAWABLE_TYPES.I_JOIST:
            case DRAWABLE_TYPES.CEILING_JOIST:
            case DRAWABLE_TYPES.FLOOR_JOIST:
            case DRAWABLE_TYPES.SLEEPER_JOIST:
            case DRAWABLE_TYPES.RAFTER:
            case DRAWABLE_TYPES.ROOF_SYSTEM:
            case DRAWABLE_TYPES.FLOOR_SYSTEM:
                return !!userPermissions.find(
                    (permission: { name: string }) => permission.name === USER_PERMISSIONS_NAMES.UPDATE_JOIST
                )
            case DRAWABLE_TYPES.FLOOR_TRUSS_SYSTEM:
            case DRAWABLE_TYPES.FLOOR_TRUSS_BRACING:
                return !!userPermissions.find(
                    (permission: { name: string }) => permission.name === USER_PERMISSIONS_NAMES.UPDATE_FLOOR_TRUSS
                )
            case DRAWABLE_TYPES.LEDGER:
                return !!userPermissions.find(
                    (permission: { name: string }) => permission.name === USER_PERMISSIONS_NAMES.UPDATE_LEDGER
                )
            case DRAWABLE_TYPES.HEADER:
            case DRAWABLE_TYPES.DROPPED_BEAM:
            case DRAWABLE_TYPES.FLUSH_BEAM:
            case DRAWABLE_TYPES.RIDGE_BEAM:
            case DRAWABLE_TYPES.HIP_AND_VALLEY_BEAM:
            case DRAWABLE_TYPES.AUTOMATED_BEAM_AND_HEADER:
                return !!userPermissions.find(
                    (permission: { name: string }) => permission.name === USER_PERMISSIONS_NAMES.UPDATE_HEADER
                )
            case DRAWABLE_TYPES.LF_FIVE_DOT_FIVE_DECKING:
                return !!userPermissions.find(
                    (permission: { name: string }) =>
                        permission.name === USER_PERMISSIONS_NAMES.UPDATE_FIVEDOTFIVE_DECKING
                )
            case DRAWABLE_TYPES.TRAYED_CEILING_AND_SOFFIT:
                return !!userPermissions.find(
                    (permission: { name: string }) =>
                        permission.name === USER_PERMISSIONS_NAMES.UPDATE_TRAYED_CEILING_AND_SOFFIT
                )
            case DRAWABLE_TYPES.FOUNDATION_PILON:
                return !!userPermissions.find(
                    (permission: { name: string }) => permission.name === USER_PERMISSIONS_NAMES.UPDATE_FOUNDATION_PILON
                )
            case DRAWABLE_TYPES.FYPON_BRACKET:
                return !!userPermissions.find(
                    (permission: { name: string }) => permission.name === USER_PERMISSIONS_NAMES.UPDATE_FYPON_BRACKET
                )
            case DRAWABLE_TYPES.INTERIOR_FINISHED_CEILING:
                return !!userPermissions.find(
                    (permission: { name: string }) =>
                        permission.name === USER_PERMISSIONS_NAMES.UPDATE_INTERIOR_FINISHED_CEILING
                )
            case DRAWABLE_TYPES.CANALES:
                return !!userPermissions.find(
                    (permission: { name: string }) => permission.name === USER_PERMISSIONS_NAMES.UPDATE_CANALES
                )
            case DRAWABLE_TYPES.EXTERIOR_DOOR:
            case DRAWABLE_TYPES.INTERIOR_DOOR_IMUP:
                return !!userPermissions.find(
                    (permission: { name: string }) => permission.name === USER_PERMISSIONS_NAMES.UPDATE_MILLWORK
                )
            case DRAWABLE_TYPES.VALLEY_PLATE:
                return !!userPermissions.find(
                    (permission: { name: string }) => permission.name === USER_PERMISSIONS_NAMES.UPDATE_VALLEY_PLATE
                )
            case DRAWABLE_TYPES.UNDER_VALLEY_SHEATHING:
                return !!userPermissions.find(
                    (permission: { name: string }) =>
                        permission.name === USER_PERMISSIONS_NAMES.UPDATE_UNDER_VALLEY_SHEATHING
                )
            default:
                // rest drawables type doesn't have permissions yet, keep show them
                return true
        }
    }

    // if there are no permissions
    return false
}

/**
 * Function that sort array of objects by order if provided, or by value
 * @param a
 * @param b
 */
export const sortDropdownOptions = (
    a: { value: string; order?: number },
    b: { value: string; order?: number }
): number => {
    if (a.order || b.order) {
        if (a.order && !b.order) return -1
        if (!a.order && b.order) return 1

        if (a.order && b.order) {
            if (a.order < b.order) return -1
            if (a.order > b.order) return 1
        }
    } else {
        if (!isNaN(Number(a.value)) && !isNaN(Number(b.value))) {
            if (Number(a.value) > Number(b.value)) return 1
            if (Number(a.value) < Number(b.value)) return -1

            return 0
        }

        if (isNaN(Number(a.value)) && isNaN(Number(b.value))) {
            // handle cases where we have X between numbers
            if (a.value.toLowerCase().includes('x') && b.value.toLowerCase().includes('x')) {
                const aVal = a.value.toLowerCase().split('x')
                const bVal = b.value.toLowerCase().split('x')

                // replace is needed to ignore text and sort by numbers
                const aValFirstNumber = Number(aVal[0].replace(/\D/g, ''))
                const aValSecondNumber = Number(aVal[1].replace(/\D/g, ''))

                const bValFirstNumber = Number(bVal[0].replace(/\D/g, ''))
                const bValSecondNumber = Number(bVal[1].replace(/\D/g, ''))

                // if first number are equals, sort by second number
                // example, 2X10 and 2X8
                if (aValFirstNumber === bValFirstNumber) {
                    if (aValSecondNumber < bValSecondNumber) return -1
                    if (aValSecondNumber > bValSecondNumber) return 1

                    return 0
                }

                if (aValFirstNumber < bValFirstNumber) return -1
                if (aValFirstNumber > bValFirstNumber) return 1

                return 0
            } else {
                if (a.value < b.value) return -1
                if (a.value > b.value) return 1

                return 0
            }
        }
    }
    return 0
}

/**
 * prepareValue used to convert value to a string and lowercase it
 * @param value
 */
export const prepareValue = (value: string | number): string => {
    if (value === null || value === undefined) {
        return ''
    }

    return String(value)?.toLowerCase()
}

export const isHipValleyBeamType = (updatedType: string): boolean => {
    return updatedType.toLowerCase() === DRAWABLE_TYPES.HIP_AND_VALLEY_BEAM.replace('_and_', '/').replace('_', ' ')
}

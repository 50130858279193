import { Mesh, VertexData } from '@babylonjs/core'
import earcut from 'earcut'
import { BabylonToolConfig } from '../../../../../types'
import BabylonTool from '../babylonTool/BabylonTool'

export class Polygon extends BabylonTool {
    public static NAME = 'POLYGON'

    private static readonly EARCUT_DIMENSIONS = 3
    private static readonly EARCUT_HOLES = null

    constructor(config: BabylonToolConfig) {
        super(config)
        this.name = Polygon.NAME
        this.registerActivationFunctions([])
    }

    private triangulatePositionsAndIndices = (
        shape: Array<number>
    ): { meshPositions: Array<number>; meshIndices: Array<number> } => {
        /**
         * Use earcut to triangulate the contour and
         * use the resulting indices with the shape
         * array to determine the vertex positions
         * of the resulting mesh.
         * https://www.npmjs.com/package/earcut#usage
         */
        const triangulatedIndices: number[] = earcut(shape, Polygon.EARCUT_HOLES, Polygon.EARCUT_DIMENSIONS)

        const [meshPositions, meshIndices] = triangulatedIndices.reduce(
            (
                [meshPositions, meshIndices]: [Array<number>, Array<number>],
                index: number
            ): [Array<number>, Array<number>] => {
                // break resulting triangles down by vertices.
                // i.e. given the triangulation, resultant triangle vertices are arranged as
                // ABC, CDE, EFG, etc. where ABCDEFG are vertices of the respective triangles with common edges
                const shiftedIndex = index * Polygon.EARCUT_DIMENSIONS
                return [
                    [...meshPositions, shape[shiftedIndex], shape[shiftedIndex + 1], shape[shiftedIndex + 2]],
                    [...meshIndices, index],
                ]
            },
            [[...shape], []]
        )

        return { meshPositions, meshIndices }
    }

    private createAndApplyVertexData = (mesh: Mesh, shape: Array<number>): void => {
        const { meshPositions, meshIndices } = this.triangulatePositionsAndIndices(shape)
        const meshNormals: number[] = []

        VertexData.ComputeNormals(meshPositions, meshIndices, meshNormals)

        const meshVertexData = new VertexData()

        meshVertexData.positions = meshPositions
        meshVertexData.indices = meshIndices
        meshVertexData.normals = meshNormals

        meshVertexData.applyToMesh(mesh, Polygon.DO_NOT_FLAG_VERTEX_DATA_AS_UPDATABLE)
    }

    /**
     * create a 3D polygon from a contour that is passed
     * then add that mesh into the scene with the correct parameters.
     * Uses earcutting algorithm to triangulate mesh vertex positions and indices
     * @param {number[]} shape a flat number array of the points on the contour x,y,z ordering
     * @param {Partial<Mesh>} meshOptions mesh options to be populated on created mesh
     * @returns {Mesh} returns null if mesh cannot be created
     */
    public create = (shape: Array<number>, meshOptions?: Partial<Mesh>): Mesh => {
        const mesh = new Mesh('polygon-mesh', this.scene)

        this.createAndApplyVertexData(mesh, shape)

        meshOptions && Object.assign(mesh, meshOptions)

        return mesh
    }
}

import { UiSchema } from '@rjsf/core'
import { FormProperties } from '../imup/types'

export const uiSchema: UiSchema = {
    location: {
        'ui:title': 'Location',
        'ui:placeholder': 'Select Location',
    },
    number_of_staircases: {
        'ui:title': 'Number of staircases',
        'ui:placeholder': 'Number of staircases',
    },
    number_half_landings: {
        'ui:title': 'Number of half landings',
        'ui:placeholder': 'Number of half landings',
    },
    number_full_landings: {
        'ui:title': 'Number of full landings',
        'ui:placeholder': 'Number of full landings',
    },
    stair_width_inches: {
        'ui:title': 'Stair Width Inches',
        'ui:placeholder': 'Stair Width Inches',
    },
    number_of_treads: {
        'ui:title': 'Number of treads',
        'ui:placeholder': 'Number of treads',
    },
    number_garage_stairs: {
        'ui:title': 'Number garage stairs',
        'ui:placeholder': 'Number garage stairs',
    },
    ext_deck_stairs_width: {
        'ui:title': 'Ext Deck Stairs Width (Inches)',
        'ui:placeholder': 'Ext Deck Stairs Width',
    },
    ext_deck_stringer_length: {
        'ui:title': 'Ext Deck Stringer Length (Feet)',
        'ui:placeholder': 'Ext Deck Stringer Length',
    },
    ext_deck_stringer: {
        'ui:placeholder': 'Select Ext Deck Stringer',
    },
    ext_deck_riser: {
        'ui:placeholder': 'Select Ext Deck Riser',
    },
    ext_deck_tread_count: {
        'ui:title': 'Ext Deck Tread Count',
        'ui:placeholder': 'Ext Deck Tread Count',
    },
    stringer_material: {
        'ui:placeholder': 'Select Stringer Material',
    },
    stairs_treads: {
        'ui:placeholder': 'Select Stair Treads',
    },
    stairs_risers: {
        'ui:placeholder': 'Select Stair Risers',
    },
    tread_material: {
        'ui:placeholder': 'Select Tread Material',
    },
    riser_material: {
        'ui:placeholder': 'Select Riser Material',
    },
    'ui:order': [
        'location',
        'stairs_treads',
        'stairs_risers',
        'riser_material',
        'number_of_treads',
        'stair_width_inches',
        'tread_material',
        'number_of_staircases',
        'number_half_landings',
        'number_full_landings',
        'stringer_material',
        'number_garage_stairs',
        'ext_deck_stairs_width',
        'ext_deck_stringer_length',
        'ext_deck_riser',
        'ext_deck_stringer',
        'ext_deck_tread_count',
    ],
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    if (!newSettings.stairs_treads) {
        newSettings.stairs_risers = null
        newSettings.riser_material = null
        newSettings.number_of_treads = null
        newSettings.stair_width_inches = null
        newSettings.tread_material = null
    }

    if (newSettings.stairs_treads === 'Finished Only') {
        newSettings.tread_material = null
    }

    if (!newSettings.stairs_risers || newSettings.stairs_risers === 'Finished Only') {
        newSettings.riser_material = null
    }

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form

import { createAction } from '@reduxjs/toolkit'
import { all, call, ForkEffect, put, takeEvery } from 'redux-saga/effects'
import { deleteMultipleFlags } from '../../../api/projects-api'
import { removeFlag } from '../../slices/materialFlags'
import PaperManager from '../../lib/managers/PaperManager'
import managers from '../../lib/managers'
import { Workspace } from '../../lib/toolBoxes/2D'

export const deleteMultipleFlagsAction = createAction<{ flag_ids: number[] }>('deleteMultipleFlags')

export function* handleDeleteMultipleFlags({ payload }: ReturnType<typeof deleteMultipleFlagsAction>) {
    try {
        const manager: PaperManager | null = yield call(managers.get2DManager)

        if (!manager) return

        const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)

        const { flag_ids } = payload

        const flagItems: paper.Item[] = yield call(workspaceTool.getItemsWithCriteria, 'data', (data) =>
            flag_ids.includes(data?.flag_id)
        )

        yield all(flagItems.map((item) => call(item.remove.bind(item))))

        yield call(deleteMultipleFlags, flag_ids)

        yield put(removeFlag({ flag_ids }))
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForDeleteMultipleFlags(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(deleteMultipleFlagsAction.type, handleDeleteMultipleFlags)
}

import { FormValidation, UiSchema } from '@rjsf/core'
import { FormProperties } from '../imup/types'

export const uiSchema: UiSchema = {
    location: {
        'ui:widget': 'hidden',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
}

const onValidate = (formData: any, errors: FormValidation) => {}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form

import { UpdateOpeningGroupApiResponse } from '../api/api-helper'
import { UpdateDrawableMeasurements } from '../imup/sagas/2D/updateDrawableCoordinates'
import { IMappings } from '../imup/slices/documents'
import { ActiveDrawable, OpeningGroup } from '../models/activeDrawable'
import { ActiveFloor } from '../models/activeFloor'
import { Project } from '../models/project'
import { Building } from '../models/projectBuilding'
import { Modification } from '../models/projectModification'
import { NewSpecSheetData } from '../models/specSheet'
import { DRAWABLE_TYPES } from '../shared/constants/drawable-types'

export const FETCH_MAPPING_PENDING = 'FETCH_MAPPING_PENDING'
export const FETCH_MAPPING_SUCCESS = 'FETCH_MAPPING_SUCCESS'
export const FETCH_MAPPING_ERROR = 'FETCH_MAPPING_ERROR'
export const FETCH_DRAWABLE_PENDING = 'FETCH_DRAWABLE_PENDING'
export const FETCH_DRAWABLE_SUCCESS = 'FETCH_DRAWABLE_SUCCESS'
export const FETCH_OPENING_GROUPS_PENDING = 'FETCH_OPENING_GROUPS_PENDING'
export const FETCH_OPENING_GROUPS_SUCCESS = 'FETCH_OPENING_GROUPS_SUCCESS'
export const UPDATE_OPENING_GROUPS_SUCCESS = 'UPDATE_OPENING_GROUPS_SUCCESS'
export const DELETE_OPENING_GROUPS = 'DELETE_OPENING_GROUPS'
export const UPDATE_DRAWABLES_MEASUREMENTS = 'UPDATE_DRAWABLES_MEASUREMENTS'
export const CREATE_DRAWABLE_PENDING = 'CREATE_DRAWABLE_PENDING'
export const CREATE_DRAWABLE_SUCCESS = 'CREATE_DRAWABLE_SUCCESS'
export const SET_ACTIVE_FLOOR = 'SET_ACTIVE_FLOOR'
export const SET_ACTIVE_FLOOR_FROM_DOC_CHUNK_ID = 'SET_ACTIVE_FLOOR_FROM_DOC_CHUNK_ID'
export const SET_FLOOR_DRAWABLES = 'SET_FLOOR_DRAWABLES'
export const SET_ACTIVE_DRAWABLE_GROUP = 'SET_ACTIVE_DRAWABLE_GROUP'
export const SET_ACTIVE_DRAWABLE_GROUP_FROM_ID = 'SET_ACTIVE_DRAWABLE_GROUP_FROM_ID'
export const TOGGLE_DRAWABLE_GROUP = 'TOGGLE_DRAWABLE_GROUP'
export const TOGGLE_DRAWABLE_GROUPS_BY_TYPE = 'TOGGLE_DRAWABLE_GROUPS_BY_TYPE'
export const ACTIVATE_ONLY_CLICKED_DRAWABLE_GROUP_BY_TYPE = 'ACTIVATE_ONLY_CLICKED_DRAWABLE_GROUP_BY_TYPE'
export const TOGGLE_DRAWABLE_GROUPS = 'TOGGLE_DRAWABLE_GROUPS'
export const TOGGLE_DRAWABLE_GROUPS_VISIBILITY = 'TOGGLE_DRAWABLE_GROUPS_VISIBILITY'
export const FILTER_DRAWABLE_GROUPS = 'FILTER_DRAWABLE_GROUPS'
export const SET_ACTIVE_CHUNK = 'SET_ACTIVE_CHUNK'
export const ADD_DRAWABLE = 'ADD_DRAWABLE'
export const SET_ACTIVE_DRAWABLE = 'SET_ACTIVE_DRAWABLE'
export const SET_ACTIVE_BUTTON = 'SET_ACTIVE_BUTTON'
export const SET_LOCATION_ERROR_MSG = 'SET_LOCATION_ERROR_MSG'
export const SET_NEW_ACTIVE_DRAWABLE_COORDINATES = 'SET_NEW_ACTIVE_DRAWABLE_COORDINATES'
export const UPDATE_DRAWABLE_COORDINATES = 'UPDATE_DRAWABLE_COORDINATES'
export const SET_CALIBRATION = 'SET_CALIBRATION'
export const UPDATE_SCALE = 'UPDATE_SCALE'
export const ADD_DELETE_DRAWABLE_INDEX = 'ADD_DELETE_DRAWABLE_INDEX'
export const CANCEL_ELEVATION_TOOL = 'CANCEL_ELEVATION_TOOL'
export const TOGGLE_ACTIVE_DRAWABLE = 'TOGGLE_ACTIVE_DRAWABLE'
export const TOGGLE_ACTIVE_DRAWABLE_GROUP = 'TOGGLE_ACTIVE_DRAWABLE_GROUP'
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS'
export const SET_PROJECT_BUILDINGS = 'SET_PROJECT_BUILDINGS'
export const UPDATE_BUILDING = 'UPDATE_BUILDING'
export const DELETE_BUILDING = 'DELETE_BUILDING'
export const ADD_BUILDING = 'ADD_BUILDING'
export const SET_PROJECT_MODIFICATIONS = 'SET_PROJECT_MODIFICATIONS'
export const SET_ERROR = 'SET_ERROR'
export const SET_PENDING = 'SET_PENDING'
export const TOGGLE_ALL_PAGES_VIEW = 'TOGGLE_ALL_PAGES_VIEW'
export const SET_NOTIFICATION_MODAL = 'SET_NOTIFICATION_MODAL'
export const TOGGLE_DRAWABLE_ON_HOVER = 'TOGGLE_DRAWABLE_ON_HOVER'
export const UPDATE_DRAWABLE = 'UPDATE_DRAWABLE'
export const ADD_NEW_DRAWABLE_TO_GROUP = 'ADD_NEW_DRAWABLE_TO_GROUP'
export const CHANGE_DRAWABLE_GROUP_TYPE = 'CHANGE_DRAWABLE_GROUP_TYPE'
export const TOGGLE_LINKING_FORM = 'TOGGLE_LINKING_FORM'
export const RESET_DRAWABLE_GROUPS = 'RESET_DRAWABLE_GROUPS'
export const FETCH_PROJECT_DATA_FROM_PORTAL = 'FETCH_PROJECT_DATA_FROM_PORTAL'
export const FETCH_CONFIGURATOR_SPEC_SHEET = 'FETCH_CONFIGURATOR_SPEC_SHEET'
export const SET_GROUP_STATUS = 'SET_GROUP_STATUS'
export const UPDATE_CARD_ACTIVE_STATUS = 'UPDATE_CARD_ACTIVE_STATUS'
export const UPDATE_MAPPING_ORDER = 'UPDATE_MAPPING_ORDER'
export const TOGGLE_NOTE = 'TOGGLE_NOTE'
export const UPDATE_JOIST_LINES = 'UPDATE_JOIST_LINES'
export const RESET_DRAWABLES = 'RESET_DRAWABLES'
export const SET_DRAWABLES = 'SET_DRAWABLES'
export const DELETE_DRAWABLE_FROM_ALL_GROUPS = 'DELETE_DRAWABLE_FROM_ALL_GROUPS'
export const ADD_DRAWABLE_TO_ALL_GROUPS = 'ADD_DRAWABLE_TO_ALL_GROUPS'

const fetchMappingPending = () => {
    return {
        type: FETCH_MAPPING_PENDING,
    }
}

const fetchMappingSuccess = (res: IMappings) => {
    return {
        type: FETCH_MAPPING_SUCCESS,
        payload: res,
    }
}

const fetchMappingError = (error) => {
    return {
        type: FETCH_MAPPING_ERROR,
        error: error,
    }
}

const fetchDrawablePending = () => {
    return {
        type: FETCH_DRAWABLE_PENDING,
    }
}

const fetchDrawableSuccess = (drawables: ActiveDrawable[]) => {
    return {
        type: FETCH_DRAWABLE_SUCCESS,
        payload: drawables,
    }
}

const fetchOpeningGroupPending = () => {
    return {
        type: FETCH_OPENING_GROUPS_PENDING,
    }
}

const fetchOpeningGroupsSuccess = (openingGroups: any) => {
    return {
        type: FETCH_OPENING_GROUPS_SUCCESS,
        payload: openingGroups,
    }
}

const fetchProjectSuccess = (project: Project | {}) => {
    return {
        type: FETCH_PROJECT_SUCCESS,
        payload: project,
    }
}

const setPendingState = () => {
    return {
        type: SET_PENDING,
    }
}

const updateOpeningGroupsSuccess = (res: UpdateOpeningGroupApiResponse) => {
    return {
        type: UPDATE_OPENING_GROUPS_SUCCESS,
        payload: {
            openingGroups: res,
        },
    }
}

const updatedDrawableMeasurements = (payload: UpdateDrawableMeasurements) => {
    return {
        type: UPDATE_DRAWABLES_MEASUREMENTS,
        payload: payload,
    }
}

const createDrawablePending = () => {
    return {
        type: CREATE_DRAWABLE_PENDING,
    }
}

const createDrawableSuccess = (drawable: ActiveDrawable) => {
    return {
        type: CREATE_DRAWABLE_SUCCESS,
        payload: drawable,
    }
}

const setErrorState = (error) => {
    return {
        type: SET_ERROR,
        error: error,
    }
}

const setActiveFloor = (floor: ActiveFloor) => {
    return {
        type: SET_ACTIVE_FLOOR,
        payload: floor,
    }
}

const setActiveFloorFromDocChunkId = (documentChunkId: string) => {
    return {
        type: SET_ACTIVE_FLOOR_FROM_DOC_CHUNK_ID,
        payload: documentChunkId,
    }
}

const toggleAllPagesView = (isAllPages: boolean) => {
    return {
        type: TOGGLE_ALL_PAGES_VIEW,
        payload: isAllPages,
    }
}

const setActiveChunk = (chunkInfo: { type: string; index: number }) => {
    return {
        type: SET_ACTIVE_CHUNK,
        payload: chunkInfo,
    }
}

const setActiveButton = (type) => {
    return {
        type: SET_ACTIVE_BUTTON,
        payload: {
            type,
        },
    }
}

const setLocationErrorMessage = (msg: string) => {
    return {
        type: SET_LOCATION_ERROR_MSG,
        payload: msg,
    }
}

const setActiveDrawable = (drawable: any, index: number) => {
    return {
        type: SET_ACTIVE_DRAWABLE,
        payload: {
            drawable,
            index,
        },
    }
}

const toggleLinkingForm = (showLinkingForm: boolean) => {
    return {
        type: TOGGLE_LINKING_FORM,
        payload: showLinkingForm,
    }
}

/**
 * Action toggles isActive property of the drawable
 * in IM isActive property is used for UI purposes (to change drawable opacity on the plan)
 * @param drawable_id
 */
const toggleActiveDrawable = (drawable_id: number | number[]) => {
    return {
        type: TOGGLE_ACTIVE_DRAWABLE,
        payload: {
            drawable_id,
        },
    }
}

const updateDrawable = (drawable: ActiveDrawable) => {
    return {
        type: UPDATE_DRAWABLE,
        payload: {
            drawable,
        },
    }
}

const addNewDrawableToGroup = (drawable: ActiveDrawable, drawableGroupId: number) => {
    return {
        type: ADD_NEW_DRAWABLE_TO_GROUP,
        payload: {
            drawable,
            drawableGroupId,
        },
    }
}

const toggleActiveDrawableGroup = (openingIds: number[]) => {
    return {
        type: TOGGLE_ACTIVE_DRAWABLE_GROUP,
        payload: {
            openingIds,
        },
    }
}

const toggleDrawableGroup = (drawables: any, activeChunkId: number | undefined) => {
    return {
        type: TOGGLE_DRAWABLE_GROUP,
        payload: { drawables: drawables || [], activeChunkId },
    }
}

const toggleDrawableGroupsByType = (type: any[], activeChunkId: number | undefined) => {
    return {
        type: TOGGLE_DRAWABLE_GROUPS_BY_TYPE,
        payload: { type, activeChunkId },
    }
}

const activateOnlyClickedDrawableGroupByType = (type: any[], activeChunkId: number | undefined) => {
    return {
        type: ACTIVATE_ONLY_CLICKED_DRAWABLE_GROUP_BY_TYPE,
        payload: { type, activeChunkId },
    }
}

const toggleDrawableGroups = () => {
    return {
        type: TOGGLE_DRAWABLE_GROUPS,
    }
}

const toggleDrawableGroupsVisibility = (isShowAllTabs: boolean) => {
    return {
        type: TOGGLE_DRAWABLE_GROUPS_VISIBILITY,
        payload: {
            isShowAllTabs,
        },
    }
}

const filterDrawableGroups = () => {
    return {
        type: FILTER_DRAWABLE_GROUPS,
    }
}

// TODO: activeDrawableGroup type should be ActiveDrawable, but it fails in other places
//  need to fix it later to make sure we use it properly
const setActiveDrawableGroup = (activeDrawableGroup: any, activeDrawableId?: number | number[]) => {
    return {
        type: SET_ACTIVE_DRAWABLE_GROUP,
        payload: {
            activeDrawableGroup: activeDrawableGroup || [],
            activeDrawableId,
        },
    }
}

const setActiveDrawableGroupFromID = (
    selectedItemId: number,
    activeDrawableId: number,
    openingGroupId: number,
    activeOpeningLocationId: number
) => {
    return {
        type: SET_ACTIVE_DRAWABLE_GROUP_FROM_ID,
        payload: {
            selectedItemId,
            activeDrawableId,
            openingGroupId,
            activeOpeningLocationId,
        },
    }
}

const setFloorDrawables = (drawables: ActiveDrawable[] | string) => {
    return {
        type: SET_FLOOR_DRAWABLES,
        payload: drawables === '' ? [] : drawables,
    }
}

const setNewActiveDrawableCoordinates = (drawable_coordinates) => {
    return {
        type: SET_NEW_ACTIVE_DRAWABLE_COORDINATES,
        payload: drawable_coordinates,
    }
}

const updateDrawableCoordinates = (drawable_coordinates: any, typeActive: string, drawable_type?: DRAWABLE_TYPES) => {
    return {
        type: UPDATE_DRAWABLE_COORDINATES,
        payload: {
            drawable_coordinates,
            typeActive,
            drawable_type,
        },
    }
}

const addDrawable = (type: string) => {
    return {
        type: ADD_DRAWABLE,
        payload: {
            type: type,
        },
    }
}

const setCalibration = (isCalibrationActive: boolean, coordinates: any) => {
    return {
        type: SET_CALIBRATION,
        payload: {
            isCalibrationActive,
            coordinates,
        },
    }
}

const updateScale = (scaleFactor: string) => {
    return {
        type: UPDATE_SCALE,
        payload: {
            scaleFactor,
        },
    }
}

const addDeleteDrawableIndex = (index: number, documentChunkId: number) => {
    return {
        type: ADD_DELETE_DRAWABLE_INDEX,
        payload: {
            index,
            documentChunkId,
        },
    }
}

const deleteDrawableFromAllGroups = (drawableId: number) => {
    return {
        type: DELETE_DRAWABLE_FROM_ALL_GROUPS,
        payload: drawableId,
    }
}

const addDrawableToAllGroups = (drawable: ActiveDrawable) => {
    return {
        type: ADD_DRAWABLE_TO_ALL_GROUPS,
        payload: drawable,
    }
}

const setProjectBuildings = (buildings: Building[]) => {
    return {
        type: SET_PROJECT_BUILDINGS,
        payload: {
            buildings,
        },
    }
}

const setProjectModifications = (modifications: Modification[]) => {
    return {
        type: SET_PROJECT_MODIFICATIONS,
        payload: {
            modifications,
        },
    }
}

const setNotificationModal = (showModal: boolean) => {
    return {
        type: SET_NOTIFICATION_MODAL,
        payload: showModal,
    }
}

// The exiting flag will only be present when a toggle is called onMouseLeave
// This exists so that we do not invert animations by only having a "one way" toggle
const toggleDrawableOnHover = (drawable_id: number, exiting?: boolean) => {
    return {
        type: TOGGLE_DRAWABLE_ON_HOVER,
        payload: {
            drawable_id,
            exiting,
        },
    }
}

const changeDrawableGroupType = (drawableGroup: OpeningGroup) => {
    return {
        type: CHANGE_DRAWABLE_GROUP_TYPE,
        payload: {
            drawableGroup,
        },
    }
}

const resetDrawableGroups = () => {
    return {
        type: RESET_DRAWABLE_GROUPS,
    }
}

const fetchProjectDataFromPortal = (data: any) => {
    return {
        type: FETCH_PROJECT_DATA_FROM_PORTAL,
        payload: data,
    }
}

const fetchConfiguratorSpecSheet = (data: NewSpecSheetData[]): { type: string; payload: NewSpecSheetData[] } => {
    return {
        type: FETCH_CONFIGURATOR_SPEC_SHEET,
        payload: data,
    }
}

const setOpeningGroupDoneStatus = (status: boolean) => {
    return {
        type: SET_GROUP_STATUS,
        payload: {
            status,
        },
    }
}

const setFilterCardValue = (filterCardValue: string): { type: string; payload: { filterCardValue: string } } => {
    return {
        type: UPDATE_CARD_ACTIVE_STATUS,
        payload: {
            filterCardValue,
        },
    }
}

const updateMappingOrder = (mapping: any): any => {
    return {
        type: UPDATE_MAPPING_ORDER,
        payload: {
            mapping,
        },
    }
}

const updateBuilding = (building: Building): any => {
    return {
        type: UPDATE_BUILDING,
        payload: {
            building,
        },
    }
}

const deleteBuilding = (buildingId: Building['id']): any => {
    return {
        type: DELETE_BUILDING,
        payload: {
            buildingId,
        },
    }
}

const addBuilding = (building: Building): any => {
    return {
        type: ADD_BUILDING,
        payload: building,
    }
}

const toggleNote = (toggleNoteId: number | null) => {
    return {
        type: TOGGLE_NOTE,
        payload: {
            toggleNoteId,
        },
    }
}

const updateJoistLinesSuccess = (res) => {
    return {
        type: UPDATE_JOIST_LINES,
        payload: {
            res,
        },
    }
}

const resetDrawablesState = () => {
    return {
        type: RESET_DRAWABLES,
    }
}

export {
    activateOnlyClickedDrawableGroupByType,
    addBuilding,
    addDeleteDrawableIndex,
    addDrawable,
    addDrawableToAllGroups,
    addNewDrawableToGroup,
    changeDrawableGroupType,
    createDrawablePending,
    createDrawableSuccess,
    deleteBuilding,
    deleteDrawableFromAllGroups,
    fetchConfiguratorSpecSheet,
    fetchDrawablePending,
    fetchDrawableSuccess,
    fetchMappingError,
    fetchMappingPending,
    fetchMappingSuccess,
    fetchOpeningGroupPending,
    fetchOpeningGroupsSuccess,
    fetchProjectDataFromPortal,
    fetchProjectSuccess,
    filterDrawableGroups,
    resetDrawableGroups,
    resetDrawablesState,
    setActiveButton,
    setActiveChunk,
    setActiveDrawable,
    setActiveDrawableGroup,
    setActiveDrawableGroupFromID,
    setActiveFloor,
    setActiveFloorFromDocChunkId,
    setCalibration,
    setErrorState,
    setFilterCardValue,
    setFloorDrawables,
    setLocationErrorMessage,
    setNewActiveDrawableCoordinates,
    setNotificationModal,
    setOpeningGroupDoneStatus,
    setPendingState,
    setProjectBuildings,
    setProjectModifications,
    toggleActiveDrawable,
    toggleActiveDrawableGroup,
    toggleAllPagesView,
    toggleDrawableGroup,
    toggleDrawableGroups,
    toggleDrawableGroupsByType,
    toggleDrawableGroupsVisibility,
    toggleDrawableOnHover,
    toggleLinkingForm,
    toggleNote,
    updateBuilding,
    updateDrawable,
    updateDrawableCoordinates,
    updateJoistLinesSuccess,
    updateMappingOrder,
    updateOpeningGroupsSuccess,
    updateScale,
    updatedDrawableMeasurements,
}

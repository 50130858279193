import { createAction } from '@reduxjs/toolkit'
import { ForkEffect, call, put, takeEvery } from 'redux-saga/effects'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Flag, Workspace } from '../../lib/toolBoxes/2D'
import { setFlagsVisibility } from '../../slices/tools'

export const toggleMaterialFlagsVisibilityAction = createAction<boolean>('toggleMaterialFlagsVisibility')

export function* handleToggleMaterialFlagsVisibility({
    payload: isMaterialFlagsVisible,
}: ReturnType<typeof toggleMaterialFlagsVisibilityAction>) {
    const paperManager: PaperManager | null = yield call(managers.get2DManager)

    if (!paperManager) return

    const workspaceTool: Workspace = yield call(paperManager.getTool, Workspace.NAME)

    const materialFlagsItems: paper.Item[] = yield call(
        workspaceTool.getItemsWithCriteria,
        'data',
        (data) => data.toolName === Flag.NAME
    )

    materialFlagsItems.forEach((materialFlag) => {
        materialFlag.visible = isMaterialFlagsVisible
    })

    yield put(setFlagsVisibility(isMaterialFlagsVisible))
}

export function* watchForToggleMaterialFlagsVisibility(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(toggleMaterialFlagsVisibilityAction.type, handleToggleMaterialFlagsVisibility)
}

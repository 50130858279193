import { FormValidation, UiSchema } from '@rjsf/core'
import { FormProperties } from '../imup/types'

const uiSchema: UiSchema = {
    label: {
        'ui:title': 'Label',
        'ui:widget': 'hidden',
    },
    'ui:order': ['label', 'type', 'selection'],
    type: {
        'ui:placeholder': 'Select Flashing Type',
    },
    selection: {
        'ui:placeholder': 'Select Selection',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
}

const onValidate = (formData: any, errors: FormValidation) => {}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    newSettings.label = !formData.label && settings.label ? null : formData.label

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form

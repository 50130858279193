import isEqual from 'lodash/isEqual'
import isNull from 'lodash/isNull'
import { all, call, CallEffect, put, select } from 'redux-saga/effects'
import { setActiveDrawableGroup, updateJoistLinesSuccess } from '../../../actions/drawable'
import { ActiveFloor } from '../../../models/activeFloor'
import { getActiveFloor } from '../../../reducers/drawable'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Color, Count, Label, PathTool, PolygonTool, RadiusLine, Workspace } from '../../lib/toolBoxes/2D'
import addTooltipsToPath from '../../lib/utils/functionality-bindings/addTooltipsToPath'
import { setDrawableLocations } from '../../slices/2D'
import { ActiveGeometryGroup, selectActiveGeometryGroup } from '../../slices/geometry'
import { IMUP2DDrawableLocation } from '../../types'
import { calculateNewDrawableLocations } from './calculateDrawableLocationsFromNewGroups'
import drawShapeByType from './drawShapeByType'
import { RedrawShapeStoreState, selectRedrawState } from './handleUpdateOpeningGroupsSuccess'

export function* redrawJoistLinesOnUpdate(action: ReturnType<typeof updateJoistLinesSuccess>) {
    try {
        const manager: PaperManager | null = yield call(managers.get2DManager)

        if (!manager) return

        const newJoistGroup = action.payload.res

        const [colorTool, countTool, pathTool, polygonTool, radiusLineTool, workspaceTool, labelTool] = yield call(
            manager.getTools,
            [Color.NAME, Count.NAME, PathTool.NAME, PolygonTool.NAME, RadiusLine.NAME, Workspace.NAME, Label.NAME]
        )

        const { areaOpacity, lineOpacity }: RedrawShapeStoreState = yield select(selectRedrawState)

        const currentDrawableGroup: ActiveGeometryGroup | null = yield select(selectActiveGeometryGroup)

        if (isNull(currentDrawableGroup)) return

        const {
            additionalDrawableLocations,
            newDrawableLocations,
        }: { additionalDrawableLocations: IMUP2DDrawableLocation[]; newDrawableLocations: IMUP2DDrawableLocation[] } =
            yield call(calculateNewDrawableLocations, [newJoistGroup])

        const currentJoists: paper.Item[] = yield call(workspaceTool.getItemsWithCriteria, 'data', (data: any) => {
            return data.opening_group_id === currentDrawableGroup.id
        })

        // Remove the current joists from the canvas -- will be replaced below
        yield all(currentJoists.map((joist) => call([joist, 'remove'])))

        const activeFloor: ActiveFloor = yield select(getActiveFloor)

        const paths: Array<paper.Path> = yield all(
            additionalDrawableLocations.reduce(
                (paths, location) =>
                    isEqual(location.document_chunk_id, activeFloor.document_chunk.id)
                        ? [
                              ...paths,
                              call(
                                  drawShapeByType,
                                  location,
                                  colorTool,
                                  countTool,
                                  pathTool,
                                  polygonTool,
                                  radiusLineTool,
                                  workspaceTool,
                                  labelTool,
                                  areaOpacity,
                                  lineOpacity,
                                  activeFloor
                              ),
                          ]
                        : paths,
                [] as Array<CallEffect<paper.Path>>
            )
        )

        yield all(paths.map((path) => call(addTooltipsToPath, path, workspaceTool)))

        // we need set active drawable group from new joist to display joist lines
        yield put(setActiveDrawableGroup(newJoistGroup))

        // We've updated the joist's drawable locations -- we need to save them in the store's 2D slice.
        yield put(setDrawableLocations(newDrawableLocations))
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}

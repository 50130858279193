import { createSelector, PayloadAction } from '@reduxjs/toolkit'
import { call, fork, select } from 'redux-saga/effects'
import { RootState } from '../../../stores'
import managers from '../../lib/managers'
import { Color, Workspace } from '../../lib/toolBoxes/2D'
import { resetDrawableHighlightState } from './resetDrawableHighlightState'

export const selectEditingActive = createSelector(
    (state: RootState) => state.IMUP['2D'].editingActive,
    (state) => state
)

export function* handleToggleDrawableOnHover2D(action: PayloadAction<{ drawable_id: number; exiting?: boolean }>) {
    const paperManager = yield call(managers.get2DManager)
    if (!paperManager) return
    const workspaceTool: Workspace = yield call(paperManager.getTool, Workspace.NAME)

    const path: paper.PathItem = yield call(workspaceTool.getItemWithDrawableId, action.payload.drawable_id)

    // Return if there's no path found
    if (!path) return

    const editingActive: boolean = yield select(selectEditingActive)

    if (editingActive) return

    // Attach the path's animation function and highlight it if there is no animation active on the project view
    // Otherwise, clear the animation and reset the shape
    const paperScope: paper.PaperScope = yield call(workspaceTool.getPaperScope)
    if (!paperScope.project.view.onFrame && !action.payload.exiting) {
        const colorTool: Color = yield call(paperManager.getTool, Color.NAME)
        const redColor = yield call(colorTool.createColor, 'red')
        yield call(highlightPath, path, redColor)
        yield call(animatePath, path)
    } else {
        yield call(removeAnimation, path)

        if (path.strokeColor || path.fillColor) yield fork(resetDrawableHighlightState, path)

        if (path.strokeWidth) path.strokeWidth = path.data.originalStrokeWidth
    }
}

export function animatePath(path: paper.PathItem) {
    path.project.view.onFrame = path.data.onFrameHandler
}

export function removeAnimation(path: paper.PathItem) {
    path.project.view.onFrame = null
}

export function highlightPath(path: paper.PathItem, color: paper.Color) {
    path.strokeColor = color
}

import { DocumentChunk } from './documentChunk'

export enum FlagEnum {
    TASK = 'task',
    NOTE = 'note',
}

export interface NewOpeningLink {
    opening_id: number | null
    coordinates: [number, number]
    document_chunk_id: DocumentChunk['id']
}

export interface OpeningLink extends NewOpeningLink {
    id: number
}

export interface OpeningLinkWithFlagId extends OpeningLink {
    flag_id: number
    order: number
    type: string | null
}

export interface FlagOption {
    id: number
    name: string
}

interface SubCategoriesOption extends FlagOption {
    category_id: number
}

export interface FlagOptions {
    types: FlagOption[]
    statuses: FlagOption[]
    severities: FlagOption[]
    categories: FlagOption[]
    subCategories: SubCategoriesOption[]
}

export interface BaseFlag {
    id: number
    project_id: number
    created_by_user_email: string
    created_by_username: string
    description: string
    document_chunk_id: number
    opening_links: OpeningLink[]
    order: number
}

export interface NormalizedFlag extends BaseFlag {
    order: number
    type?: FlagOption | null
    status?: FlagOption | null
    severity?: FlagOption | null
    category?: FlagOption | null
    sub_category?: FlagOption | null
    created_at: string | null
}

export interface FlagFormData {
    description: string
    type_id: number
    created_by_username: string
    created_by_user_email: string
    status_id?: number | null
    category_id?: number | null
    sub_category_id?: number | null
    severity_id?: number | null
}

export interface FlagAPIPayload extends FlagFormData {
    opening_links: NewOpeningLink[]
}

export interface FlagAPIUpdatePayload {
    status_id: number
    category_id?: number
    sub_category_id?: number
    description?: string
}

export interface FlagAPIResponse extends BaseFlag {
    category_id?: number | null
    severity_id?: number | null
    status_id?: number | null
    sub_category_id?: number | null
    type_id?: number | null
    created_at: string | null
}

export interface FlagOpeningLinksAPIUpdatePayload {
    id: number
    flag_id: number
    coordinates: [number, number]
    opening_id?: number
}

export interface FlagAxiosAPIResponse {
    updatedFlag: FlagAPIResponse
}

import isNull from 'lodash/isNull'
import { AzureFeatureFlagIds } from '../../constants/azure-feature-flags'
import { AzureFeatureFlag } from '../../../models/azureFeatureFlags'
import { getAzureFeatureFlags } from '../../../api/permissions-api'

/**
 * By list of received azure feature flags, check if a current flag exists and if it's enabled
 *
 * @param azureFeatureFlags
 * @param azureFeatureFlagIds
 */
export const isAzureFeatureFlagEnabled = (
    azureFeatureFlags: AzureFeatureFlag[] | null,
    azureFeatureFlagIds: AzureFeatureFlagIds
): boolean => {
    if (isNull(azureFeatureFlags)) return false

    const foundAzureFeatureFlag: AzureFeatureFlag | undefined = azureFeatureFlags.find(
        (flag) => flag.id === azureFeatureFlagIds
    )

    return foundAzureFeatureFlag?.id ? foundAzureFeatureFlag.enabled : false
}

export const getAzureFeatureFlagsOrUseExisting = async (
    azureFeatureFlags: AzureFeatureFlag[] | null,
    setAzureFlagsCallBack: (azureFeatureFlags: AzureFeatureFlag[]) => void
): Promise<AzureFeatureFlag[] | null> => {
    if (isNull(azureFeatureFlags)) {
        return await getAzureFeatureFlags().then((res) => {
            // set flags into store
            setAzureFlagsCallBack(res)

            // return flags in case we use them without page refresh
            return res
        })
    }

    return azureFeatureFlags
}

import { Cursors } from '../../../types'
import { Workspace } from '../../toolBoxes/2D'

const show2DTooltip = (workspaceTool: Workspace): (() => void) =>
    function showTooltip(this: paper.Path) {
        if (workspaceTool.currentActiveCursor === Cursors.GRABBING) return
        const { originalStrokeColor, title, opening_location_id } = this.data
        if (originalStrokeColor) {
            workspaceTool.setTooltip({
                visible: true,
                title: title,
                color: originalStrokeColor.toCSS(true),
            })
            workspaceTool.toggleDrawable(opening_location_id)
        }
    }

const hide2DTooltip = (workspaceTool: Workspace): (() => void) =>
    function hideTooltip(this: paper.Path) {
        if (workspaceTool.currentActiveCursor === Cursors.GRABBING) return
        workspaceTool.setTooltip({
            visible: false,
            title: '',
            color: '#ffffff',
        })
        if (!this.data.shapeHasChanged) {
            workspaceTool.toggleDrawable(this.data.opening_location_id, true)
        }
        this.data.shapeHasChanged = false
    }

export default function addTooltipsToPath(path: paper.Path, workspaceTool: Workspace): void {
    // show tooltips on mouseenter
    path.onMouseEnter = show2DTooltip(workspaceTool).bind(path)

    // hide tooltip on mouseleave
    path.onMouseLeave = hide2DTooltip(workspaceTool).bind(path)
}

import { History } from 'history'
import moment from 'moment'
import { ReactElement } from 'react'
import { Dropdown } from 'react-bootstrap'
import { reCreateMarkupOnlyProject, reset3DProject, resetProject } from '../../api/projects-api'
import { completeMarkup, updateProjectByPortalNumber } from '../../api/takeoff-api'
import { Project } from '../../models/project'
import { UserData } from '../../models/user'
import { APP_NAMES } from '../../shared/constants/app-names'
import { DRAWABLE_TYPES } from '../../shared/constants/drawable-types'
import { PROJECT_ACTION_VISIBILITY } from '../../shared/constants/project-action-visibility'
import { PROJECT_ACTIONS } from '../../shared/constants/project-actions'
import { PROJECT_STATUSES_ENUM } from '../../shared/constants/project-list.constants'
import { PROJECT_TYPES_ENUM } from '../../shared/constants/project-type.constants'
import { PROJECT_STATUS_NAME, isProjectStatusEnableFlags } from '../../shared/constants/projectStatusName'
import { USER_PERMISSIONS_NAMES } from '../../shared/constants/user-permissions'
import { prepareDataQA } from '../stringFormatters'

const actionPerformed = (
    action: string,
    project: Project,
    history: any,
    handleUpdateDataModalOpen: (isMerge: boolean) => void,
    handleAIMagicModalOpen: (id?: number) => void,
    handleRecalculateMeasurementsModalOpen?: (id: number) => void,
    setProject?: (project: Project | undefined) => void
): void => {
    switch (action) {
        case 'settings':
            history.push(`/project/${project.id}/settings`)
            break
        case 'takeoff':
            if (project?.projectStatus?.name === PROJECT_STATUS_NAME.AI_PROCESSING) handleAIMagicModalOpen()
            else history.push(`/project/${project.id}/takeoff`)
            break
        case 'markup':
            history.push(`/project/${project.id}/markup`)
            break
        case 'update_data':
            handleUpdateDataModalOpen(false)
            break
        case 'update_data_2d':
            resetProject(project.id, false, false)
            break
        case 'update_data_3d':
            reset3DProject(project.portal_number)
            break
        case 'merge_data':
            handleUpdateDataModalOpen(true)
            break
        case 'windows_schedule':
            window.open(project.outputs?.scheduleURL ?? '', '_blank')
            break
        case 'windows_schedule_lite':
            window.open(project.outputs?.detailLiteOutputURL ?? '', '_blank')
            break
        case 'windows_schedule_gateway':
            window.open(project.outputs?.detailGatewayOutputURL ?? '', '_blank')
            break
        case 'windows_schedule_group':
            window.open(project.outputs?.hybridURL ?? '', '_blank')
            break
        case 'clear_project_errors':
            project.portal_number &&
                updateProjectByPortalNumber(
                    project.portal_number,
                    project.type,
                    PROJECT_STATUSES_ENUM.IN_PROGRESS,
                    PROJECT_STATUS_NAME.IN_PROGRESS,
                    setProject
                )
            break
        case 're_create_markup_only_project':
            project.portal_number && reCreateMarkupOnlyProject(project.portal_number)
            break
        case 'view_window_takeoff_csv':
            window.open(project.outputs?.csvUrl ?? '', '_blank')
            break
        case 'recalculate_measurements':
            handleRecalculateMeasurementsModalOpen && handleRecalculateMeasurementsModalOpen(project.id)
            break
        case 'generate_deliverables':
            completeMarkup(project.id, '', true)
            break
        default:
            break
    }
}

export const getProjectPDFActions = (
    project: Project,
    userPermissions: UserData['permissions'],
    isProjectList: boolean,
    handleUpdateDataModalOpen: () => void,
    handleAIMagicModalOpen: (id?: number) => void,
    history: any,
    handleRecalculateMeasurementsModalOpen?: (id?: number) => void
): {
    openScheduleAction?: () => void
    openLiteAction?: () => void
    openGatewayAction?: () => void
    openHybridGatewayAction?: () => void
} => {
    let openScheduleAction: (() => void) | undefined = undefined
    if (project.outputs?.scheduleURL) {
        openScheduleAction = getAction(
            project,
            userPermissions,
            'windows_schedule',
            handleUpdateDataModalOpen,
            handleAIMagicModalOpen,
            history,
            handleRecalculateMeasurementsModalOpen
        )
    }

    let openLiteAction: (() => void) | undefined = undefined
    if (project.outputs?.detailLiteOutputURL) {
        openLiteAction = getAction(
            project,
            userPermissions,
            'windows_schedule_lite',
            handleUpdateDataModalOpen,
            handleAIMagicModalOpen,
            history,
            handleRecalculateMeasurementsModalOpen
        )
    }

    let openGatewayAction: (() => void) | undefined = undefined
    if (project.outputs?.detailGatewayOutputURL) {
        openGatewayAction = getAction(
            project,
            userPermissions,
            'windows_schedule_gateway',
            handleUpdateDataModalOpen,
            handleAIMagicModalOpen,
            history,
            handleRecalculateMeasurementsModalOpen
        )
    }

    let openHybridGatewayAction: (() => void) | undefined = undefined
    if (project.outputs?.hybridURL) {
        openHybridGatewayAction = getAction(
            project,
            userPermissions,
            'windows_schedule_group',
            handleUpdateDataModalOpen,
            handleAIMagicModalOpen,
            history,
            handleRecalculateMeasurementsModalOpen
        )
    }

    return {
        openScheduleAction: openScheduleAction,
        openLiteAction: openLiteAction,
        openGatewayAction: openGatewayAction,
        openHybridGatewayAction: openHybridGatewayAction,
    }
}

function getAction(
    project: Project,
    userPermissions: UserData['permissions'],
    actionKey: string,
    handleUpdateDataModalOpen: (isMerge?: boolean) => void,
    handleAIMagicModalOpen: (id?: number) => void,
    handleRecalculateMeasurementsModalOpen: (id?: number) => void,
    history: any
): (() => void) | undefined {
    const projectActions = PROJECT_ACTION_VISIBILITY[actionKey]

    if (!projectActions) {
        return
    }

    const userPermissionNames = projectActions.permissions.filter((permission) =>
        Object.values(USER_PERMISSIONS_NAMES).find((value) => value === permission)
    )

    if (
        projectActions?.projectStatuses?.includes(project?.projectStatus?.name) &&
        hasPermission(userPermissions, userPermissionNames) &&
        projectActions?.projectTypes.includes(project.type)
    ) {
        return (): void =>
            actionPerformed(
                actionKey,
                project,
                history,
                handleUpdateDataModalOpen,
                handleAIMagicModalOpen,
                handleRecalculateMeasurementsModalOpen
            )
    }

    return
}

function projectCanBeRecreated(projectCreatedAt: string): boolean {
    const minutes = 15

    const projectCreatedAtTimestamp: number = moment.utc(projectCreatedAt).local().add(minutes, 'minutes').valueOf()

    const currentDate: Date = new Date()
    const currentTimestamp: number = currentDate.getTime()

    return projectCreatedAtTimestamp <= currentTimestamp
}

export const initActions = (
    project: Project,
    userPermissions: UserData['permissions'],
    isProjectList: boolean,
    handleUpdateDataModalOpen: (isMerge: boolean) => void,
    handleAIMagicModalOpen: (id?: number) => void,
    history: History,
    handleRecalculateMeasurementsModalOpen?: (id: number) => void,
    setProject?: (project: Project | undefined) => void
): ReactElement[] => {
    const actions: ReactElement[] = []
    PROJECT_ACTIONS.forEach((action: { label: string; hash: string }, index: number) => {
        const projectActions = PROJECT_ACTION_VISIBILITY[action.hash]

        if (projectActions) {
            const userPermissionNames = projectActions.permissions.filter((role) =>
                Object.values(USER_PERMISSIONS_NAMES).find((value) => value === role)
            )

            if (
                !projectActions?.projectStatuses?.includes(project?.projectStatus?.name) ||
                !hasPermission(userPermissions, userPermissionNames) ||
                !projectActions.projectTypes.includes(project.type)
            ) {
                return
            }
        }

        if (action.hash === 'windows_schedule' && !project.outputs?.scheduleURL) {
            return
        }

        if (action.hash === 'windows_schedule_lite' && !project.outputs?.detailLiteOutputURL) {
            return
        }

        if (action.hash === 'windows_schedule_gateway' && !project.outputs?.detailGatewayOutputURL) {
            return
        }

        if (action.hash === 'windows_schedule_group' && !project.outputs?.hybridURL) {
            return
        }

        if (action.hash === 'view_window_takeoff_csv' && !project.outputs?.csvUrl) {
            return
        }

        if (action.hash === 'assign') {
            return
        }

        if (action.hash === 'recalculate_measurements' && project.type !== PROJECT_TYPES_ENUM.INTERACTIVE_MARKUP_ONLY) {
            return
        }

        const isProjectCanBeRecreated: boolean = projectCanBeRecreated(project.created_at)
        if (action.hash === 're_create_markup_only_project' && !isProjectCanBeRecreated) {
            return
        }

        if (!isProjectList) {
            actions.push(
                <li
                    className="tool"
                    data-qa={`${prepareDataQA(action.hash)}-button`}
                    onClick={(): void =>
                        actionPerformed(
                            action.hash,
                            project,
                            history,
                            handleUpdateDataModalOpen,
                            handleAIMagicModalOpen,
                            handleRecalculateMeasurementsModalOpen,
                            setProject
                        )
                    }
                >
                    {action.label}
                </li>
            )
        } else {
            // Don't show update & merge Data actions when the project isn't out of sync or in an error state
            if (
                (action.hash === 'update_data' || action.hash === 'merge_data') &&
                project?.projectStatus?.name !== PROJECT_STATUS_NAME.ERROR
            ) {
                return
            }

            actions.push(
                <Dropdown.Item
                    key={index}
                    data-qa={`${prepareDataQA(action.hash)}-button`}
                    onClick={(): void =>
                        actionPerformed(
                            action.hash,
                            project,
                            history,
                            handleUpdateDataModalOpen,
                            handleAIMagicModalOpen,
                            handleRecalculateMeasurementsModalOpen
                        )
                    }
                >
                    {action.label}
                </Dropdown.Item>
            )
        }
    })

    return actions
}

export const hasPermission = (
    permissions: UserData['permissions'],
    permissionNames: USER_PERMISSIONS_NAMES[]
): boolean => {
    return !!permissions?.find(
        (permission) => permission.application === APP_NAMES.DIGITIZER && permissionNames?.includes(permission.name)
    )
}

const allowedJoistLinesDrawingTypes = new Set<DRAWABLE_TYPES>([
    DRAWABLE_TYPES.ROOF_SYSTEM,
    DRAWABLE_TYPES.FLOOR_SYSTEM,
    DRAWABLE_TYPES.I_JOIST,
    DRAWABLE_TYPES.RAFTER,
    DRAWABLE_TYPES.CEILING_JOIST,
    DRAWABLE_TYPES.SLEEPER_JOIST,
    DRAWABLE_TYPES.JOIST,
    DRAWABLE_TYPES.FLOOR_JOIST,
    DRAWABLE_TYPES.LE_JOIST,
])

// Determines in Paper.js if a Paper item is a joist line that should be hidden by default
export const isNonAreaJoistLine = (item: paper.Item) => {
    if (!item.data?.drawing_type) return false
    return allowedJoistLinesDrawingTypes.has(item.data.drawing_type) && !item.fillColor
}

export const getCustomerName = (customers, currentCustomerId?: string | undefined): string => {
    return customers?.find((customer) => customer.id === currentCustomerId)?.name || ''
}

export const getCustomerLocation = (locations, currentLocationId): string => {
    return locations?.find((location) => location.id === currentLocationId)?.name || ''
}

export const getPackagesList = (packages: string[]): string => {
    return packages.join(', ')
}

/**
 * Return boolean value based on a list of project statuses when the flags and flag tool should be visible
 * @param projectStatusName
 */
export const isFlagsEnabledForProject = (projectStatusName: PROJECT_STATUS_NAME): boolean => {
    return isProjectStatusEnableFlags.has(projectStatusName)
}

import { IManager, IStateMediator, ITool, ManagerConfig } from '../../types'
import AbstractToolBox from '../toolBoxes/AbstractToolBox'

/**
 * AbstractManager
 *
 * Holds reference to a mediator over app state
 *
 * Concrete implementations rely on a concrete implementation of the AbstractToolBox
 * to maintain a set of drawing or modeling tools.
 */
abstract class AbstractManager implements IManager {
    protected mediator: IStateMediator
    protected toolBox!: AbstractToolBox

    constructor(config: ManagerConfig) {
        this.mediator = config.mediator
    }

    public getTool = <T extends ITool>(name: string): T => this.toolBox.getTool(name)
    public getTools = (names: Array<string>): Array<ITool> => this.toolBox.getTools(names)

    public useTool = (name: string): ITool => {
        const tool = this.toolBox.getTool(name)

        tool.activate()

        this.mediator.mediate('common', { cursor: tool.cursor }) // default to auto cursor if tool cursor not specified
        this.mediator.mediate('tools', { activeTool: tool.name })
        return tool
    }

    public useTools = (names: Array<string>): Array<ITool> => names.map((name) => this.useTool(name))

    public dispose = () => {
        this.mediator.dispose()
    }
}

export default AbstractManager

// error messages that are used in markup forms
export const FORM_ERROR_MESSAGES = {
    LOCATION: 'At least one location needs to be selected',
    BATON_SPACING: 'Value must be between 1-99',
    CORNER_WIDTH: 'Provide a width with this selection',
    FRACTION:
        'Only numeric characters, decimals, and fractions allowed. Range limited from 0.01 to 99999.99 or from 1/2 to 99999 9/999. Fractions cannot represent a number greater than or equal to one. For example,  5/4 is not a valid value.',
    FEET: 'Wall Height must be a round number of 4 or higher',
    NUMBER_OF_PANELS:
        'The number of panels should match the sum of all the values given in Panels Operation Direction input',
    GREATER_ZERO: 'Should be greater than 0',
    FULL_NUMBER: 'Please enter a numeric value (Full number)',
    POSITIVE_INTEGER_REQUIRED: 'Please enter an integer greater than or equal to zero',
    STEEL_BEAM_SIZE: 'The Steel Beam Size should match the following format W[number]X[number]',
    PITCH_NUMBER: 'Value must be between 0 and 25',
    VALUE_MANDATORY: 'Value is mandatory',
    REQUIRED: 'is a required property',
    SOLID_BEAM_WIDTH_AND_HEIGHT: 'Please enter a numeric value (Full number) between 2 and 36',
    POST_WRAP_BOX_COLUMN_SIZE: 'Box Column Size must be provided',
    POST_WRAP_BOX_COLUMN_HEIGHT: 'Box Column Height Other value needs to be an even number',
    EMPTY_CASE: 'should be equal to one of the allowed values',
    WHOLE_NUMBER_AND_FRACTION: 'Should be whole number >= 1 or whole number with fraction ex. 3 or 5-5/8',
    WHOLE_NUMBER_WITH_POINT_FIVE: 'Full number between 0-99 and decimal only .5 ex. 3.5',
    WHOLE_NUMBER_WITH_DECIMAL_QUARTER_INCREMENT:
        'Full number between 0-99 and whole number with quarter decimals ex. 0.25, 1.5, 2.75',
    OC_SPACING: 'Should be the number between 6 and 72',
}

export const TRANSFORM_ERRORS = {
    FULL_NUMBER: '^\\d+$',
    FRACTION: '^\\d+([- ]\\d+)?[ /]\\d+$',
    WHOLE_NUMBER_WITH_POINT_FIVE: '^(?:\\d|[1-8]\\d|9[0-9])(?:\\.5)?$',
    WHOLE_NUMBER_WITH_DECIMAL_QUARTER_INCREMENT: '^(?:[1-9]\\d?|99|\\d{1,2}(?:\\.(?:25|5|75))?)$',
    WHOLE_NUMBER_AND_FRACTION: '^\\d+([- ]\\d+\\/\\d+)?$',
}

export const ACCESS_MESSAGES = {
    MARKUP: 'You do not have access to the markup',
    TAKEOFF: 'You do not have access to the takeoff',
    PROJECT_LIST: 'You do not have access to the project list',
    PROJECT_CREATION: 'You do not have access to project creation',
    PROJECT_PAGE: 'You do not have access to the project page',
    ADMIN_PAGE: 'You do not have access to the admin page',
}

export const CLASSIFICATION_MESSAGES = {
    VALIDATION: 'Please fill out all form fields before saving',
    NO_RASTER: 'No raster found in current project',
    NO_MANAGER: 'No manager for current project',
    NO_ACTIVE_DOCUMENT: 'No active document for current project',
    NO_MAPPING: 'No mapping found for this document. Has this document been grouped?',
    NO_REGION: 'No active region selected. Please click a region and retry.',
    CALIBRATION: 'Please input an expected length value for this line.',
    NOT_EDITABLE:
        'This region is not editable because there are materials placed within it. Please delete these items on the digitizer page before editing this region.',
}

export const ROTATE_ERRORS = {
    CANNOT_ROTATE: 'No regions or drawables can exist while rotating a plan.',
}

export const IMUP_MESSAGES = {
    SELECT_PAGE: 'Please select a page in the plans dropdown to the left.',
    DISABLED_NEEDS_CLASSIFICATION:
        'IMUP is disabled until plans have been grouped and classified. Move to the Plans tab to begin classification.',
    REGION_DELETION: 'Please note: all materials in the deleted region, will be re-calculated using Page Scale.',
}

import { PayloadAction } from '@reduxjs/toolkit'
import { call, fork, select, StrictEffect, takeEvery } from 'redux-saga/effects'
import { TOGGLE_ACTIVE_DRAWABLE } from '../../../actions/drawable'
import { ActiveDrawable } from '../../../models/activeDrawable'
import PaperManager from '../../lib/managers/PaperManager'
import { Workspace } from '../../lib/toolBoxes/2D'
import { selectActiveMode } from '../../slices/common'
import { VIEW_MODE } from '../../types'
import { handleToggleActiveDrawable2D } from '../2D/handleToggleActiveDrawable'
import { handleToggleActiveDrawable3D } from '../3D/handleToggleActiveDrawable'

export function* handleToggleActiveDrawable(
    action: PayloadAction<{ drawable_id: number | number[] }>
): Generator<StrictEffect, void, PaperManager & Workspace & paper.PathItem & VIEW_MODE & ActiveDrawable[]> {
    try {
        const activeMode = yield select(selectActiveMode)

        switch (activeMode) {
            case VIEW_MODE.Markup2D:
                yield fork(handleToggleActiveDrawable2D, action.payload.drawable_id)
                break
            case VIEW_MODE.Markup3D:
                yield fork(handleToggleActiveDrawable3D, action)
                break
            default:
                break
        }
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForToggleActiveDrawable() {
    yield takeEvery(TOGGLE_ACTIVE_DRAWABLE, handleToggleActiveDrawable)
}

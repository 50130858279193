import {
    CLEAN_PROJECT_STATE,
    FETCH_CUSTOMERS_SUCCESS,
    FETCH_CUSTOMER_LOCATIONS_SUCCESS,
    FETCH_PROJECT_ERROR,
    FETCH_PROJECT_PENDING,
    FETCH_PROJECT_SUCCESS,
    REMOVE_FLOOR_COORDINATES,
    ROTATE_IMAGES_LEFT,
    ROTATE_IMAGES_RIGHT,
    ROTATE_IMAGES_UPDATE,
    SELECTED_BOX_CHANGED,
    SET_SELECTED_CHUNK_ID,
    SET_SELECTED_FLOORS,
    STORE_IMAGE_REGION,
    STORE_IMAGE_SCALE,
    STORE_PAGE_NAME,
} from '../actions'
import { ActiveFloor } from '../models/activeFloor'
import { PLAN_VIEWS_ENUM } from '../shared/constants/plan-views'

const initialState = {
    pending: true,
    project: {},
    regions: [],
    fileScales: {},
    fileNames: {},
    error: null,
    selectedFloors: {
        floor: {},
        elevation: {},
        schedule: {},
        other: {},
    },
    customers: [],
    customerLocations: [],
}

export const projectReducer = (state: any = initialState, action) => {
    let regions
    let fileId
    let chunkId
    switch (action.type) {
        case FETCH_PROJECT_PENDING:
            return {
                ...state,
                pending: true,
            }
        case FETCH_PROJECT_SUCCESS:
            return {
                ...state,
                pending: false,
                project: action.payload,
            }
        case CLEAN_PROJECT_STATE:
            return {
                pending: true,
                project: {},
                regions: [],
                fileScales: {},
                fileNames: {},
                error: null,
                selectedFloors: {
                    floor: {},
                    elevation: {},
                    schedule: {},
                    other: {},
                },
                customers: [],
                customerLocations: [],
            }
        case FETCH_PROJECT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        case SELECTED_BOX_CHANGED:
            return {
                ...state,
                selectedBox: action.payload,
            }
        case SET_SELECTED_CHUNK_ID:
            return {
                ...state,
                chunkId: action.payload,
            }
        case STORE_IMAGE_REGION:
            regions = state.regions
            chunkId = action.payload.chunkId
            fileId = action.payload.fileId
            const regionsData = action.payload.regions
            const chunkData = regions.find((region) => region.chunkId === chunkId)

            if (chunkData) {
                chunkData.coordinates = regionsData
            } else {
                regions.push({
                    chunkId,
                    fileId,
                    coordinates: regionsData,
                })
            }

            return {
                ...state,
                regions,
            }
        case STORE_IMAGE_SCALE:
            const scale = action.payload.scale
            const fileScales = state.fileScales

            fileScales[state.chunkId] = scale

            return {
                ...state,
                fileScales,
            }
        case STORE_PAGE_NAME:
            const pageName = action.payload.pageName
            const fileNames = state.fileNames
            fileNames[state.chunkId] = pageName

            return {
                ...state,
                fileNames,
            }
        case SET_SELECTED_FLOORS:
            const selectedFloors = action.payload.selectedFloors
            const stateSelectedFloors = state.selectedFloors
            fileId = action.payload.fileId
            chunkId = state.chunkId

            if (state.selectedBox.type === 'floor') {
                stateSelectedFloors[state.selectedBox.type][fileId] = selectedFloors
            } else {
                if (stateSelectedFloors[state.selectedBox.type][fileId]) {
                    stateSelectedFloors[state.selectedBox.type][fileId][chunkId] = selectedFloors
                } else {
                    stateSelectedFloors[state.selectedBox.type][fileId] = {
                        [chunkId]: selectedFloors,
                    }
                }
            }

            return {
                ...state,
                selectedFloors: stateSelectedFloors,
            }
        case REMOVE_FLOOR_COORDINATES:
            const floorHash = action.payload.floorHash
            let chunk_id = action.payload.chunkId
            let file_id = action.payload.fileId
            regions = state.regions

            // If floor coordinates are removed by clicking region-selector icon, the current chuck_id and file_id can be used
            // If it's removed from the selection form - the Id will be passed
            if (!chunk_id) chunk_id = state.chunkId
            if (!file_id) file_id = state.selectedBox.file.id

            regions = regions.filter((region) => {
                return (region.coordinates = region.coordinates.filter((coordinate) => {
                    return (
                        region.fileId !== file_id || coordinate.data.floor !== floorHash || region.chunkId !== chunk_id
                    )
                }))
            })

            return {
                ...state,
                regions,
            }
        case FETCH_CUSTOMERS_SUCCESS:
            return {
                ...state,
                customers: action.payload,
            }
        case FETCH_CUSTOMER_LOCATIONS_SUCCESS:
            return {
                ...state,
                customerLocations: action.payload,
            }
        case ROTATE_IMAGES_RIGHT:
            const rotatedChunksRight = state.selectedBox.file.document_chunks.map((imageChunk, index) => {
                if (action.payload === index) {
                    // For some reason, this gets triggered twice on one click, will need to investigate for later
                    // For now, we will change rotation degree to 45 instead of 90
                    imageChunk.rotateDegree += 45
                }

                return imageChunk
            })

            return {
                ...state,
                selectedBox: {
                    ...state.selectedBox,
                    file: {
                        ...state.selectedBox.file,
                        document_chunks: rotatedChunksRight,
                    },
                },
            }
        case ROTATE_IMAGES_LEFT:
            const rotatedChunksLeft = state.selectedBox.file.document_chunks.map((imageChunk, index) => {
                if (action.payload === index) {
                    // For some reason, this gets triggered twice on one click, will need to investigate for later
                    // For now, we will change rotation degree to 45 instead of 90
                    imageChunk.rotateDegree -= 45
                }

                return imageChunk
            })

            return {
                ...state,
                selectedBox: {
                    ...state.selectedBox,
                    file: {
                        ...state.selectedBox.file,
                        document_chunks: rotatedChunksLeft,
                    },
                },
            }
        case ROTATE_IMAGES_UPDATE:
            return {
                ...state,
                selectedBox: {
                    ...state.selectedBox,
                    file: {
                        ...state.selectedBox.file,
                        document_chunks: action.payload,
                    },
                },
            }
        default:
            return state
    }
}

export const getProject = (state) => {
    return state.project
}
export const getProjectFloors = (state) => {
    return state.project?.project_floors?.map((projectFloor) => projectFloor.floor)
}
export const getSelectedBox = (state) => {
    return (
        state.selectedBox || {
            file: state.project?.project_documents && state.project?.project_documents[0]?.files[0],
        }
    )
}
export const getSelectedChunkId = (state) => {
    return state.chunkId
}

export const getProjectPending = (state) => state.pending
export const getProjectError = (state) => state.error
export const getRegionsCoordinates = (state, chunkId?) => {
    if (!chunkId) {
        const regions: any[] = Object.values(state.regions)
        return [].concat(...regions.map((region) => region.coordinates))
    }
    return state.regions.find((region) => region.chunkId === chunkId)?.coordinates || []
}
export const getRegions = (state) => {
    const regions = state.regions || []
    const result: any[] = []

    regions.forEach((region) => {
        region.coordinates.forEach((coordinate) => {
            result.push({
                floor: coordinate.data.floor,
                coordinates: coordinate,
                type: getProjectFloors(state)?.find((floor: ActiveFloor) => floor.hash === coordinate.data.floor).type,
                chunk_id: region.chunkId,
                file_id: region.fileId,
            })
        })
    })

    return result
}

export const getFileScales = (state) => {
    return state.fileScales
}
export const getFileNames = (state) => {
    return state.fileNames
}
export const getSelectedFloors = (state, type, fileId) => {
    if (type === 'floor') {
        if (!state.selectedFloors[type][fileId]) {
            return []
        }

        return state.selectedFloors[type][fileId]
    }
    if (state.selectedBox?.file.id !== fileId) {
        if (!state.selectedFloors[type][fileId]) {
            return []
        }
        const floors: any[] = Object.values(state.selectedFloors[type][fileId])
        return Array.from(new Set([].concat(...floors)))
    }

    const fileFloors = state.selectedFloors[type][fileId]

    if (fileFloors) {
        return fileFloors[state.chunkId] || []
    }

    return []
}

export const isMappingValid = (state) => {
    const projectDocuments = state.project.project_documents

    if (!projectDocuments) {
        return false
    }

    // Check if at least one region is mapped
    return projectDocuments[0].files.find((file) =>
        state.regions.find(
            (region) => region.fileId === file.id && region.coordinates?.length && state.fileScales[region.chunkId]
        )
    )
}

export const getDocumentsMapping = (state) => {
    const regions = state.regions
    const result: any[] = []

    regions.forEach((region) => {
        const remappedRegions = region.coordinates.map((coordinate) => {
            return {
                floor: coordinate.data.floor,
                coordinates: coordinate,
                type: getProjectFloors(state)?.find((floor: ActiveFloor) => floor.hash === coordinate.data.floor)?.type,
            }
        })

        const elevationRegions = remappedRegions.filter((region) => region.type === PLAN_VIEWS_ENUM.ELEVATION)
        const floorRegions = remappedRegions.filter((region) => region.type === PLAN_VIEWS_ENUM.FLOOR)
        const scheduleRegions = remappedRegions.filter((region) => region.type === PLAN_VIEWS_ENUM.SCHEDULE)
        const otherRegions = remappedRegions.filter((region) => region.type === PLAN_VIEWS_ENUM.OTHER)

        if (elevationRegions.length)
            result.push({
                chunk_id: region.chunkId,
                regions: elevationRegions,
                type: PLAN_VIEWS_ENUM.ELEVATION,
            })
        if (floorRegions.length)
            result.push({
                chunk_id: region.chunkId,
                regions: floorRegions,
                type: PLAN_VIEWS_ENUM.FLOOR,
            })
        if (scheduleRegions.length)
            result.push({
                chunk_id: region.chunkId,
                regions: scheduleRegions,
                type: PLAN_VIEWS_ENUM.SCHEDULE,
            })
        if (otherRegions.length)
            result.push({
                chunk_id: region.chunkId,
                regions: otherRegions,
                type: PLAN_VIEWS_ENUM.OTHER,
            })
    })

    // region.type is used to form Mapping objects correctly and for floor & elevation UI list, but it's not needed on API call
    result.forEach((mapping) => mapping.regions.forEach((region) => delete region.type))

    return result
}
export const getCustomers = (state) => {
    return state.customers
}
export const getCustomerLocations = (state) => {
    return state.customerLocations
}

import { createAction } from '@reduxjs/toolkit'
import { call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects'
import { createRegion as saveRegion } from '../../../api/takeoff-api'
import { Project } from '../../../models/project'
import { Region } from '../../../models/region'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Workspace } from '../../lib/toolBoxes/2D'
import { addRegionToStore } from '../../slices/region'
import { selectProject } from './createDrawableLocation'

export const createRegionAction = createAction<{ newRegion: Omit<Region, 'id'>; paperId: number }>('createRegion')

export function* handleCreateRegion({ payload }: ReturnType<typeof createRegionAction>) {
    const paperManager: PaperManager | null = yield call(managers.get2DManager)

    if (!paperManager) return

    try {
        const project: Project = yield select(selectProject)
        const workspaceTool: Workspace = yield call(paperManager.getTool, Workspace.NAME)

        const response = yield call(saveRegion, project.id, payload.newRegion)

        const newRegion: Region = {
            id: response.id,
            name: response.name,
            type: response.type,
            scale: response.scale,
            coordinates: response.coordinates,
            document_chunk_id: response.document_chunk_id,
        }

        // We have to assign the id to the region once it has been created
        const regionPaperItem: paper.PathItem = yield call(workspaceTool.getItemWithPaperId, payload.paperId)
        regionPaperItem.data.region_id = response.id
        regionPaperItem.data.scale = payload.newRegion.scale

        yield put(addRegionToStore(newRegion))
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForCreateRegion(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(createRegionAction.type, handleCreateRegion)
}

import { createSlice } from '@reduxjs/toolkit'
import { ShortcutKeybind } from '../types'

export type ShortcutState = {
    getPanTool: ShortcutKeybind
    getSelectTool: ShortcutKeybind
    getMeasureTool: ShortcutKeybind
    getPitchTool: ShortcutKeybind
    zoomIn: ShortcutKeybind
    zoomOut: ShortcutKeybind
    resetZoom: ShortcutKeybind
    center: ShortcutKeybind
    moveUp: ShortcutKeybind
    moveLeft: ShortcutKeybind
    moveDown: ShortcutKeybind
    moveRight: ShortcutKeybind
}

export const initialShortcutState: ShortcutState = {
    getPanTool: {
        primary: 'h',
    },
    getSelectTool: {
        primary: 'v',
    },
    getMeasureTool: {
        primary: 'm',
    },
    getPitchTool: {
        primary: 'm',
        modifier: 'shift',
    },
    zoomIn: {
        primary: '=',
        modifier: 'shift',
    },
    zoomOut: {
        primary: '-',
        modifier: 'shift',
    },
    resetZoom: {
        primary: '0',
        modifier: 'shift',
    },
    center: {
        primary: 'c',
        modifier: 'shift',
    },
    moveUp: {
        primary: 'up',
        modifier: 'shift',
    },
    moveLeft: {
        primary: 'left',
        modifier: 'shift',
    },
    moveDown: {
        primary: 'down',
        modifier: 'shift',
    },
    moveRight: {
        primary: 'right',
        modifier: 'shift',
    },
}

const shortcutsSlice = createSlice({
    name: 'shortcuts',
    initialState: initialShortcutState,
    reducers: {},
})

export default shortcutsSlice

import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { put } from 'redux-saga/effects'
import ModelUtils from '../../../components/IMUP3DComponent/babylon/ModelUtils'
import {
    IBabylonPolygonContourWithMetadata,
    IPolygonWithHoles,
    ModelType,
    RevitIdToConfigIdMap,
    ThreeDElementsResponse,
    ThreeDToTwoDRecord,
} from '../../../types'

export function* preparePolygons(
    polygonResponse: ThreeDElementsResponse,
    threeDToTwoDMappings: ThreeDToTwoDRecord,
    metadata: {
        modelType: ModelType
        storeyName: string
        isInterior: boolean
    },
    geometriesRevitIdToConfigIdMap: RevitIdToConfigIdMap,
    updateStoreHandler: ActionCreatorWithPayload<Record<string, IBabylonPolygonContourWithMetadata> | null, string>
) {
    const polygonDictionary: Record<string, IBabylonPolygonContourWithMetadata> = yield Object.keys(
        polygonResponse
    ).reduce((polygonDictionary, id) => {
        const parsedData: IPolygonWithHoles = JSON.parse(polygonResponse[id].data)
        const vector3Path: number[] = parsedData.contour.reduce(
            (vector3Path, { point1, point2 }) => [
                ...vector3Path,
                point1.x,
                point1.y,
                -point1.z,
                point2.x,
                point2.y,
                -point2.z,
            ],
            [] as number[]
        )

        polygonDictionary[parsedData.customRevitId] = {
            shape: vector3Path,
            holes: [],
            metaData: {
                isReflectedInTwoD: !!threeDToTwoDMappings[parsedData.customRevitId],
                modelType: metadata.modelType,
                ids: threeDToTwoDMappings[parsedData.customRevitId]
                    ? threeDToTwoDMappings[parsedData.customRevitId]
                    : [],
                storeyName: metadata.storeyName,
                isInterior: metadata.isInterior,
                configuration_id:
                    geometriesRevitIdToConfigIdMap[
                        ModelUtils.extractGeometryIDFromBaseId(parsedData.customRevitIdBase)
                    ],
            },
        }

        return polygonDictionary
    }, {} as Record<string, IBabylonPolygonContourWithMetadata>)

    yield put(updateStoreHandler(polygonDictionary))
}

export function* updatePolygonMetadata(
    polygonDictionary: Record<string, IBabylonPolygonContourWithMetadata>,
    threeDToTwoDMappings: ThreeDToTwoDRecord,
    updateStoreHandler: ActionCreatorWithPayload<Record<string, IBabylonPolygonContourWithMetadata> | null, string>
) {
    const updatedPolyDictionary: Record<string, IBabylonPolygonContourWithMetadata> = {}

    Object.keys(polygonDictionary).forEach((id) => {
        updatedPolyDictionary[id] = {
            ...polygonDictionary[id],
            metaData: {
                ...polygonDictionary[id]['metaData'],
                ids: threeDToTwoDMappings[id] ? threeDToTwoDMappings[id] : [],
            },
        }
    })

    yield put(updateStoreHandler(updatedPolyDictionary))
}

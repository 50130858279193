import { FormValidation, UiSchema } from '@rjsf/core'
import isUndefined from 'lodash/isUndefined'
import { calculateAreaMeasurementsForMaterial } from '../imup/sagas/2D/createDrawableLocation'
import { FormProperties } from '../imup/types'
import { ActiveDrawable } from '../models/activeDrawable'
import { Region } from '../models/region'
import { GeneralDrawableSettings } from '../models/activeDrawableSettings'
import { DRAWABLE_TYPES } from '../shared/constants/drawable-types'
import { FORM_ERROR_MESSAGES } from '../shared/constants/error-messages'
import { pitchNumberValidation } from '../shared/services/field-validation/field-validation-service'
import { calculateTotalArea } from '../utils/formSchema/formSchema-helper-functions'
import { deckOrPorch } from '../utils/formSchema/constants'

const uiSchema: UiSchema = {
    name: {
        'ui:title': 'Generic SKU',
        'ui:placeholder': 'Enter Generic SKU',
    },
    description: {
        'ui:title': 'Description',
        'ui:placeholder': 'Enter Description',
    },
    package: {
        'ui:placeholder': 'Select Package',
    },
    quantity: {
        'ui:title': 'Quantity',
        'ui:placeholder': 'Enter Quantity',
    },
    pitch: {
        'ui:title': 'Pitch',
        'ui:placeholder': 'Enter pitch',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
    bundle_location: {
        'ui:placeholder': 'Select Sub-Region Location',
    },
    bundle_floor_level: {
        'ui:placeholder': 'Select Sub-Region Floor Level',
    },
    bundle_other_floor_level: {
        'ui:placeholder': 'Enter Sub-Region Other Floor Level',
    },
}

const onValidate = (formData: any, errors: FormValidation) => {
    if (!isUndefined(formData.pitch) && !pitchNumberValidation(formData.pitch)) {
        errors['pitch'].addError(FORM_ERROR_MESSAGES.PITCH_NUMBER)
    }
}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    newSettings.quantity = Math.ceil(formData.quantity)

    if (!deckOrPorch.includes(formData.bundle_name)) {
        newSettings.bundle_floor_level = null
    }

    if (formData.bundle_floor_level !== 'other') {
        newSettings.bundle_other_floor_level = null
    }

    return newSettings
}

/**
 * Augment the drawables of the from based on the new settings
 * @returns
 * @param input
 */
function prepareDrawables(input: {
    drawables: ActiveDrawable[]
    newSettings: GeneralDrawableSettings
    scaleFactor: number
    dpi: number | null
    pdfScale: number
    xCalibrationFactor: number
    yCalibrationFactor: number
    regions: Region[]
}): ActiveDrawable[] {
    const { drawables, newSettings, scaleFactor, dpi, pdfScale, xCalibrationFactor, yCalibrationFactor, regions } =
        input

    return drawables.map((drawable) => {
        const totalArea: number = calculateTotalArea(
            drawable.opening_locations,
            regions,
            scaleFactor,
            pdfScale,
            xCalibrationFactor,
            yCalibrationFactor,
            dpi
        )

        drawable.settings.quantity = calculateAreaMeasurementsForMaterial(totalArea, {
            type: DRAWABLE_TYPES.WILDCARD_AREA,
            settings: newSettings,
        })
        return drawable
    })
}

const form: FormProperties = { uiSchema, onValidate, onSubmit, prepareDrawables }

export default form

import Paper from 'paper'
import { call, CallEffect } from 'redux-saga/effects'
import { PolygonTool } from '../../lib/toolBoxes/2D'
import { Coordinates2D } from '../../types'

export function* cutoutAreas(
    path: paper.Path,
    cutouts: Array<{ coordinates: Coordinates2D }> = [],
    polygonTool: PolygonTool
): Generator<CallEffect<paper.PathItem | paper.Item | boolean>, paper.Path, paper.Path> {
    const originalPath: paper.Path = path
    let newPath: paper.Path = path

    for (const cutout of cutouts) {
        // create polygon from cutout coordinates
        const cutoutPath: paper.Path = yield call(polygonTool.createPolygon, cutout.coordinates)

        // subtract polygon from path
        newPath = yield call([newPath, 'subtract'], cutoutPath, { insert: false })

        // remove cutout polygon from view
        yield call([cutoutPath, 'remove'])
    }

    // add the new path with cutouts to active layer
    yield call([Paper.project.activeLayer, 'addChild'], newPath)

    // remove original path without cutouts
    yield call([originalPath, 'remove'])

    // return path with cutouts
    return newPath
}

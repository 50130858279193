import { isOpeningLocationAJoistLine } from './data-prep/prepareJoistLinesData'
/**
 * Calculates the list of Mesh ids that are associated with a current active drawable group
 * @param activeDrawableGroup the active drawable group
 * @param openingIdsToMeshIdsMap mapping between 3D opening ids and mesh id
 * @returns an array of the mesh ids to select
 */
export function* calculate3DMeshIdsFromActiveDrawableGroup(
    activeDrawableGroup: any,
    openingIdsToMeshIdsMap: Record<string, string> | null
): Generator<Array<string>, Array<string>, Array<string>> {
    return yield activeDrawableGroup.openings.flatMap((opening) =>
        opening.opening_locations
            .filter((location) => location.three_d_identifier && !isOpeningLocationAJoistLine(location))
            .map((location) =>
                openingIdsToMeshIdsMap && openingIdsToMeshIdsMap[location.three_d_identifier]
                    ? openingIdsToMeshIdsMap[location.three_d_identifier]
                    : location.three_d_identifier
            )
    )
}

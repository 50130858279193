import { UiSchema } from '@rjsf/core'
import { JSONSchema7 } from 'json-schema'
import get from 'lodash/get'
import has from 'lodash/has'
import isArray from 'lodash/isArray'
import isNull from 'lodash/isNull'
import omit from 'lodash/omit'
import { call, fork, put, select, StrictEffect, takeLatest } from 'redux-saga/effects'
import {
    CHANGE_DRAWABLE_GROUP_TYPE,
    changeDrawableGroupType,
    SET_ACTIVE_DRAWABLE_GROUP,
    setActiveDrawableGroup,
} from '../../../actions/drawable'
import { getFormSchema } from '../../../api/takeoff-api'
import { materialToFormMap } from '../../../formSchemas'
import { addAdvancedSettingsToSchemaByType } from '../../../formSchemas/advanced_settings'
import { ActiveFloor } from '../../../models/activeFloor'
import { AzureFeatureFlag } from '../../../models/azureFeatureFlags'
import { IToolObject } from '../../../models/tool'
import { getActiveFloor } from '../../../reducers/drawable'
import { DRAWABLE_TYPES } from '../../../shared/constants/drawable-types'
import { FormTypes } from '../../../shared/constants/form'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Workspace } from '../../lib/toolBoxes/2D'
import { selectAzureFeatureFlags } from '../../slices/flags'
import {
    activateForm,
    cacheForm,
    clearFormErrors,
    formError,
    FormsState,
    GENERAL_CACHE,
    selectFormCache,
} from '../../slices/forms'
import { selectActiveToolObject, setActiveToolObjectId } from '../../slices/tools'
import { FormProperties, TOOL_TYPE_ENUMS } from '../../types'

export const addBundleAndToggleToUISchema = (uiSchema: UiSchema): UiSchema => {
    const incompleteOrder = uiSchema['ui:order'] ?? []

    return {
        ...uiSchema,
        'ui:order': [
            ...incompleteOrder,
            '*',
            'ignore_for_report',
            'bundle_name',
            'bundle_location',
            'bundle_floor_level',
            'bundle_other_floor_level',
        ],
        ignore_for_report: {
            'ui:title': 'Add to the material list?',
            'ui:widget': 'radio',
            'ui:options': {
                inline: true,
            },
        },
    }
}

export function* getFormByType(
    action:
        | ReturnType<typeof setActiveDrawableGroup>
        | ReturnType<typeof changeDrawableGroupType>
        | ReturnType<typeof setActiveToolObjectId>
) {
    try {
        if (action.type === setActiveToolObjectId.type) {
            if (isNull(action.payload)) return

            const manager: PaperManager = yield call(managers.get2DManager)

            if (!manager) return

            const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)

            const toolItem: paper.PathItem = yield call(workspaceTool.getItemWithPaperId, action.payload as number)

            const currentToolObject: IToolObject = yield select(selectActiveToolObject)

            const currentFormType = currentToolObject?.type || toolItem?.data?.shapeType

            if (currentFormType) {
                yield fork(getAndActivateFormByType, currentFormType, FormTypes.tools)
                return
            }
        }

        const drawableGroup =
            action.type === SET_ACTIVE_DRAWABLE_GROUP
                ? (action as ReturnType<typeof setActiveDrawableGroup>).payload.activeDrawableGroup
                : (action as ReturnType<typeof changeDrawableGroupType>).payload.drawableGroup

        // do nothing if an active drawable group is null
        if (isNull(drawableGroup) || (isArray(drawableGroup) && drawableGroup.length === 0)) {
            yield put(activateForm(''))

            return
        }

        const { type, openings } = drawableGroup

        yield fork(getAndActivateFormByType, type, FormTypes.materials, openings)
    } catch (error) {
        yield put(formError((error as any).message))
    }
}

export function* getAndActivateFormByType(type: DRAWABLE_TYPES | TOOL_TYPE_ENUMS, formType: FormTypes, openings?) {
    try {
        // clear the redux forms slice error state
        yield put(clearFormErrors())

        // get the form cache from redux
        const formCache: FormsState['formCache'] = yield select(selectFormCache)

        // get the base form by drawable group type
        const form: FormProperties | undefined = get(materialToFormMap, type)

        const activeFloor: ActiveFloor = yield select(getActiveFloor)

        let building_id = GENERAL_CACHE
        // use GENERAL_CACHE for tool objects and use building_id for others
        if (!(type in TOOL_TYPE_ENUMS) && activeFloor?.building_id) {
            building_id = activeFloor?.building_id?.toString()
        }

        // if form cache has the form, set it to be the active form
        const buildingFormCache = formCache?.[building_id]

        if (has(buildingFormCache, type)) {
            // ensure that WALL type form schema has the correct default for king stud,
            // based on the length of opening_locations for an active drawable group
            if (type === DRAWABLE_TYPES.WALL && openings) {
                const { schema, uiSchema } = buildingFormCache[type]

                const jsonSchema = schema as JSONSchema7

                yield put(
                    cacheForm({
                        form: {
                            schema: {
                                ...jsonSchema,
                                definitions: {
                                    ...jsonSchema.definitions,
                                    is_king_stud: {
                                        type: 'boolean',
                                        default: openings.some((opening) => opening.opening_locations.length > 1),
                                    },
                                },
                            },
                            uiSchema,
                        },
                        type,
                        buildingId: building_id,
                    })
                )
            }

            yield put(activateForm(type))

            return
        }

        const completeSchema = yield call(getFormSchema, type, building_id)

        let completeUISchema: UiSchema = form.uiSchema

        // in case it's drawable type, add rest of UI schema
        if (formType === FormTypes.materials) {
            completeUISchema = yield call(addBundleAndToggleToUISchema, form.uiSchema)

            const azureFeatureFlags: AzureFeatureFlag[] = yield select(selectAzureFeatureFlags)
            // add Advanced Settings To Schema
            completeUISchema = yield call(
                addAdvancedSettingsToSchemaByType,
                completeUISchema,
                type,
                azureFeatureFlags,
                completeSchema
            )
        }

        // cache the new form, fields, and activate the form for use
        yield put(
            cacheForm({
                form: {
                    schema: omit(completeSchema, 'description'),
                    uiSchema: completeUISchema,
                },
                type,
                buildingId: building_id,
            })
        )
        yield put(activateForm(type))
    } catch (error) {
        yield put(formError((error as any).message))
    }
}

export function* watchForMaterialFormChange(): Generator<StrictEffect, void, unknown> {
    yield takeLatest([SET_ACTIVE_DRAWABLE_GROUP, CHANGE_DRAWABLE_GROUP_TYPE, setActiveToolObjectId.type], getFormByType)
}

export default getFormByType

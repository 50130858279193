import { put } from 'redux-saga/effects'
import ModelUtils from '../../../components/IMUP3DComponent/babylon/ModelUtils'
import { updateCorners } from '../../../slices/3D'
import {
    CornersMeshInputData,
    ICornersData,
    IVector,
    ModelType,
    RevitIdToConfigIdMap,
    ThreeDElementsResponse,
    ThreeDToTwoDRecord,
} from '../../../types'

export function* prepareCorners(
    lineResponse: ThreeDElementsResponse,
    threeDToTwoDMappings: ThreeDToTwoDRecord,
    metadata: {
        modelType: ModelType
        storeyName: string
        isInterior: boolean
    },
    geometriesRevitIdToConfigIdMap: RevitIdToConfigIdMap
) {
    const lineDictionary: Record<string, CornersMeshInputData> = {}

    yield Object.keys(lineResponse).forEach((id) => {
        const parsedData: ICornersData = JSON.parse(lineResponse[id].data)
        const path: IVector[] = []
        path.push(
            ...[
                { x: parsedData.point1.x, y: parsedData.point1.y, z: -parsedData.point1.z },
                { x: parsedData.point2.x, y: parsedData.point2.y, z: -parsedData.point2.z },
            ]
        )
        lineDictionary[parsedData.customRevitId] = {
            path,
            metaData: {
                isReflectedInTwoD: !!threeDToTwoDMappings[parsedData.customRevitId],
                ids: threeDToTwoDMappings[parsedData.customRevitId]
                    ? threeDToTwoDMappings[parsedData.customRevitId]
                    : [],
                configuration_id:
                    geometriesRevitIdToConfigIdMap[
                        ModelUtils.extractGeometryIDFromBaseId(parsedData.customRevitIdBase)
                    ],
                isInterior: metadata.isInterior,
                storeyName: metadata.storeyName,
                modelType: metadata.modelType,
            },
            intersections: parsedData.intersections,
        }
    })
    yield put(updateCorners(lineDictionary))
}

export function* updateCornersMetadata(
    corners: Record<string, CornersMeshInputData>,
    threeDToTwoDMappings: ThreeDToTwoDRecord
) {
    const updatedCornerData: Record<string, CornersMeshInputData> = {}

    Object.keys(corners).forEach((id) => {
        updatedCornerData[id] = {
            ...corners[id],
            metaData: { ...corners[id]['metaData'], ids: threeDToTwoDMappings[id] ? threeDToTwoDMappings[id] : [] },
        }
    })

    yield put(updateCorners(updatedCornerData))
}

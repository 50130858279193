import isEqual from 'lodash/isEqual'
import throttle from 'lodash/throttle'
import { WheelEvent } from 'react'
import { distinctUntilChanged, map, takeWhile } from 'rxjs'
import { initial2DState } from '../../../../../slices/2D'
import { initialUserSettingsState } from '../../../../../slices/userSettings'
import { PaperToolConfig, VIEW_MODE } from '../../../../../types'
import Base from '../paperTool/PaperTool'

/**
 * Zoom.tool.tsx
 * Zooms paper canvas based on relative coordinates
 * Default usage is via onScroll in a Paper.js canvas container
 */
export class Zoom extends Base {
    static NAME = 'ZOOM'
    static INCREMENT = 0.5 // arbitrary number as a baseline for zoom speed (tested and this is a good middle ground)
    static MINIMUM_ZOOM = 0.5 // 1 = 100%, 0.5 = 50%

    private zoomSetting = initialUserSettingsState.settings.scrollStep
    private defaultZoom = initial2DState.defaultZoom
    private boundingBoxTop: number
    private boundingBoxLeft: number

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Zoom.NAME
        this.mediator
            .get$()
            .pipe(
                takeWhile((state) => isEqual(state.common.activeMode, VIEW_MODE.Markup2D)),
                map((state) => {
                    return {
                        zoomSetting: state['userSettings'].settings.scrollStep,
                        defaultZoom: state['2D'].defaultZoom,
                    }
                }),
                distinctUntilChanged()
            )
            .subscribe(({ zoomSetting, defaultZoom }) => {
                this.zoomSetting = zoomSetting
                this.defaultZoom = defaultZoom
            })

        const { top, left } = this.paper.view.element.getBoundingClientRect()
        this.boundingBoxTop = top
        this.boundingBoxLeft = left
    }

    /**
     * Update the bounding box coordinates. Used for window resize events.
     */
    updateClientBounds = (boundingRectangle: DOMRect) => {
        this.boundingBoxLeft = boundingRectangle.left
        this.boundingBoxTop = boundingRectangle.top
    }

    /**
     * Change the zoom by a percentage (button clicks)
     * @param increment example: 0.05 -> increases zoom level by 5%, -0.05 -> decreases the zoom level by 5%
     */
    changeZoom = (increment: number): void => {
        const minZoom = this.defaultZoom * Zoom.MINIMUM_ZOOM
        const newZoom = this.paper.view.zoom * (1 + increment)
        this.paper.view.zoom = newZoom > minZoom ? newZoom : minZoom // if the new zoom level is smaller than the min zoom, take the min zoom
        this.mediator.mediate('2D', { currentZoom: this.paper.view.zoom })
    }

    throttledZoomStoreUpdate = throttle((newZoom) => this.mediator.mediate('2D', { currentZoom: newZoom }), 200, {
        trailing: true,
    })

    /**
     * Reset the zoom to the default determined on load
     */
    resetZoom = () => {
        this.paper.view.zoom = this.defaultZoom
    }

    /**
     * Control zooming via mouse scroll wheel or trackpad
     * @param event
     * @returns
     */
    onScroll = (event: WheelEvent<HTMLElement>): void => {
        const { pageX, pageY, deltaY } = event

        const oldZoom = this.paper.view.zoom

        // Use default/user zoomSetting (%) to adjust zoom step amount. Smaller values are slower.
        const step = Zoom.INCREMENT * (this.zoomSetting / 100)

        const newZoom = deltaY > 0 ? this.paper.view.zoom * (1 - step) : this.paper.view.zoom * (1 + step) // deltaY is positive when scrolling down, negative when scrolling up

        if (newZoom < this.defaultZoom * Zoom.MINIMUM_ZOOM) return // set a minimum zoom distance to 80%

        const beta = oldZoom / newZoom

        const mousePosition = new this.paper.Point(pageX - this.boundingBoxLeft, pageY - this.boundingBoxTop) // transform the mouse position to be relative to the position of the canvas within the viewport
        const viewPosition = this.paper.view.viewToProject(mousePosition)
        const center = this.paper.view.center

        const pc = viewPosition.subtract(center) // need better variable name
        const offset = viewPosition.subtract(pc.multiply(beta)).subtract(center)

        this.paper.view.zoom = newZoom
        this.paper.view.center = this.paper.view.center.add(offset)
        this.throttledZoomStoreUpdate(newZoom)
    }
}

export default Zoom

import isNull from 'lodash/isNull'
import { DRAWABLE_TYPES, DRAWABLE_TYPES_LABELS } from '../../shared/constants/drawable-types'
import { PROJECT_SETTINGS_NAMES } from '../../shared/constants/project-settings-names'
import { determineAllSupportedDrawingTypes } from '../../shared/services/drawable-type-service'
import { sortASCByNumberOrText } from '../../utils/sorting'
import { MaterialOption } from '../components/IMUP2DComponent/components/Material-selector/Material-selector'
import { hiddenMaterialOptionsList, materialDropdownSortOrder } from './constants'
import { AzureFeatureFlag } from '../../models/azureFeatureFlags'

const framingBundle = [
    DRAWABLE_TYPES.APPLIANCE,
    DRAWABLE_TYPES.FLOOR_AREA,
    DRAWABLE_TYPES.GARAGE_AREA,
    DRAWABLE_TYPES.BASEMENT_FINISHED_CEILING,
    DRAWABLE_TYPES.FRAMING_ROOFING,
    DRAWABLE_TYPES.GABLE_AREA,
    DRAWABLE_TYPES.EAVE_LENGTH,
    DRAWABLE_TYPES.GABLE_LENGTH,
    DRAWABLE_TYPES.TRIM,
    DRAWABLE_TYPES.CORNER,
    DRAWABLE_TYPES.WALL,
    DRAWABLE_TYPES.SHEARWALL,
    DRAWABLE_TYPES.FOUNDATION_PILON,
    DRAWABLE_TYPES.FLOOR_SYSTEM,
    DRAWABLE_TYPES.LEDGER,
    DRAWABLE_TYPES.STEEL_BEAM_PLATE,
    DRAWABLE_TYPES.STAIRS,
    DRAWABLE_TYPES.POST,
    DRAWABLE_TYPES.LF_RAILING,
    DRAWABLE_TYPES.ROOF_SYSTEM,
    DRAWABLE_TYPES.TRAYED_CEILING_AND_SOFFIT,
    DRAWABLE_TYPES.CANALES,
    DRAWABLE_TYPES.INTERIOR_FINISHED_CEILING,
    DRAWABLE_TYPES.EXTERIOR_DOOR,
    DRAWABLE_TYPES.INTERIOR_DOOR_IMUP,
    DRAWABLE_TYPES.HARDWARE,
    DRAWABLE_TYPES.VALLEY_PLATE,
    DRAWABLE_TYPES.DROPPED_BEAM,
    DRAWABLE_TYPES.FLUSH_BEAM,
    DRAWABLE_TYPES.RIDGE_BEAM,
    DRAWABLE_TYPES.HIP_AND_VALLEY_BEAM,
    DRAWABLE_TYPES.HEADER,
    DRAWABLE_TYPES.WILDCARD_AREA,
    DRAWABLE_TYPES.WILDCARD_COUNT,
    DRAWABLE_TYPES.WILDCARD_LINE,
    DRAWABLE_TYPES.SCREEN_AREA,
    DRAWABLE_TYPES.UNDER_VALLEY_SHEATHING,
    DRAWABLE_TYPES.LF_FIVE_DOT_FIVE_DECKING,
    DRAWABLE_TYPES.FLOOR_TRUSS_BRACING,
    DRAWABLE_TYPES.LOUVER_HOG_FENCE,
    DRAWABLE_TYPES.LF_SCREEN_OPENING,
    DRAWABLE_TYPES.BLOCK_ROUGH,
    DRAWABLE_TYPES.CANTILEVER_CLOSURE,
]

const exteriorBundle = [
    DRAWABLE_TYPES.EXTERIOR_ROOFING,
    DRAWABLE_TYPES.ROOF_LINE,
    DRAWABLE_TYPES.FLASHING,
    DRAWABLE_TYPES.SIDING,
    DRAWABLE_TYPES.SIDING_STARTER,
    DRAWABLE_TYPES.PORCH_CEILING,
    DRAWABLE_TYPES.POST_WRAP,
    DRAWABLE_TYPES.FYPON_BRACKET,
]

const determineBundleName = (materialValue: DRAWABLE_TYPES): string => {
    if (framingBundle.includes(materialValue)) {
        return PROJECT_SETTINGS_NAMES.FRAMING
    }

    if (exteriorBundle.includes(materialValue)) {
        return PROJECT_SETTINGS_NAMES.EXTERIOR
    }

    return 'Not defined'
}

const determineBuzzWords = (materialValue: DRAWABLE_TYPES): string[] | [] => {
    switch (materialValue) {
        case DRAWABLE_TYPES.APPLIANCE:
            return ['refrigerator', 'dishwasher', 'microwave', 'ventilation over range', 'oven', 'range']
        case DRAWABLE_TYPES.FLOOR_AREA:
            return ['area', 'main floor area', 'third floor area'] // TODO: add rest floors
        case DRAWABLE_TYPES.FRAMING_ROOFING:
            return ['roof', 'area']
        case DRAWABLE_TYPES.EAVE_LENGTH:
            return ['fascia', 'frieze', 'soffit']
        case DRAWABLE_TYPES.GABLE_LENGTH:
            return ['fascia', 'frieze', 'soffit']
        case DRAWABLE_TYPES.TRIM:
            return ['rough opening', 'window', 'door']
        case DRAWABLE_TYPES.CORNER:
            return ['trim']
        case DRAWABLE_TYPES.WALL:
            return ['stud']
        case DRAWABLE_TYPES.SHEARWALL:
            return ['wall']
        case DRAWABLE_TYPES.FLOOR_SYSTEM:
            return ['i-joist', 'truss', 'dimensional']
        case DRAWABLE_TYPES.CANTILEVER_CLOSURE:
            return ['sheathing', 'cantilever', 'overhang', 'bumpout']
        case DRAWABLE_TYPES.LEDGER:
            return ['floor', 'roof']
        case DRAWABLE_TYPES.STEEL_BEAM_PLATE:
            return ['beam', 'dropped']
        case DRAWABLE_TYPES.STAIRS:
            return ['riser', 'tread', 'stringer', 'deck']
        case DRAWABLE_TYPES.POST:
            return ['bearing column', 'porch', 'deck', 'psl', 'lvl', 'lsl', 'treated', 'standard']
        case DRAWABLE_TYPES.ROOF_SYSTEM:
            return ['truss', 'stick', 'rafter', 'ceiling joist']
        case DRAWABLE_TYPES.TRAYED_CEILING_AND_SOFFIT:
            return ['tray', 'soffit']
        case DRAWABLE_TYPES.BASEMENT_FINISHED_CEILING:
        case DRAWABLE_TYPES.INTERIOR_FINISHED_CEILING:
            return ['t&g', 'tg', 'finish']
        case DRAWABLE_TYPES.EXTERIOR_DOOR:
        case DRAWABLE_TYPES.INTERIOR_DOOR_IMUP:
            return ['doors', 'millwork']
        case DRAWABLE_TYPES.HARDWARE:
            return ['hold down', 'handger', 'anchor', 'tie', 'strap', 'clip']
        case DRAWABLE_TYPES.EXTERIOR_ROOFING:
            return ['roof', 'asphalt']
        case DRAWABLE_TYPES.ROOF_LINE:
            return ['hip', 'ridge', 'valley']
        case DRAWABLE_TYPES.FLASHING:
            return ['endwall', 'step']
        case DRAWABLE_TYPES.SIDING:
            return ['vinyl', 'lp', 'cedar', 'b&b', 'bb', 'shake']
        case DRAWABLE_TYPES.FYPON_BRACKET:
            return ['gable vent', 'decorative']
        case DRAWABLE_TYPES.UNDER_VALLEY_SHEATHING:
            return ['roof sheathing', 'overframing']
        case DRAWABLE_TYPES.LF_FIVE_DOT_FIVE_DECKING:
            return ['decking', 'boards']
        case DRAWABLE_TYPES.FLOOR_TRUSS_BRACING:
            return ['bracing']
        case DRAWABLE_TYPES.LOUVER_HOG_FENCE:
            return ['louver', 'hog fence', 'basement', 'walk under']
        case DRAWABLE_TYPES.LF_SCREEN_OPENING:
            return ['screen', 'porch']
        case DRAWABLE_TYPES.BLOCK_ROUGH:
            return ['block', 'opening', 'trim']
        case DRAWABLE_TYPES.DROPPED_BEAM:
        case DRAWABLE_TYPES.FLUSH_BEAM:
        case DRAWABLE_TYPES.RIDGE_BEAM:
        case DRAWABLE_TYPES.HIP_AND_VALLEY_BEAM:
        case DRAWABLE_TYPES.HEADER:
            return ['solid beam']
        case DRAWABLE_TYPES.GABLE_AREA:
        case DRAWABLE_TYPES.SCREEN_AREA:
        case DRAWABLE_TYPES.FOUNDATION_PILON:
        case DRAWABLE_TYPES.LF_RAILING:
        case DRAWABLE_TYPES.CANALES:
        case DRAWABLE_TYPES.SIDING_STARTER:
        case DRAWABLE_TYPES.PORCH_CEILING:
        case DRAWABLE_TYPES.POST_WRAP:
        case DRAWABLE_TYPES.VALLEY_PLATE:
        case DRAWABLE_TYPES.WILDCARD_AREA:
        case DRAWABLE_TYPES.WILDCARD_COUNT:
        case DRAWABLE_TYPES.WILDCARD_LINE:
            return []
        default:
            return []
    }
}

export const getMaterialOptions = (azureFeatureFlags: AzureFeatureFlag[] | null) => {
    return Object.values(DRAWABLE_TYPES)
        .reduce((valuesAndLabel, typeKey: DRAWABLE_TYPES) => {
            if (!isNull(determineAllSupportedDrawingTypes(typeKey, azureFeatureFlags))) {
                valuesAndLabel.push({
                    order: materialDropdownSortOrder.findIndex((materialName) => materialName === typeKey) + 1,
                    value: typeKey,
                    label: DRAWABLE_TYPES_LABELS[typeKey],
                    optGroup: determineBundleName(typeKey),
                    children: determineBuzzWords(typeKey),
                })
            }

            return valuesAndLabel
        }, [] as MaterialOption[])
        .filter((option) => !hiddenMaterialOptionsList.includes(option.value as DRAWABLE_TYPES))
        .sort((a, b) => sortASCByNumberOrText(a.order, b.order))
}

export const checkNameByType = (type: string, name: string) => {
    const elevationNames = [
        'EAST ELEVATION',
        'ELEVATIONS',
        'FRONT ELEVATION',
        'LEFT ELEVATION',
        'NORTH ELEVATION',
        'REAR ELEVATION',
        'RIGHT ELEVATION',
        'SOUTH ELEVATION',
        'WEST ELEVATION',
    ]
    const floorPlanNames = [
        '2ND FLOOR',
        '2ND FLOOR FRAMING',
        '3RD FLOOR',
        '3RD FLOOR FRAMING',
        '4TH FLOOR',
        '4TH FLOOR FRAMING',
        '5TH FLOOR',
        '5TH FLOOR FRAMING',
        '6TH FLOOR',
        '6TH FLOOR FRAMING',
        '7TH FLOOR',
        '7TH FLOOR FRAMING',
        '8TH FLOOR',
        '8TH FLOOR FRAMING',
        '9TH FLOOR',
        '9TH FLOOR FRAMING',
        '10TH FLOOR',
        '10TH FLOOR FRAMING',
        'BASEMENT',
        'CRAWLSPACE',
        'FOUNDATION',
        'MAIN FLOOR',
        'MAIN FLOOR FRAMING',
    ]
    const scheduleNames = ['DETAILS', 'HARDWARE DETAIL', 'SCHEDULE', 'WALL TYPES']
    const otherNames = ['DETAILS', 'SECTION', 'SHEARWALL PLAN', 'SPEC SHEET', 'WALL SECTION', 'WALL TYPES']
    const roofNames = ['BIRDS EYE ROOF', 'BIRDS EYE ROOF FRAMING']

    switch (type) {
        case 'elevation':
            return elevationNames.includes(name)
        case 'floor':
            return floorPlanNames.includes(name)
        case 'schedule':
            return scheduleNames.includes(name)
        case 'other':
            return otherNames.includes(name)
        case 'roof':
            return roofNames.includes(name)
        default:
            return false
    }
}

export const PROJECT_ACTIONS = [
    {
        label: 'Settings',
        hash: 'settings',
    },
    {
        label: 'View Takeoff',
        hash: 'takeoff',
    },
    {
        label: 'W&D Schedule',
        hash: 'windows_schedule',
    },
    {
        label: 'Group Schedule',
        hash: 'windows_schedule_group',
    },
    {
        label: 'W&D Itemized',
        hash: 'windows_schedule_gateway',
    },
    {
        label: 'W&D Itemized - No Images',
        hash: 'windows_schedule_lite',
    },
    {
        label: 'Assign',
        hash: 'assign',
    },
    {
        label: 'Interactive Markup',
        hash: 'markup',
    },
    {
        label: 'Update Data',
        hash: 'update_data',
    },
    {
        label: 'Merge Data',
        hash: 'merge_data',
    },
    {
        label: 'Update 2D Project',
        hash: 'update_data_2d',
    },
    {
        label: 'Update 3D Project',
        hash: 'update_data_3d',
    },
    {
        label: 'Clear Project Error',
        hash: 'clear_project_errors',
    },
    {
        label: 'Re-Create Project',
        hash: 're_create_markup_only_project',
    },
    {
        label: 'View CSV',
        hash: 'view_window_takeoff_csv',
    },
    {
        label: 'Recalculate Measurements',
        hash: 'recalculate_measurements',
    },
    {
        label: 'Generate Deliverables',
        hash: 'generate_deliverables',
    },
]

export const lastActiveProjectDocumentMappingId = 'lastActiveProjectDocumentMappingId'

export enum PROJECT_SETTINGS_NAMES {
    EXTERIOR = 'Exterior',
    FRAMING = 'Framing',
}

export enum PROJECT_PAGE_TAB_VALUES {
    HISTORY = 0,
    SETTINGS = 1,
    MERGE_NOTIFICATIONS = 2,
    TAKE_OFF = 3,
    DIGITIZER = 4,
    PLANS = 5,
}

import { all, call, select } from 'redux-saga/effects'
import managers from '../../lib/managers'
import { Flag, Workspace } from '../../lib/toolBoxes/2D'
import { selectMaterialFlags } from '../../slices/materialFlags'
import { NormalizedFlag } from '../../../models/flags'
import PaperManager from '../../lib/managers/PaperManager'
import { deleteMultipleFlagsAction, handleDeleteMultipleFlags } from './deleteMultipleFlags'
import { handleUnlinkGeometryFromFlag, unlinkGeometryFromFlagAction } from './unlinkGeometryFromFlag'

export function* unlinkOrDeleteFlag(opening_id: number) {
    try {
        const manager: PaperManager = yield call(managers.get2DManager)

        if (!manager) return

        const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)

        const flags: NormalizedFlag[] = yield select(selectMaterialFlags)

        // get flags where opening_id match with opening_id of material which is deleted
        const linkedFlags = flags.filter((flag) =>
            flag.opening_links.find((link: any) => link.opening_id === opening_id)
        )

        const deleteFlags: NormalizedFlag[] = []
        const unlinkOpeningLinks: { flag_id: number; id: number }[] = []

        linkedFlags.forEach((flag) => {
            const uniqueOpeningIds = new Set()

            flag.opening_links.forEach((link) => {
                uniqueOpeningIds.add(link.opening_id)
            })

            if (uniqueOpeningIds.size === 1 && flag.opening_links[0]?.opening_id === opening_id) {
                deleteFlags.push(flag)
            } else {
                const openingLinks = flag.opening_links.filter((link) => link.opening_id === opening_id)

                openingLinks.forEach((openingLink) => unlinkOpeningLinks.push({ flag_id: flag.id, id: openingLink.id }))
            }
        })

        // get flags by opening id
        const flagItemsByOpeningId: paper.Item[] = yield call(
            workspaceTool.getItemsWithCriteria,
            'data',
            (data) => data?.openingId === opening_id && data.toolName === Flag.NAME
        )

        // remove flags
        yield all(flagItemsByOpeningId.map((item) => call(item.remove.bind(item))))

        if (!!deleteFlags.length) {
            const flagIdsToDelete: number[] = deleteFlags.map((flag) => flag.id)

            // call saga to remove flag
            yield all(
                flagIdsToDelete.map((flagId) =>
                    call(handleDeleteMultipleFlags, {
                        payload: { flag_ids: [flagId] },
                        type: deleteMultipleFlagsAction.type,
                    })
                )
            )
        }

        if (!!unlinkOpeningLinks.length) {
            // call saga to unlink
            yield all(
                unlinkOpeningLinks.map((link) =>
                    call(handleUnlinkGeometryFromFlag, {
                        payload: { opening_link_id: link.id, flag_id: link.flag_id, opening_id: opening_id },
                        type: unlinkGeometryFromFlagAction.type,
                    })
                )
            )
        }
    } catch (error) {
        console.log('error ', error)
    }
}

import { FormValidation, UiSchema } from '@rjsf/core'
import omit from 'lodash/omit'
import { FormProperties } from '../imup/types'
import { OpeningGroup } from '../models/activeDrawable'
import { FORM_ERROR_MESSAGES } from '../shared/constants/error-messages'
import { feetValidation } from '../shared/services/field-validation/field-validation-service'

const interiorPonyWall = 'INTERIOR PONY WALL'
const exteriorPonyWall = 'EXTERIOR PONY WALL'
const partyWall = 'PARTY WALL'
const wallTypesWithoutStudSpacing = [
    'FOUNDATION WALL',
    'BLOCK INTERIOR CABINET OR VANITY WALL',
    'BLOCK EXTERIOR CABINET OR VANITY WALL',
    'BLOCK EXTERIOR WALL',
    'BLOCK FIRE WALL',
    'BLOCK FOUNDATION WALL',
    'BLOCK INTERIOR WALL',
    'BLOCK PARTY WALL',
]
const wallTypesForStudFurringWidth = [
    'BLOCK INTERIOR CABINET OR VANITY WALL',
    'BLOCK EXTERIOR CABINET OR VANITY WALL',
    'BLOCK EXTERIOR WALL',
    'BLOCK FIRE WALL',
    'BLOCK INTERIOR WALL',
    'BLOCK PARTY WALL',
]
const sillPlateTypes = ['FOUNDATION WALL', 'BLOCK FOUNDATION WALL', 'KNEE WALL', 'WALK OUT WALL']

export const uiSchema: UiSchema = {
    classNames: 'GeneralForm',
    title: {
        classNames: 'general-form-title',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
    drywall: {
        'ui:placeholder': 'Select Drywall',
    },
    party_shaft_wall_assembly: {
        'ui:placeholder': 'Select Party Shaft Wall Assembly',
    },
    misc_wall_material: {
        'ui:title': 'Misc. Wall Material',
    },
    height: {
        'ui:placeholder': 'Enter Wall Height',
    },
    king_stud: {
        'ui:field': 'KingStudField',
        'ui:placeholder': 'Select King Stud',
    },
    shearwall: {
        'ui:field': 'ShearwallField',
        'ui:placeholder': 'Select Shearwall',
    },
    staggered_studs: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    insulation: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    fire_rated_sheathing: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    double_studs: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    type: {
        'ui:placeholder': 'Select Type',
    },
    floor_level: {
        'ui:placeholder': 'Select Floor Level',
    },
    size: {
        'ui:placeholder': 'Select Wall Size',
    },
    sill_plate: {
        'ui:placeholder': 'Select Sill Plate',
    },
    sill_plate_2: {
        'ui:placeholder': 'Select Sill Plate',
    },
    sill_plate_3: {
        'ui:placeholder': 'Select Sill Plate',
    },
    bottom_plate: {
        'ui:placeholder': 'Select Bottom Plate',
    },
    bottom_plate_2: {
        'ui:placeholder': 'Select Bottom Plate',
    },
    bottom_plate_3: {
        'ui:placeholder': 'Select Bottom Plate',
    },
    plate_species: {
        'ui:placeholder': 'Select Plate Species',
    },
    plate_grade: {
        'ui:placeholder': 'Select Plate Grade',
    },
    stud_species: {
        'ui:placeholder': 'Select Stud Species',
    },
    stud_grade: {
        'ui:placeholder': 'Select Stud Grade',
    },
    stud_spacing: {
        'ui:placeholder': 'Select Stud Spacing',
    },
    stud_furring_width: {
        'ui:placeholder': 'Select Stud Furring Width',
    },
    'ui:order': [
        'type',
        'party_shaft_wall_assembly',
        'drywall',
        'floor_level',
        'floor_level_other',
        'size',
        'height',
        'stud_furring_width',
        'sill_plate',
        'sill_plate_2',
        'sill_plate_3',
        'bottom_plate',
        'bottom_plate_2',
        'bottom_plate_3',
        'plate_species',
        'plate_grade',
        'stud_species',
        'stud_grade',
        'stud_spacing',
        'king_stud',
        'shearwall',
        'misc_wall_material',
        'staggered_studs',
        'insulation',
        'fire_rated_sheathing',
        'double_studs',
    ],
}

const onValidate = (formData: any, errors: FormValidation) => {
    if (formData.height) {
        if (!feetValidation(formData.height)) {
            errors.height.addError(FORM_ERROR_MESSAGES.FEET)
        }
    }
}

const onSubmit = (settings, formData): OpeningGroup['settings'] => {
    const newSettings = { ...settings, ...formData }

    if (newSettings.type !== interiorPonyWall && newSettings.type !== exteriorPonyWall) {
        newSettings.drywall = null
    }

    if (
        newSettings.type !== interiorPonyWall &&
        newSettings.type !== exteriorPonyWall &&
        newSettings.type !== partyWall
    ) {
        newSettings.party_shaft_wall_assembly = null
    }

    if (wallTypesWithoutStudSpacing.includes(newSettings.type)) {
        newSettings.stud_spacing = null
    }

    // clean up bottom or sill plate base on a selected type
    if (sillPlateTypes.includes(newSettings.type)) {
        newSettings.bottom_plate = null
        newSettings.bottom_plate_2 = null
        newSettings.bottom_plate_3 = null
    } else {
        newSettings.sill_plate = null
        newSettings.sill_plate_2 = null
        newSettings.sill_plate_3 = null
    }

    if (!wallTypesForStudFurringWidth.includes(newSettings.type)) {
        newSettings.stud_furring_width = null
    }

    return {
        ...newSettings,
        ...omit(newSettings, ['misc_wall_material']),
    }
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form

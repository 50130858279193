import {
    Color3,
    Nullable,
    PickingInfo,
    PointerEventTypes,
    PointerInfo,
    StandardMaterial,
    Vector3,
} from '@babylonjs/core'
import { BabylonToolConfig, MouseButtonCodes } from '../../../../../types'
import BabylonTool from '../babylonTool/BabylonTool'

export class Measure extends BabylonTool {
    static NAME = 'MEASURE'
    public static readonly MEASUREMENT_MESH_ID = 'measurement'
    public static readonly MEASUREMENT_DIAMETER = 0.3
    public static readonly MEASUREMENT_LINE_COLOR = Color3.Black()
    public static readonly MEASUREMENT_LINE_EDGE_WIDTH = 20
    public static readonly MEASUREMENT_DASH_SIZE = 12
    public static readonly MEASUREMENT_DASH_GAP_SIZE = 6
    public static readonly MEASUREMENT_DASH_NUMBER = 40

    private static readonly MEASUREMENT_HANDLE_MATERIAL_NAME = 'measurement-material'
    private static readonly MEASUREMENT_HANDLE_COLOR = Color3.White()

    private measurementPoints: Vector3[] = []
    public measureHandleMaterial: StandardMaterial

    constructor(config: BabylonToolConfig) {
        super(config)
        this.name = Measure.NAME
        this.measureHandleMaterial = new StandardMaterial(Measure.MEASUREMENT_HANDLE_MATERIAL_NAME, this.scene)
        this.measureHandleMaterial.emissiveColor = Measure.MEASUREMENT_HANDLE_COLOR
        this.measureHandleMaterial.freeze()
        this.registerActivationFunctions([this.onMouseDown])
    }

    /**
     * Predicate function for determining which mesh is clickable used in selection function to determine what
     * meshes are selectable
     * @returns returns the babylonJS picking info or null
     */
    private pickWithRayPicker = (): Nullable<PickingInfo> =>
        this.scene.pickWithRay(this.createPickingRay(), (mesh) => {
            return mesh.isEnabled()
        })

    /**
     * Handle pointer observrable event and emit the correct
     * events on measurement points change
     * @param evt pointer observable event
     * @returns
     */
    private selectPoint = (evt: PointerInfo) => {
        if (evt.type !== PointerEventTypes.POINTERDOWN) return
        const pick = this.pickWithRayPicker()
        const leftClicked = evt.event.button === MouseButtonCodes.Left
        const hitAMesh = pick && pick.hit

        if (!leftClicked) return

        if (!hitAMesh) {
            this.clearMeasurements()
            return
        }

        if (this.measurementPoints.length < 2) {
            if (pick && pick.pickedPoint) {
                this.measurementPoints.push(pick.pickedPoint)
                this.mediator.mediate('3D', {
                    measurementPoints: this.measurementPoints.map((vector) => {
                        return { x: vector.x, y: vector.y, z: vector.z }
                    }),
                })
            }
        } else {
            this.clearMeasurements()
        }

        if (this.measurementPoints.length === 2) {
            const measurement = this.measurementPoints[0].subtract(this.measurementPoints[1]).length()
            this.mediator.mediate('tools', { measurement })
        }
    }

    private onMouseDown = (): (() => void) => {
        const pointerObserver = this.scene.onPointerObservable.add(this.selectPoint)
        return (): void => {
            this.scene.onPointerObservable.remove(pointerObserver)
        }
    }

    private clearMeasurements = () => {
        this.measurementPoints = []
        this.mediator.mediate('3D', { measurementPoints: null }).mediate('tools', { measurement: null })
    }
}

import isNull from 'lodash/isNull'
import { call, put } from 'redux-saga/effects'
import { setActiveDrawable } from '../../../actions/drawable'
import managers from '../../lib/managers'
import { BabylonManager } from '../../lib/managers/BabylonManager'
import { CameraTool, Select } from '../../lib/toolBoxes/3D'
import { updateCameraResetFlag, updateSelectedOpeningGroup } from '../../slices/3D'
import { cleanupJoistLinesMeshesAndLabels } from './handleUpdateJoistLinesUpdate'
import { selectionPredicateGenerator } from './selectMesh'

export function* handleSetActiveDrawable3D(action: ReturnType<typeof setActiveDrawable>) {
    const manager: BabylonManager | null = yield call(managers.get3DManager)

    if (isNull(manager)) return

    if (isNull(action.payload.drawable)) {
        const selectTool: Select = yield call(manager.getTool, Select.NAME)
        yield call(selectTool.clearSelectedMeshes)
        // clear all joist lines and joist lines labels
        yield call(cleanupJoistLinesMeshesAndLabels, manager)
        yield put(updateSelectedOpeningGroup({ id: null }))
        yield call(selectTool.setSelectionPredicate, selectionPredicateGenerator(null))
        yield put(updateCameraResetFlag(true))
        const cameraTool: CameraTool = yield call(manager.getTool, CameraTool.NAME)
        yield call(cameraTool.enableFullControlOrOnlyAllowPanning, true)
    }
}

import { createAction } from '@reduxjs/toolkit'
import { call, ForkEffect, put, select, takeLatest } from 'redux-saga/effects'
import { FETCH_OPENING_GROUPS_SUCCESS } from '../../../actions/drawable'
import { fetchOpeningGroupsByProjectId, updateRegion } from '../../../api/takeoff-api'
import { OpeningGroupAPI } from '../../../models/activeDrawable'
import { Project } from '../../../models/project'
import { Region } from '../../../models/region'
import { IUpdatedRegionResponse } from '../../../models/updatedRegionResponse'
import { convertScaleEnumToString } from '../../../utils/calculations/scaleConversion/scaleConversion'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { RegionTool, Select, Workspace } from '../../lib/toolBoxes/2D'
import { preparedDrawablesSuccess } from '../../slices/2D'
import { resetRegionFormState, selectAllRegions, updateSingleRegion } from '../../slices/region'
import { IMUP2DCoordinatesToUpdate, IMUP2DDrawableLocation } from '../../types'
import { selectProject } from './createDrawableLocation'
import { convertOpeningGroupsToDrawableLocations } from './data-prep/convertOpeningsToDrawableLocations'

export const updateRegionFromId = createAction<{
    regionId: number
    coordinatesToUpdate?: IMUP2DCoordinatesToUpdate
    propertiesToUpdate?: Partial<Region>
}>('updateRegionFromId')

export function* handleUpdateRegionFromId({ payload }: ReturnType<typeof updateRegionFromId>) {
    const paperManager: PaperManager | null = yield call(managers.get2DManager)

    if (!paperManager) return

    try {
        const { regionId, coordinatesToUpdate, propertiesToUpdate } = payload

        const project: Project = yield select(selectProject)

        const [selectTool, workspaceTool, regionTool]: [Select, Workspace, RegionTool] = yield call(
            paperManager.getTools,
            [Select.NAME, Workspace.NAME, RegionTool.NAME]
        )

        const allRegions: Region[] = yield select(selectAllRegions)
        let currentRegion = allRegions.find((r) => r.id === regionId)

        if (!currentRegion) return

        const updatedRegion: Omit<Region, 'id'> = {
            document_chunk_id: propertiesToUpdate?.document_chunk_id ?? currentRegion.document_chunk_id,
            name: propertiesToUpdate?.name ?? currentRegion.name,
            type: propertiesToUpdate?.type ?? currentRegion.type,
            scale: propertiesToUpdate?.scale ?? currentRegion.scale,
            coordinates: coordinatesToUpdate?.coordinates ?? currentRegion.coordinates,
        }

        const response: IUpdatedRegionResponse = yield call(updateRegion, project.id, currentRegion.id, updatedRegion)

        if (response.updatedRegion) {
            yield put(updateSingleRegion(response.updatedRegion))
        }

        const regionItem: paper.Path | null = yield call(workspaceTool.getItemWithRegionId, regionId)

        // If updating the scale on a region, update the associated
        // regions label text to reflect the new scale
        if (propertiesToUpdate && propertiesToUpdate.scale && regionItem) {
            yield call(
                workspaceTool.updateScaleForRegion,
                regionItem.id,
                convertScaleEnumToString(propertiesToUpdate.scale)
            )
        }

        // If the coordinates are updated then
        // update the position of the label text
        if (coordinatesToUpdate && coordinatesToUpdate.coordinates && regionItem) {
            const labelItem: paper.TextItem | null = yield call(
                workspaceTool.getItemWithPaperId,
                regionItem.data.labelId
            )
            if (labelItem) {
                yield call(regionTool.updateScaleTextPositionRelativeToRegionPath, labelItem, regionItem)
            }
        }

        if (!coordinatesToUpdate) {
            yield call(selectTool.exitSelectMode)
            yield put(resetRegionFormState())
        }

        // Fetch opening groups from api
        const openingGroups: Array<OpeningGroupAPI> = yield call(fetchOpeningGroupsByProjectId, project.id)

        // Original IMUP: pass api response to original IMUP reducer
        yield put({ type: FETCH_OPENING_GROUPS_SUCCESS, payload: openingGroups })

        // Convert opening groups to drawable locations with appropriate metadata
        const drawableLocations: Array<IMUP2DDrawableLocation> = yield call(
            convertOpeningGroupsToDrawableLocations,
            openingGroups
        )

        // Retrieve the unique set of document chunk IDs for all locations
        const documentChunkIds: Array<number> = [
            ...new Set(drawableLocations.map((location) => location.document_chunk_id)),
        ]

        // Provide drawable locations and unique document chunk IDs to the store
        yield put(preparedDrawablesSuccess({ drawableLocations, documentChunkIds }))
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForUpdateRegionFromId(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(updateRegionFromId.type, handleUpdateRegionFromId)
}

import { Mesh, Scene } from '@babylonjs/core'
import isNull from 'lodash/isNull'
import { call, StrictEffect, takeLatest } from 'redux-saga/effects'
import managers from '../../lib/managers'
import { BabylonManager } from '../../lib/managers/BabylonManager'
import { Label, Workspace } from '../../lib/toolBoxes/3D'
import { updateVisibleFloor } from '../../slices/3D'
import { createLabelsFromMeshesInScene } from './handleUpdateJoistLinesUpdate'

export function shouldOnlyRenderLabelsForVisibleMeshes(mesh: Mesh) {
    return mesh.isEnabled()
}

export function* handleVisibleFloorChange(
    action: ReturnType<typeof updateVisibleFloor>
): Generator<StrictEffect, void, (BabylonManager | null) & Workspace & Label & Scene> {
    const manager: BabylonManager | null = yield call(managers.get3DManager)

    if (isNull(manager)) return

    const [workspace, label]: [Workspace, Label] = yield call(manager.getTools, [Workspace.NAME, Label.NAME])

    const floorHashesChosen = isNull(action.payload) ? [] : [action.payload]

    yield call(workspace.toggleVisibleFloor, floorHashesChosen)

    yield call(label.clearLabels)

    const scene: Scene = yield call(manager.getScene)

    yield call(createLabelsFromMeshesInScene, scene, label, shouldOnlyRenderLabelsForVisibleMeshes)
}

export function* watchFloorVisibilityChange(): Generator<StrictEffect, void, void> {
    yield takeLatest(updateVisibleFloor.type, handleVisibleFloorChange)
}

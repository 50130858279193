import { createAction } from '@reduxjs/toolkit'
import { all, call, ForkEffect, put, takeEvery } from 'redux-saga/effects'
import { unlinkGeometryFromFlag } from '../../../api/projects-api'
import { unlinkFlagFromMaterial } from '../../slices/materialFlags'
import managers from '../../lib/managers'
import { Workspace } from '../../lib/toolBoxes/2D'

export const unlinkGeometryFromFlagAction = createAction<{
    opening_link_id: number
    flag_id: number
    opening_id: number | null
}>('unlinkGeometryFromFlag')

export function* handleUnlinkGeometryFromFlag({ payload }: ReturnType<typeof unlinkGeometryFromFlagAction>) {
    try {
        const paperManager = yield call(managers.get2DManager)

        if (!paperManager) return

        const workspaceTool: Workspace = yield call(paperManager.getTool, Workspace.NAME)

        const { flag_id, opening_link_id, opening_id } = payload

        const temporaryFlagItems: paper.Item[] = yield call(
            workspaceTool.getItemsWithCriteria,
            'data',
            (data) => flag_id === data?.flag_id && opening_id === data?.openingId
        )

        yield all(temporaryFlagItems.map((item) => call(item.remove.bind(item))))

        yield call(unlinkGeometryFromFlag, opening_link_id)

        yield put(unlinkFlagFromMaterial({ flag_id, opening_link_id }))
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForUnlinkGeometryFromFlag(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(unlinkGeometryFromFlagAction.type, handleUnlinkGeometryFromFlag)
}

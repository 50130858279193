import { OpeningGroup } from '../../../models/activeDrawable'
import { call, put, select } from 'redux-saga/effects'
import { setVisibleAndHiddenDrawableGroupIds, updateCameraResetFlag } from '../../slices/3D'
import { CameraTool, Workspace } from '../../lib/toolBoxes/3D'
import { CameraPositions } from '../../types'
import { getHiddenAndVisibleGroups } from '../utils/getHiddenAndVisibleGroups'
import { GeometryGroup } from '../../slices/geometry'
import { selectDrawableGroups } from '../2D/handleToggleDrawableGroups'
import { BabylonManager } from '../../lib/managers/BabylonManager'
import managers from '../../lib/managers'

export function* handleCameraAndGroupsVisibility(activeDrawableGroup: GeometryGroup | OpeningGroup) {
    const manager: BabylonManager | null = yield call(managers.get3DManager)

    if (!manager) return

    const [workspaceTool, cameraTool] = yield call(manager.getTools, [Workspace.NAME, CameraTool.NAME])

    // update camera reset flag, since we are locking camera position
    yield put(updateCameraResetFlag(false))

    // lock camera position
    cameraTool.setPosition(CameraPositions.TOP_DOWN, true)

    // detach control from camera which prevent moving
    cameraTool.enableFullControlOrOnlyAllowPanning(false)

    // get current state of visible hidden drawable group ids
    const { hiddenDrawableGroupIds, visibleDrawableGroupIds } = yield call(getHiddenAndVisibleGroups)

    // store drawable groups visibility, to reset visibility to same state when we save on cancel the form
    yield put(setVisibleAndHiddenDrawableGroupIds({ visibleDrawableGroupIds, hiddenDrawableGroupIds }))

    const allDrawableGroups: Array<GeometryGroup> = yield select(selectDrawableGroups)

    // get groups ids without active
    const groupIdsToHide = allDrawableGroups
        .filter((group) => group.id !== activeDrawableGroup.id)
        .map((group) => group.id)

    // hide every group
    yield call(workspaceTool.modifyNodeVisibility, groupIdsToHide, false)

    // keep active group visible and update it in nodeCache
    const groupIdsToShow: number[] = yield call(workspaceTool.filterOutVisibleNodeGroupIds, [activeDrawableGroup.id])

    // make active group visible
    yield call(workspaceTool.modifyNodeVisibility, groupIdsToShow, true)
}

import { FormValidation, UiSchema } from '@rjsf/core'
import { calculateAreaMeasurementsForMaterial } from '../imup/sagas/2D/createDrawableLocation'
import { FormProperties } from '../imup/types'
import { ActiveDrawable } from '../models/activeDrawable'
import { GeneralDrawableSettings } from '../models/activeDrawableSettings'
import { Region } from '../models/region'
import { DRAWABLE_TYPES } from '../shared/constants/drawable-types'
import { FORM_ERROR_MESSAGES } from '../shared/constants/error-messages'
import { pitchNumberValidation } from '../shared/services/field-validation/field-validation-service'
import { calculateTotalArea } from '../utils/formSchema/formSchema-helper-functions'

const pitchOtherValue = 'other'

const uiSchema: UiSchema = {
    'ui:order': ['type', 'pitch', 'pitch_other'],
    type: {
        'ui:placeholder': 'Select Framing Roofing Type',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    if (newSettings.pitch === pitchOtherValue && newSettings.pitch_other) {
        newSettings.pitch = String(newSettings.pitch_other).toUpperCase()

        delete newSettings.pitch_other
    } else if (newSettings.pitch !== pitchOtherValue) {
        newSettings.pitch_other = null
    }

    return newSettings
}

const onValidate = (formData: any, errors: FormValidation) => {
    const pitchFields = ['pitch']
    pitchFields.forEach((pitchName) => {
        if (formData[pitchName] === pitchOtherValue) {
            const pitchOtherFieldName = `${pitchName}_other`
            if (!pitchNumberValidation(formData[pitchOtherFieldName])) {
                errors[pitchOtherFieldName].addError(FORM_ERROR_MESSAGES.PITCH_NUMBER)
            }
        }
    })
}

/**
 * Augment the drawables of the from based on the new settings
 * @returns
 * @param input
 */
function prepareDrawables(input: {
    drawables: ActiveDrawable[]
    newSettings: GeneralDrawableSettings
    scaleFactor: number
    dpi: number | null
    pdfScale: number
    xCalibrationFactor: number
    yCalibrationFactor: number
    regions: Region[]
    is3D?: boolean
}): ActiveDrawable[] {
    const {
        drawables,
        newSettings,
        scaleFactor,
        dpi,
        pdfScale,
        xCalibrationFactor,
        yCalibrationFactor,
        regions,
        is3D,
    } = input

    if (is3D) return drawables

    return drawables.map((drawable) => {
        const totalArea: number = calculateTotalArea(
            drawable.opening_locations,
            regions,
            scaleFactor,
            pdfScale,
            xCalibrationFactor,
            yCalibrationFactor,
            dpi
        )

        drawable.settings.quantity = calculateAreaMeasurementsForMaterial(totalArea, {
            type: DRAWABLE_TYPES.FRAMING_ROOFING,
            settings: newSettings,
        })

        return drawable
    })
}

const form: FormProperties = { uiSchema, onValidate, onSubmit, prepareDrawables }

export default form

import { Coordinates2D, Cursors, ItemScale, PaperToolConfig } from '../../../../../types'
import Base from '../paperTool/PaperTool'

export class PathTool extends Base {
    static NAME = 'PATH'
    static CURSOR = Cursors.CROSSHAIR // all creation path tools should have crosshair cursors

    private circleFill: paper.Color = new this.paper.Color('white')
    private circleColor: paper.Color = new this.paper.Color('blue')

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = PathTool.NAME
        this.cursor = PathTool.CURSOR
    }

    protected renderPoint = (center: paper.Point): paper.Group => {
        const point = this.constructCrosshairMarker(center)

        point.strokeWidth = ItemScale.POINT / 2
        point.strokeColor = this.circleColor

        return point
    }

    /**
     * Creates a new paper path from a collection of 2D Coords
     * @param coordinates the 2D coords that make up the line
     * @returns the newly created paper path
     */
    public createPath = (coordinates: Coordinates2D): paper.Path =>
        coordinates.reduce((path, [x, y]) => {
            path.add(new this.paper.Point(x, y))
            return path
        }, new this.paper.Path())
}

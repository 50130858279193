import { createAction } from '@reduxjs/toolkit'
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects'
import { fetchProjectFlagsOptions } from '../../../../api/projects-api'
import { setOptions } from '../../../slices/admin'
import { ResponseAdminOptions } from '../../../../models/adminOptions'

export const fetchAdminData = createAction('fetchAdminData')

export function* handleFetchAdminData() {
    const flagsOptions = yield call(fetchProjectFlagsOptions)

    yield put(setOptions({ options: flagsOptions as ResponseAdminOptions }))
}

export function* watchForFetchAdminData(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(fetchAdminData.type, handleFetchAdminData)
}

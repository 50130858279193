import { PBRMaterialNames, PBRMaterialOptions } from '../../../../types'

const DEFAULT_MATERIAL_ROUGHNESS = 1
const DEFAULT_MATERIAL_METALNESS = 0
const DEFAULT_PARALLAX_SCALE_BIAS = 0.005
const DEFAULT_GLOSSINESS = 1
const DEFAULT_INDEX_OF_REFRACTION = 0.52
const DEFAULT_RENDER_MODE = 'Parallax Occlusion'

// Roof material constants
const ROOF_ROUGHNESS = 0.0
const ROOF_METALNESS = 0
const ROOF_PARALLAX_SCALE_BIAS = 0.02
const ROOF_TEXTURE_WIDTH = 7.5
const ROOF_TEXTURE_HEIGHT = 7.5
const ROOF_TEXTURE_URL = '/textures/roof_albedoMap.jpg'
const ROOF_NORMAL_TEXTURE_URL = '/textures/roof_normalMap.jpg'

// Siding material constants
const SIDING_COLOR_HEX = '#EBECE8'
const SIDING_TEXTURE_WIDTH = 2.0
const SIDING_TEXTURE_HEIGHT = 2.0
const SIDING_TEXTURE_URL = '/textures/siding_albedoMap.jpg'
const SIDING_NORMAL_TEXTURE_URL = '/textures/siding_normalMap.jpg'

// Glass material constants
const GLASS_REFLECTIVITY_COLOR_HEX = '#727272'
const GLASS_ALPHA = 0.77
const GLASS_GLOSSINESS = 1
const GLASS_COLOR_HEX = '#000000'
const GLASS_ROUGHNESS = 0
const GLASS_METALNESS = 0
const GLASS_IS_REFLECTION_TEXTURE = true
const GLASS_REFRACTION_INDEX = 1

const createPBRMatOption = (): Partial<PBRMaterialOptions> => {
    return {
        reflectivityColor: null,
        reflectivityTextureUrl: null,
        ambientColor: null,
        ambientTextureUrl: null,
        emissiveColor: null,
        emissiveTextureUrl: null,
        metallicTextureUrl: null,
        reflectionColor: null,
        roughness: DEFAULT_MATERIAL_ROUGHNESS,
        metallic: DEFAULT_MATERIAL_METALNESS,
        parallaxScaleBias: DEFAULT_PARALLAX_SCALE_BIAS,
        microSurface: DEFAULT_GLOSSINESS,
        indexOfRefraction: DEFAULT_INDEX_OF_REFRACTION,
        useMicroSurfaceFromReflectivityMapAlpha: false,
        linkRefractionWithTransparency: false,
        refractionTexture: false,
        renderMode: DEFAULT_RENDER_MODE,
    }
}

const PBROPTIONS = [
    {
        ...createPBRMatOption(),
        roughness: ROOF_ROUGHNESS,
        metallic: ROOF_METALNESS,
        parallaxScaleBias: ROOF_PARALLAX_SCALE_BIAS,
        textureWidth: ROOF_TEXTURE_WIDTH,
        textureHeight: ROOF_TEXTURE_HEIGHT,
        textureUrl: ROOF_TEXTURE_URL,
        normalTextureUrl: ROOF_NORMAL_TEXTURE_URL,
        name: PBRMaterialNames.Roof,
    } as PBRMaterialOptions,
    {
        ...createPBRMatOption(),
        color: SIDING_COLOR_HEX,
        textureWidth: SIDING_TEXTURE_WIDTH,
        textureHeight: SIDING_TEXTURE_HEIGHT,
        textureUrl: SIDING_TEXTURE_URL,
        normalTextureUrl: SIDING_NORMAL_TEXTURE_URL,
        name: PBRMaterialNames.Siding,
    } as PBRMaterialOptions,
    {
        ...createPBRMatOption(),
        indexOfRefraction: GLASS_REFRACTION_INDEX,
        alpha: GLASS_ALPHA,
        microSurface: GLASS_GLOSSINESS,
        reflectivityColor: GLASS_REFLECTIVITY_COLOR_HEX,
        color: GLASS_COLOR_HEX,
        roughness: GLASS_ROUGHNESS,
        metallic: GLASS_METALNESS,
        refractionTexture: GLASS_IS_REFLECTION_TEXTURE,
        name: PBRMaterialNames.Glass,
    } as PBRMaterialOptions,
]

export default PBROPTIONS

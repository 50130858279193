import moment from 'moment'

const YODA_TIME = 'YYYY-MM-DD H:mm:ss'

export const getFormattedDatetime = (projectCreatedAt: string | number, format: string): string => {
    return moment.utc(projectCreatedAt).local().format(format)
}
export const getTimeAgo = (time: string) => {
    return moment(getFormattedDatetime(time, YODA_TIME)).fromNow()
}

import { PayloadAction } from '@reduxjs/toolkit'
import { call, fork, select, StrictEffect, takeEvery } from 'redux-saga/effects'
import { editingActive } from '../../slices/2D'
import { selectActiveMode } from '../../slices/common'
import { VIEW_MODE } from '../../types'
import { handleEditingActive2D } from '../2D/handleEditingActive'

export function* handleEditingActive(action: PayloadAction<boolean>): Generator<StrictEffect, void, VIEW_MODE> {
    try {
        const activeMode: VIEW_MODE = yield select(selectActiveMode)

        switch (activeMode) {
            case VIEW_MODE.Markup2D:
                yield fork(handleEditingActive2D, action)
                break
            case VIEW_MODE.Markup3D:
            default:
                break
        }
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForEditingActive() {
    yield takeEvery(editingActive.type, handleEditingActive)
}

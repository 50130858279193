import { AISuggestion } from '../../../../models/aiClassifications'
import { IToolObject } from '../../../../models/tool'
import { ACTIVE_OPACITY_VALUE, INACTIVE_OPACITY_VALUE } from '../../../../shared/constants/colors'
import { capitalizeEachWord } from '../../../../utils/stringFormatters'
import { flash2D } from '../../../lib/animations'
import { IMUP2DDrawableLocation } from '../../../types'

export default function addMetadataToPath(
    path: paper.Path,
    scaleFactor: string,
    location?: IMUP2DDrawableLocation,
    aiSuggestion?: AISuggestion,
    toolObject?: IToolObject
): void {
    // style metadata
    path.data.opacityChangeVal = -0.02 // used by animations, i.e. hover
    path.data.originalOpacityValue = path.fillColor?.alpha ?? path.strokeColor?.alpha ?? 1.0
    path.data.opacityValue = path.fillColor?.alpha ?? path.strokeColor?.alpha ?? 1.0
    path.data.originalStrokeColor = path.strokeColor
    path.data.originalStrokeWidth = path.strokeWidth
    path.data.activeOpacityValue = ACTIVE_OPACITY_VALUE
    path.data.inactiveOpacityValue = INACTIVE_OPACITY_VALUE

    // animation metadata
    path.data.onFrameHandler = flash2D.bind(path)
    path.data.shapeHasChanged = false

    // set scale factor
    path.data.scale = scaleFactor

    // set AI suggestion if path created from AI
    if (aiSuggestion) {
        path.data.aiSuggestion = aiSuggestion
        path.data.shapeType = aiSuggestion.shape_type
    }

    if (toolObject) {
        path.data.toolObject = toolObject
        path.data.shapeType = toolObject.type
        path.data.toolName = toolObject.type
        path.data.isToolObject = true
    }

    // set location metadata if path is created by user
    if (!location) return

    const {
        opening_group_id,
        drawable_id,
        drawing_type,
        label,
        settings,
        opening_location_id,
        shapeType,
        isActive,
        document_chunk_id,
        additionalData,
    } = location

    // location metadata
    path.data.opening_group_id = opening_group_id
    path.data.drawable_id = drawable_id
    path.data.drawing_type = drawing_type
    path.data.document_chunk_id = document_chunk_id
    path.data.shapeType = shapeType
    path.data.title = `${label} ${capitalizeEachWord(settings.name.replaceAll('_', ' '))}`
    path.data.opening_location_id = opening_location_id
    path.data.isActive = isActive
    path.data.additionalData = additionalData
}

// first regExp check for digits(0-9) - slash - digits(0-9), eg: 1/2, 9/999, 99/999
// second regExp check for number 1-5 digits one space digits(0-9) - slash - digits(0-9), eg: 1 1/2, 11 1/16, 23 2/111, 99999 9/999
// third regExp check for number from 1-5 digits before dot and 1-2 digits
export const fractionAndNumbersRegExp = new RegExp(
    /^\d{1,2}\/\d{1,3}|^(\d{1,5}\s\d{1,2}\/\d{1,3})|^(\d{1,5}(\.\d{1,2})?)/
)

export const feetRegExp = new RegExp(/^[w|W]\d+[x|X]\d+/)

export const fullNumberPattern = '^\\d+$'

export const fullNumberWithDecimals = '^(\\d+(\\.\\d+)?|\\d+\\.\\d*|\\d*\\.\\d+)$'

const splitAndCheckFractionElements = (fraction: string): boolean => {
    const [numerator, denominator] = fraction.split('/')

    if (Number(numerator) > Number(denominator)) {
        return false
    } else {
        return true
    }
}

/**
 * Check if value is fraction, if so, check if denominator is bigger then numerator
 * @param value
 */
export const isFractionCorrect = (value: string | undefined): boolean => {
    const checkValue = value?.split(' ')

    if (checkValue?.length === 2 && checkValue[1]?.includes('/')) {
        return splitAndCheckFractionElements(checkValue[1])
    } else if (checkValue?.length === 1 && checkValue[0].includes('/')) {
        return splitAndCheckFractionElements(checkValue[0])
    }

    return true
}

/**
 * Function which validate fraction
 * @param selectedOption
 */
export const fractionValidation = (selectedOption: string): boolean => {
    const fieldCheck = selectedOption.match(fractionAndNumbersRegExp)

    if (!isFractionCorrect(fieldCheck?.[0])) {
        return false
    }

    return !(!fieldCheck || fieldCheck[0] === '0' || fieldCheck[0] !== fieldCheck.input)
}

export const fractionValidationAllowZero = (selectedOption: string): boolean => {
    const fieldCheck = selectedOption.match(fractionAndNumbersRegExp)

    if (!isFractionCorrect(fieldCheck?.[0])) {
        return false
    }

    return !(!fieldCheck || fieldCheck[0] !== fieldCheck.input)
}

/**
 * Function which validate feet
 * @param selectedOption
 */
export const feetValidation = (selectedOption: string): boolean => {
    // pattern check for any digit
    const minDigit = 4
    const feetRegExp = new RegExp(fullNumberPattern)
    const fieldCheck = selectedOption.match(feetRegExp)

    // on cases when field is not required, and it's empty
    if (!selectedOption) {
        return true
    }

    // check if selectedOption is digit | if digit doesn't start with 0 | if digit is higher or equal minDigit
    return !(!fieldCheck || fieldCheck[0].charAt(0) === '0' || (fieldCheck?.input && Number(selectedOption) < minDigit))
}

/**
 * Function which validate full number
 * @param selectedOption
 */
export const fullNumberValidation = (selectedOption: string): boolean => {
    const feetRegExp = new RegExp(/^\d+$/)
    const fieldCheck = selectedOption.match(feetRegExp)

    // on cases when field is not required, and it's empty
    if (!selectedOption) {
        return true
    }

    // check if selectedOption is digit
    return !!fieldCheck
}

/**
 * Function which validate full number without zero
 * @param selectedOption
 */
export const fullNumberWithoutZeroValidation = (selectedOption: string): boolean => {
    const feetRegExp = new RegExp(/^[0-9]*$/)
    const fieldCheck = selectedOption.match(feetRegExp)

    // on cases when field is not required, and it's empty
    if (!selectedOption) {
        return true
    }

    // check if selectedOption is digit
    return !(!fieldCheck || fieldCheck[0].charAt(0) === '0')
}

/**
 * Function which validate if number is between 0 and 25 included
 * @param selectedOption
 */
export const pitchNumberValidation = (selectedOption: string): boolean => {
    return Number(selectedOption) >= 0 && Number(selectedOption) <= 25
}

/**
 * Function validates if number is within min and max passed
 * @param selectedOption
 * @param min
 * @param max
 */
export const checkNumberLimits = (selectedOption: string, min?: number, max?: number): boolean => {
    let isMin = true
    let isMax = true
    if (min || min === 0) {
        isMin = Number(selectedOption) >= min
    }
    if (max || max === 0) {
        isMax = Number(selectedOption) <= max
    }

    return isMin && isMax
}

/**
 * Function which validate steel beam plate size: W[number]X[number]
 * @param selectedOption
 */
export const steelBeamPlateSizeValidation = (selectedOption: string): boolean => {
    const fieldCheck = selectedOption.match(feetRegExp)

    // check if selectedOption match pattern
    return !(!fieldCheck || fieldCheck[0] !== fieldCheck.input)
}

export const mandatoryValueValidation = (value: string | undefined): boolean => {
    const trimmedValue = value?.trim() ?? ''
    return trimmedValue !== ''
}

/**
 * Function which validate if number is even
 * @param value
 */
export const evenNumberValidation = (value: string): boolean => {
    return Number(value) % 2 === 0 && value.trim().length > 0
}

import { createAction } from '@reduxjs/toolkit'
import { call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects'
import { updateFlag } from '../../../api/projects-api'
import { FlagAxiosAPIResponse } from '../../../models/flags'
import { Project } from '../../../models/project'
import { updateFlagStatus } from '../../slices/materialFlags'
import { selectProject } from './createDrawableLocation'

interface UpdateFlagData {
    flag_id: number
    flagFormData: {
        status_id: number
        category_id?: number
        sub_category_id?: number
        description?: string
    }
}

export const updateFlagAction = createAction<UpdateFlagData>('updateFlag')

export function* handleUpdateFlag({ payload }: ReturnType<typeof updateFlagAction>) {
    try {
        const { flag_id, flagFormData } = payload

        const project: Project = yield select(selectProject)

        const response: FlagAxiosAPIResponse = yield call(updateFlag, project.id, flag_id, flagFormData)

        yield put(updateFlagStatus(response))
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForUpdateFlag(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(updateFlagAction.type, handleUpdateFlag)
}

import defaultTo from 'lodash/defaultTo'
import { call, CallEffect, put, PutEffect, select, SelectEffect } from 'redux-saga/effects'
import { ActiveFloor } from '../../../models/activeFloor'
import { AISuggestion } from '../../../models/aiClassifications'
import { DRAWING_TYPES } from '../../../shared/constants/drawable-types'
import { DEFAULT_SCALE_FACTOR } from '../../../shared/constants/scales'
import { drawableBackgroundAndBorderColor } from '../../../shared/services/drawable-color-services'
import { convertScaleFactorLabelEnumToDecimal } from '../../../utils/calculations/scaleConversion/scaleConversion'
import PaperManager from '../../lib/managers/PaperManager'
import { Color, Count, PathTool, PolygonTool, Workspace } from '../../lib/toolBoxes/2D'
import addSelectFunctionalityToAiSuggestion from '../../lib/utils/functionality-bindings/addSelectFunctionalityToAiSuggestion'
import { selectActiveMappingAsFloor } from '../../slices/mappings'
import { changeStrokeWidth, initialToolsState } from '../../slices/tools'
import { ItemScale, ITool, REGION_ENUMS } from '../../types'
import addMetadataToPath from './data-prep/addMetadataToPath'

type DrawAiSuggesstionsByTypeYield =
    | CallEffect<string | paper.Color | paper.Path | void | paper.Raster | paper.Group | ITool | PaperManager | null>
    | PutEffect
    | SelectEffect

type DrawAiSuggesstionsByTypeNext = string &
    paper.Color &
    (paper.Raster | null) &
    paper.Path &
    paper.Group &
    ITool &
    PaperManager &
    (ActiveFloor | null)

export default function* drawAiSuggestionsByType(
    location: AISuggestion,
    colorTool: Color,
    countTool: Count,
    pathTool: PathTool,
    polygonTool: PolygonTool,
    workspaceTool: Workspace,
    areaOpacity: number,
    lineOpacity: number
): Generator<DrawAiSuggesstionsByTypeYield, paper.Path, DrawAiSuggesstionsByTypeNext> {
    const { coordinates, type, settings, shape_type } = location

    // determine color by location settings and drawable_type
    const colorValueFromConstants: string = yield call(
        drawableBackgroundAndBorderColor,
        defaultTo(type, ''),
        settings.name,
        settings.selection,
        settings.location,
        {},
        settings.material
    )

    // create color with color tool
    const shapeColor: paper.Color = yield call(colorTool.createColor, colorValueFromConstants)

    // Determine if this drawable is inside a region so we can scale it appropriately
    const regionPaths = workspaceTool.getItemsWithCriteria(
        'data',
        (data: any) => data.shapeType === REGION_ENUMS.TYPE
    ) as paper.Path[]

    let thisRegionGroup: paper.Path | null = null
    for (const regionPath of regionPaths) {
        for (const c of location.coordinates) {
            if (!regionPath.contains(workspaceTool.generatePoint(c))) {
                break
            }
            thisRegionGroup = regionPath
        }
    }

    const activeFloor: ActiveFloor | null = yield select(selectActiveMappingAsFloor)
    const scaleFactor = thisRegionGroup?.data?.scale
        ? thisRegionGroup.data.scale
        : activeFloor?.scale_factor ?? DEFAULT_SCALE_FACTOR
    const currentScaleFactor = convertScaleFactorLabelEnumToDecimal(scaleFactor)

    // create a paper.Path based on the drawing type - point, area, or section/default
    let path: paper.Path
    switch (shape_type) {
        case DRAWING_TYPES.POINT:
            const [center] = coordinates
            path = yield call(countTool.createPoint, center, currentScaleFactor * ItemScale.POINT)

            shapeColor.alpha = areaOpacity

            path.strokeColor = shapeColor
            path.fillColor = shapeColor
            path.strokeWidth = 0 // hide since shape is filled
            break

        case DRAWING_TYPES.AREA:
            path = yield call(polygonTool.createPolygon, coordinates)

            shapeColor.alpha = areaOpacity

            path.strokeColor = shapeColor
            path.fillColor = shapeColor
            path.strokeWidth = 0 // hide since shape is filled
            break

        case DRAWING_TYPES.SECTION:
        default:
            path = yield call(pathTool.createPath, coordinates)

            shapeColor.alpha = lineOpacity

            const raster: paper.Raster | null = yield call(workspaceTool.getPlanRaster)
            let strokeWidthScale = 100
            if (raster) {
                // Scale the lines based on the size of the image we are working with
                strokeWidthScale = workspaceTool.calculateStrokeWidthBasedOnRaster(raster)
                yield put({ type: changeStrokeWidth.type, payload: strokeWidthScale * ItemScale.LINE })
            }

            path.strokeColor = shapeColor
            path.strokeWidth = strokeWidthScale * ItemScale.LINE

            // these are multiplied to scale the dashes based on the stroke width (the default numbers in the store are based on 1px width)
            const dashLength = initialToolsState.dashArray[0] * path.strokeWidth
            const spacePercentage = 20
            path.dashArray = [(dashLength * (100 - spacePercentage)) / 100, (dashLength * spacePercentage) / 100]
            break
    }

    // add metadata to paths
    yield call(addMetadataToPath, path, scaleFactor, undefined, location)
    yield call(addSelectFunctionalityToAiSuggestion, path)

    return path
}

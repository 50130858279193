import { Mesh } from '@babylonjs/core'
import { AdvancedDynamicTexture, Rectangle, TextBlock } from '@babylonjs/gui'
import { BabylonToolConfig } from '../../../../../types'
import BabylonTool from '../babylonTool/BabylonTool'

export class Label extends BabylonTool {
    static NAME = 'LABEL'
    private static readonly LABEL_FONT_COLOR = 'black'
    private static readonly LABEL_FONT_SIZE = '10px'
    private static readonly LABEL_WIDTH_HEIGHT = '15px'
    private static readonly LABEL_BACKGROUND = 'white'
    private static readonly LABEL_ADVANCED_TEXTURE_NAME = 'Label-UI'

    private guiAdvancedTexture: AdvancedDynamicTexture

    constructor(config: BabylonToolConfig) {
        super(config)
        this.name = Label.NAME
        this.registerActivationFunctions([])
        this.guiAdvancedTexture = AdvancedDynamicTexture.CreateFullscreenUI(Label.LABEL_ADVANCED_TEXTURE_NAME)
    }

    /**
     * Create a billboard styled text box and attach it to the provided mesh
     * @param labelText the text to put into the text box
     * @param meshToConnectTo the mesh to attach it to
     */
    public create = (labelText: string, meshToConnectTo: Mesh): void => {
        const labelContainer = new Rectangle()
        labelContainer.height = Label.LABEL_WIDTH_HEIGHT
        labelContainer.width = Label.LABEL_WIDTH_HEIGHT
        labelContainer.background = Label.LABEL_BACKGROUND
        this.guiAdvancedTexture.addControl(labelContainer)
        labelContainer.linkWithMesh(meshToConnectTo)

        const labelTxt = new TextBlock()
        labelTxt.text = labelText
        labelTxt.fontSize = Label.LABEL_FONT_SIZE
        labelTxt.color = Label.LABEL_FONT_COLOR

        labelContainer.addControl(labelTxt)
    }

    /**
     * Clear all labels that are drawn in the scene
     */
    public clearLabels = () => {
        this.guiAdvancedTexture.dispose()
        this.guiAdvancedTexture = AdvancedDynamicTexture.CreateFullscreenUI(Label.LABEL_ADVANCED_TEXTURE_NAME)
    }
}

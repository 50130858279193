import { UiSchema } from '@rjsf/core'
import { FormProperties } from '../imup/types'
import { deckOrPorch } from '../utils/formSchema/constants'

export const uiSchema: UiSchema = {
    'ui:order': ['item_number', 'sheathing'],
    item_number: {
        'ui:placeholder': 'Select Decking Material',
    },
    sheathing: {
        'ui:placeholder': 'Select Decking Sheathing Type',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
    bundle_location: {
        'ui:placeholder': 'Select Sub-Region Location',
    },
    bundle_floor_level: {
        'ui:placeholder': 'Select Sub-Region Floor Level',
    },
    bundle_other_floor_level: {
        'ui:placeholder': 'Enter Sub-Region Other Floor Level',
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    if (!deckOrPorch.includes(formData.bundle_name)) {
        newSettings.bundle_floor_level = null
    }

    if (formData.bundle_floor_level !== 'other') {
        newSettings.bundle_other_floor_level = null
    }

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form

import round from 'lodash/round'
import React from 'react'
import './MeasurementContainer.styles.scss'

/**
 * Convert number into feet and inches
 * @param length
 */
export const convertDecimalFeetToFractionalFeet = (length: number): string => {
    const [feet, feetRemainder] = length.toString().split('.')
    const inches = round(Number('.' + feetRemainder) * 12, 2)

    return `${feet}' ${inches !== 0 ? inches + '"' : ''}`
}

const MeasurementContainer: React.FC<{ measurement?: number; warningText?: string }> = ({
    measurement,
    warningText = 'Cannot calculate, please set scale',
}): JSX.Element => {
    return (
        <div className="MeasurementInfo">
            <p>
                <strong>{`${
                    measurement
                        ? `Calculated Length: ${convertDecimalFeetToFractionalFeet(measurement)}`
                        : `${warningText}`
                }`}</strong>
            </p>
        </div>
    )
}

export default MeasurementContainer

import { Color3, Color4, Engine, Mesh, MeshBuilder, Scene } from '@babylonjs/core'
import { distinctUntilChanged, map, takeWhile } from 'rxjs'
import { InteractionManager } from '../../components/IMUP3DComponent/babylon/interactionManager'
import { BabylonManagerConfig, IMUPState, ITool, VIEW_MODE } from '../../types'
import { BabylonToolBox } from '../toolBoxes/3D'
import AbstractManager from './AbstractManager'
// Viewer manager for babylonJS implementation for IMUP
export class BabylonManager extends AbstractManager {
    // Match the color grey from 2D to ensure that the 3D/2D IMUP look similar
    private static readonly SCENE_CLEAR_COLOR: Color4 = Color3.FromHexString('#e0e0e0').toColor4(1)
    public static readonly ROOT_MESH_NAME: string = 'ViewerRootMesh'
    public static readonly SCENE_DEFAULT_AMBIENT_COLOR: Color3 = Color3.White()
    public static readonly SCENE_DIM_COLOR: Color3 = new Color3(0.5, 0.5, 0.5)

    private scene: Scene
    private activeTool: string = ''

    protected toolBox: BabylonToolBox

    constructor(config: BabylonManagerConfig) {
        super({ mediator: config.mediator })
        this.scene = config.scene
        this.toolBox = new BabylonToolBox(config)
        this.mediator
            .get$()
            .pipe(
                takeWhile((state: IMUPState) => state.common.activeMode === VIEW_MODE.Markup3D),
                map((state: IMUPState) => state.tools.activeTool),
                distinctUntilChanged()
            )
            .subscribe((activeTool) => {
                if (this.toolBox.tools[activeTool]) {
                    this.activeTool = activeTool
                }
            })
    }

    private initRootNode = () => {
        const root = MeshBuilder.CreateSphere(BabylonManager.ROOT_MESH_NAME, { diameter: 0.001 }, this.scene)
        root.isVisible = false
    }

    private initEnvironment = (): void => {
        this.scene.ambientColor = BabylonManager.SCENE_DEFAULT_AMBIENT_COLOR
        this.scene.clearColor = BabylonManager.SCENE_CLEAR_COLOR
    }

    /**
     * Initializer for scene functionality.
     * Adds shortcut keyboard interactions.
     *
     * @returns void
     */
    public init = (): void => {
        this.checkWebGl2Support()
        this.initEnvironment()
        this.initRootNode()
        const interactionManager = InteractionManager.getInstance()
        interactionManager.register(this.scene)
        this.render()
    }

    /**
     * Return a promise indicating that the
     * scene is ready
     * @returns
     */
    public onReady = (): Promise<void> => {
        return this.scene!.whenReadyAsync()
    }

    /**
     * Disposes of meshes,
     * clears cached vertex and texture data,
     * disposes of the scene,
     * drops webgl rendering context,
     * disposes of the babylon engine.
     *
     * @returns void
     */
    private releaseResources = (): void => {
        this.scene.meshes.forEach((m) => m.dispose())
        this.scene.meshes = []
        this.scene.clearCachedVertexData()
        this.scene.cleanCachedTextureBuffer()
        this.scene.dispose()

        if (this.canvas) {
            const gl = this.canvas.getContext('webgl2')
            if (gl && gl instanceof WebGLRenderingContext) {
                const context = gl.getExtension('WEBGL_lose_context')
                if (context) {
                    context.loseContext()
                }
            }
        }

        this.engine.dispose()
    }

    /**
     * Get the engine instance associated with the current scene
     *
     * @returns Engine
     */
    public get engine(): Engine {
        return this.scene.getEngine()
    }

    /**
     * Get the scene instance
     *
     * @returns Scene
     */
    public getScene = (): Scene => {
        return this.scene
    }

    /**
     * Get the rendering canvas from the babylon engine
     *
     * @returns HTMLCanvasElement | null
     */
    public get canvas(): HTMLCanvasElement | null {
        return this.engine.getRenderingCanvas()
    }

    /**
     * Public accessor to get the root node
     */
    public get rootNode(): Mesh | null {
        const resultingNodeOrNull = this.scene.rootNodes.find((node) => node.name === BabylonManager.ROOT_MESH_NAME)
        return resultingNodeOrNull ? (resultingNodeOrNull as Mesh) : null
    }

    /**
     * Starts engine render loop, rendering scene
     *
     * @returns void
     */
    protected render = (): void => {
        this.engine.runRenderLoop(() => {
            this.scene.render()
        })
    }

    /**
     * Release all the resources in use by
     * the manager
     */
    public dispose = (): void => {
        this.releaseResources()
        this.mediator.dispose()
    }

    private checkWebGl2Support = (): void => {
        // We create a new canvas here because application canvas doesn't exist at this moment
        const gl = document.createElement('canvas').getContext('webgl2')
        if (!gl) {
            console.warn('No WebGL 2.0 support. Some features will not be available')
        }
    }

    /**
     * Override the abstract tool box use tool function to deactivate all other tools when one is activated
     * @param name tool name
     * @returns returns the tool needed
     */
    public useTool = (name: string): ITool => {
        // Deactivate the currently active tool
        const activeTool = this.toolBox.getTool(this.activeTool)
        activeTool && activeTool.remove()

        const tool = this.toolBox.getTool(name)
        tool.activate()

        return tool
    }
}

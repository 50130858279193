/**
 * List of azure feature flag ids
 */
export enum AzureFeatureFlagIds {
    testAzureFeatureFlagId = 'testFeatureFlagId', // used only for testing
    settings_page_new_ui = 'settings_page_new_ui',
    sg_drop_beam = 'sg_drop_beam',
    sg_eave_length = 'sg_eave_length',
    sg_floor_system = 'sg_floor_system',
    sg_flush_beam = 'sg_flush_beam',
    sg_framing_roofing = 'sg_framing_roofing',
    sg_front_end = 'sg_front_end',
    sg_gable_length = 'sg_gable_length',
    sg_header_beam = 'sg_header_beam',
    sg_hipvalley_beam = 'sg_hipvalley_beam',
    sg_ledger = 'sg_ledger',
    sg_post = 'sg_post',
    sg_ridge_beam = 'sg_ridge_beam',
    sg_roof_system = 'sg_roof_system',
    sg_wall = 'sg_wall',
    robust_overhang = 'robust_overhang',
    ledger_enhancement = 'ledger_enhancement',
    master_plans_sets = 'master_plans_sets',
    is_new_spec_sheet = 'is_new_spec_sheet',
}

export const SCALES = [
    {
        label: '1/32"',
        value: '1:384',
    },
    {
        label: '1/16"',
        value: '1:192',
    },
    {
        label: '3/32"',
        value: '1:128',
    },
    {
        label: '1/8"',
        value: '1:96',
    },
    {
        label: '3/16"',
        value: '1:64',
    },
    {
        label: '1/4"',
        value: '1:48',
    },
    {
        label: '3/8"',
        value: '1:32',
    },
    {
        label: '1/2"',
        value: '1:24',
    },
    {
        label: '3/4"',
        value: '1:16',
    },
    {
        label: '1"',
        value: '1:12',
    },
    {
        label: '1-1/2"',
        value: '1:8',
    },
    {
        label: '3"',
        value: '1:4',
    },
]

/**
 *  pdf_scale normally is provided by back-end as a property inside document_chunk.
 *  However, if it's null, value of 1 has to be used
 *
 *  Pdf scale is importat to convert pdf into images. Value of 5 has been introduced during development after experimenting with 'image sharpness'.
 *  For old prjects the scale were equal to 1, that's why the default scale should be 1
 */
export const DEFAULT_PDF_SCALE = 1

export const DEFAULT_SCALE_FACTOR = '0'

import { put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects'
import {
    setActiveFloorFromDocChunkId,
    SET_ACTIVE_FLOOR,
    SET_ACTIVE_FLOOR_FROM_DOC_CHUNK_ID,
} from '../../../actions/drawable'
import { DocumentMapping } from '../../../models/documentMapping'
import { Project } from '../../../models/project'
import { getDrawableMapping } from '../../../reducers/drawable'
import { PROJECT_TYPES_ENUM } from '../../../shared/constants/project-type.constants'
import { RootState } from '../../../stores'
import { selectDocumentChunks, selectDocumentMappings } from '../../slices/documents'
import { BasicFloorMapping, selectAllPossibleFloors } from '../../slices/mappings'
import { selectProject } from '../2D/createDrawableLocation'
import { ActiveFloor } from '../../../models/activeFloor'
import { DocumentChunk } from '../../../models/documentChunk'

export function* handleSetActiveFloorFromDocChunkId(action: ReturnType<typeof setActiveFloorFromDocChunkId>): Generator<
    | SelectEffect
    | PutEffect<{
          type: string
          payload: ActiveFloor
      }>,
    void,
    Project & DocumentChunk[] & DocumentMapping[] & BasicFloorMapping & BasicFloorMapping[] & ActiveFloor[]
> {
    const project: Project = yield select(selectProject)

    if (project.type === PROJECT_TYPES_ENUM.INTERACTIVE_MARKUP_ONLY) {
        // We must select from the new redux store if we are in a new project
        const documentChunks: DocumentChunk[] | null = yield select(selectDocumentChunks)
        const allFloors: BasicFloorMapping[] = yield select(selectAllPossibleFloors)

        if (!documentChunks || !allFloors) return

        const documentChunk = documentChunks?.find((c) => c.id === parseInt(action.payload))

        const mappings: DocumentMapping[] | null = yield select(selectDocumentMappings)

        if (!mappings || !documentChunk) return

        const mapping = mappings.find((m) => m.document_chunk_id === documentChunk!.id)

        if (!mapping) return

        const matchingFloor: BasicFloorMapping | undefined = allFloors?.find((f) => f.hash === mapping.floor_hash)

        if (!matchingFloor) return

        yield put({
            type: SET_ACTIVE_FLOOR,
            payload: {
                ...matchingFloor,
                id: mapping.id,
                building_id: mapping.building_id,
                project_document: documentChunk.project_document,
                document_chunk: documentChunk,
                additional_data: {},
                coordinates: mapping.coordinates,
                scale_factor: mapping.scale_factor,
                ai_classification: { id: -1, ai_classification_data: '' }, // we do not run AI on IMUP only at the present
            },
        })
    } else {
        const mappings: ActiveFloor[] = yield select((state: RootState) => getDrawableMapping(state.drawable))
        const floor = mappings.find((f) => f.document_chunk.id === parseInt(action.payload))
        if (floor) yield put({ type: SET_ACTIVE_FLOOR, payload: floor })
    }
}

export function* watchForSetActiveFloorFromDocChunkId() {
    yield takeLatest(SET_ACTIVE_FLOOR_FROM_DOC_CHUNK_ID, handleSetActiveFloorFromDocChunkId)
}

import { Ray, Scene } from '@babylonjs/core'
import { BabylonToolConfig, Cursors, IDENTITY_MATRIX, IStateMediator, ITool } from '../../../../../types'

export class BabylonTool implements ITool {
    protected static DO_NOT_FLAG_VERTEX_DATA_AS_UPDATABLE = false
    protected isActive = false
    protected toolFns
    protected mediator: IStateMediator
    protected scene: Scene
    protected activationFunctions: Array<() => () => void> = []
    protected deactivationFunctions: Array<() => void> = []
    public name?: string
    public cursor: string = Cursors.AUTO

    constructor(config: BabylonToolConfig) {
        this.scene = config.scene
        this.mediator = config.mediator
    }

    /**
     * Creates the babylonJS picking ray
     * @returns the picking ray
     */
    protected createPickingRay = (): Ray =>
        this.scene.createPickingRay(this.scene.pointerX, this.scene.pointerY, IDENTITY_MATRIX, this.scene.cameras[0])

    /**
     * Activate the tool
     * changing which tool is
     * active and modify the cursor
     */
    public activate(): void {
        this.isActive = true
        this.deactivationFunctions = this.activationFunctions.map((activationFunction) => activationFunction())
        this.mediator.mediate('common', { cursor: this.cursor }).mediate('tools', { activeTool: this.name })
    }

    /**
     * Deactivate the tool
     */
    public remove(): void {
        this.isActive = false
        this.deactivationFunctions.forEach((deactivationFunction) => deactivationFunction())
    }

    /**
     * Set the activation functions
     * for the tool
     */
    public registerActivationFunctions = (activationFns: Array<() => () => void>): void => {
        this.activationFunctions = activationFns
    }
}

export default BabylonTool

import {
    Animation,
    ArcRotateCamera,
    ArcRotateCameraPointersInput,
    Camera,
    CubicEase,
    EasingFunction,
    Nullable,
    PointerTouch,
    Tools,
    Vector3,
} from '@babylonjs/core'
import isNumber from 'lodash/isNumber'
import pick from 'lodash/pick'
import { distinctUntilChanged, map, takeWhile } from 'rxjs'
import { BabylonToolConfig, CameraPositions, FPS, IMUPState, MouseButtonCodes, VIEW_MODE } from '../../../../../types'
import BabylonTool from '../babylonTool/BabylonTool'

export class CameraTool extends BabylonTool {
    static NAME = 'CAMERA'
    private static readonly CAMERA_LOWER_RADIUS_LIMIT: number = 2
    private static readonly CAMERA_RADIUS: number = 120
    private static readonly CAMERA_NAME: string = 'MainCamera'
    private static readonly CAMERA_FOV_MODE: number = Camera.FOVMODE_HORIZONTAL_FIXED
    private static readonly SHOULD_CAMERA_CHECK_COLLISIONS: boolean = true
    private static readonly SHOULD_CAMERA_PREVENT_DEFAULT: boolean = false
    private static readonly SHOULD_CAMERA_USE_CTRL_FOR_PANNING: boolean = true
    private static readonly CAMERA_TARGET: Vector3 = Vector3.Zero()
    private static readonly CAMERA_ALPHA: number = Tools.ToRadians(-90)
    private static readonly CAMERA_BETA: number = Tools.ToRadians(90)
    static readonly POINTERS_INPUT_NAME = 'pointers'
    static readonly DEACTIVATED_BUTTONS = [MouseButtonCodes.Middle, MouseButtonCodes.Right]
    static readonly ACTIVATED_BUTTONS = [MouseButtonCodes.Left, MouseButtonCodes.Middle, MouseButtonCodes.Right]
    private static readonly TWO_PI = 2 * Math.PI
    // https://github.com/BabylonJS/Babylon.js/blob/fdbf393d1d7699dc7cc69cec1dca0819ebd2622a/packages/dev/core/src/Cameras/Inputs/arcRotateCameraPointersInput.ts#L86
    // The default of this value is 1000
    static readonly PANNING_SPEED = 300
    // https://github.com/BabylonJS/Babylon.js/blob/fdbf393d1d7699dc7cc69cec1dca0819ebd2622a/packages/dev/core/src/Cameras/Inputs/arcRotateCameraPointersInput.ts#L43
    // The default for this value is 1000
    static readonly ROTATING_SPEED = 500
    // Change the camera panning button
    static readonly PANNING_MOUSE_BUTTON = MouseButtonCodes.Middle

    private static readonly PRE_DEFINED_CAMERA_POSITIONS: Record<string, { alpha?: number; beta?: number }> = {
        [CameraPositions.TOP_DOWN]: {
            alpha: -Math.PI / 2,
            beta: 0,
        },
        [CameraPositions.BOTTOM_UP]: {
            alpha: Math.PI / 2,
            beta: Math.PI,
        },
        [CameraPositions.RIGHT_SIDE]: {
            alpha: 0,
            beta: Math.PI / 2,
        },
        [CameraPositions.LEFT_SIDE]: {
            alpha: Math.PI,
            beta: Math.PI / 2,
        },
        [CameraPositions.FRONT]: {
            alpha: -Math.PI / 2,
            beta: Math.PI / 2,
        },
        [CameraPositions.BACK]: {
            alpha: Math.PI / 2,
            beta: Math.PI / 2,
        },
    }
    private static readonly ALPHA_ANIMATION_NAME: string = 'alpha-animation'
    private static readonly BETA_ANIMATION_NAME: string = 'beta-animation'
    private static readonly TOTAL_ANIMATION_FRAME_LENGTH: number = FPS * 1.5

    private shouldResetCameraSelection: boolean = true

    constructor(config: BabylonToolConfig) {
        super(config)
        this.name = CameraTool.NAME
        this.initSceneCamera()
        this.activationFunctions = [this.activateCameraTool]

        this.mediator
            .get$()
            .pipe(
                takeWhile((state: IMUPState) => state.common.activeMode === VIEW_MODE.Markup3D),
                map((state) => pick(state['3D'], ['shouldResetCameraSelection'])),
                distinctUntilChanged()
            )
            .subscribe(({ shouldResetCameraSelection }) => {
                this.shouldResetCameraSelection = shouldResetCameraSelection
            })
    }

    private getSceneCamera = (): ArcRotateCamera | null => {
        return this.scene.activeCamera as ArcRotateCamera
    }

    private initSceneCamera = (): Camera => {
        const camera = new ArcRotateCamera(
            CameraTool.CAMERA_NAME,
            CameraTool.CAMERA_ALPHA,
            CameraTool.CAMERA_BETA,
            CameraTool.CAMERA_RADIUS,
            CameraTool.CAMERA_TARGET,
            this.scene
        )

        camera.lowerRadiusLimit = CameraTool.CAMERA_LOWER_RADIUS_LIMIT
        camera.checkCollisions = CameraTool.SHOULD_CAMERA_CHECK_COLLISIONS
        camera.fovMode = CameraTool.CAMERA_FOV_MODE
        // In order to speed up panning
        // reduce the value panningSensibility / angularSensibilityX / angularSensibilityY
        // below the 1000 default value
        // refer to link below for the code that
        // manages this value
        // https://github.com/BabylonJS/Babylon.js/blob/fdbf393d1d7699dc7cc69cec1dca0819ebd2622a/packages/dev/core/src/Cameras/Inputs/arcRotateCameraPointersInput.ts#L115
        camera.panningSensibility = CameraTool.PANNING_SPEED
        camera.angularSensibilityX = CameraTool.ROTATING_SPEED
        camera.angularSensibilityY = CameraTool.ROTATING_SPEED
        const pointerInput = camera.inputs.attached[CameraTool.POINTERS_INPUT_NAME] as ArcRotateCameraPointersInput

        // Attached listener to on mouse move event
        const oldPointerOnTouch = pointerInput['onTouch'].bind(pointerInput)
        pointerInput['onTouch'] = (point: Nullable<PointerTouch>, offsetX: number, offsetY: number) => {
            oldPointerOnTouch(point, offsetX, offsetY)
            this.onCameraMove()
        }
        return camera
    }

    private onCameraMove = (): void => {
        if (!this.shouldResetCameraSelection) {
            this.mediator.mediate('3D', { shouldResetCameraSelection: true })
        }
    }

    private activateCameraTool = () => {
        const activeCamera = this.getSceneCamera()

        /**
         * This activation attaches the camera controls
         * with full functionality if it has not been attached
         * to the scene and if it has already then restore the full
         * functionality (as of June, 6th) this only includes
         * adding back the use of the left mouse button to rotate
         *
         * This works because of the fact that babylon camera
         * input manager discards any mouse events that are
         * not the button codes defined in the buttons array
         *
         * https://github.com/BabylonJS/Babylon.js/blob/7a6e50670663e742eca7a3aad1d78754e753ac82/packages/dev/core/src/Cameras/Inputs/BaseCameraPointersInput.ts
         */
        if (activeCamera) {
            if (!activeCamera.inputs.attachedToElement) {
                activeCamera.attachControl(
                    null,
                    CameraTool.SHOULD_CAMERA_PREVENT_DEFAULT,
                    CameraTool.SHOULD_CAMERA_USE_CTRL_FOR_PANNING
                )
                activeCamera._panningMouseButton = CameraTool.PANNING_MOUSE_BUTTON
            } else {
                const pointerInput = activeCamera!.inputs.attached[
                    CameraTool.POINTERS_INPUT_NAME
                ] as ArcRotateCameraPointersInput
                pointerInput.buttons = CameraTool.ACTIVATED_BUTTONS
            }
        }

        /**
         * On deactivation of the camera tool then remove the
         * use of the left mouse button and only allow the center
         * and right mouse buttons for panning and rotation
         * respectively
         */
        return () => {
            const pointerInput = activeCamera!.inputs.attached[
                CameraTool.POINTERS_INPUT_NAME
            ] as ArcRotateCameraPointersInput
            pointerInput.buttons = CameraTool.DEACTIVATED_BUTTONS
        }
    }

    /**
     * Use the camera position name and calculate the new alpha and betas for the camera
     * Animate the easing to the new camera position
     * @param position The new position string
     * @param skipAnimations should skip animation
     * @returns void
     */
    public setPosition = (position: CameraPositions, skipAnimations: boolean = false): void => {
        const camera = this.getSceneCamera()! as ArcRotateCamera
        const cameraAlphaAndBeta = CameraTool.PRE_DEFINED_CAMERA_POSITIONS[position]
        if (!cameraAlphaAndBeta) return

        const ease = new CubicEase()

        ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT)

        if (isNumber(cameraAlphaAndBeta.alpha)) {
            if (!skipAnimations) {
                Animation.CreateAndStartAnimation(
                    CameraTool.ALPHA_ANIMATION_NAME,
                    camera,
                    'alpha',
                    FPS,
                    CameraTool.TOTAL_ANIMATION_FRAME_LENGTH,
                    camera.alpha % CameraTool.TWO_PI,
                    cameraAlphaAndBeta.alpha,
                    0,
                    ease
                )
            } else {
                camera.alpha = cameraAlphaAndBeta.alpha
            }
        }

        if (isNumber(cameraAlphaAndBeta.beta)) {
            if (!skipAnimations) {
                Animation.CreateAndStartAnimation(
                    CameraTool.BETA_ANIMATION_NAME,
                    camera,
                    'beta',
                    FPS,
                    CameraTool.TOTAL_ANIMATION_FRAME_LENGTH,
                    camera.beta % CameraTool.TWO_PI,
                    cameraAlphaAndBeta.beta,
                    0,
                    ease
                )
            } else {
                camera.beta = cameraAlphaAndBeta.beta
            }
        }
    }

    public enableFullControlOrOnlyAllowPanning = (shouldEnableOnlyPanning: boolean): void => {
        const camera = this.getSceneCamera()! as ArcRotateCamera

        const pointerInput = camera!.inputs.attached[CameraTool.POINTERS_INPUT_NAME] as ArcRotateCameraPointersInput

        if (shouldEnableOnlyPanning) {
            // restore previous buttons
            pointerInput.buttons = CameraTool.ACTIVATED_BUTTONS
        } else {
            // allow only panning
            pointerInput.buttons = [CameraTool.PANNING_MOUSE_BUTTON]
        }
    }
}

export default CameraTool

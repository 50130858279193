export const sortASCByNumberOrText = (numberOrTextA: number | string, numberOrTextB: number | string): number => {
    if (typeof numberOrTextA === 'string' && typeof numberOrTextB === 'string') {
        return numberOrTextA > numberOrTextB ? 1 : numberOrTextA < numberOrTextB ? -1 : 0
    }
    if (typeof numberOrTextA === 'number' && typeof numberOrTextB === 'string') {
        return -1 // numbers are considered less than strings
    }
    if (typeof numberOrTextA === 'string' && typeof numberOrTextB === 'number') {
        return 1 // strings are considered greater than numbers
    }

    return numberOrTextA > numberOrTextB ? 1 : numberOrTextA < numberOrTextB ? -1 : 0
}

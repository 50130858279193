import { createAction } from '@reduxjs/toolkit'
import { all, call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects'
import { deleteRegion } from '../../../api/takeoff-api'
import { Region } from '../../../models/region'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Color, RegionTool, Select, Workspace } from '../../lib/toolBoxes/2D'
import { removeRegionById, selectAllRegions, setActiveRegion, updateRegions } from '../../slices/region'
import { REGION_COLOR } from './../../../shared/constants/colors'
import { createRegionGroupWithFunctionality } from './reviewPlan'

export const deleteRegionAction = createAction<{ itemId: number }>('deleteRegion')

export function* handleRegionDelete({ payload }: ReturnType<typeof deleteRegionAction>) {
    const paperManager: PaperManager | null = yield call(managers.get2DManager)

    if (!paperManager) return

    const [workspaceTool, regionTool, selectTool, colorTool]: [Workspace, RegionTool, Select, Color] = yield call(
        paperManager.getTools,
        [Workspace.NAME, RegionTool.NAME, Select.NAME, Color.NAME]
    )

    const regionItem: paper.Path | null = yield call(workspaceTool.getItemWithPaperId, payload.itemId)

    if (!regionItem) return

    const regionLabel: paper.PointText | null = yield call(workspaceTool.getItemWithPaperId, regionItem.data.labelId)

    if (!regionLabel) return

    const regions: Region[] = yield select(selectAllRegions)

    // Deleting a region without any regions in store is
    // not allowed
    if (regions.length === 0) return

    const regionToDelete: Region | undefined = yield regions.find((region) => region.id === regionItem.data.region_id)

    // cant delete the item if
    // it is not associated with a region
    if (!regionToDelete) return

    yield all([
        put(setActiveRegion(null)),
        put(removeRegionById(regionItem.data.region_id)),
        call(regionItem.remove.bind(regionItem)),
        call(regionLabel.remove.bind(regionLabel)),
        call(selectTool.exitSelectMode),
    ])

    try {
        const response: string = yield call(deleteRegion, regionItem.data.region_id)
        if (response !== 'True') {
            throw new Error('Deletion was unsuccessful')
        }
    } catch (e) {
        yield call(console.error, `Error on API deletion: ${e}`)
        yield all([
            put(updateRegions(regions)),
            call(createRegionGroupWithFunctionality, regionToDelete, colorTool.createColor(REGION_COLOR), regionTool),
        ])
    }
}

export function* watchForDeleteRegion(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(deleteRegionAction.type, handleRegionDelete)
}

import { FormValidation, UiSchema } from '@rjsf/core'
import { FormProperties } from '../imup/types'
import { FORM_ERROR_MESSAGES } from '../shared/constants/error-messages'
import { fieldsToNull } from '../utils/formSchema/formSchema-helper-functions'

const cedarMaterialName = 'cedar'
const stoneMaterialName = 'stone'
const smoothPanelMaterialName = 'smooth panel'
const sqFtMaterialName = 'sq ft'
const aluminumMaterialName = 'aluminum'
const stuccoMaterialName = 'stucco'
const fiberCementMaterialName = 'fiber cement'
const lpMaterialName = 'lp'
const shapeShake = 'shake'
const shapeBB = 'b&b'

export const uiSchema: UiSchema = {
    'ui:order': ['label', 'material', 'shape', 'texture', 'board_length', 'panels', 'panels_size', 'baton_spacing'],
    shape: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    texture: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    material: {
        'ui:placeholder': 'Select Material',
    },
    baton_spacing: {
        'ui:placeholder': 'Spacing in Inches',
    },
    board_width: {
        'ui:placeholder': 'Select a Lap Size',
    },
    panels: {
        'ui:placeholder': 'Select B&B Panel',
    },
    panels_size: {
        'ui:placeholder': 'Select Shake Panel',
    },
    dimension: {
        'ui:placeholder': 'Select Straight Edge Dimention',
    },
}

const onValidate = (formData: any, errors: FormValidation) => {
    if (formData.baton_spacing && ['fiber cement b&b', 'lp b&b'].includes(formData.selection)) {
        const batonSpacingValue = parseInt(formData.baton_spacing, 10)
        if (batonSpacingValue < 1 || batonSpacingValue > 99) {
            errors.baton_spacing.addError(FORM_ERROR_MESSAGES.BATON_SPACING)
        }
    }
}

const onSubmit = (settings, formData) => {
    const newSettings = fieldsToNull({ ...settings, ...formData }, [
        'shape',
        'panels',
        'panels_size',
        'baton_spacing',
        'edges',
        'dimensions',
        'texture',
    ])

    const isAluminum = formData.material?.toLowerCase() === aluminumMaterialName
    const isStone = formData.material?.toLowerCase() === stoneMaterialName
    const isSqFt = formData.material?.toLowerCase() === sqFtMaterialName
    const isSmoothPanel = formData.material?.toLowerCase() === smoothPanelMaterialName
    const isStucco = formData.material?.toLowerCase() === stuccoMaterialName
    const isFiberCement = formData.material?.toLowerCase() === fiberCementMaterialName
    const isLP = formData.material?.toLowerCase() === lpMaterialName
    const isShake = formData.shape?.toLowerCase() === shapeShake
    const isBB = formData.shape?.toLowerCase() === shapeBB
    const isCedar = formData.material?.toLowerCase() === cedarMaterialName

    if (isAluminum || isStone || isSqFt || isStucco) {
        newSettings.shape = null
        newSettings.panels = null
        newSettings.panels_size = null
        newSettings.baton_spacing = null
    }

    if (isSmoothPanel) {
        newSettings.shape = null
        newSettings.panels = null
        newSettings.baton_spacing = null
    }

    if (!isFiberCement || (isFiberCement && !isShake)) {
        newSettings.edges = null
        newSettings.dimension = null
    }

    if (!isFiberCement && !isLP) {
        newSettings.texture = null
    }

    if (isCedar) {
        if (isShake || isBB) {
            newSettings.board_width = null
        }
        if (!isBB) {
            newSettings.spacing = null
        }
    }

    return {
        ...newSettings,
        label: !formData.label && settings.label ? null : formData.label,
    }
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form

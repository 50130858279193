import { createSelector } from '@reduxjs/toolkit'
import isNull from 'lodash/isNull'
import { call, delay, put, select } from 'redux-saga/effects'
import { updatedDrawableMeasurements } from '../../../actions/drawable'
import { UpdateOpeningGroupApiResponse } from '../../../api/api-helper'
import { updateOpeningLocationCoordinates } from '../../../api/projects-api'
import { updateJoistLines } from '../../../api/takeoff-api'
import { Coordinate } from '../../../models/activeDrawable'
import { ActiveFloor } from '../../../models/activeFloor'
import { getActiveFloor } from '../../../reducers/drawable'
import { DRAWING_TYPES, NEW_IMUP_JOIST_TYPES_GROUP } from '../../../shared/constants/drawable-types'
import { DEFAULT_SCALE_FACTOR } from '../../../shared/constants/scales'
import { ENV_VAR_NAMES, getEnvVar } from '../../../shared/services/env-services'
import { RootState } from '../../../stores'
import {
    DPI_72,
    applyScaleFactorToPathArea,
    applyScaleFactorToPathLength,
    convertScaleFactorLabelEnumToDecimal,
} from '../../../utils/calculations/scaleConversion/scaleConversion'
import { defineLocationRegion } from '../../../utils/coordinates/defineLocationRegion'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Color, Count, Label, PathTool, PolygonTool, RadiusLine, Select, Workspace } from '../../lib/toolBoxes/2D/tools'
import {
    getCurrentDrawableLocations,
    resetUpdatedCoordinates,
    updateDrawableLocationCoordinates,
    updateSelectedCoordinates,
} from '../../slices/2D'
import { updateToolbarMessage } from '../../slices/common'
import { ActiveGeometryGroup, selectActiveGeometryGroup } from '../../slices/geometry'
import { selectProjectId } from '../../slices/project'
import {
    IMUP2DCoordinatesToUpdate,
    IMUP2DDrawableCutout,
    IMUP2DDrawableLocation,
    MeasurementsToUpdate,
    SAVING_CHANGES_ERROR_MESSAGE,
    SAVING_MESSAGE,
    TOOLBAR_MESSAGE_TIMER,
} from '../../types'
import { calculateAreaMeasurementsForMaterial, calculateLFMeasurementOfMaterial } from './createDrawableLocation'
import drawShapeByType from './drawShapeByType'
import { RedrawShapeStoreState, RedrawShapeTools, selectRedrawState } from './handleUpdateOpeningGroupsSuccess'
import { redrawFlagsOnUpdate } from './redrawFlagsOnUpdate'

export interface UpdateCoordinatesState {
    activeDrawableId: number | null
    activeDrawableGroup: ActiveGeometryGroup | null
    activeOpeningLocationId: number | null
    coordinates: IMUP2DCoordinatesToUpdate | null
    drawableLocations: IMUP2DDrawableLocation[]
    selectedItemId: number | null
}

export interface UpdateDrawableMeasurements {
    activeDrawableId: number
    groupMeasurements: MeasurementsToUpdate
    openingMeasurements: MeasurementsToUpdate
    coordinates: Coordinate[]
    cutouts: IMUP2DDrawableCutout[] | undefined
    updatedLocationID: number
}

export const selectUpdateCoordinatesState = createSelector(
    (state: RootState): UpdateCoordinatesState => ({
        drawableLocations: getCurrentDrawableLocations(state.IMUP),
        activeDrawableGroup: selectActiveGeometryGroup(state),
        activeDrawableId: state.IMUP['2D'].activeDrawableId,
        activeOpeningLocationId: state.IMUP['2D'].activeOpeningLocationId,
        coordinates: state.IMUP['2D'].coordinatesToUpdate,
        selectedItemId: state.IMUP['2D'].selectedItem,
    }),
    (state: UpdateCoordinatesState): UpdateCoordinatesState => state
)

export const prepareMeasurements = (
    measurements: MeasurementsToUpdate,
    materialGroup: ActiveGeometryGroup | null
): MeasurementsToUpdate => {
    const rawMeasurements = { ...measurements }
    const typeAndSettings = materialGroup ? { type: materialGroup.type, settings: materialGroup.settings } : {}

    if (!!rawMeasurements.area && !isNull(rawMeasurements.area)) {
        const newArea = calculateAreaMeasurementsForMaterial(rawMeasurements.area, typeAndSettings)

        return {
            ...rawMeasurements,
            area: rawMeasurements.area,
            quantity: newArea,
        }
    } else if (!!rawMeasurements.linear_total && !isNull(rawMeasurements.linear_total)) {
        const newLF = calculateLFMeasurementOfMaterial(rawMeasurements.linear_total, typeAndSettings)

        return {
            ...rawMeasurements,
            linear_total: rawMeasurements.linear_total,
            quantity: newLF,
        }
    }

    return rawMeasurements
}

export function* clearToolbarMessageAndClearCoordinateState() {
    // Clear the state
    yield put(resetUpdatedCoordinates())
    yield delay(TOOLBAR_MESSAGE_TIMER)
    yield put(updateToolbarMessage(null))
}

export function* updateActiveDrawableCoordinates(action: ReturnType<typeof updateSelectedCoordinates>) {
    // get coordinates state from redux store
    const {
        activeDrawableId,
        activeOpeningLocationId,
        coordinates,
        drawableLocations,
        activeDrawableGroup,
        selectedItemId,
    }: UpdateCoordinatesState = yield select(selectUpdateCoordinatesState)

    yield put(updateToolbarMessage(SAVING_MESSAGE))

    const activeFloor: ActiveFloor = yield select(getActiveFloor)

    try {
        // Call API to update opening
        if (((activeDrawableId && activeOpeningLocationId) || selectedItemId) && coordinates && activeDrawableGroup) {
            const enableFlags = getEnvVar(ENV_VAR_NAMES.ENABLE_FLAGS).toString() === 'true'

            const paperManager = yield call(managers.get2DManager)
            const workspaceTool: Workspace = yield call(paperManager.getTool, Workspace.NAME)

            if (paperManager && NEW_IMUP_JOIST_TYPES_GROUP.includes(activeDrawableGroup.type)) {
                const visibilityTest = (item: paper.Item): boolean =>
                    activeOpeningLocationId === item.data.opening_location_id

                yield call(workspaceTool.hideDrawablesWithCondition, visibilityTest)
            }

            let item: paper.Item | null = null
            if (activeOpeningLocationId) {
                item = yield call(workspaceTool.getItemWithOpeningLocationId, activeOpeningLocationId)
            }
            if (!item && activeDrawableId) {
                item = yield call(workspaceTool.getItemWithDrawableId, activeDrawableId)
            }
            if (!item && selectedItemId) {
                item = yield call(workspaceTool.getItemWithPaperId, selectedItemId)
            }
            if (!item) {
                throw new Error('Item being edited was not found')
            }

            const path = item as paper.Path
            const region_id = defineLocationRegion(path, activeFloor.document_chunk.regions, workspaceTool)
            const project_id = yield select(selectProjectId)

            // If region_id has changed, measurements have to be updated considering new scale
            const currentLocation = drawableLocations.find((l) => l.opening_location_id === activeOpeningLocationId)
            let newQty = 0

            if (currentLocation && currentLocation.region_id !== region_id) {
                const scaleFactor = region_id
                    ? activeFloor.document_chunk.regions.find((r) => r.id === region_id)?.scale
                    : activeFloor.scale_factor

                const scale = convertScaleFactorLabelEnumToDecimal(scaleFactor ?? DEFAULT_SCALE_FACTOR)

                // Update item scale factor
                item.data.scale = scaleFactor

                switch (item.data.shapeType) {
                    case DRAWING_TYPES.AREA:
                        newQty = applyScaleFactorToPathArea({
                            coordinates: coordinates.coordinates,
                            pxValue: path.area,
                            scaleFactor: Number(scale),
                            dpi: activeFloor.document_chunk.dpi ?? DPI_72,
                            xCalibrationFactor: activeFloor.document_chunk.calibration_factor_x,
                            yCalibrationFactor: activeFloor.document_chunk.calibration_factor_y,
                            pdfScale: activeFloor.document_chunk.pdf_scale ?? 1,
                        })
                        break
                    case DRAWING_TYPES.SECTION:
                    case DRAWING_TYPES.RADIUS:
                        newQty = applyScaleFactorToPathLength({
                            coordinates: coordinates.coordinates,
                            pxValue: path.length,
                            scaleFactor: Number(scale),
                            dpi: activeFloor.document_chunk.dpi ?? DPI_72,
                            xCalibrationFactor: activeFloor.document_chunk.calibration_factor_x,
                            yCalibrationFactor: activeFloor.document_chunk.calibration_factor_y,
                            pdfScale: activeFloor.document_chunk.pdf_scale ?? 1,
                        })
                        break
                    default:
                        break
                }
            }

            const measurements = {
                ...coordinates.measurements,
                linear_total: newQty ? Math.abs(newQty) : coordinates.measurements.linear_total,
                area: newQty ? Math.abs(newQty) : coordinates.measurements.area,
            }

            const openingId = activeDrawableId || item.data.drawable_id

            if (enableFlags) {
                yield call(redrawFlagsOnUpdate, openingId)
            }

            const response: UpdateOpeningGroupApiResponse = yield call(
                updateOpeningLocationCoordinates,
                project_id,
                openingId,
                activeOpeningLocationId || item.data.opening_location_id,
                {
                    ...coordinates,
                    measurements: prepareMeasurements(measurements, activeDrawableGroup),
                },
                region_id
            )

            // After API success, update drawableLocations 2D slice and
            // update opening & opening group settings (quantity/linear total)
            const newDrawable = response.newGroup.openings.find((drawable) => drawable.id === openingId)

            const newQuantity = newDrawable?.settings.quantity
            const newLinearTotal = newDrawable?.settings.linear_total
            const newCutouts = coordinates.cutouts

            yield put(
                updateDrawableLocationCoordinates({
                    opening_location_id: activeOpeningLocationId || item.data.opening_location_id,
                    coordinates: coordinates.coordinates,
                    linear_total: newLinearTotal ?? null,
                    quantity: newQuantity ?? null,
                    cutouts: newCutouts,
                    region_id: region_id,
                })
            )

            // update the quantity & linear total inside the activeDrawableGroup and drawable groups
            const groupMeasurements = {
                quantity: response.newGroup.settings.quantity,
                linear_total: response.newGroup.settings.linear_total,
            }

            const opening = response.newGroup.openings.find(
                (o) => o.id === activeDrawableId || o.id === item?.data.drawable_id
            )
            const openingMeasurements = {
                area: opening?.settings.area,
                quantity: opening?.settings.quantity ?? null,
                linear_total: opening?.settings.linear_total ?? null,
            }

            yield put(
                updatedDrawableMeasurements({
                    activeDrawableId: activeDrawableId || item.data.drawable_id,
                    groupMeasurements,
                    openingMeasurements,
                    cutouts: newCutouts,
                    coordinates: coordinates.coordinates,
                    updatedLocationID: activeOpeningLocationId || item.data.opening_location_id,
                })
            )

            const shoudUpdateJoistLines = Boolean(
                NEW_IMUP_JOIST_TYPES_GROUP.includes(activeDrawableGroup.type) &&
                    activeDrawableGroup.openings.find((o) => o.opening_locations.length > 1) &&
                    activeDrawableGroup.settings.direction &&
                    activeDrawableGroup.settings.oc_spacing
            )

            if (shoudUpdateJoistLines) {
                yield put(yield call(updateJoistLines, activeDrawableGroup.project_id, activeDrawableGroup.id))
            }

            yield delay(TOOLBAR_MESSAGE_TIMER)
            yield put(resetUpdatedCoordinates())
            yield put(updateToolbarMessage(null))
        } else {
            throw new Error('Item being edited was not found')
        }
    } catch (error) {
        console.error(error)
        try {
            yield put(updateToolbarMessage(SAVING_CHANGES_ERROR_MESSAGE))

            /* Redraw item to the original state */
            // 1. Remove drawable from workspace
            const manager: PaperManager = yield call(managers.get2DManager)

            const [
                colorTool,
                pathTool,
                countTool,
                polygonTool,
                radiusLineTool,
                workspaceTool,
                labelTool,
                selectTool,
            ]: RedrawShapeTools = yield call(manager.getTools, [
                Color.NAME,
                PathTool.NAME,
                Count.NAME,
                PolygonTool.NAME,
                RadiusLine.NAME,
                Workspace.NAME,
                Label.NAME,
                Select.NAME,
            ])

            let item: paper.Item | null = null
            if (activeOpeningLocationId) {
                item = yield call(workspaceTool.getItemWithOpeningLocationId, activeOpeningLocationId)
            }
            if (!item && activeDrawableId) {
                item = yield call(workspaceTool.getItemWithDrawableId, activeDrawableId)
            }
            if (!item && selectedItemId) {
                item = yield call(workspaceTool.getItemWithPaperId, selectedItemId)
            }

            // 2. Remove vertices & disable select mode
            yield call(selectTool.exitSelectMode)

            if (item) {
                yield call([item, 'remove'])
            }

            // 3. Redraw drawable in the original state
            const { areaOpacity, lineOpacity }: RedrawShapeStoreState = yield select(selectRedrawState)

            const location: IMUP2DDrawableLocation | undefined = drawableLocations.find(
                (l) => l.drawable_id === item?.data.drawable_id
            )

            if (!location) {
                throw Error('Location not found')
            }

            yield call(
                drawShapeByType,
                location,
                colorTool,
                countTool,
                pathTool,
                polygonTool,
                radiusLineTool,
                workspaceTool,
                labelTool,
                areaOpacity,
                lineOpacity,
                activeFloor
            )

            yield clearToolbarMessageAndClearCoordinateState()
        } catch (e) {
            console.error(e)
            yield clearToolbarMessageAndClearCoordinateState()
        }
    }
}

import { UiSchema } from '@rjsf/core'
import { calculateLFMeasurementOfMaterial } from '../imup/sagas/2D/createDrawableLocation'
import { FormProperties } from '../imup/types'
import { ActiveDrawable } from '../models/activeDrawable'
import { GeneralDrawableSettings } from '../models/activeDrawableSettings'
import { DRAWABLE_TYPES } from '../shared/constants/drawable-types'

export const uiSchema: UiSchema = {
    'ui:order': ['board_size', 'plies', 'plies_other', 'pitch_1', 'pitch_other_1', 'pitch_2', 'pitch_other_2'],
    board_size: {
        'ui:title': 'Board Size',
        'ui:placeholder': 'Select Board Size',
    },
    plies: {
        'ui:title': 'Plies',
        'ui:placeholder': 'Select Plies',
    },
    plies_other: {
        'ui:title': 'Plies Other',
    },
    pitch_1: {
        'ui:title': 'Pitch 1',
        'ui:placeholder': 'Select Pitch 1',
    },
    pitch_other_1: {
        'ui:title': 'Pitch 1 Other',
        'ui:placeholder': 'Enter Pitch 1 Other',
    },
    pitch_2: {
        'ui:title': 'Pitch 2',
        'ui:placeholder': 'Select Pitch 2',
    },
    pitch_other_2: {
        'ui:title': 'Pitch 2 Other',
        'ui:placeholder': 'Enter Pitch 2 Other',
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    if (formData.plies === 'other') {
        newSettings.plies = formData.plies_other
        newSettings.plies_other = null
    }

    if (formData.plies !== 'other') {
        newSettings.plies_other = null
    }

    if (formData.pitch_1 === 'other') {
        newSettings.pitch_1 = formData.pitch_other_1
    }

    newSettings.pitch_other_1 = null

    if (formData.pitch_2 === 'other') {
        newSettings.pitch_2 = formData.pitch_other_2
    }

    newSettings.pitch_other_2 = null

    return newSettings
}

/**
 * Augment the drawables of the from based on the new settings
 * @param drawables the drawables in the group
 * @param newSettings the new group settings
 * @param scaleFactor the scaleFactor of the page
 * @returns
 */
function prepareDrawables(input: {
    drawables: ActiveDrawable[]
    newSettings: GeneralDrawableSettings
    scaleFactor: number
}): ActiveDrawable[] {
    const { drawables, newSettings } = input
    return drawables.map((drawable) => {
        drawable.settings.quantity = calculateLFMeasurementOfMaterial(drawable.settings.linear_total, {
            type: DRAWABLE_TYPES.VALLEY_PLATE,
            settings: newSettings,
        })
        return drawable
    })
}

const form: FormProperties = { uiSchema, onValidate, onSubmit, prepareDrawables }

export default form

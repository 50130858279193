import { FormValidation, UiSchema } from '@rjsf/core'
import { FormProperties } from '../imup/types'
import { FORM_ERROR_MESSAGES } from '../shared/constants/error-messages'
import { validateGivenFieldsAreFractions } from './validation/fractionValidation'

const slidingTypeValue = 'sliding' // confirmed casing in seed data
const foldingTypeValue = 'folding' // confirmed casing in seed data
const swingingTypeValue = 'swinging' // confirmed casing in seed data

const panelsOperationDirectionSum = (value): number => {
    return value
        ? Object.values(value).reduce(
              (numberAccumulator: number, currentPanelInfo: unknown) =>
                  Number(currentPanelInfo) ? numberAccumulator + Number(currentPanelInfo) : numberAccumulator,
              0
          )
        : 0
}

const uiSchema: UiSchema = {
    schedule_code: {
        'ui:title': 'Schedule Code',
    },
    number_wide: {
        'ui:title': 'Number of Panels',
    },
    handling: {
        'ui:title': 'Handling',
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    swing: {
        'ui:title': 'Swing',
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    configuration: {
        'ui:title': 'Configuration',
        'ui:widget': 'textarea',
        'ui:options': {
            rows: 3,
        },
    },
    pocket: {
        'ui:title': 'Pocket',
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    transom: {
        'ui:title': 'Has Mulled Transom?',
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    transom_comment: {
        'ui:title': 'Transom Height',
        'ui:placeholder': 'Transom Height',
    },
    grilles: {
        'ui:title': 'Grilles',
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    manual_width: { 'ui:title': 'Width' },
    manual_height: { 'ui:title': 'Height' },
    wall_depth_other: { 'ui:title': 'Other Wall Depth' },
    sidelight_width: {
        'ui:title': 'Sidelight Width',
        'ui:placeholder': 'Door sidelight',
    },
    discrepancy: {
        'ui:title': 'Discrepancy Exists?',
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    discrepancy_comment: {
        'ui:title': 'Discrepancy Comment',
    },
    remarks: {
        'ui:title': 'Remarks',
        'ui:widget': 'textarea',
        'ui:options': {
            rows: 3,
        },
    },
    panelsOperationDirection: {
        'ui:field': 'PanelOperationField',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
    'ui:order': [
        'schedule_code',
        'type',
        'number_wide',
        'configuration',
        'pocket',
        'panelsOperationDirection',
        'handling',
        'swing',
        'transom',
        'transom_comment',
        'grilles',
        'manual_width',
        'manual_height',
        'wall_depth',
        'wall_depth_other',
        'sidelight',
        'sidelight_width',
        'discrepancy',
        'discrepancy_comment',
        'remarks',
    ],
}

const onValidate = (formData: any, errors: FormValidation) => {
    if (formData.number_wide <= 0) {
        errors['number_wide'].addError(FORM_ERROR_MESSAGES.GREATER_ZERO)
    }

    if (
        [slidingTypeValue, foldingTypeValue].includes(formData.type) &&
        formData.panelsOperationDirection &&
        panelsOperationDirectionSum(formData.panelsOperationDirection)
    ) {
        if (Number(panelsOperationDirectionSum(formData.panelsOperationDirection)) !== Number(formData.number_wide)) {
            errors['number_wide'].addError(FORM_ERROR_MESSAGES.NUMBER_OF_PANELS)
        }
    }

    validateGivenFieldsAreFractions(
        ['transom_comment', 'wall_depth_other', 'sidelight_width', 'manual_width', 'manual_height'],
        formData,
        errors
    )
}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    if (newSettings.discrepancy === 'false') {
        newSettings.discrepancy_comment = null
    }

    if (newSettings.wall_depth !== 'other') {
        newSettings.wall_depth_other = null
    }

    if (newSettings.transom === 'false') {
        newSettings.transom_comment = null
    }

    if (newSettings.type !== swingingTypeValue) {
        newSettings.handling = null
        newSettings.swing = null
    }

    if (newSettings.type !== slidingTypeValue) {
        newSettings.pocket = null
    }

    if (newSettings.type === swingingTypeValue) {
        newSettings.panelsOperationDirection = null
    }

    newSettings.isWidthManual = true
    newSettings.isHeightManual = true

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form

import isEqual from 'lodash/isEqual'
import { select, SelectEffect } from 'redux-saga/effects'
import { ActiveFloor } from '../../../models/activeFloor'
import { getActiveFloor } from '../../../reducers/drawable'
import { GeometryGroup } from '../../slices/geometry'
import { selectDrawableGroups } from '../2D/handleToggleDrawableGroups'

export function* getHiddenAndVisibleGroups(): Generator<
    SelectEffect | [Array<number>, Array<number>],
    { hiddenDrawableGroupIds: Array<number>; visibleDrawableGroupIds: Array<number> },
    Array<GeometryGroup> & ActiveFloor & [Array<number>, Array<number>]
> {
    const allDrawableGroups: Array<GeometryGroup> = yield select(selectDrawableGroups)
    const { document_chunk }: ActiveFloor = yield select(getActiveFloor)

    const [hiddenDrawableGroupIds, visibleDrawableGroupIds]: [Array<number>, Array<number>] =
        yield allDrawableGroups.reduce(
            (
                [hiddenDrawableGroupIds, visibleDrawableGroupIds]: [Array<number>, Array<number>],
                drawableGroup: GeometryGroup
            ): [Array<number>, Array<number>] =>
                isEqual(drawableGroup.isActive[document_chunk.id], false)
                    ? [[...hiddenDrawableGroupIds, drawableGroup.id], visibleDrawableGroupIds]
                    : [hiddenDrawableGroupIds, [...visibleDrawableGroupIds, drawableGroup.id]],
            [[], []]
        )

    return { hiddenDrawableGroupIds, visibleDrawableGroupIds }
}

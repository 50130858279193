import { FormValidation, UiSchema } from '@rjsf/core'
import { FormProperties } from '../imup/types'

const otherValue = 'other'
const holdDownValue = 'HOLDOWN'
const threadedRodValue = 'THREADED ROD'
const anchorValue = 'ANCHOR'

export const uiSchema: UiSchema = {
    location: {
        'ui:placeholder': 'Select Location',
    },
    location_other: {
        'ui:placeholder': 'Location Other',
    },
    height: {
        'ui:placeholder': 'Select Height',
    },
    height_other: {
        'ui:placeholder': 'Select Height Other',
    },
    diameter: {
        'ui:placeholder': 'Select Diameter',
    },
    diameter_other: {
        'ui:placeholder': 'Select Diameter Other',
    },
    multiplier: {
        'ui:placeholder': 'Multiplier',
    },
    spacing_in_inches: {
        'ui:placeholder': 'Spacing in inches',
    },
    screw_called_out: {
        'ui:placeholder': 'Screw called out',
    },
    screw_qty_per_holdown: {
        'ui:placeholder': 'Screw qty per holdown',
    },
    type: {
        'ui:placeholder': 'Select Hardware Type',
    },
    as_called_out: {
        'ui:placeholder': 'Select Generic List',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
    only_include_washer: {
        'ui:title': 'Only Include Washer?',
        'ui:widget': 'radio',
        'ui:options': {
            inline: true,
        },
    },
    'ui:order': [
        'type',
        'height',
        'height_other',
        'diameter',
        'diameter_other',
        'location',
        'location_other',
        'as_called_out',
        'called_out_other',
        'multiplier',
        'spacing_in_inches',
        'washer_part_as_called_out',
        'only_include_washer',
        'screw_called_out',
        'screw_qty_per_holdown',
    ],
}

const onValidate = (formData: any, errors: FormValidation) => {}

const onSubmit = (settings, formData) => {
    const newSettings = {
        ...settings,
        ...formData,
    }

    if (newSettings.location !== otherValue) {
        newSettings.location_other = null
    }

    if (newSettings.type !== holdDownValue) {
        newSettings.screw_called_out = null
        newSettings.screw_qty_per_holdown = null
    }

    if (newSettings.as_called_out !== otherValue) {
        newSettings.called_out_other = null
    }

    if (newSettings.type !== threadedRodValue) {
        newSettings.height = null
        newSettings.diameter = null
    }

    if (newSettings.height !== otherValue) {
        newSettings.height_other = null
    }

    if (newSettings.diameter !== otherValue) {
        newSettings.diameter_other = null
    }

    if (newSettings.type !== anchorValue) {
        newSettings.washer_part_as_called_out = null
        newSettings.only_include_washer = null
    }

    if (newSettings.type === threadedRodValue) {
        newSettings.as_called_out = null
    }

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form

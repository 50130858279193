import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { localStorageItem } from '../../models/localStorageItem'
import { IToolObject } from '../../models/tool'
import { RootState } from '../../stores'
import { HIGHLIGHT_COLOR_OPTIONS, IMUP2DCoordinatesToUpdate, SmoothingValues } from '../types'

export type ZoomToolState = {
    programmaticStep: number
    scrollStep: number
}

export type ToolsState = {
    activeTool: string
    color: string
    rasterSmoothing: SmoothingValues
    dashArray: [number, number] | Array<never>
    strokeWidth: number
    areaStrokeWidth: number
    pointRadius: number
    areaOpacityValue: number
    pointOpacityValue: number
    lineOpacityValue: number
    highlightOpacityValue: number
    measureStrokeColor: string
    measureStrokeWidth: number
    measurement: number | null
    crosshairMarkerSize: number
    deletionModalVisible: boolean
    highlightColor: string
    calibrationLineLength: number | null
    calibrationRatio: number
    calibrationLineCoords: number[][] | null
    snappingEnabled: boolean
    isSnappingTemporaryDisabled: boolean
    imageRotateDegree: number
    cutoutItem: number | null
    disableSelect: boolean
    isRegionLinesVisible: boolean
    isMaterialFlagsVisible: boolean

    // TODO: move it to separate slice
    toolObjects: IToolObject[]
    activeToolObject: IToolObject | null
    activeToolObjectId: number | null
    toolObjectCoordinatesToUpdate: IMUP2DCoordinatesToUpdate | null

    selectedToolObjectPathIds: number[]
} & ZoomToolState

export const initialToolsState: ToolsState = {
    activeTool: '',
    color: 'blue',
    rasterSmoothing: SmoothingValues.HIGH,
    dashArray: [6, 4], // default to the design specification for dashed lines => [length of dash, length of gap]
    strokeWidth: 20,
    areaStrokeWidth: 0, // created rectangles by default have 0 strokewidth and are filled with color value
    pointRadius: 30,
    areaOpacityValue: 0.4,
    pointOpacityValue: 0.8,
    lineOpacityValue: 0.8,
    programmaticStep: 0.1,
    scrollStep: 0.05,
    highlightOpacityValue: 0.25,
    measureStrokeColor: 'red',
    measureStrokeWidth: 2,
    crosshairMarkerSize: 10,
    measurement: null,
    deletionModalVisible: false,
    highlightColor: HIGHLIGHT_COLOR_OPTIONS['red'],
    calibrationLineLength: null,
    calibrationRatio: 1,
    calibrationLineCoords: null,
    snappingEnabled: false,
    isSnappingTemporaryDisabled: false,
    imageRotateDegree: 0,
    cutoutItem: null,
    disableSelect: false,
    isRegionLinesVisible: JSON.parse(localStorage.getItem(localStorageItem.REGION_LINES_VISIBILITY) ?? 'true') === true,
    isMaterialFlagsVisible:
        JSON.parse(localStorage.getItem(localStorageItem.MATERIAL_FLAGS_VISIBILITY) ?? 'true') === true,
    toolObjects: [],
    activeToolObject: null,
    activeToolObjectId: null,
    selectedToolObjectPathIds: [],
    toolObjectCoordinatesToUpdate: null,
}

const setActiveTool = (state: ToolsState, action: PayloadAction<string>): void => {
    state.activeTool = action.payload
}

const setActiveColor = (state: ToolsState, action: PayloadAction<ToolsState['color']>): void => {
    state.color = action.payload
}

const setStrokeWidth = (state: ToolsState, action: PayloadAction<number>): void => {
    state.strokeWidth = action.payload
}
const setAreaStrokeWidth = (state: ToolsState, action: PayloadAction<ToolsState['areaStrokeWidth']>): void => {
    state.areaStrokeWidth = action.payload
}

const setAreaOpacityValue = (state: ToolsState, action: PayloadAction<ToolsState['areaOpacityValue']>): void => {
    state.areaOpacityValue = action.payload
}

const setPointRadius = (state: ToolsState, action: PayloadAction<number>): void => {
    state.pointRadius = action.payload
}

const setMeasurement = (state: ToolsState, action: PayloadAction<number | null>): void => {
    state.measurement = action.payload
}

const setDeletionModalVisibleHandler = (state: ToolsState, action: PayloadAction<boolean>): void => {
    state.deletionModalVisible = action.payload
}

const setDashArray = (state: ToolsState, action: PayloadAction<ToolsState['dashArray']>): void => {
    state.dashArray = action.payload
}

const setHighlightColor = (state: ToolsState, action: PayloadAction<ToolsState['highlightColor']>): void => {
    state.highlightColor = action.payload
}

const handleSetCalibrationRatio = (state: ToolsState, action: PayloadAction<ToolsState['calibrationRatio']>): void => {
    state.calibrationRatio = action.payload
}

const setSnappingEnabled = (state: ToolsState, action: PayloadAction<ToolsState['snappingEnabled']>): void => {
    state.snappingEnabled = action.payload
}

const handleSetIsSnappingTemporaryDisabled = (
    state: ToolsState,
    action: PayloadAction<ToolsState['isSnappingTemporaryDisabled']>
): void => {
    state.isSnappingTemporaryDisabled = action.payload
}

const setImageRotateDegreeValue = (state: ToolsState, action: PayloadAction<ToolsState['imageRotateDegree']>): void => {
    state.imageRotateDegree = action.payload
}

const setCutoutItemValue = (state: ToolsState, action: PayloadAction<ToolsState['cutoutItem']>) => {
    state.cutoutItem = action.payload
}

const handleSetRegionLinesVisibility = (
    state: ToolsState,
    action: PayloadAction<ToolsState['isRegionLinesVisible']>
): void => {
    localStorage.setItem(localStorageItem.REGION_LINES_VISIBILITY, action.payload.toString())
    state.isRegionLinesVisible = action.payload
}

const handleSetFlagsVisibility = (
    state: ToolsState,
    action: PayloadAction<ToolsState['isMaterialFlagsVisible']>
): void => {
    localStorage.setItem(localStorageItem.MATERIAL_FLAGS_VISIBILITY, action.payload.toString())
    state.isMaterialFlagsVisible = action.payload
}

const setDisableSelectToolValue = (state: ToolsState, action: PayloadAction<boolean>) => {
    state.disableSelect = action.payload
}

const handleSetCalibrationLineLength = (
    state: ToolsState,
    action: PayloadAction<ToolsState['calibrationLineLength']>
): void => {
    state.calibrationLineLength = action.payload
}

const handleSetCalibrationLineCoords = (
    state: ToolsState,
    action: PayloadAction<ToolsState['calibrationLineCoords']>
): void => {
    state.calibrationLineCoords = action.payload
}

// TODO: probably it's better separate tools to own slice
const handleSetToolObjects = (state: ToolsState, action: PayloadAction<ToolsState['toolObjects']>): void => {
    state.toolObjects = action.payload
}

const handleAddNewToolObjects = (state: ToolsState, action: PayloadAction<ToolsState['toolObjects']>): void => {
    state.toolObjects = [...state.toolObjects, ...action.payload]
}

const handleRemoveToolObjectsByToolId = (state: ToolsState, action: PayloadAction<number[]>) => {
    state.toolObjects = state.toolObjects.filter((toolObject) => {
        return !action.payload.includes(toolObject.id)
    })
}

const handleUpdateToolObjectByToolId = (state: ToolsState, action: PayloadAction<IToolObject>) => {
    state.toolObjects = [...state.toolObjects].map((toolObject) => {
        if (toolObject.id === action.payload.id) {
            return action.payload
        }

        return toolObject
    })
}

const handleSetSelectedToolObjectPathIds = (
    state: ToolsState,
    action: PayloadAction<ToolsState['selectedToolObjectPathIds']>
): void => {
    state.selectedToolObjectPathIds = action.payload
}

const handleResetSelectedToolObjectPathIds = (state: ToolsState): void => {
    state.selectedToolObjectPathIds = []
}

const handleSetActiveToolObjectId = (
    state: ToolsState,
    action: PayloadAction<ToolsState['activeToolObjectId']>
): void => {
    state.activeToolObjectId = action.payload

    const selectedToolObject = state.toolObjects.find((toolObject) => toolObject.id === action.payload)

    state.activeToolObject = selectedToolObject || null
}

const handleUpdateToolObjectCoordinates = (
    state: ToolsState,
    action: PayloadAction<ToolsState['toolObjectCoordinatesToUpdate']>
): void => {
    state.toolObjectCoordinatesToUpdate = action.payload
}

const reducers = {
    activateTool: setActiveTool,
    changeColor: setActiveColor,
    changeStrokeWidth: setStrokeWidth,
    changeAreaStrokeWidth: setAreaStrokeWidth,
    changeAreaOpacity: setAreaOpacityValue,
    changePointRadius: setPointRadius,
    updateMeasurement: setMeasurement,
    setDeletionModalVisible: setDeletionModalVisibleHandler,
    changeLineDashes: setDashArray,
    changeHighlightColor: setHighlightColor,
    setCalibrationRatio: handleSetCalibrationRatio,
    setCalibrationLineLength: handleSetCalibrationLineLength,
    setSnappingStatus: setSnappingEnabled,
    setIsSnappingTemporaryDisabled: handleSetIsSnappingTemporaryDisabled,
    setImageRotateDegree: setImageRotateDegreeValue,
    setCutoutItem: setCutoutItemValue,
    setDisableSelectTool: setDisableSelectToolValue,
    setCalibrationLineCoords: handleSetCalibrationLineCoords,
    setRegionLinesVisibility: handleSetRegionLinesVisibility,
    setFlagsVisibility: handleSetFlagsVisibility,
    setToolObjects: handleSetToolObjects,
    addNewToolObjects: handleAddNewToolObjects,
    setSelectedToolObjectPathIds: handleSetSelectedToolObjectPathIds,
    resetSelectedToolObjectPathIds: handleResetSelectedToolObjectPathIds,
    setActiveToolObjectId: handleSetActiveToolObjectId,
    removeToolObjectsByToolId: handleRemoveToolObjectsByToolId,
    updateToolObjectCoordinates: handleUpdateToolObjectCoordinates,
    updateToolObjectByToolId: handleUpdateToolObjectByToolId,
}

const toolsSlice = createSlice({
    name: 'tools',
    initialState: initialToolsState,
    reducers,
})

export const {
    activateTool,
    changeColor,
    changeStrokeWidth,
    changeAreaStrokeWidth,
    changeAreaOpacity,
    changePointRadius,
    updateMeasurement,
    setDeletionModalVisible,
    changeLineDashes,
    changeHighlightColor,
    setCalibrationRatio,
    setCalibrationLineLength,
    setCalibrationLineCoords,
    setSnappingStatus,
    setIsSnappingTemporaryDisabled,
    setImageRotateDegree,
    setCutoutItem,
    setDisableSelectTool,
    setRegionLinesVisibility,
    setFlagsVisibility,
    setToolObjects,
    addNewToolObjects,
    setSelectedToolObjectPathIds,
    resetSelectedToolObjectPathIds,
    setActiveToolObjectId,
    removeToolObjectsByToolId,
    updateToolObjectCoordinates,
    updateToolObjectByToolId,
} = toolsSlice.actions

export default toolsSlice

export const selectHighlightColor = createSelector(
    (state: RootState) => state.IMUP.tools.highlightColor,
    (color) => color
)

export const selectCalibrationRatio = createSelector(
    (state: RootState) => state.IMUP.tools.calibrationRatio,
    (calibrationRatio) => calibrationRatio
)

export const selectCalibrationLineLength = createSelector(
    (state: RootState) => state.IMUP.tools.calibrationLineLength,
    (calibrationLineLength) => calibrationLineLength
)

export const selectCalibrationLineCoords = createSelector(
    (state: RootState) => state.IMUP.tools.calibrationLineCoords,
    (calibrationLineCoords) => calibrationLineCoords
)

export const selectDisableSelectTool = createSelector(
    (state: RootState) => state.IMUP.tools.disableSelect,
    (disableSelect) => disableSelect
)

export const selectRegionLinesVisibility = createSelector(
    (state: RootState) => state.IMUP.tools.isRegionLinesVisible,
    (showRegionLines: boolean) => showRegionLines
)

export const selectAnnotationToolsState = createSelector(
    (state: RootState) => {
        return {
            activeTool: state.IMUP.tools.activeTool,
            highlightColor: state.IMUP.tools.highlightColor,
        }
    },
    (state) => state
)

export const selectSnappingEnabled = createSelector(
    (state: RootState) => state.IMUP.tools.snappingEnabled,
    (snappingEnabled: boolean) => snappingEnabled
)

export const selectIsSnappingTemporaryDisabled = createSelector(
    (state: RootState) => state.IMUP.tools.isSnappingTemporaryDisabled,
    (isSnappingTemporaryDisabled: boolean) => isSnappingTemporaryDisabled
)

export const getActiveTool = createSelector(
    (state: RootState) => state.IMUP.tools.activeTool,
    (activeTool) => activeTool
)

export const selectVisibility = createSelector(
    (state: RootState) => {
        return {
            isRegionLinesVisible: state.IMUP.tools.isRegionLinesVisible,
            isMaterialFlagsVisible: state.IMUP.tools.isMaterialFlagsVisible,
        }
    },
    (state) => state
)

export const selectActiveToolObject = createSelector(
    (state: RootState) => state.IMUP.tools.activeToolObject,
    (activeToolObject) => activeToolObject
)

export const selectSelectedToolObjectPathIdsState = createSelector(
    (state: RootState) => ({
        selectedToolObjectPathIds: state.IMUP.tools.selectedToolObjectPathIds,
    }),
    (selectedToolObjectPathIds) => selectedToolObjectPathIds
)

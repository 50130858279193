import { UiSchema } from '@rjsf/core'
import omit from 'lodash/omit'
import { FieldValues } from 'react-hook-form'
import { FormProperties } from '../imup/types'
import { DRAWABLE_TYPES } from '../shared/constants/drawable-types'
import { prepareValue } from '../shared/services/opening-form-services'

export const valuesPreparation = (formData: FieldValues) => {
    const settings = { ...formData }

    if (prepareValue(settings.type) === DRAWABLE_TYPES.I_JOIST) {
        // clear fields that can't be selected when type is I-Joist
        settings.pitch = null
        settings.board_size = null
        settings.floor_level = null
        settings.floor_level_other = null

        if (prepareValue(settings.ijoist_size).includes('tji')) {
            settings.bci_series = null
            settings.lpi_series = null
            settings.pwi_series = null
        }

        if (prepareValue(settings.ijoist_size).includes('bci')) {
            settings.tji_series = null
            settings.lpi_series = null
            settings.pwi_series = null
        }

        if (prepareValue(settings.ijoist_size).includes('lpi')) {
            settings.bci_series = null
            settings.tji_series = null
            settings.pwi_series = null
        }

        if (prepareValue(settings.ijoist_size).includes('pwi')) {
            settings.bci_series = null
            settings.tji_series = null
            settings.lpi_series = null
        }
    } else {
        // clean fields that can't be selected when type is not I-Joist
        settings.ijoist_size = null
        settings.bci_series = null
        settings.tji_series = null
        settings.lpi_series = null
        settings.pwi_series = null
    }

    return settings
}

export const uiSchema: UiSchema = {
    'ui:order': [
        'type',
        'oc_spacing',
        'oc_spacing_other',
        'direction',
        'is_treated',
        'ijoist_size',
        'pitch',
        'board_size',
        'rafter_tail',
        'floor_level',
        'floor_level_other',
        'bci_series',
        'lpi_series',
        'pwi_series',
        'tji_series',
    ],
    type: {
        'ui:title': 'Joist Type',
        'ui:placeholder': 'Select Joist Type',
    },
    oc_spacing: {
        'ui:title': 'Joist O.C Spacing',
        'ui:placeholder': 'Select Joist O.C Spacing',
    },
    direction: {
        'ui:title': 'Joist Direction',
        'ui:placeholder': 'Select Joist Direction',
    },
    is_treated: {
        'ui:title': 'Joist Treated',
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    ijoist_size: {
        'ui:title': 'I-Joist Size',
        'ui:placeholder': 'Select I-Joist Size',
    },
    bci_series: {
        'ui:title': 'BCI Series',
        'ui:placeholder': 'Select BCI Series',
    },
    lpi_series: {
        'ui:title': 'LPI Series',
        'ui:placeholder': 'Select LPI Series',
    },
    pwi_series: {
        'ui:title': 'PWI Series',
        'ui:placeholder': 'Select PWI Series',
    },
    tji_series: {
        'ui:title': 'TJI Series',
        'ui:placeholder': 'Select TJI Series',
    },
    pitch: {
        'ui:title': 'Pitch',
        'ui:placeholder': 'Select Pitch',
    },
    board_size: {
        'ui:title': 'Board Size',
        'ui:placeholder': 'Select Board Size',
    },
    rafter_tail: {
        'ui:title': 'Rafter Tail',
        'ui:placeholder': 'Select Rafter Tail',
    },
    floor_level: {
        'ui:title': 'Floor Level',
        'ui:placeholder': 'Select Floor Level',
    },
    floor_level_other: {
        'ui:title': 'Floor Level Other',
        'ui:placeholder': 'Select Floor Level Other',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => ({
    ...settings,
    ...omit(valuesPreparation(formData), ['quantity']),
})

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form

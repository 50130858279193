import isNull from 'lodash/isNull'
import { call, select, StrictEffect } from 'redux-saga/effects'
import { toggleActiveDrawableGroup } from '../../../actions/drawable'
import managers from '../../lib/managers'
import { BabylonManager } from '../../lib/managers/BabylonManager'
import { Select } from '../../lib/toolBoxes/3D'
import { IMUPMappingsSelector } from '../../slices/common'
import { ActiveGeometryGroup, selectActiveGeometryGroup } from '../../slices/geometry'
import { IBabylonLineWithMetadata, ThreeDToTwoDRecord } from '../../types'
import {
    cleanupJoistLinesMeshesAndLabels,
    createAndLabelJoistLines,
    filterJoistLinesData,
} from './handleUpdateJoistLinesUpdate'
import { selectAndHighlightRequiredMeshes } from './selectMesh'

export function* handleToggleActiveDrawableGroups3D(
    action: ReturnType<typeof toggleActiveDrawableGroup>
): Generator<
    StrictEffect | string[] | number[],
    void,
    (BabylonManager | null) & ThreeDToTwoDRecord & ActiveGeometryGroup & string[] & Select & number[]
> {
    const manager: BabylonManager | null = yield call(managers.get3DManager)

    if (isNull(manager)) return

    const threeDToTwoDRecord: ThreeDToTwoDRecord = yield select(IMUPMappingsSelector)
    const activeDrawables: ActiveGeometryGroup | null = yield select(selectActiveGeometryGroup)

    if (isNull(activeDrawables)) return

    const threeDIds: string[] = yield Object.keys(threeDToTwoDRecord).filter((id) => {
        const openingIds = threeDToTwoDRecord[id] ? threeDToTwoDRecord[id].map((record) => record.openingId) : []
        return action.payload.openingIds.some((openingId) => openingIds.includes(openingId))
    })

    // Test if any of the openings have already had their state changed by deselecting all or
    // selecting all, if they are active means that all meshes need to be highlighted as if
    // the are the "main" mesh i.e in greater focus if not then make it more subtle as the user has
    // deselected all meshes in the opening group
    const shouldMainHighlightAll = activeDrawables.openings && activeDrawables.openings[0].isActive

    yield call(selectAndHighlightRequiredMeshes, threeDIds, shouldMainHighlightAll === true, manager)

    if (!shouldMainHighlightAll) {
        yield call(cleanupJoistLinesMeshesAndLabels, manager)
    } else {
        const activeDrawableOpeningIds: number[] = yield activeDrawables.openings
            .filter((opeening) => opeening.isActive)
            .map((opening) => opening.id)
        const filteredJoistLines: Record<string, IBabylonLineWithMetadata> = yield call(
            filterJoistLinesData,
            activeDrawableOpeningIds
        )
        yield call(createAndLabelJoistLines, filteredJoistLines, manager)
    }
}

import { FormValidation, UiSchema } from '@rjsf/core'
import { FormProperties } from '../imup/types'
import { validateGivenFieldsAreFractions } from './validation/fractionValidation'
import { generateFeetAndInchesString } from '../components/markup/utils/helpers'

const labelOther = 'OTHER'

export const uiSchema: UiSchema = {
    type: {
        'ui:placeholder': 'Select Door Type',
    },
    handling: {
        'ui:title': 'Handling',
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    width: {
        'ui:placeholder': 'Select Door Width',
    },
    width_other: {
        'ui:title': 'Other Door Width',
        'ui:field': 'FeetAndInchesField',
    },
    height: {
        'ui:placeholder': 'Select Door Height',
    },
    height_other: {
        'ui:title': 'Other Door Height',
        'ui:field': 'FeetAndInchesField',
    },
    sidelight_width: {
        'ui:title': 'Door Sidelight Width',
        'ui:placeholder': 'Door Sidelight Width',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
    'ui:order': [
        'type',
        'width',
        'width_other',
        'height',
        'height_other',
        'casing',
        'handling',
        'sidelight',
        'sidelight_width',
    ],
}

const onValidate = (formData: any, errors: FormValidation) => {
    if (formData.width === labelOther) validateGivenFieldsAreFractions(['width_other'], formData, errors)
    if (formData.height === labelOther) validateGivenFieldsAreFractions(['height_other'], formData, errors)
    if (formData.sidelight) validateGivenFieldsAreFractions(['sidelight_width'], formData, errors)
}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData, number_wide: '1', room_location: 'IMuP' }
    if (!formData.sidelight) {
        newSettings.sidelight = ''
        newSettings.sidelight_width = null
    }

    if (newSettings.width === labelOther) {
        newSettings.width = generateFeetAndInchesString(newSettings.width_other)
    }

    newSettings.width_other = null

    if (newSettings.height === labelOther) {
        newSettings.height = generateFeetAndInchesString(newSettings.height_other)
    }

    newSettings.height_other = null

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form

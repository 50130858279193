import { call, put, SagaReturnType, select, StrictEffect, throttle } from 'redux-saga/effects'
import { updateProjectContextMarkups } from '../../../api/projects-api'
import { Project } from '../../../models/project'
import { Region } from '../../../models/region'
import { IToolObject } from '../../../models/tool'
import { defineLocationRegion } from '../../../utils/coordinates/defineLocationRegion'
import managers from '../../lib/managers'
import { Workspace } from '../../lib/toolBoxes/2D'
import { resetUpdatedCoordinates } from '../../slices/2D'
import { selectAllRegions } from '../../slices/region'
import {
    selectActiveToolObject,
    setActiveToolObjectId,
    updateToolObjectByToolId,
    updateToolObjectCoordinates,
} from '../../slices/tools'
import { SAGA_THROTTLE_TIMER } from '../../types'
import { selectProject } from './createDrawableLocation'

export function* handleToolCoordinatesUpdate(action: ReturnType<typeof updateToolObjectCoordinates>) {
    try {
        if (!action.payload?.coordinates?.length) return

        const paperManager = yield call(managers.get2DManager)

        if (!paperManager) return

        const workspaceTool: Workspace = yield call(paperManager.getTool, Workspace.NAME)

        const currentToolObject: IToolObject = yield select(selectActiveToolObject)

        const items: paper.Item[] = yield call(
            workspaceTool.getItemsWithCriteria,
            'data',
            (data) => data?.toolObject?.id === currentToolObject.id
        )

        if (!items.length) return

        const project: Project = yield select(selectProject)

        const regions: Region[] = yield select(selectAllRegions)

        const itemPath = items[0] as paper.Path

        const regionId = defineLocationRegion(itemPath, regions, workspaceTool)

        const toolObjectWithUpdatedData = {
            ...currentToolObject,
            coordinates: action.payload.coordinates,
            region_id: regionId,
        }

        const updatedContextMarkup: SagaReturnType<typeof updateProjectContextMarkups> = yield call(
            updateProjectContextMarkups,
            project.id,
            currentToolObject.document_chunk_id,
            toolObjectWithUpdatedData
        )

        yield put(resetUpdatedCoordinates())

        // update tool object in all tool objects
        yield put(updateToolObjectByToolId(updatedContextMarkup))

        // we set active tool id to update active tool object
        yield put(setActiveToolObjectId(updatedContextMarkup.id))
    } catch (error) {
        yield call(console.error, (error as Error).message)
    }
}

export function* watchForToolCoordinatesUpdate(): Generator<StrictEffect, void, unknown> {
    yield throttle(SAGA_THROTTLE_TIMER, updateToolObjectCoordinates.type, handleToolCoordinatesUpdate)
}

import { FormValidation, UiSchema } from '@rjsf/core'
import { FormProperties } from '../imup/types'
import { FORM_ERROR_MESSAGES } from '../shared/constants/error-messages'
import { evenNumberValidation } from '../shared/services/field-validation/field-validation-service'

const PostWrapSelectionOptions = {
    BOX_COLUMN: 'BOX COLUMN',
    CEDAR: 'CEDAR',
    CEMTRIM: 'CEMTRIM',
    FIBER_CEMENT: 'FIBER CEMENT',
    LP: 'LP',
    PVC: 'PVC',
    RS_CEDAR: 'RS CEDAR',
    S4S_CEDAR: 'S4S CEDAR',
    TAPERED_COLUMN: 'TAPERED COLUMN',
    WOODTONE: 'WOODTONE',
}

export const uiSchema: UiSchema = {
    'ui:order': [
        'selection',
        'thickness',
        'width_four_feet_four_inches',
        'width_two_feet',
        'width_five_feet_four_inches',
        'texture',
        'size',
        'height',
        'height_other',
    ],
    fc_texture: {
        'ui:field': 'RadioButtons',
    },
    size: {
        'ui:title': 'Size',
    },
    thickness: {
        'ui:title': 'Woodtone Thickness',
        'ui:placeholder': 'None',
    },
    width_four_feet_four_inches: {
        'ui:title': 'Woodtone Width',
        'ui:placeholder': 'Select Woodtone Width',
    },
    width_two_feet: {
        'ui:title': 'Woodtone Width',
        'ui:placeholder': 'Select Woodtone Width',
    },
    width_five_feet_four_inches: {
        'ui:title': 'Woodtone Width',
        'ui:placeholder': 'Select Woodtone Width',
    },
    texture: {
        'ui:title': 'Texture',
        'ui:placeholder': 'Not Specified',
    },
    height: {
        'ui:title': 'Box Column Height',
    },
    height_other: {
        'ui:title': 'Box Column Height Other',
    },
    selection: {
        'ui:placeholder': 'Select Selection',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
}

const onValidate = (formData: any, errors: FormValidation) => {
    if (formData.height === 'other') {
        if (!evenNumberValidation(formData?.height_other) && formData?.height_other) {
            errors['height_other'].addError(FORM_ERROR_MESSAGES.POST_WRAP_BOX_COLUMN_HEIGHT)
        }
    }

    if (formData.selection === PostWrapSelectionOptions.BOX_COLUMN) {
        if (formData.size === '') {
            errors['size'].addError(FORM_ERROR_MESSAGES.REQUIRED)
        }

        if (formData.height === '') {
            errors['height'].addError(FORM_ERROR_MESSAGES.REQUIRED)
        }
    }

    if (
        formData.selection !== PostWrapSelectionOptions.BOX_COLUMN &&
        formData.selection !== PostWrapSelectionOptions.PVC &&
        formData.selection !== PostWrapSelectionOptions.TAPERED_COLUMN &&
        formData.width === ''
    ) {
        errors['width'].addError(FORM_ERROR_MESSAGES.REQUIRED)
    }

    if (
        formData.selection === PostWrapSelectionOptions.RS_CEDAR ||
        formData.selection === PostWrapSelectionOptions.S4S_CEDAR
    ) {
        if (formData.thickness === '') {
            errors['thickness'].addError(FORM_ERROR_MESSAGES.REQUIRED)
        }
    }
}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    if (formData.selection === PostWrapSelectionOptions.BOX_COLUMN) {
        newSettings.width = null
        newSettings.thickness = null
        newSettings.height = newSettings.height === 'other' ? newSettings.height_other : newSettings.height
        newSettings.height_other = null
    } else {
        newSettings.size = null
        newSettings.height = null
    }

    if (formData.selection === PostWrapSelectionOptions.TAPERED_COLUMN) {
        newSettings.width = null
        newSettings.thickness = null
    }

    if (formData.selection === PostWrapSelectionOptions.CEMTRIM) {
        newSettings.thickness = null
    }

    if (formData.selection === PostWrapSelectionOptions.WOODTONE) {
        if (newSettings?.width_five_feet_four_inches) {
            newSettings.width = newSettings?.width_five_feet_four_inches
            delete newSettings?.width_five_feet_four_inches
        }

        if (newSettings?.width_two_feet) {
            newSettings.width = newSettings?.width_two_feet
            delete newSettings?.width_two_feet
        }

        if (newSettings?.width_four_feet_four_inches) {
            newSettings.width = newSettings?.width_four_feet_four_inches
            delete newSettings?.width_four_feet_four_inches
        }
    }

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form

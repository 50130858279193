import { call, put, select } from 'redux-saga/effects'
import { createDocumentMapping } from '../../../api/takeoff-api'
import { DocumentMapping } from '../../../models/documentMapping'
import { Project } from '../../../models/project'
import { updateDocumentMappings } from '../../slices/documents'
import { createSingleDocumentMapping } from '../effects/createSingleDocumentMapping'
import { selectProject } from './createDrawableLocation'

export function* handleCreateSingleDocumentMapping2D({ payload }: ReturnType<typeof createSingleDocumentMapping>) {
    try {
        const project: Project = yield select(selectProject)

        // Save the document mapping on the server and wait for the callback with the final mappings returned
        const resultMappings: DocumentMapping[] = yield call(createDocumentMapping, project.id, payload)

        if (resultMappings.length) {
            yield put(updateDocumentMappings(resultMappings))
        }
    } catch (error) {
        yield call(console.error, error)
    }
}

import { createSelector } from '@reduxjs/toolkit'
import axios from 'axios'
import isNull from 'lodash/isNull'
import { all, call, put, select, StrictEffect } from 'redux-saga/effects'
import {
    ADD_NEW_DRAWABLE_TO_GROUP,
    SET_ACTIVE_DRAWABLE_GROUP,
    UPDATE_OPENING_GROUPS_SUCCESS,
} from '../../../actions/drawable'
import { createOpening, CreateOpeningResponse } from '../../../api/projects-api'
import { eaveGablePattern, flushBeamPattern, hipPattern, valleyPattern } from '../../../formSchemas/roofing'
import { hipRooflineType, valleyRoofLineType } from '../../../formSchemas/roofingLine'
import { ActiveDrawable, Coordinate, OpeningGroupAPI } from '../../../models/activeDrawable'
import { GeneralDrawableSettings } from '../../../models/activeDrawableSettings'
import { ActiveFloor } from '../../../models/activeFloor'
import { Project } from '../../../models/project'
import { getProject } from '../../../reducers'
import { getActiveFloor } from '../../../reducers/drawable'
import { DRAWABLE_TYPES, DRAWING_TYPES } from '../../../shared/constants/drawable-types'
import { prepareValue } from '../../../shared/services/opening-form-services'
import { RootState } from '../../../stores'
import { calculateCustomMeasurements } from '../../../utils/calculations/customBusnessFormulas/customFormulas'
import {
    correctAreaUsingPitch,
    correctHipValleyLFUsingGivenPitch,
    correctRoofLFUsingPitch,
} from '../../../utils/calculations/pitchCorrection/pitchCorrection'
import {
    applyScaleFactorToPathArea,
    applyScaleFactorToPathLength,
    convertScaleEnumToString,
} from '../../../utils/calculations/scaleConversion/scaleConversion'
import {
    buildCoordinatesFromPaperItem,
    getMultipleArcCoordinatesFromPath,
} from '../../../utils/coordinates/buildCoordinates'
import { defineLocationRegion } from '../../../utils/coordinates/defineLocationRegion'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Workspace } from '../../lib/toolBoxes/2D'
import {
    getSelectedMaterial,
    insertDrawableLocation,
    selectedPaperItem,
    selectNewCreatedDrawables,
    setNewCreatedDrawables,
} from '../../slices/2D'
import { selectActiveChunkScalesAndDPI } from '../../slices/documents'
import {
    ActiveGeometryGroup,
    createBlankGroup,
    GeometricDrawable,
    GeometryGroup,
    selectActiveGeometryGroup,
    selectActiveGroupToCreate,
} from '../../slices/geometry'
import {
    DRAWABLE_UNITS_OF_MEASURE,
    IMUP2DDrawableLocation,
    MeasurementsToUpdate,
    OpeningWithGroupId,
} from '../../types'
import { handleCreateDrawableForGroup } from '../effects/createDrawableGroup'
import { convertOpeningsWithGroupIdsToDrawableLocations } from './data-prep/convertOpeningsToDrawableLocations'

interface RegionDTO {
    coordinates: Array<[number, number]>
    scale: string
    type: string
    name: string
    view: string
}

interface Region extends RegionDTO {
    id: number
    project_document_id: number
}

export const selectProject = createSelector(
    ({ drawable }: RootState): Project => getProject(drawable),
    (project) => project
)

export const selectScaleFactor = createSelector(
    (state: RootState) => state.IMUP['2D'].scaleFactor,
    (scaleFactor) => scaleFactor
)

/**
 * Determine the pitch corrected area of a given material
 * @param rawArea the raw area
 * @param param1 the settings and the type
 * @returns the pitch corrected area
 */
export const calculateAreaMeasurementsForMaterial = (
    rawArea: number,
    { type, settings }: { type?: DRAWABLE_TYPES; settings?: GeneralDrawableSettings }
): number => {
    const EVName = 'EV'
    const PVName = 'PV'
    if (type && settings) {
        if (settings.pitch) {
            if (type === DRAWABLE_TYPES.EXTERIOR_ROOFING || type === DRAWABLE_TYPES.FRAMING_ROOFING) {
                if (!settings.type) return rawArea
                return correctAreaUsingPitch(
                    parseFloat(String(settings.pitch)),
                    rawArea,
                    settings.type.toUpperCase() === PVName
                )
            }
            // If it is not exterior or framing roofing then use plan view (overhead projection)
            return correctAreaUsingPitch(parseFloat(String(settings.pitch)), rawArea, true)
        } else if (type === DRAWABLE_TYPES.ROOFING && settings.name) {
            // Assuming that the group name is EV ### or PV ###
            // Use parse float as the pitch can be a decimal
            const isEV = settings.name.toUpperCase().includes(EVName)
            const isPV = settings.name.toUpperCase().includes(PVName)
            if (!isEV && !isPV) return rawArea
            const pitch = parseFloat(settings.name.split(' ')[1])
            return correctAreaUsingPitch(pitch, rawArea, isPV)
        }
    }
    return rawArea
}

/**
 * Determine the linear footage of a given section (modify LF to account for pitch)
 * @param materialGroup the material group the section belongs to
 * @param rawLF the raw linear footage in ft
 * @returns the linear footage of the section
 */
export const calculateLFMeasurementOfMaterial = (
    rawLF: number,
    { type, settings }: { type?: DRAWABLE_TYPES; settings?: GeneralDrawableSettings }
): number => {
    if (type && settings) {
        if (type === DRAWABLE_TYPES.ROOFING) {
            const groupName = settings.name ? prepareValue(settings.name) : ''
            const isEveGable = new RegExp(eaveGablePattern).test(groupName)
            const isHipValley =
                new RegExp(hipPattern).test(groupName) ||
                new RegExp(valleyPattern).test(groupName) ||
                new RegExp(flushBeamPattern).test(groupName)
            if (isEveGable) {
                return correctRoofLFUsingPitch(parseFloat(String(settings.pitch)), rawLF)
            }
            if (isHipValley) {
                const pitches: [number, number] = [
                    parseFloat(String(settings.pitch1)),
                    parseFloat(String(settings.pitch2)),
                ]
                return correctHipValleyLFUsingGivenPitch(pitches, rawLF)
            }
        } else if (type === DRAWABLE_TYPES.HIP_AND_VALLEY_BEAM || type === DRAWABLE_TYPES.VALLEY_PLATE) {
            const pitches: [number, number] = [
                parseFloat(String(settings.pitch_1)),
                parseFloat(String(settings.pitch_2)),
            ]
            return correctHipValleyLFUsingGivenPitch(pitches, rawLF)
        } else if (type === DRAWABLE_TYPES.GABLE_LENGTH) {
            return correctRoofLFUsingPitch(parseFloat(String(settings.pitch)), rawLF)
        } else if (
            type === DRAWABLE_TYPES.ROOF_LINE &&
            settings.type &&
            [hipRooflineType, valleyRoofLineType].includes(settings.type)
        ) {
            const pitches: [number, number] = [parseFloat(String(settings.pitch1)), parseFloat(String(settings.pitch2))]
            return correctHipValleyLFUsingGivenPitch(pitches, rawLF)
        } else if (type === DRAWABLE_TYPES.FLUSH_BEAM || type === DRAWABLE_TYPES.WILDCARD_LINE) {
            return correctRoofLFUsingPitch(parseFloat(String(settings.pitch)), rawLF)
        }
    }
    return rawLF
}

export function postRegion(projectId: number, documentId: number, region: RegionDTO): Promise<Region> {
    return axios.post<RegionDTO, Region>(`project/${projectId}/document/${documentId}/region`, region)
}

export function* createGroupFromType(
    newDrawableGroupType: DRAWABLE_TYPES,
    projectId: number,
    settings: Record<string, any> = {}
): Generator<
    Generator<StrictEffect, void, GeometryGroup[] & OpeningGroupAPI> | StrictEffect,
    ActiveGeometryGroup | null,
    ActiveGeometryGroup
> {
    yield handleCreateDrawableForGroup({
        projectId,
        settings,
        type: newDrawableGroupType,
    })
    const newActiveDrawableGroup: ActiveGeometryGroup = yield select(selectActiveGroupToCreate)
    yield put(createBlankGroup(null))
    return newActiveDrawableGroup
}

export function* createDrawableLocation2D({ payload }: { payload: number }): Generator<
    StrictEffect | Coordinate[],
    void,
    PaperManager &
        Workspace &
        (ActiveGeometryGroup | null) &
        (DRAWABLE_TYPES | null) &
        ActiveFloor & {
            dpi: number | null
            xCalibrationFactor: number
            yCalibrationFactor: number
            pdfScale: number
        } & Project &
        (ActiveDrawable | null)
> {
    try {
        const manager: PaperManager = yield call(managers.get2DManager)

        if (!manager) return

        const selectedMaterialType: DRAWABLE_TYPES | null = yield select(getSelectedMaterial)
        const newCreatedDrawablesIds = yield select(selectNewCreatedDrawables)

        const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)

        let activeDrawableGroup: ActiveGeometryGroup | null = yield select(selectActiveGeometryGroup)
        const activeFloor: ActiveFloor = yield select(getActiveFloor)
        const project: Project = yield select(selectProject)
        const scaleFactor: number = yield select(selectScaleFactor)
        const {
            dpi,
            xCalibrationFactor,
            yCalibrationFactor,
            pdfScale,
        }: {
            dpi: number | null
            xCalibrationFactor: number
            yCalibrationFactor: number
            pdfScale: number
        } = yield select(selectActiveChunkScalesAndDPI)
        const item: paper.Item | null = yield call(workspaceTool.getItemWithPaperId, payload)

        if (!item) return

        if (!activeDrawableGroup && isNull(selectedMaterialType)) {
            yield call(item.remove.bind(item))
            return
        }

        let drawableGroupId = activeDrawableGroup ? activeDrawableGroup.id : null

        if (drawableGroupId === null && !isNull(selectedMaterialType)) {
            const newlyCreatedActiveDrawableGroup: ActiveDrawable | null = yield call(
                createGroupFromType,
                selectedMaterialType,
                project.id,
                item.data.aiSuggestion?.settings
            )
            drawableGroupId = newlyCreatedActiveDrawableGroup ? newlyCreatedActiveDrawableGroup.id : null
            activeDrawableGroup = newlyCreatedActiveDrawableGroup ? (newlyCreatedActiveDrawableGroup as any) : null
        }

        if (isNull(drawableGroupId)) {
            // There was an issue creating the group so delete
            // element and bail
            yield call(item.remove.bind(item))
            return
        }

        const path = item as paper.Path
        const activeFloorHash = activeFloor.hash
        const projectId = project.id
        const documentChunkId = activeFloor.document_chunk.id

        let coordinates: Coordinate[] = yield call(buildCoordinatesFromPaperItem, item, path)

        // Define if location exists within a region
        const regions = activeFloor.document_chunk.regions
        const region_id = defineLocationRegion(path, regions, workspaceTool)

        const measurements: MeasurementsToUpdate = {
            quantity: null,
            linear_total: null,
            area: null,
            count: null,
        }

        if (path.data.shapeType === DRAWING_TYPES.SECTION || path.data.shapeType === DRAWING_TYPES.RADIUS) {
            const linearFootage = calculateLFMeasurementOfMaterial(
                applyScaleFactorToPathLength({
                    pxValue: path.length,
                    scaleFactor,
                    pdfScale,
                    xCalibrationFactor,
                    yCalibrationFactor,
                    coordinates,
                    dpi,
                }),
                activeDrawableGroup ? { type: activeDrawableGroup.type, settings: activeDrawableGroup.settings } : {}
            )
            measurements.linear_total = applyScaleFactorToPathLength({
                pxValue: path.length,
                scaleFactor,
                pdfScale,
                xCalibrationFactor,
                yCalibrationFactor,
                coordinates,
                dpi,
            })
            measurements.quantity = linearFootage
        } else if (path.data.shapeType === DRAWING_TYPES.AREA) {
            // For Area materials, quantity is Square & linear_total is Perimeter
            const areaInSquareFeet = calculateAreaMeasurementsForMaterial(
                applyScaleFactorToPathArea({
                    pxValue: Math.abs(path.area),
                    scaleFactor,
                    pdfScale,
                    xCalibrationFactor,
                    yCalibrationFactor,
                    dpi,
                }),
                activeDrawableGroup ? { type: activeDrawableGroup.type, settings: activeDrawableGroup.settings } : {}
            )
            measurements.quantity = areaInSquareFeet
            measurements.area = areaInSquareFeet
            measurements.linear_total = applyScaleFactorToPathLength({
                pxValue: path.length,
                scaleFactor,
                pdfScale,
                xCalibrationFactor,
                yCalibrationFactor,
                coordinates,
                dpi,
            })
        } else if (path.data.shapeType === DRAWING_TYPES.POINT) {
            // when creating the group there will be 1 point inside it
            measurements.count = 1
            measurements.quantity = calculateCustomMeasurements(measurements, activeDrawableGroup?.type).quantity
        }

        // If material is a radius line, pass multiple points to support MaterialCalc model
        let additional_data: Record<string, any> = {}
        if (path.data.shapeType === DRAWING_TYPES.RADIUS) {
            additional_data.arc_points = getMultipleArcCoordinatesFromPath(path)
        }

        if (region_id) {
            const regionPath = workspaceTool.getRegionItemByRegionId(region_id)
            additional_data.scale_factor = convertScaleEnumToString(regionPath.data.scale)
        } else {
            additional_data.scale_factor = convertScaleEnumToString(activeFloor.scale_factor)
        }

        const drawable: CreateOpeningResponse = yield call(
            createOpening,
            projectId,
            activeFloorHash,
            drawableGroupId,
            coordinates,
            documentChunkId,
            measurements,
            {
                unit_of_measure:
                    path.data.shapeType === DRAWING_TYPES.SECTION || path.data.shapeType === DRAWING_TYPES.RADIUS
                        ? DRAWABLE_UNITS_OF_MEASURE.line
                        : path.data.shapeType === DRAWING_TYPES.AREA
                        ? DRAWABLE_UNITS_OF_MEASURE.area
                        : DRAWABLE_UNITS_OF_MEASURE.count,
                shape_type: path.data.shapeType,
            },
            region_id,
            additional_data,
            path.data.aiSuggestion?.id
        )

        // set the drawable ids as latest added
        yield put(setNewCreatedDrawables(drawable.id))

        const drawableWithGroupId: OpeningWithGroupId = {
            ...drawable,
            groupId: drawableGroupId,
        }

        const drawableLocations: IMUP2DDrawableLocation[] = yield call(convertOpeningsWithGroupIdsToDrawableLocations, [
            drawableWithGroupId,
        ])

        const drawableLocation = drawableLocations[0]

        // Add new drawable to store
        yield all([
            put({ type: insertDrawableLocation.type, payload: drawableLocation }),
            put({ type: ADD_NEW_DRAWABLE_TO_GROUP, payload: { drawable, drawableGroupId } }),
        ])

        const drawableWithDoneState: GeometricDrawable = {
            ...drawable,
            is_marked_done: activeDrawableGroup?.is_marked_done ?? null,
        }

        const newActiveDrawableGroup = {
            ...activeDrawableGroup,
            openings: activeDrawableGroup?.openings
                ? activeDrawableGroup?.openings.concat([drawableWithDoneState])
                : [drawableWithDoneState],
            drawables: activeDrawableGroup?.drawables
                ? activeDrawableGroup?.drawables.concat([drawableWithDoneState])
                : [drawableWithDoneState],
            settings: drawable.opening_group.settings,
        }

        yield all([
            put({
                type: UPDATE_OPENING_GROUPS_SUCCESS,
                payload: {
                    openingGroups: {
                        newGroup: newActiveDrawableGroup,
                        originalGroup: activeDrawableGroup,
                    },
                },
            }),
            call(item.remove.bind(item)),
        ])

        yield put({
            type: SET_ACTIVE_DRAWABLE_GROUP,
            payload: {
                activeDrawableGroup: newActiveDrawableGroup,
                activeDrawableId: [...newCreatedDrawablesIds, drawable.id],
            },
        })

        if (item.id && drawable.id) {
            yield put(
                selectedPaperItem({
                    selectedItem: item.id,
                    activeDrawableId: drawable.id,
                    activeOpeningLocationId: drawableLocation.opening_location_id ?? null,
                    openingGroupId: newActiveDrawableGroup.id ?? null,
                })
            )
        }
    } catch (error) {
        yield call(console.error, error)
    }
}

import { FormValidation } from '@rjsf/core'
import { FORM_ERROR_MESSAGES } from '../../shared/constants/error-messages'
import { fractionValidation } from '../../shared/services/field-validation/field-validation-service'

export const validateGivenFieldsAreFractions = (fieldsToValidate: string[], formData: any, errors: FormValidation) => {
    fieldsToValidate.forEach((field) => {
        const fieldFormData = formData[field]
        if (fieldFormData) {
            if (!fractionValidation(String(fieldFormData))) {
                errors[field].addError(FORM_ERROR_MESSAGES.FRACTION)
            }
        }
    })
}

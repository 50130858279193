import { UiSchema } from '@rjsf/core'
import { JSONSchema7 } from 'json-schema'
import { TOOL_TYPE_ENUMS } from '../imup/types'
import { AzureFeatureFlag } from '../models/azureFeatureFlags'
import { DRAWABLE_TYPES } from '../shared/constants/drawable-types'
import { advanced_settings_helper } from '../utils/formSchema/formSchema-helper-functions'

// This object must be initiate each time different since it is repetitive and should be influenced separately.
const get_schema_species_and_grade_selections = (): UiSchema => {
    return {
        species: {
            'ui:placeholder': 'Select Species',
        },
        grade: {
            'ui:placeholder': 'Select Grade',
        },
        thickness: {
            'ui:placeholder': 'Select Thickness',
        },
        dry: {},
        fire_rated: {},
        finger_jointed: {},
    }
}

const advanced_settings: UiSchema = {
    advanced_settings: {
        'ui:widget': 'hidden',

        studs: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        top_plates: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        bottom_plates: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        wall_blocking: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        wall_bracing: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        treated_plates: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        fire_blocking: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        joist: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        floor_truss: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        rimboard: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        rafters: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        post: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        ledger: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        blocking: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        rafter_tail: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        fascia_boards_1: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        fascia_boards_2: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        beam: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        ledger_enhancement: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
    },
    advanced_settings_without_plates: {
        'ui:widget': 'hidden',
        studs: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        wall_blocking: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        wall_bracing: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
    },
    advanced_settings_without_studs: {
        'ui:widget': 'hidden',
        top_plates: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        bottom_plates: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        wall_blocking: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        wall_bracing: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        treated_plates: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
        fire_blocking: {
            'ui:field': 'customTitle',
            ...get_schema_species_and_grade_selections(),
        },
    },
}

/**This will add missing properties to UI schemas , that we could hide them */
const updateUISchemaWithConditionalProperties = (completeUISchema: UiSchema, completeSchema: JSONSchema7): UiSchema => {
    advanced_settings_helper.addConditionalProperties(completeUISchema, completeSchema)

    // add missing properties
    for (const prop in completeSchema.properties) {
        if (!completeUISchema[prop]) {
            completeUISchema[prop] = {}
        }
    }

    return completeUISchema
}
/**This will add advanced settings to UI schemas if applicable */
export const addAdvancedSettingsToSchemaByType = (
    uiSchema: UiSchema,
    type: DRAWABLE_TYPES | TOOL_TYPE_ENUMS,
    azureFeatureFlags: AzureFeatureFlag[],
    completeSchema: any
): UiSchema => {
    if (advanced_settings_helper.isAdvancedSettingsEnabled(type, azureFeatureFlags)) {
        let completeUISchema = {
            ...uiSchema,
            ...advanced_settings,
        }
        //add missing properties from schema to UI schema
        completeUISchema = updateUISchemaWithConditionalProperties(completeUISchema, completeSchema)
        return completeUISchema
    }
    return uiSchema
}

/**This will remove all advanced settings data from formData is the conditions in schema are not allow to see advanced settings */
export const removeAdvancedSettingsDataIfConditionTurnedOff = (formData, schema) => {
    const res = advanced_settings_helper.getVisibleProperties(formData, schema)
    for (const property in advanced_settings) {
        if (res[property] === false) {
            delete formData[property]
        }
    }
    return formData
}

import { call, put, select } from 'redux-saga/effects'
import { FETCH_OPENING_GROUPS_SUCCESS } from '../../../actions/drawable'
import { fetchOpeningGroupsByProjectId, updateDocumentMapping } from '../../../api/takeoff-api'
import { OpeningGroupAPI } from '../../../models/activeDrawable'
import { preparedDrawablesSuccess } from '../../slices/2D'
import {
    resetDocumentChunkCalibration,
    selectActiveDocumentChunk,
    selectDocumentMappings,
    updateDocumentMappings,
} from '../../slices/documents'
import { selectProjectId } from '../../slices/project'
import { IMUP2DDrawableLocation } from '../../types'
import { updateSingleDocumentMapping } from '../effects/updateSingleDocumentMapping'
import { convertOpeningGroupsToDrawableLocations } from './data-prep/convertOpeningsToDrawableLocations'

export function* handleUpdateSingleDocumentMapping2D({ payload }: ReturnType<typeof updateSingleDocumentMapping>) {
    try {
        const projectId: number = yield select(selectProjectId)
        const activeDocumentChunk = yield select(selectActiveDocumentChunk)
        const documentMappings = yield select(selectDocumentMappings)

        yield call(updateDocumentMapping, projectId, payload)

        // Update the mapping in the store
        yield put(updateDocumentMappings([payload]))

        const foundDocumentMapping = yield documentMappings.find((documentMapping) => documentMapping.id === payload.id)

        if (foundDocumentMapping && foundDocumentMapping.scale_factor !== payload.scale_factor) {
            yield put(resetDocumentChunkCalibration({ documentChunkId: activeDocumentChunk.id }))
        }

        // Fetch opening groups from api
        const openingGroups: Array<OpeningGroupAPI> = yield call(fetchOpeningGroupsByProjectId, projectId)

        // Original IMUP: pass api response to original IMUP reducer
        yield put({ type: FETCH_OPENING_GROUPS_SUCCESS, payload: openingGroups })

        // Convert opening groups to drawable locations with appropriate metadata
        const drawableLocations: Array<IMUP2DDrawableLocation> = yield call(
            convertOpeningGroupsToDrawableLocations,
            openingGroups
        )

        // Retrieve the unique set of document chunk IDs for all locations
        const documentChunkIds: Array<number> = [
            ...new Set(drawableLocations.map((location) => location.document_chunk_id)),
        ]

        // Provide drawable locations and unique document chunk IDs to the store
        yield put(preparedDrawablesSuccess({ drawableLocations, documentChunkIds }))
    } catch (error) {
        yield call(console.error, error)
    }
}

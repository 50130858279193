import { UiSchema } from '@rjsf/core'
import { FormProperties } from '../imup/types'

export const uiSchema: UiSchema = {
    'ui:order': ['louver_type', 'horizontal_board_type', 'perimeter_framing'],
    louver_type: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    horizontal_board_type: {
        'ui:placeholder': 'Select Horizontal Board Type',
    },
    perimeter_framing: {
        'ui:placeholder': 'Select Perimeter Framing',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    return {
        ...settings,
        ...formData,
    }
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form

import { MsalProvider } from '@azure/msal-react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import { msalInstance } from './authConfig'
import App from './components/app/App'
import './index.css'
import { ConfigureInterceptors } from './interceptors/request-interceptor'
import * as serviceWorker from './serviceWorker'

// Cant use JSONSchema7 as enum names is not supported
// https://github.com/rjsf-team/react-jsonschema-form/issues/2663#issuecomment-1106698186
declare module 'json-schema' {
    export interface JSONSchema7 {
        enumNames?: Array<string>
    }
}

ConfigureInterceptors()

ReactDOM.render(
    <Router>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    </Router>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import { createAction } from '@reduxjs/toolkit'
import isUndefined from 'lodash/isUndefined'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import {
    fetchOpeningGroupPending,
    filterDrawableGroups,
    setActiveDrawable,
    setActiveDrawableGroup,
    updateOpeningGroupsSuccess,
} from '../../../actions/drawable'
import { UpdateOpeningGroupApiResponse } from '../../../api/api-helper'
import { updateOpeningGroup } from '../../../api/takeoff-api'
import { OpeningGroup } from '../../../models/activeDrawable'
import { Project } from '../../../models/project'
import { updateDrawableSelections } from '../../../shared/services/opening-form-services'
import { resetNewCreatedDrawables, setFlag, unsetActiveDrawableId } from '../../slices/2D'
import { formError } from '../../slices/forms'
import { selectAllRegions } from '../../slices/region'
import { DRAW_TOOL_ACTION, SAVING_MESSAGE } from '../../types'
import { selectProject } from '../2D/createDrawableLocation'
import { prepareActiveDrawableBeforeSubmit } from '../utils/prepareActiveDrawableBeforeSubmit'
import { updateToolbarMessage } from '../../slices/common'

export const submitMaterialForm = createAction<{ formData: OpeningGroup['settings']; isMarkedDone: boolean }>(
    'submitMaterialForm'
)

export function* handleMaterialFormSubmit({ payload }: ReturnType<typeof submitMaterialForm>) {
    try {
        yield put(updateToolbarMessage(SAVING_MESSAGE))

        const results = yield call(prepareActiveDrawableBeforeSubmit, { formData: payload.formData })
        const regions = yield select(selectAllRegions)

        // Something went wrong with preparing data for submit bail
        if (!results) return

        const {
            activeDrawableGroup,
            prepareDrawables,
            openings,
            settings,
            configuration,
            remarks,
            scaleFactor,
            dpi,
            xCalibrationFactor,
            yCalibrationFactor,
            pdfScale,
        } = results

        yield put(fetchOpeningGroupPending())

        const project: Project = yield select(selectProject)

        const preparedOpenings = isUndefined(prepareDrawables)
            ? yield call(updateDrawableSelections, openings, {
                  settings,
                  configuration,
                  remarks,
              })
            : yield call(prepareDrawables, {
                  drawables: yield call(updateDrawableSelections, openings, {
                      settings,
                      configuration,
                      remarks,
                  }),
                  newSettings: settings,
                  scaleFactor,
                  dpi,
                  xCalibrationFactor,
                  yCalibrationFactor,
                  pdfScale,
                  regions,
                  is3D: project?.is3D,
              })

        const req = {
            openings: preparedOpenings,
            settings,
            type: activeDrawableGroup.type,
            configuration,
            remarks,
            is_marked_done: payload.isMarkedDone,
        }

        const res = yield call(updateOpeningGroup, activeDrawableGroup.project_id, req, String(activeDrawableGroup.id))

        const formattedResponse: UpdateOpeningGroupApiResponse = {
            newGroup: res,
            originalGroup: {
                id: activeDrawableGroup.id,
                project_id: activeDrawableGroup.project_id,
                color: null,
                ...req,
            },
        }

        yield put(updateOpeningGroupsSuccess(formattedResponse))

        yield all([
            put(unsetActiveDrawableId()),
            put(setActiveDrawable(null, 0)),
            put(setActiveDrawableGroup(null)),
            put(filterDrawableGroups()),
            put(setFlag(DRAW_TOOL_ACTION.NEW_OPENING)),
            put(resetNewCreatedDrawables()),
            put(updateToolbarMessage(null)),
        ])
    } catch (error) {
        yield put(formError((error as any).message))
    }
}

export function* watchForMaterialFormSubmit() {
    yield takeLatest(submitMaterialForm.type, handleMaterialFormSubmit)
}

import { UiSchema } from '@rjsf/core'
import { FormProperties } from '../imup/types'
import { deckOrPorch } from '../utils/formSchema/constants'

const OTHER = 'other'
const TWO_X_BUILD_UP = '2X BUILD UP'

export const uiSchema: UiSchema = {
    'ui:order': [
        'type',
        'size',
        'size_other_1',
        'size_other_2',
        'height',
        'height_other',
        'called_out_cap',
        'called_out_anchor',
    ],
    type: {
        'ui:placeholder': 'Select Post Type',
    },
    size: {
        'ui:placeholder': 'Select Size',
    },
    height: {
        'ui:placeholder': 'Select Height',
    },
    height_other: { 'ui:title': 'Height other (feet)' },
    called_out_cap: {
        'ui:title': 'Called Out Cap',
        'ui:placeholder': 'Select Called Out Cap',
    },
    called_out_anchor: {
        'ui:title': 'Called Out Base',
        'ui:placeholder': 'Select Called Out Base',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
    bundle_location: {
        'ui:placeholder': 'Select Bundle Location',
    },
    bundle_floor_level: {
        'ui:placeholder': 'Select Bundle Floor Level',
    },
    bundle_other_floor_level: {
        'ui:placeholder': 'Enter Bundle Other Floor Level',
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    if (formData.height !== OTHER && formData.hasOwnProperty('height_other')) {
        newSettings.height_other = null
    }

    if (formData.height === OTHER && formData.hasOwnProperty('height_other')) {
        newSettings.height = formData.height_other
    }

    if (formData.type === TWO_X_BUILD_UP && formData.size.includes('/')) {
        newSettings.size = null
    }

    if (!deckOrPorch.includes(formData.bundle_name)) {
        newSettings.bundle_floor_level = null
    }

    if (formData.bundle_floor_level !== 'other') {
        newSettings.bundle_other_floor_level = null
    }

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form

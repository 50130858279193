export const PLAN_VIEWS = {
    floor: {
        label: 'Floor Plan',
        article: 'a',
    },
    elevation: {
        label: 'Elevation',
        article: 'an',
    },
}

export enum PLAN_VIEWS_ENUM {
    FLOOR = 'floor',
    ELEVATION = 'elevation',
    SCHEDULE = 'schedule',
    OTHER = 'other',
}

export const PLAN_VIEWS_LABELS = {
    floor: 'Floor Plan',
    elevation: 'Elevation',
    schedule: 'Schedule',
    other: 'Other',
}

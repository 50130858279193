import axios from 'axios'
import { getToken, msalInstance } from '../authConfig'

const hostApi = process.env.REACT_APP_API_URL

let timer: ReturnType<typeof setTimeout> | undefined
const DELEYED_LOGOUT_TIMEOUT = 1000

export const ConfigureInterceptors = () => {
    axios.interceptors.request.use(async (config) => {
        const token = await getToken()
        if (token) {
            config.headers.common.Authorization = `Bearer ${token}`
            if (timer) {
                clearTimeout(timer)
            }
        }

        config.url = hostApi + `/${config.url}`

        return config
    })

    axios.interceptors.response.use(
        (response) => {
            return response.data
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                if (!timer) {
                    timer = setTimeout(() => {
                        msalInstance.logoutRedirect()
                    }, DELEYED_LOGOUT_TIMEOUT)
                }
                // Introduce a delay of 2000ms before rejecting the promise
                // This delay allows time for potential side effects or unexpected displays
                // before triggering the reload, reducing the likelihood of issues.
                return new Promise((_, reject) => {
                    setTimeout(() => {
                        reject(error)
                    }, 2000)
                })
            }
            return Promise.reject(error)
        }
    )
}

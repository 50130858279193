export const EAVE_GABLE_SOFFIT_TYPE_FOR_TG_WIDTH = [
    'CEDAR TG',
    'PINE TG',
    'TG PAT122',
    '#2YP PAT122',
    'D YP TG SPRUCE AQUA SEAL',
    'V GROOVE',
    'FINE LINE',
    'BEADED CEILING',
]

import { call, ForkEffect, select, takeEvery } from 'redux-saga/effects'
import { UPDATE_JOIST_LINES } from '../../../actions/drawable'
import { selectActiveMode } from '../../slices/common'
import { VIEW_MODE } from '../../types'
import { redrawJoistLinesOnUpdate } from '../2D/redrawJoistLinesOnUpdate'
import { processJoistLinesDataUpdate } from '../3D/handleUpdateJoistLinesUpdate'
import { OpeningGroup } from '../../../models/activeDrawable'

export function* handleUpdateJoistLines(newJoistActionAndPayloadAndResponse: {
    type: string
    payload: { res: OpeningGroup }
}) {
    try {
        const activeMode: VIEW_MODE | null = yield select(selectActiveMode)

        switch (activeMode) {
            case VIEW_MODE.Markup2D:
                yield call(redrawJoistLinesOnUpdate, newJoistActionAndPayloadAndResponse)
                break
            case VIEW_MODE.Markup3D:
                yield call(processJoistLinesDataUpdate, newJoistActionAndPayloadAndResponse.payload.res)
                break
            default:
                break
        }
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForUpdateJoistLines(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(UPDATE_JOIST_LINES, handleUpdateJoistLines)
}

import { UserPermission, UserSettings } from '../models/user'

export const FETCH_USER_PERMISSIONS_SUCCESS = 'FETCH_USER_PERMISSIONS_SUCCESS'
export const FETCH_USER_SETTINGS_SUCCESS = 'FETCH_USER_SETTINGS_SUCCESS'
export const UPDATE_SCROLL_STEP = 'UPDATE_SCROLL_STEP'

const fetchUserPermissionsSuccess = (userPermissions: UserPermission | undefined): any => {
    return {
        type: FETCH_USER_PERMISSIONS_SUCCESS,
        payload: {
            userPermissions,
        },
    }
}

const fetchUserSettingsSuccess = (userSettings: UserSettings): any => {
    return {
        type: FETCH_USER_SETTINGS_SUCCESS,
        payload: {
            userSettings,
        },
    }
}

const updateScrollStep = (scrollStep: UserSettings['scrollStep']): any => {
    return {
        type: UPDATE_SCROLL_STEP,
        payload: {
            scrollStep,
        },
    }
}

export { fetchUserPermissionsSuccess, fetchUserSettingsSuccess, updateScrollStep }

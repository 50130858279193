import { call, fork, select, StrictEffect, takeEvery } from 'redux-saga/effects'
import { updateOpeningGroupsSuccess, UPDATE_OPENING_GROUPS_SUCCESS } from '../../../actions/drawable'
import { selectActiveMode } from '../../slices/common'
import { VIEW_MODE } from '../../types'
import { handleUpdateOpeningGroupsSuccess2D } from '../2D/handleUpdateOpeningGroupsSuccess'
import { handleUpdateOpeningGroupsSuccess3D } from '../3D/handleUpdateOpeningGroupsSuccess'

export function* handleUpdateOpeningGroupsSuccess(
    action: ReturnType<typeof updateOpeningGroupsSuccess>
): Generator<StrictEffect, void, VIEW_MODE> {
    try {
        const activeMode: VIEW_MODE = yield select(selectActiveMode)

        switch (activeMode) {
            case VIEW_MODE.Markup2D:
                yield fork(handleUpdateOpeningGroupsSuccess2D, action)
                break
            case VIEW_MODE.Markup3D:
                yield fork(handleUpdateOpeningGroupsSuccess3D)
                break

            default:
                break
        }
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}

export function* watchForSelectionCompletion(): Generator<StrictEffect, void, unknown> {
    yield takeEvery(UPDATE_OPENING_GROUPS_SUCCESS, handleUpdateOpeningGroupsSuccess)
}

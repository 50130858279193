import { distinctUntilChanged, map, takeWhile } from 'rxjs'
import { Cursors, IMUPState, PaperToolConfig, TOOL_TYPE_ENUMS, VIEW_MODE } from '../../../../../types'
import { PathTool } from '../path/Path.tool'

/**
 * FloorLevelBreaklineTool.tool.tsx
 * Creates a Floor Level Breakline
 */
export class FloorLevelBreaklineTool extends PathTool {
    static NAME = TOOL_TYPE_ENUMS.FLOOR_LEVEL_BREAK_LINE
    static TYPE = TOOL_TYPE_ENUMS.FLOOR_LEVEL_BREAK_LINE
    static CURSOR = Cursors.CROSSHAIR

    private lineAndCircleColor: paper.Color = new this.paper.Color('grey')

    private startPoint: paper.Point | null = null
    private endPoint: paper.Point | null = null

    private temporaryPath: paper.Path | null = null
    private activeForm: string | null = null

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = FloorLevelBreaklineTool.NAME

        this.mediator
            .get$()
            .pipe(
                takeWhile((state: IMUPState) => {
                    // Include the logic to check if active mode is VIEW_MODE.Markup2D
                    // and activeForm is not null or undefined
                    return state.common.activeMode === VIEW_MODE.Markup2D
                }),
                map((state: IMUPState) => ({
                    activeForm: state.forms.activeForm,
                })),
                distinctUntilChanged()
            )
            .subscribe(({ activeForm }) => {
                this.activeForm = activeForm
            })
    }

    cancel = (): void => {
        this.cleanUpPoints()
        this.temporaryPath?.removeSegments() // Clear the previous segments of the temporary path
    }

    onMouseMove = (event: paper.ToolEvent) => {
        if (this.startPoint && !this.endPoint) {
            if (!this.temporaryPath) {
                // Create a new temporary path if it doesn't exist
                this.temporaryPath = new this.paper.Path()
                this.temporaryPath.strokeColor = this.lineAndCircleColor
                this.temporaryPath.strokeWidth = this.strokeWidth
            } else {
                // Clear the previous segments of the temporary path
                this.temporaryPath.removeSegments()
            }

            const newPoint = this.updateYCoordinateForNewPoint(event.point)
            // Create segments from points
            const startPointSegment = new this.paper.Segment(this.startPoint)
            const newPointSegment = new this.paper.Segment(newPoint)

            // draw a new temporary part based on the new Point Segment
            this.temporaryPath.addSegments([startPointSegment, newPointSegment])
        }
    }

    onMouseDown = (event: paper.ToolEvent) => {
        if (this.isPanningClick(event)) return

        const isDrawingEnabled = this.isDrawingEnabled()

        if (isDrawingEnabled) {
            this.drawBreakLineOnClick(event)
        }
    }

    /**
     * Clean up startPoint and endPoint
     */
    private cleanUpPoints = () => {
        this.startPoint = null
        this.endPoint = null
    }

    /**
     * Based on a current points set startPoint and endPoint
     * @param point
     */
    private setFloorLevelBreakLinePoints = (point: paper.Point) => {
        if (!this.startPoint) {
            this.startPoint = point
        } else {
            this.endPoint = point
        }
    }

    /**
     * Draw points on mouse down and once two points are
     *  drawn, create a path drawable and trigger the event
     * that will create a new drawable for Floor level break line from that
     * line, after that event is fired clean up the points
     * previously drawn
     *
     * @param event
     */
    private drawBreakLineOnClick = (event: paper.ToolEvent) => {
        const newPoint = this.updateYCoordinateForNewPoint(event.point)

        this.setFloorLevelBreakLinePoints(newPoint)

        if (this.startPoint && this.endPoint) {
            this.temporaryPath?.removeSegments() // Clear the previous segments of the temporary path

            const newPath = this.createPath([
                [this.startPoint.x, this.startPoint.y],
                [this.endPoint.x, this.endPoint.y],
            ])

            newPath.strokeColor = this.lineAndCircleColor
            newPath.strokeWidth = this.strokeWidth
            if (newPath.data) {
                newPath.data.shapeType = FloorLevelBreaklineTool.TYPE
                newPath.data.toolName = this.name
                newPath.data.isToolObject = true
                newPath.data.isTemporaryGroup = true

                const documentChunk = this.getActiveDocumentChunk()

                if (documentChunk?.id) {
                    newPath.data.document_chunk_id = documentChunk?.id
                }
            }

            this.cleanUpPoints()

            this.setState('2D', { drawablesToCreateByTool: [newPath.id] })
        } else {
            this.setScaleFromPointClick(newPoint)
        }
    }

    /**
     * Determines what the new point should be based on:
     *  1. Should be only horizontal line
     * @param eventPoint
     * @returns
     */
    private updateYCoordinateForNewPoint = (eventPoint: paper.Point): paper.Point => {
        if (this.startPoint) {
            // make sure that the line is horizontal
            eventPoint.y = this.startPoint.y
        }
        return eventPoint
    }

    /**
     * We can draw if one of the points is still empty, and we don't open active form
     */
    private isDrawingEnabled = (): boolean => {
        return (!this.startPoint || !this.endPoint) && !this.activeForm
    }

    onMouseDrag = (event: paper.ToolEvent) => {
        this.toolPanning(event)
    }
}

export enum USER_PERMISSIONS_NAMES {
    SEE_ALL_COMPANIES_AND_LOCATIONS = 'seeAllCompaniesAndLocations',
    FETCH_PROJECT_LIST = 'fetchProjectList',
    BYPASS_PREFIX_TO_COMPLETE = 'bypassOutputFilePrefix',
    CREATE_PROJECT = 'createDigitizerProject',
    FLAG_NOTE_ADMINISTRATOR = 'flagNoteAdministrator',
    UPDATE_WINDOW_DOOR_TAKEOFF = 'updateWindowDoorTakeoff',
    UPDATE_INTERACTIVE_MARKUP = 'updateInteractiveMarkup',
    UPDATE_DATA = 'updateData',
    MERGE_DATA = 'mergeData',
    UPDATE_FRAMING_SETTINGS = 'updateFramingProjectSettings',
    UPDATE_WALL_FORM = 'updateWallForm',
    UPDATE_POST_FORM = 'updatePost',
    UPDATE_FLOOR_TRUSS = 'updateFloorTruss',
    UPDATE_JOIST = 'updateJoist',
    UPDATE_STAIRS = 'updateStairs',
    UPDATE_STEEL_BEAM_PLATE = 'updateSteelBeamPlate',
    UPDATE_LF_RAILING = 'updateLFRailing',
    UPDATE_HARDWARE = 'updateHardware',
    UPDATE_LEDGER = 'updateLedger',
    UPDATE_HEADER = 'updateHeader',
    UPDATE_FIVEDOTFIVE_DECKING = 'updateFiveDotFiveDecking',
    UPDATE_FOUNDATION_PILON = 'updateFoundationPilon',
    UPDATE_MILLWORK = 'updateMillwork',
    UPDATE_SUBFASCIA = 'updateSubFascia',
    UPDATE_DECK = 'updateDeck',
    UPDATE_PORCH = 'updatePorch',
    UPDATE_SCREEN_PORCH = 'updateScreenPorch',
    UPDATE_FOUNDATION = 'updateFoundation',
    UPDATE_POCKET_DOOR = 'updatePocketDoor',
    UPDATE_TRAYED_CEILING_AND_SOFFIT = 'updateTrayedCeilingAndSoffit',
    UPDATE_FYPON_BRACKET = 'updateFyponBracket',
    UPDATE_GABLE_AND_FLOOR_AREA = 'updateGableAndFloorArea',
    UPDATE_SCREEN_AREA = 'updateScreenArea',
    UPDATE_INTERIOR_FINISHED_CEILING = 'updateInteriorFinishedCeiling',
    UPDATE_CANALES = 'updateCanales',
    UPDATE_VALLEY_PLATE = 'updateValleyPlate',
    UPDATE_UNDER_VALLEY_SHEATHING = 'updateUnderValleySheathing',
}

export enum FILTER_CARD_VALUES {
    ALL_CARDS = 'allCards',
    DONE_CARDS = 'doneCards',
    NOT_DONE_CARDS = 'notDoneCards',
}

export const FILTER_CARD_OPTIONS = {
    allCards: {
        label: 'All',
        value: FILTER_CARD_VALUES.ALL_CARDS,
    },
    doneCards: {
        label: 'Done',
        value: FILTER_CARD_VALUES.DONE_CARDS,
    },
    notDoneCards: {
        label: 'Not Done',
        value: FILTER_CARD_VALUES.NOT_DONE_CARDS,
    },
}

export const ACTIVE_PLAN = {
    all: 'all',
    single: 'single',
}

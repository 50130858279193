import { applyScaleFactorToPathLength } from '../../../../../../utils/calculations/scaleConversion/scaleConversion'
import { Coordinates2D, Cursors, PaperToolConfig } from '../../../../../types'
import Calibrate from '../calibrate/Calibrate.tool'
import PaperTool from '../paperTool/PaperTool'

/**
 * Measure.tool.tsx
 * Calculates distances between two points
 */
export class Measure extends PaperTool {
    static NAME = 'MEASURE'
    static CURSOR = Cursors.CROSSHAIR

    /**
     * This is largely an arbitrary value to allow for
     * crosshair hit detection
     */
    private static readonly CROSS_HAIR_HIT_BOX_IN_PX = 5

    protected path: paper.Path | null = null
    private points: Array<paper.Group> = []
    private paperPoints: Coordinates2D = []
    private selectedCrossHair: paper.Group | null = null

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Measure.NAME
        this.cursor = Measure.CURSOR
    }

    /**
     * Cleanup all items that have been added to the canvas on cancel
     */
    cancel = () => {
        this.path?.remove()
        this.points.forEach((point: paper.Group) => {
            point.remove()
        })
        this.mediator.mediate('tools', { measurement: null, calibrationLineLength: null }) // remove lingering measurements

        // reset local vars
        this.path = null
        this.points = []
        this.paperPoints = []
    }

    private measurementPointHitTest = (point1: paper.Point, point2: paper.Point): boolean => {
        return (
            Math.abs(point1.x - point2.x) < Measure.CROSS_HAIR_HIT_BOX_IN_PX &&
            Math.abs(point1.y - point2.y) < Measure.CROSS_HAIR_HIT_BOX_IN_PX
        )
    }

    onMouseDrag = (event: paper.ToolEvent): void => {
        if (this.toolPanning(event)) return

        if (this.selectedCrossHair) {
            const newPosition = this.selectedCrossHair.position.add(event.delta)
            if (this.selectedCrossHair.data.pathSegment) {
                const selectedSegment = this.selectedCrossHair.data.pathSegment
                if (selectedSegment) selectedSegment.point = newPosition
                this.path?.segments.length === 2 &&
                    this.name === Measure.NAME &&
                    this.mediator.mediate('tools', {
                        measurement: applyScaleFactorToPathLength({
                            pxValue: this.path.length,
                            scaleFactor: this.scaleFactor,
                            dpi: this.getActiveDocumentChunk()?.dpi ?? null,
                            xCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_x ?? 1,
                            yCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_y ?? 1,
                            coordinates: this.paperPoints,
                            pdfScale: this.getActiveDocumentChunk()?.pdf_scale ?? 1,
                        }),
                    })
            }
            this.selectedCrossHair.position = newPosition
        }
    }

    onMouseMove = (event) => {
        const hitTargets = this.points
            ? this.points.filter((crosshair) => this.measurementPointHitTest(crosshair.position, event.point))
            : null
        if (hitTargets && hitTargets.length > 0) {
            this.setState('common', { cursor: Cursors.MOVE })
        } else {
            this.setState('common', { cursor: this.cursor })
        }
    }

    onMouseUp = (): void => {
        this.setState('common', { cursor: Measure.CURSOR }) // resets the cursor in case panning was done
        if (this.selectedCrossHair) {
            this.selectedCrossHair = null
        }
    }

    onMouseDown = (event: paper.ToolEvent): void => {
        if (this.isPanningClick(event)) return

        // Force calibraiton angle
        const newPoint =
            this.name === Calibrate.NAME
                ? this.points.length === 1
                    ? this.calculateFixedAnglePoint(this.points[0].position, event.point)
                    : event.point
                : event.point

        const hitTargets = this.points
            ? this.points.filter((crosshair) => this.measurementPointHitTest(crosshair.position, newPoint))
            : null
        if (hitTargets && hitTargets.length > 0) {
            this.selectedCrossHair = hitTargets[0]
        } else {
            // if there are already 2 points, clear the path
            if (this.path?.segments.length === 2) {
                this.cancel()
                return
            }
            // create a new segment if segment doesn't exist
            if (!this.path) {
                this.path = new this.paper.Path()
                this.path.strokeColor = new this.paper.Color(this.measureStrokeColor)
                this.path.strokeWidth = this.measureStrokeWidth
                this.path.strokeScaling = false // maintain the same stroke width regardless of zoom; see: http://paperjs.org/reference/style/#strokescaling
                this.path.locked = true

                const scaleFactor = this.setScaleFromPointClick(newPoint)
                if (scaleFactor) this.scaleFactor = scaleFactor
            }

            const crossHair = this.constructCrosshairMarker(newPoint)
            this.paperPoints.push([newPoint.x, newPoint.y])
            this.points.push(crossHair)
            crossHair.data.pathSegment = this.path.add(crossHair.position)

            // only set the measurement when there are 2 points
            this.path?.segments.length === 2 &&
                this.name === Measure.NAME &&
                this.mediator.mediate('tools', {
                    measurement: applyScaleFactorToPathLength({
                        pxValue: this.path.length,
                        scaleFactor: this.scaleFactor,
                        dpi: this.getActiveDocumentChunk()?.dpi ?? null,
                        xCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_x ?? 1,
                        yCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_y ?? 1,
                        coordinates: this.paperPoints,
                        pdfScale: this.getActiveDocumentChunk()?.pdf_scale ?? 1,
                    }), // take the canvas coordinate length and divide by the scale factor. this is possible because we maintain the underlying image size in pixels
                })
        }
    }
}

export default Measure

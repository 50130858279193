import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import isArray from 'lodash/isArray'
import { Region } from '../../models/region'
import { RootState } from '../../stores'
import { checkNameByType } from '../utils/helpers'

export type RegionFormFields = {
    name: string | undefined
    type: string | undefined
    scale: string | undefined
}

export type RegionState = {
    paperId: paper.Path['id'] | null
    regions: Region[]
    activeRegionId: number | null
    regionFormState: RegionFormFields
}

export const initialRegionFormState: RegionFormFields = {
    name: undefined,
    type: undefined,
    scale: undefined,
}

export const initialRegionState: RegionState = {
    paperId: null,
    activeRegionId: null,
    regionFormState: initialRegionFormState,
    regions: [],
}

const regionSlice = createSlice({
    name: 'region',
    initialState: initialRegionState,
    reducers: {
        updateRegionFormState: (state: RegionState, action: PayloadAction<Partial<RegionFormFields>>) => {
            return {
                ...state,
                regionFormState: {
                    ...state.regionFormState,
                    ...action.payload,
                    name: checkNameByType(action.payload.type!, action.payload.name || '')
                        ? (action.payload.name as string)
                        : '',
                },
            }
        },
        updatePaperRegion: (state: RegionState, action: PayloadAction<RegionState['paperId']>) => {
            state.paperId = action.payload
        },
        resetRegionFormState: (state: RegionState) => {
            return { ...state, regionFormState: initialRegionFormState, paperId: null, activeRegionId: null }
        },
        addRegionToStore: (state: RegionState, action: PayloadAction<Region>) => {
            state.regions = [...state.regions, action.payload]
        },
        updateRegions: (state: RegionState, action: PayloadAction<Region | Region[] | null>) => {
            if (!action.payload) {
                state.regions = initialRegionState.regions
            } else if (isArray(action.payload)) {
                state.regions = action.payload
            } else {
                state.regions = [action.payload]
            }
        },
        removeRegionById: (state: RegionState, action: PayloadAction<number>) => {
            state.regions = state.regions.filter((region) => region.id !== action.payload)
        },
        updateSingleRegion: (state: RegionState, action: PayloadAction<Region>) => {
            state.regions = state.regions.map((r) => (r.id === action.payload.id ? action.payload : r))
        },
        setActiveRegion: (state: RegionState, action: PayloadAction<number | null>) => {
            if (action.payload === null) {
                state.activeRegionId = null
                state.regionFormState = initialRegionFormState
            } else {
                const region = state.regions.find((r) => r.id === action.payload)
                if (region) {
                    state.activeRegionId = action.payload
                    state.regionFormState.name = region.name
                    state.regionFormState.type = region.type
                    state.regionFormState.scale = region.scale
                }
            }
        },
    },
})

export const {
    updateRegionFormState,
    updatePaperRegion,
    resetRegionFormState,
    updateRegions,
    addRegionToStore,
    removeRegionById,
    updateSingleRegion,
    setActiveRegion,
} = regionSlice.actions

export default regionSlice

export const selectAllRegions = createSelector(
    ({ IMUP: { region } }: RootState) => region.regions,
    (state) => state
)

export const selectActiveRegionId = createSelector(
    ({ IMUP: { region } }: RootState) => region.activeRegionId,
    (state) => state
)

import { ActionManager, ExecuteCodeAction, Scene } from '@babylonjs/core'
//TODO: Implement gestures for these keyboard interactions https://portal.myparadigm.com/browse/BLUEPRINTS-7291
enum keyCode {
    CTRL = 17,
    ALT = 18,
    I = 73,
    L = 76,
    U = 85,
}

export class InteractionManager {
    private static interactionManger: InteractionManager

    protected scene: Scene | null = null
    protected keyboardMapping: Map<string, boolean> = new Map<string, boolean>()

    register(scene: Scene): void {
        this.scene = scene
        this.initSceneActionManager(this.scene)
        this.initShortcutKeyboard(this.scene)
    }

    static getInstance(): InteractionManager {
        if (!this.interactionManger) {
            this.interactionManger = new InteractionManager()
        }
        return this.interactionManger
    }

    /**
     * Creates an action manager and associates it
     * with the scene object
     * @param  {Scene} scene the babylon scene object
     * @returns void
     */
    protected initSceneActionManager(scene: Scene): void {
        if (!scene.actionManager) {
            scene.actionManager = new ActionManager(scene)
        }
    }

    /**
     * Adds keyboard shortcut triggers to scene action manager.
     * Creates scene action manager if undefined.
     *
     * @param  {Scene} scene
     * @returns void
     */
    protected initShortcutKeyboard(scene: Scene): void {
        scene.actionManager.registerAction(
            new ExecuteCodeAction(ActionManager.OnKeyDownTrigger, (evt) => {
                this.keyboardMapping[evt.sourceEvent.keyCode] = evt.sourceEvent.type === 'keydown'
            })
        )

        scene.actionManager.registerAction(
            new ExecuteCodeAction(ActionManager.OnKeyUpTrigger, (evt) => {
                this.keyboardMapping[evt.sourceEvent.keyCode] = evt.sourceEvent.type === 'keydown'
            })
        )

        // Adds callback function on each render to detect if debug menu needs to be opened or closed
        scene.registerAfterRender(() => {
            if (
                this.keyboardMapping[keyCode.CTRL] &&
                this.keyboardMapping[keyCode.ALT] &&
                this.keyboardMapping[keyCode.I]
            ) {
                if (scene.debugLayer.isVisible()) {
                    scene.debugLayer.hide()
                } else {
                    scene.debugLayer.show({
                        embedMode: true,
                    })
                }
                // Here I set keyPressed to false because I lose the focus when tool is show. So the event 'OnKeyUpTrigger' is not triggered
                this.keyboardMapping[keyCode.CTRL] = false
                this.keyboardMapping[keyCode.ALT] = false
                this.keyboardMapping[keyCode.I] = false
            }
        })
    }
}

import { PayloadAction } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import { call, select, StrictEffect } from 'redux-saga/effects'
import managers from '../../lib/managers'
import { BabylonManager } from '../../lib/managers/BabylonManager'
import { Highlight } from '../../lib/toolBoxes/3D'
import { IMUPMappingsSelector } from '../../slices/common'
import { ThreeDToTwoDRecord } from '../../types'

export function* getThreeDIdFromOpeningIds(
    mappings: ThreeDToTwoDRecord,
    drawableIds: number[]
): Generator<Array<string>, Array<string>, Array<string>> {
    return yield Object.keys(mappings).filter((id) => {
        const openingIds = mappings[id] ? mappings[id].flatMap((ids) => ids.openingId) : []
        return drawableIds.some((dId) => openingIds.includes(dId))
    })
}

export function* findMeshIdBasedOnOpeningId(
    mappings: ThreeDToTwoDRecord,
    drawableId: number
): Generator<string | undefined, string | undefined, string | undefined> {
    return yield Object.keys(mappings).find((id) => mappings[id].some((ids) => isEqual(ids.openingId, drawableId)))
}

export function* handleToggleDrawableOnHover3D(
    action: PayloadAction<{ drawable_id: number }>
): Generator<
    StrictEffect,
    void,
    Highlight & ThreeDToTwoDRecord & string & (BabylonManager | null) & (string | undefined)
> {
    const manager: BabylonManager | null = yield call(managers.get3DManager)

    if (isNull(manager)) return

    const highlightTool: Highlight = yield call(manager.getTool, Highlight.NAME)
    const threeDToTwoDRecord: ThreeDToTwoDRecord = yield select(IMUPMappingsSelector)

    const resultantThreeDId: string | undefined = yield call(
        findMeshIdBasedOnOpeningId,
        threeDToTwoDRecord,
        action.payload.drawable_id
    )

    if (!isUndefined(resultantThreeDId)) {
        yield call(highlightTool.toggleMeshMaterial, resultantThreeDId)
    }
}

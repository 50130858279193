import { DRAWABLE_TYPES } from '../../../../../../shared/constants/drawable-types'
import { MouseButtonCodes, PaperToolConfig } from '../../../../../types'
import SelectTool from '../select/Select.tool'

/**
 * Label.tool.tsx
 * Creates a label -- mainly used for joist lines
 */
export class Label extends SelectTool {
    static NAME = 'LABEL'
    private static LABEL_TEXT_FONT_SIZE_MULTIPLIER = 0.8
    public static LABEL_RECTANGLE_GROUP_CHILD_INDEX = 1

    private readonly LABEL_SHADOW = {
        color: new this.paper.Color(0, 0, 0),
        blur: 5,
        offset: new this.paper.Point(2, 3),
    }

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Label.NAME
    }

    /**
     * insertLabel
     * @param  {paper.Path} path: The path on which the label sits
     * @param  {string} title: The text of the label
     * @param  {number} rectSize: The size of the bounding rectangle
     * @param  {x: number, y: number} labelTextOffsets The x and y offsets of the text within the label
     * @returns paper.Group A group that contains the original path, a label box and a text label centered on the path.
     */
    public insertLabel = (
        path: paper.Path,
        title: string,
        rectSize: number,
        labelTextOffsets: { x: number; y: number }
    ): paper.Group => {
        const rect: paper.Path.Rectangle = new this.paper.Path.Rectangle(
            path.bounds.center.subtract(new this.paper.Point(rectSize, rectSize)),
            path.bounds.center.add(new this.paper.Point(rectSize, rectSize))
        )

        rect.fillColor = new this.paper.Color('white')
        rect.shadowColor = this.LABEL_SHADOW.color
        rect.shadowBlur = this.LABEL_SHADOW.blur
        rect.shadowOffset = this.LABEL_SHADOW.offset

        // lock possibility to click on header label square only
        rect.locked = path?.data?.drawing_type === DRAWABLE_TYPES.HEADER

        const pointText = new this.paper.PointText(
            rect.bounds.leftCenter.add(new this.paper.Point(labelTextOffsets.x, labelTextOffsets.y))
        )
        pointText.fontSize = rectSize * Label.LABEL_TEXT_FONT_SIZE_MULTIPLIER
        pointText.locked = true

        // Need to add space for single character titles to center -- AreaText is still "under construction"
        pointText.content = title.length > 1 ? `${title}` : ` ${title}`

        // We bring the labels to the front to ensure they are not covered by any lines or area types in any case
        rect.bringToFront()
        pointText.bringToFront()

        rect.onClick = (event: paper.ToolEvent) => {
            // allow only a mouse left to click to toggle item
            if (event['event']['button'] !== MouseButtonCodes.Left) return

            if (path.data.drawing_type === DRAWABLE_TYPES.HEADER) {
                this.toggleItemOnClick(path)
            }
        }

        const labelGroup = new this.paper.Group([path, rect, pointText])

        return labelGroup
    }
}

export default Label

import { FETCH_USER_PERMISSIONS_SUCCESS, FETCH_USER_SETTINGS_SUCCESS, UPDATE_SCROLL_STEP } from '../actions/user'
import { UserData, UserSettings } from '../models/user'

const initialState: UserData = {
    permissions: undefined,
    settings: {
        scrollStep: 20,
    },
}

export const userReducer = (state: UserData = initialState, action): UserData => {
    switch (action.type) {
        case FETCH_USER_PERMISSIONS_SUCCESS:
            return {
                ...state,
                permissions: action.payload.userPermissions,
            }

        case FETCH_USER_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ...action.payload.userSettings,
                },
            }

        case UPDATE_SCROLL_STEP:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    scrollStep: action.payload.scrollStep,
                },
            }

        default:
            return state
    }
}

export const getUserPermissions = (state: UserData): UserData['permissions'] => state.permissions
export const getScrollStep = (state: UserData): UserSettings['scrollStep'] => state.settings.scrollStep

import { createAction } from '@reduxjs/toolkit'
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects'
import { getTakeoffData } from '../../../api/takeoff-api'
import { getFormattedDatetime } from '../../../utils/datetime'
import { setTakeoffData } from '../../slices/2D'
import { setLastLoadingTime, setPageDataLoading } from '../../slices/loading'
import { PreparedTakeoff } from '../../../models/takeoff'

export const fetchTakeoffData = createAction<{ projectId: number; timeout: number }>('fetchTakeoffData')

const keyGroup1 = 'buildingName'
const keyGroup2 = 'corePackage'

// Function to group the array by 'field1' and 'field2'
function groupByTwoFields(array, field1, field2) {
    const groupedByField1 = array.reduce((result, item) => {
        const key1 = item[field1]
        if (!result[key1]) {
            result[key1] = {}
        }
        return result
    }, {})

    return array.reduce((result, item) => {
        const key1 = item[field1]
        const key2 = item[field2]
        if (!result[key1][key2]) {
            result[key1][key2] = []
        }
        result[key1][key2].push(item)
        return result
    }, groupedByField1)
}

function finalPrepareTakeoffData(groupedArray) {
    const groupedUpdatedTakeoffData: {
        description: string
        quantity?: string
        sku?: string
        isBuilding?: boolean
        isCorePackage?: boolean
    }[] = []

    Object.keys(groupedArray).forEach((buildingName) => {
        // get the building name for row
        groupedUpdatedTakeoffData.push({ description: buildingName, isBuilding: true })

        Object.keys(groupedArray[buildingName]).forEach((corePackage) => {
            // get the core package name for row
            groupedUpdatedTakeoffData.push({ description: corePackage, isCorePackage: true })

            groupedArray[buildingName][corePackage].forEach((item) => {
                // get the needed data for row
                groupedUpdatedTakeoffData.push({
                    description: item.description,
                    quantity: item.quantity,
                    sku: item.sku,
                })
            })
        })
    })

    return groupedUpdatedTakeoffData
}

function groupByMeasurementFile(data) {
    return data.reduce((acc, obj) => {
        const { measurementFile, ...rest } = obj
        if (!acc[measurementFile]) {
            acc[measurementFile] = []
        }
        acc[measurementFile].push(rest)
        return acc
    }, {})
}

export function* handleFetchTakeoffData({ payload }: ReturnType<typeof fetchTakeoffData>) {
    const { projectId, timeout } = payload

    if (!projectId) return

    yield put(setPageDataLoading(true))

    const newTakeoffData: any = yield call(getTakeoffData, projectId, timeout)

    if (newTakeoffData) {
        const preparedNewTakeoffData = yield newTakeoffData.data.AllParts.filter((part) => part.Quantity > 0).map(
            (part) => {
                return {
                    buildingName: part.BuildingName,
                    corePackage: part.CorePackage,
                    measurementFile: part.MeasurementFile,
                    description: part.Description,
                    quantity: part.Quantity,
                    sku: part.SKU,
                }
            }
        )

        const takeoffDataGropedByMeasurementFile = yield groupByMeasurementFile(preparedNewTakeoffData)

        let groupDataInsideMeasureFiles = {}
        Object.keys(takeoffDataGropedByMeasurementFile).forEach((measureFileName, index) => {
            groupDataInsideMeasureFiles[measureFileName] = groupByTwoFields(
                takeoffDataGropedByMeasurementFile[measureFileName],
                keyGroup1,
                keyGroup2
            )
        })

        let finalGroupedData: PreparedTakeoff[] = []
        Object.keys(groupDataInsideMeasureFiles).forEach((measureFileName, index) => {
            const res = finalPrepareTakeoffData(groupDataInsideMeasureFiles[measureFileName])
            finalGroupedData.push({ description: measureFileName, isMeasureFile: true }, ...res)
        })

        const lastUpdatedTime: string = getFormattedDatetime(Date.now(), 'YYYY-MM-DD H:mm:ss')
        yield put(setLastLoadingTime(lastUpdatedTime))
        yield put(setTakeoffData({ projectId, takeoffData: finalGroupedData }))
    }

    yield put(setPageDataLoading(false))
}

export function* watchForFetchTakeoffData(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(fetchTakeoffData.type, handleFetchTakeoffData)
}

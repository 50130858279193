import defaultTo from 'lodash/defaultTo'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import { call, CallEffect, PutEffect, SelectEffect } from 'redux-saga/effects'
import { convertAbsoluteDistanceToFeetAndInches } from '../../../components/markup/utils/helpers'
import { ActiveFloor } from '../../../models/activeFloor'
import { DRAWING_TYPES } from '../../../shared/constants/drawable-types'
import { DEFAULT_SCALE_FACTOR } from '../../../shared/constants/scales'
import { drawableBackgroundAndBorderColor } from '../../../shared/services/drawable-color-services'
import { isHeaderAndBeamGroup } from '../../../shared/services/drawable-groups-service'
import store from '../../../stores'
import { convertScaleFactorLabelEnumToDecimal } from '../../../utils/calculations/scaleConversion/scaleConversion'
import { isNonAreaJoistLine } from '../../../utils/project/project-helper-functions'
import PaperManager from '../../lib/managers/PaperManager'
import { Color, Count, Label, PathTool, PolygonTool, RadiusLine, Workspace } from '../../lib/toolBoxes/2D'
import addSelectFunctionalityToDrawable from '../../lib/utils/functionality-bindings/addSelectFunctionalityToDrawable'
import { GeometricDrawable } from '../../slices/geometry'
import { Coordinates2D, IMUP2DDrawableLocation, ItemScale, ITool, MouseButtonCodes, REGION_ENUMS } from '../../types'
import { cutoutAreas } from './cutoutAreas'
import addMetadataToPath from './data-prep/addMetadataToPath'
import { updateDoubleJoistAction, updateJoistLabelGroupStyle } from './updateDoubleJoistLines'

type DrawShapeByTypeYield =
    | CallEffect
    | CallEffect<
          | string
          | paper.Color
          | paper.Path
          | void
          | paper.Raster
          | paper.Group
          | ITool
          | PaperManager
          | null
          | Coordinates2D
      >
    | PutEffect
    | SelectEffect

type DrawShapeByTypeNext = string &
    paper.Color &
    (paper.Raster | null) &
    paper.Path &
    paper.Group &
    ITool &
    PaperManager &
    GeometricDrawable

export default function* drawShapeByType(
    location: IMUP2DDrawableLocation,
    colorTool: Color,
    countTool: Count,
    pathTool: PathTool,
    polygonTool: PolygonTool,
    radiusLineTool: RadiusLine,
    workspaceTool: Workspace,
    labelTool: Label,
    areaOpacity: number,
    lineOpacity: number,
    activeFloor: ActiveFloor | null,
    regionPaths?: paper.Path[]
): Generator<DrawShapeByTypeYield, paper.Path, DrawShapeByTypeNext> {
    const { coordinates, shapeType, drawing_type, cutouts, settings, additionalData, ai_suggestion_id } = location

    // determine color by location settings and drawing_type
    const colorValueFromConstants: string = yield call(
        drawableBackgroundAndBorderColor,
        defaultTo(drawing_type, ''),
        settings.name,
        settings.selection,
        settings.location,
        additionalData,
        settings.material,
        ai_suggestion_id
    )

    if (!regionPaths) {
        regionPaths = workspaceTool.getItemsWithCriteria(
            'data',
            (data: any) => data.shapeType === REGION_ENUMS.TYPE
        ) as paper.Path[]
    }

    // create color with color tool
    const shapeColor: paper.Color = yield call(colorTool.createColor, colorValueFromConstants)

    // Determine if this drawable is inside a region so we can scale it appropriately
    let thisRegionGroup: paper.Path | null = null
    for (const regionPath of regionPaths) {
        for (const c of location.coordinates) {
            if (!regionPath.contains(workspaceTool.generatePoint(c))) {
                break
            }
            thisRegionGroup = regionPath
        }
    }

    const scaleFactor = thisRegionGroup?.data?.scale
        ? thisRegionGroup.data.scale
        : activeFloor?.scale_factor ?? DEFAULT_SCALE_FACTOR
    const currentScaleFactor = convertScaleFactorLabelEnumToDecimal(scaleFactor)

    // create a paper.Path based on the drawing type - point, area, or section/default
    let path: paper.Path
    switch (shapeType) {
        case DRAWING_TYPES.POINT:
            const [center] = coordinates
            path = yield call(countTool.createPoint, center, currentScaleFactor * ItemScale.POINT)

            shapeColor.alpha = areaOpacity

            path.strokeColor = shapeColor
            path.fillColor = shapeColor
            path.strokeWidth = 0 // hide since shape is filled
            break
        case DRAWING_TYPES.AREA:
            path = yield call(polygonTool.createPolygon, coordinates)

            if (!isEmpty(cutouts)) {
                path = yield call(cutoutAreas, path, cutouts, polygonTool)
            }

            shapeColor.alpha = areaOpacity

            path.strokeColor = shapeColor
            path.fillColor = shapeColor
            path.strokeWidth = 0 // hide since shape is filled
            break
        case DRAWING_TYPES.RADIUS:
            // Radius Line coordinates are stored in the following order: start, center, end
            const startPoint = coordinates[0]
            const centerPoint = coordinates[1]
            const endPoint = coordinates[2]

            path = yield call(radiusLineTool.createArc, startPoint, centerPoint, endPoint)
            shapeColor.alpha = lineOpacity

            const radiusRaster: paper.Raster | null = yield call(workspaceTool.getPlanRaster)
            let radiusStrokeWidthScale = 100
            if (radiusRaster) {
                radiusStrokeWidthScale = workspaceTool.calculateStrokeWidthBasedOnRaster(radiusRaster)
            }

            path.strokeColor = shapeColor
            path.strokeWidth = radiusStrokeWidthScale * ItemScale.LINE
            break

        case DRAWING_TYPES.SECTION:
        default:
            path = yield call(pathTool.createPath, coordinates)

            shapeColor.alpha = lineOpacity

            const raster: paper.Raster | null = yield call(workspaceTool.getPlanRaster)
            let strokeWidthScale = 100
            if (raster) {
                // Scale the lines based on the size of the image we are working with
                strokeWidthScale = workspaceTool.calculateStrokeWidthBasedOnRaster(raster)
            }

            path.strokeColor = shapeColor
            path.strokeWidth = strokeWidthScale * ItemScale.LINE
            break
    }

    // add metadata to paths
    yield call(addMetadataToPath, path, scaleFactor, location)
    yield call(addSelectFunctionalityToDrawable, path)

    // hide joist drawing types and add labels
    if (isNonAreaJoistLine(path)) {
        const planRaster = workspaceTool.getPlanRaster()
        // Derived from the above stroke width logic for paths
        // using 1/6 to make sure that the joist lines label is only as wide as
        // the joist line itself.
        // No overlapping of joist lines labels
        const strokeWidth = 6
        const labelRectSize = planRaster ? planRaster.width / 100 / strokeWidth : 100 / strokeWidth
        const group: paper.Group = yield call(
            labelTool.insertLabel,
            path,
            String(additionalData.joist_length) ?? '',
            labelRectSize,
            {
                x: 0.5 * labelRectSize,
                y: 0.35 * labelRectSize,
            }
        )
        group.data = path.data
        path.data = null
        // Make sure that the joist line path is not clickable
        path.locked = true

        group.visible = false

        // On initial render of a joist line
        // check whether it is a double joist
        // and if it is then render its label correctly
        updateJoistLabelGroupStyle(group, additionalData, workspaceTool)

        // On each double joist label group add an on Click
        // event that will toggle whether it is
        // a double joist
        group.children[Label.LABEL_RECTANGLE_GROUP_CHILD_INDEX].onClick = (event: paper.MouseEvent) => {
            if (event['event']['button'] === MouseButtonCodes.Left) {
                store.dispatch(
                    updateDoubleJoistAction({
                        id: group.data.opening_location_id,
                        additionalData: group.data.additionalData,
                    })
                )
            }
        }
    }

    // Add length to headers
    if (
        isHeaderAndBeamGroup(drawing_type, settings.type) &&
        !isUndefined(settings.linear_total) &&
        !isNull(settings.linear_total)
    ) {
        const { feet, inches } = convertAbsoluteDistanceToFeetAndInches(settings.quantity)
        const labelRectSize = 17

        const group: paper.Group = yield call(labelTool.insertLabel, path, `${feet}' ${inches}''`, labelRectSize, {
            x: 0.25 * labelRectSize,
            y: 0.45 * labelRectSize,
        })
        group.visible = true
        group.locked = false
        group.data = path.data
    }

    return path
}

import { Mesh } from '@babylonjs/core'
import DrawableColorsMaterialGenerator from '../../../../../components/IMUP3DComponent/babylon/materialOptions/generators/DrawableColorsMaterialGenerator'
import PBRMaterialGenerator from '../../../../../components/IMUP3DComponent/babylon/materialOptions/generators/PBRMaterialGenerator'
import { BabylonToolConfig, IMaterialGenerator, IModel, MaterialOptionsType } from '../../../../../types'
import BaseTool from '../babylonTool/BabylonTool'

export class CustomMaterial extends BaseTool {
    static NAME = 'CUSTOM_MATERIAL'

    private static AVAILABLE_GENERATORS = [MaterialOptionsType.PBR, MaterialOptionsType.DRAWABLE_COLORS]
    private materialGenerators: { [key: string]: IMaterialGenerator } = {}

    constructor(config: BabylonToolConfig) {
        super(config)
        this.registerActivationFunctions([])
        this.name = CustomMaterial.NAME
    }

    /**
     * Create and inits a material generator of correct type and populates it in the custom material tool
     * @param type string of material options enum to indicate the type of generator to make
     */
    private createAndInitMaterialGenerator = async (
        type: MaterialOptionsType,
        structure: IModel
    ): Promise<IMaterialGenerator | null> => {
        let materialGenerator: IMaterialGenerator | null = null
        switch (type) {
            case MaterialOptionsType.PBR:
                materialGenerator = new PBRMaterialGenerator()
                await materialGenerator?.initGenerator(this.scene, structure)
                this.materialGenerators[type] = materialGenerator
                break
            case MaterialOptionsType.DRAWABLE_COLORS:
                materialGenerator = new DrawableColorsMaterialGenerator()
                await materialGenerator?.initGenerator(this.scene)
                this.materialGenerators[type] = materialGenerator
                break
            default:
                break
        }
        return materialGenerator
    }

    /**
     * Preload all materials/Textures based on the material options populated
     * @param {IModel} structure the structures for the model to be rendered.
     */
    public prepareGenerators = async (structure: IModel): Promise<void> => {
        await Promise.all(
            CustomMaterial.AVAILABLE_GENERATORS.map((key) => this.createAndInitMaterialGenerator(key, structure))
        )
    }

    /**
     * Apply the materials to meshes of a given generator type
     * @param {MaterialOptionsType} generatorType the generator type to apply to meshes
     * @param {Mesh[] | undefined} meshes optional meshes to apply material to
     */
    public applyMaterialOptionsToMeshes = (generatorType: MaterialOptionsType, meshes?: Mesh[]): void => {
        const generator = this.materialGenerators[generatorType]
        if (generator) {
            generator.applyMaterial(meshes)
        }
    }
}

export default CustomMaterial

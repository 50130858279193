import { setActiveDrawableGroupFromID } from '../../../../actions/drawable'
import store from '../../../../stores'
import { editingActive, selectedPaperItem, updateSelectedCoordinates } from '../../../slices/2D'
import { IMUP2DCoordinatesToUpdate } from '../../../types'

function save2DDrawable(): (coordinatesToUpdate: IMUP2DCoordinatesToUpdate) => void {
    return function saveDrawable(this: paper.Path, coordinatesToUpdate) {
        store.dispatch(updateSelectedCoordinates(coordinatesToUpdate))
    }
}

function select2DDrawable(): () => void {
    return function selectDrawable(this: paper.Path) {
        store.dispatch(
            setActiveDrawableGroupFromID(
                this.id,
                this.data.drawable_id,
                this.data.opening_group_id,
                this.data.opening_location_id
            )
        )
        store.dispatch(editingActive(false))
    }
}

function deselect2DDrawable(): () => void {
    return function deselectDrawable(this: paper.Path) {
        store.dispatch(
            selectedPaperItem({
                selectedItem: null,
                activeDrawableId: null,
                activeOpeningLocationId: null,
                openingGroupId: null,
            })
        )
    }
}

export default function addSelectFunctionalityToDrawable(drawable: paper.PathItem): void {
    drawable.data.onSave = save2DDrawable().bind(drawable)
    drawable.data.onSelect = select2DDrawable().bind(drawable)
    drawable.data.onDeselect = deselect2DDrawable().bind(drawable)
}

import { all, call, put } from 'redux-saga/effects'
import { deleteProjectContextMarkups } from '../../../api/projects-api'
import { removeToolObjectsByToolId, setActiveToolObjectId } from '../../slices/tools'
import PaperManager from '../../lib/managers/PaperManager'
import managers from '../../lib/managers'
import { Select } from '../../lib/toolBoxes/2D'
import { clearActiveForm } from '../../slices/forms'

export function* deleteToolObject(items: paper.Item[]) {
    try {
        const manager: PaperManager = yield call(managers.get2DManager)

        if (!manager) return

        const selectTool = yield call(manager.getTool, Select.NAME)

        const contextMarkupIds: number[] = items.map((item) => item.data.toolObject.id)

        const response = yield all(contextMarkupIds.map((id) => deleteProjectContextMarkups(id)))

        if (!response) {
            throw new Error('Deletion was unsuccessful')
        }

        // remove items from blueprint
        yield all(items.map((item) => call(item.remove.bind(item))))

        // remove items from store
        yield put(removeToolObjectsByToolId(contextMarkupIds))

        // unset active tool object to null, because we deleted it
        yield put(setActiveToolObjectId(null))

        yield put(clearActiveForm())

        // exit selected mode, remove handlers
        yield call(selectTool.exitSelectMode)
    } catch (e) {
        console.log('Error on deleting Tool Object', e)
    }
}

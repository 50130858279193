import { Color3, Mesh, StandardMaterial } from '@babylonjs/core'
import { BabylonToolConfig } from '../../../../../types'
import { BabylonManager } from '../../../../managers/BabylonManager'
import BabylonTool from '../babylonTool/BabylonTool'

export class Highlight extends BabylonTool {
    static NAME = 'HIGHLIGHT'

    // Highlighted material in this case only applies when a mesh opening group is being
    // hovered over
    private static HIGHLIGHT_MATERIAL_TIME_OFFSET_INCREMENT: number = 1 / 60
    private static HIGHLIGHT_MATERIAL_COLOR_PERIOD: number = 90 * Highlight.HIGHLIGHT_MATERIAL_TIME_OFFSET_INCREMENT

    private timeOffset: number = 0
    private highlightMaterial: StandardMaterial
    private meshMaterialCache: Record<number, { mesh: Mesh; materialName: string }> = {}
    private meshMaterialBaselineColorR: number = BabylonManager.SCENE_DEFAULT_AMBIENT_COLOR.r
    private meshMaterialBaselineColorG: number = BabylonManager.SCENE_DEFAULT_AMBIENT_COLOR.g
    private meshMaterialBaselineColorB: number = BabylonManager.SCENE_DEFAULT_AMBIENT_COLOR.b

    private meshMaterialTargetColorR: number = BabylonManager.SCENE_DEFAULT_AMBIENT_COLOR.r
    private meshMaterialTargetColorG: number = BabylonManager.SCENE_DEFAULT_AMBIENT_COLOR.g
    private meshMaterialTargetColorB: number = BabylonManager.SCENE_DEFAULT_AMBIENT_COLOR.b

    constructor(config: BabylonToolConfig) {
        super(config)
        this.registerActivationFunctions([])
        this.name = Highlight.NAME

        // Material configuration
        this.highlightMaterial = new StandardMaterial('highlightMaterial', this.scene)
        this.highlightMaterial.useLogarithmicDepth = true
    }

    /**
     * Calculate the R/G/B value at current time step using the base line target
     * and whether or not the value is intended to go to 1
     * Uses a triangular function if the value is going to 1 the it goes from the baseline value
     * to 1 and if it does not go to one it goes from the baseline to zero
     * desmos visualization with all parameters editable https://www.desmos.com/calculator/1w9x3iq36g
     * @param baseline the baseline component value
     */
    private calculateMaterialColorComponent(baseline: number, target?: number) {
        const coeffNumerator: number = target ? target - baseline : -1 * baseline
        const yTranslation: number = baseline
        const coeff: number = coeffNumerator / Highlight.HIGHLIGHT_MATERIAL_COLOR_PERIOD
        const mainFunction: number =
            Highlight.HIGHLIGHT_MATERIAL_COLOR_PERIOD -
            Math.abs(
                (this.timeOffset % (2 * Highlight.HIGHLIGHT_MATERIAL_COLOR_PERIOD)) -
                    Highlight.HIGHLIGHT_MATERIAL_COLOR_PERIOD
            )
        return yTranslation + coeff * mainFunction
    }
    /**
     * Uses saw tooth function to calculate the new material color
     * only in use if the tool is activated and the mesh is toggled to use
     * the highlighted material
     */
    private calculateAndChangeNewMaterialColor = () => {
        this.timeOffset += Highlight.HIGHLIGHT_MATERIAL_TIME_OFFSET_INCREMENT
        // Oscillate between the dim color of the surroundings and
        // a deep red color using a saw tooth function (linear interpolation)
        const newColor = Color3.FromArray([
            this.calculateMaterialColorComponent(this.meshMaterialBaselineColorR, this.meshMaterialTargetColorR),
            this.calculateMaterialColorComponent(this.meshMaterialBaselineColorG, this.meshMaterialTargetColorG),
            this.calculateMaterialColorComponent(this.meshMaterialBaselineColorB, this.meshMaterialTargetColorB),
        ])
        this.highlightMaterial.emissiveColor = newColor
    }

    /**
     * DANGER DANGER DANGER This function uses internal private parameters of the scene babylonjs
     * object and relies on babylonjs implementation of observables and callbacks, this should be
     * removed as soon as babylonjs implements a check to see if a callback is registered
     * @returns boolean indicating that the class animation callback is already registered to
     * on before render
     */
    private isRenderAnimationFunctionBound = (): boolean => {
        const res = this.scene['onBeforeRenderObservable']['_observers'].filter((observer) => {
            return observer.callback === this.calculateAndChangeNewMaterialColor
        })
        return res.length !== 0
    }

    /**
     * Register the functions that flash material colors when the tool
     * is activated
     * @returns deactivation function
     */
    private registerMaterialFlashAnimation = (): void => {
        const isAnimationFunctionCallbackPresent = this.isRenderAnimationFunctionBound()
        if (!isAnimationFunctionCallbackPresent) {
            this.scene.registerBeforeRender(this.calculateAndChangeNewMaterialColor)
        }
    }

    private resetAnimationAndParams = (): void => {
        this.meshMaterialBaselineColorR = BabylonManager.SCENE_DEFAULT_AMBIENT_COLOR.r
        this.meshMaterialBaselineColorG = BabylonManager.SCENE_DEFAULT_AMBIENT_COLOR.g
        this.meshMaterialBaselineColorB = BabylonManager.SCENE_DEFAULT_AMBIENT_COLOR.b

        this.meshMaterialTargetColorR = BabylonManager.SCENE_DEFAULT_AMBIENT_COLOR.r
        this.meshMaterialTargetColorG = BabylonManager.SCENE_DEFAULT_AMBIENT_COLOR.g
        this.meshMaterialTargetColorB = BabylonManager.SCENE_DEFAULT_AMBIENT_COLOR.b
        this.timeOffset = 0
        this.scene.unregisterBeforeRender(this.calculateAndChangeNewMaterialColor)
    }

    /**
     * Toggle mesh material to highlight material or base material
     * @param meshId the mesh id that needs to change
     */
    public toggleMeshMaterial = (meshId: string) => {
        if (Object.keys(this.meshMaterialCache).length === 0) {
            this.registerMaterialFlashAnimation()
        }

        const meshes = this.scene.getMeshesById(meshId) as Mesh[]
        if (meshes) {
            meshes.forEach((mesh) => {
                if (!this.meshMaterialCache[mesh.uniqueId]) {
                    this.meshMaterialCache[mesh.uniqueId] = {
                        mesh,
                        materialName: mesh.material!.name,
                    }

                    const stdMat = mesh.material! as StandardMaterial

                    this.meshMaterialTargetColorR = stdMat.emissiveColor.r
                    this.meshMaterialTargetColorG = stdMat.emissiveColor.g
                    this.meshMaterialTargetColorB = stdMat.emissiveColor.b
                    mesh.material = this.highlightMaterial
                } else {
                    mesh.material = this.scene.getMaterialByName(this.meshMaterialCache[mesh.uniqueId].materialName)
                    delete this.meshMaterialCache[mesh.uniqueId]

                    if (Object.keys(this.meshMaterialCache).length === 0) {
                        this.resetAnimationAndParams()
                    }
                }
            })
        }
    }
}

import { call, fork, select, StrictEffect, takeEvery } from 'redux-saga/effects'
import { deleteDrawableLocation } from '../../slices/2D'
import { selectActiveMode } from '../../slices/common'
import { VIEW_MODE } from '../../types'
import { deleteDrawableLocation2D } from '../2D/deleteDrawableLocation'

export function* handleDeleteDrawableLocation(
    action: ReturnType<typeof deleteDrawableLocation>
): Generator<StrictEffect, void, VIEW_MODE> {
    try {
        const activeMode: VIEW_MODE = yield select(selectActiveMode)

        switch (activeMode) {
            case VIEW_MODE.Markup2D:
                yield fork(deleteDrawableLocation2D, action)
                break
            case VIEW_MODE.Markup3D:
                break
            default:
                break
        }
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}

export function* watchForDeleteDrawableLocation(): Generator<StrictEffect, void, unknown> {
    yield takeEvery(deleteDrawableLocation.type, handleDeleteDrawableLocation)
}

import { createAction } from '@reduxjs/toolkit'
import { call, fork, select, StrictEffect, takeEvery } from 'redux-saga/effects'
import { selectActiveMode } from '../../slices/common'
import { VIEW_MODE } from '../../types'
import { handleCreateSingleDocumentMapping2D } from '../2D/createSingleDocumentMapping'

export const createSingleDocumentMapping = createAction<any>('createSingleDocumentMapping') // Type is any due to unique payload type of create document mapping

export function* handleCreateSingleDocumentMapping(
    action: ReturnType<typeof createSingleDocumentMapping>
): Generator<StrictEffect, void, VIEW_MODE> {
    try {
        const activeMode: VIEW_MODE = yield select(selectActiveMode)

        switch (activeMode) {
            case VIEW_MODE.Markup2D:
                yield fork(handleCreateSingleDocumentMapping2D, action)
                break
            case VIEW_MODE.Markup3D:
                break

            default:
                break
        }
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}

export function* watchForCreateSingleDocumentMapping(): Generator<StrictEffect, void, unknown> {
    yield takeEvery(createSingleDocumentMapping.type, handleCreateSingleDocumentMapping)
}

import { TakeableChannel } from 'redux-saga'
import { actionChannel, call, fork, select, StrictEffect, take } from 'redux-saga/effects'
import { createDrawableByTool } from '../../slices/2D'
import { selectActiveMode } from '../../slices/common'
import { VIEW_MODE } from '../../types'
import { setActiveToolAndLoadSchema } from '../2D/setActiveToolAndLoadSchema'

export function* handleCreateDrawableByTool(
    action: ReturnType<typeof createDrawableByTool>
): Generator<StrictEffect, void, VIEW_MODE> {
    try {
        const activeMode: VIEW_MODE = yield select(selectActiveMode)

        switch (activeMode) {
            case VIEW_MODE.Markup2D:
                yield fork(setActiveToolAndLoadSchema)
                break
            case VIEW_MODE.Markup3D:
                break
            default:
                break
        }
    } catch (error) {
        yield call(console.error, (error as Error).message)
    }
}

export function* watchForCreateDrawableByTool(isTesting?: boolean): Generator<StrictEffect, void, unknown> {
    const creationRequestChannel = yield actionChannel(createDrawableByTool.type)
    while (true) {
        const action = yield take(creationRequestChannel as TakeableChannel<ReturnType<typeof createDrawableByTool>>)
        yield call(handleCreateDrawableByTool, action as ReturnType<typeof createDrawableByTool>)
        if (isTesting) {
            break
        }
    }
}

import { Workspace } from '../../imup/lib/toolBoxes/2D'
import { Region } from '../../models/region'

const getItemSegments = (child: paper.Item) => {
    return (child as paper.Path).segments
}

/**
 * Function defines if opening_location belongs to a region or not
 * @param regions array of Active Floor regions
 * @param locationPath drawable paper.Path
 * @returns region_id or null
 */
export const defineLocationRegion = (
    locationPath: paper.Path,
    regions: Region[] | null | undefined,
    workspaceTool: Workspace
): number | null => {
    if (!regions || !regions.length) return null
    let region_id: number | null = null

    for (const region of regions) {
        const regionPath = workspaceTool.getRegionItemByRegionId(region.id)

        // Path with cutouts have segments nested into childrens
        const segments =
            locationPath.segments ??
            locationPath.children
                .map((child) => getItemSegments(child))
                .flat()
                .filter((el) => el !== undefined)
        if (region_id || !segments) break

        // Find the region which contains all points of the path
        for (const segment of segments) {
            if (!regionPath?.contains(segment.point)) {
                break
            }
            region_id = region.id
        }
    }

    return region_id
}

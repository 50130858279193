import { FormProperties, TOOL_TYPE_ENUMS } from '../imup/types'
import { DRAWABLE_TYPES } from '../shared/constants/drawable-types'
import applianceForm from './appliance'
import basementFinishedCeilingForm from './basementFinishedCeiling'
import blockRoughForm from './blockRough'
import canalesForm from './canales'
import cantileverClosureForm from './cantileverClosure'
import cornerForm from './corner'
import deckingForm from './decking'
import dropBeamForm from './droppedBeam'
import eaveLengthForm from './eaveLength'
import entryDoorForm from './entryDoor'
import exteriorDoorForm from './exteriorDoor'
import exteriorRoofingForm from './externalRoofing'
import flashingForm from './flashing'
import floorAreaForm from './floorArea'
import floorLevelBreakLine from './floorLevelBreakLine'
import floorSystemForm from './floorSystem'
import floorTrussBracingForm from './floorTruss/floorTrussBracing'
import floorTrussSystemForm from './floorTruss/floorTrussSystem'
import flushBeamForm from './flushBeam'
import foundationPilonFrom from './foundationPilon'
import framingRoofingForm from './framingRoofing'
import fyponBracketForm from './fyponBracket'
import gableAreaForm from './gable-area'
import gableLengthForm from './gableLength'
import garageAreaFrom from './garageArea'
import genericAreaForm from './genericArea'
import genericCountForm from './genericCount'
import genericLineForm from './genericLine'
import hardwareForm from './hardware'
import headerForm from './header'
import hipValleyBeamForm from './hipValleyBeam'
import interiorDoorForm from './interiorDoor'
import interiorFinishedCeilingForm from './interiorFinishedCeiling'
import joistForm from './joist'
import ledgerForm from './ledger'
import louverHogFenceForm from './louverHogFence'
import patioDoorForm from './patioDoor'
import porchCeilingForm from './porchCeiling'
import postForm from './post'
import postWrapForm from './postWrap'
import railingForm from './railing'
import regionForm from './region'
import ridgeBeamForm from './ridgeBeam'
import roofSystemForm from './roofSystem'
import roofingForm from './roofing'
import roofingLineFrom from './roofingLine'
import screenAreaForm from './screenArea'
import screenOpeningForm from './screenOpening'
import shearwallForm from './shearwall'
import sidelightForm from './sidelight'
import sidingForm from './siding'
import sidingStarterForm from './sidingStarter'
import stairsForm from './stairs'
import steelBeamPlateForm from './steelBeamPlate'
import trayedCeilingAndSofitForm from './trayedCeilingAndSofit'
import trimForm from './trim'
import underValleySheathingForm from './underValleySheathing'
import valleyPlateForm from './valleyPlate'
import wallForm from './wall'
import windowForm from './window'

export const materialToFormMap: Record<string, FormProperties<any>> = {
    /** Appliance materials */
    [DRAWABLE_TYPES.APPLIANCE]: applianceForm,

    /** BEAM FORM MATERIALS */
    [DRAWABLE_TYPES.DROPPED_BEAM]: dropBeamForm,
    [DRAWABLE_TYPES.FLUSH_BEAM]: flushBeamForm,
    [DRAWABLE_TYPES.HIP_AND_VALLEY_BEAM]: hipValleyBeamForm,
    [DRAWABLE_TYPES.RIDGE_BEAM]: ridgeBeamForm,
    [DRAWABLE_TYPES.HEADER]: headerForm,

    /** Decking materials  */
    [DRAWABLE_TYPES.LF_FIVE_DOT_FIVE_DECKING]: deckingForm,

    /** Ledger materials  */
    [DRAWABLE_TYPES.LEDGER]: ledgerForm,

    /** Railing materials  */
    [DRAWABLE_TYPES.LF_RAILING]: railingForm,

    /** Shearwall materials  */
    [DRAWABLE_TYPES.SHEARWALL]: shearwallForm,

    /** Stairs materials  */
    [DRAWABLE_TYPES.STAIRS]: stairsForm,

    /** Exterior Door materials */
    [DRAWABLE_TYPES.EXTERIOR_DOOR]: exteriorDoorForm,

    /** Canales materials */
    [DRAWABLE_TYPES.CANALES]: canalesForm,

    /** Sidelight materials */
    [DRAWABLE_TYPES.SIDELIGHT]: sidelightForm,

    /** Window materials */
    [DRAWABLE_TYPES.WINDOW]: windowForm,
    [DRAWABLE_TYPES.LE_WINDOW]: windowForm,

    /** Trim materials */
    [DRAWABLE_TYPES.TRIM]: trimForm,

    /** Hardware materials */
    [DRAWABLE_TYPES.HARDWARE]: hardwareForm,

    /** Steel beam plate */
    [DRAWABLE_TYPES.STEEL_BEAM_PLATE]: steelBeamPlateForm,

    /** Floor truss materials */
    // TODO: delete this type when we will use only markup only
    [DRAWABLE_TYPES.FLOOR_TRUSS_BRACING]: floorTrussBracingForm,
    [DRAWABLE_TYPES.FLOOR_TRUSS_SYSTEM]: floorTrussSystemForm,

    /** Trayed ceiling and soffit */
    [DRAWABLE_TYPES.TRAYED_CEILING_AND_SOFFIT]: trayedCeilingAndSofitForm,

    /** Entry Door materials */
    [DRAWABLE_TYPES.ENTRY_DOOR]: entryDoorForm,

    /** Post Wrap materials */
    [DRAWABLE_TYPES.POST_WRAP]: postWrapForm,

    /** Corner materials */
    [DRAWABLE_TYPES.CORNER]: cornerForm,

    /** Interior Door materials */
    [DRAWABLE_TYPES.INTERIOR_DOOR_IMUP]: interiorDoorForm,

    /** Corner materials */
    [DRAWABLE_TYPES.FYPON_BRACKET]: fyponBracketForm,

    /** Interior finished ceiling material */
    [DRAWABLE_TYPES.INTERIOR_FINISHED_CEILING]: interiorFinishedCeilingForm,

    /** Patio door material */
    [DRAWABLE_TYPES.PATIO]: patioDoorForm,

    /** Siding materials */
    [DRAWABLE_TYPES.SIDING]: sidingForm,

    /** Wall materials */
    [DRAWABLE_TYPES.WALL]: wallForm,

    /** Joist materials */
    [DRAWABLE_TYPES.ROOF_SYSTEM]: roofSystemForm,
    [DRAWABLE_TYPES.FLOOR_SYSTEM]: floorSystemForm,

    // TODO: delete these types when we will use only markup only
    [DRAWABLE_TYPES.I_JOIST]: joistForm,
    [DRAWABLE_TYPES.RAFTER]: joistForm,
    [DRAWABLE_TYPES.CEILING_JOIST]: joistForm,
    [DRAWABLE_TYPES.SLEEPER_JOIST]: joistForm,
    [DRAWABLE_TYPES.JOIST]: joistForm,
    [DRAWABLE_TYPES.FLOOR_JOIST]: joistForm,
    [DRAWABLE_TYPES.LE_JOIST]: joistForm,

    /** Post materials */
    [DRAWABLE_TYPES.POST]: postForm,

    /** Valley Plate materials */
    [DRAWABLE_TYPES.VALLEY_PLATE]: valleyPlateForm,

    /** Roofing materials */
    [DRAWABLE_TYPES.ROOFING]: roofingForm,

    /** Foundation pilon form */
    [DRAWABLE_TYPES.FOUNDATION_PILON]: foundationPilonFrom,

    /** Floor area form */
    [DRAWABLE_TYPES.FLOOR_AREA]: floorAreaForm,

    /** Finished Basement Ceiling form */
    [DRAWABLE_TYPES.BASEMENT_FINISHED_CEILING]: basementFinishedCeilingForm,

    /** Under valley sheathing materials */
    [DRAWABLE_TYPES.UNDER_VALLEY_SHEATHING]: underValleySheathingForm,

    /** Wildcard materials  */
    [DRAWABLE_TYPES.WILDCARD_COUNT]: genericCountForm,
    [DRAWABLE_TYPES.WILDCARD_LINE]: genericLineForm,
    [DRAWABLE_TYPES.WILDCARD_AREA]: genericAreaForm,

    /** Gable area form */
    [DRAWABLE_TYPES.GABLE_AREA]: gableAreaForm,

    /** Screen area form */
    [DRAWABLE_TYPES.SCREEN_AREA]: screenAreaForm,

    /** Screen opening form */
    [DRAWABLE_TYPES.LF_SCREEN_OPENING]: screenOpeningForm,

    /** region   */
    REGION: regionForm,

    /** Exterior roofing */
    [DRAWABLE_TYPES.EXTERIOR_ROOFING]: exteriorRoofingForm,

    /** Framing roofing */
    [DRAWABLE_TYPES.FRAMING_ROOFING]: framingRoofingForm,

    /** EAVE LENGTH */
    [DRAWABLE_TYPES.EAVE_LENGTH]: eaveLengthForm,

    /** GABLE LENGTH */
    [DRAWABLE_TYPES.GABLE_LENGTH]: gableLengthForm,

    /** SIDING STARTER */
    [DRAWABLE_TYPES.SIDING_STARTER]: sidingStarterForm,

    /** Porch Ceiling */
    [DRAWABLE_TYPES.PORCH_CEILING]: porchCeilingForm,

    /** Flashing */
    [DRAWABLE_TYPES.FLASHING]: flashingForm,

    /** Ridge/Hip/Valley Roofing */
    [DRAWABLE_TYPES.ROOF_LINE]: roofingLineFrom,

    /** Louver/Hog Fence */
    [DRAWABLE_TYPES.LOUVER_HOG_FENCE]: louverHogFenceForm,

    /** Block rough */
    [DRAWABLE_TYPES.BLOCK_ROUGH]: blockRoughForm,

    /** Cantilever Closure */
    [DRAWABLE_TYPES.CANTILEVER_CLOSURE]: cantileverClosureForm,

    /** Garage Area */
    [DRAWABLE_TYPES.GARAGE_AREA]: garageAreaFrom,

    /** tools   */
    [TOOL_TYPE_ENUMS.FLOOR_LEVEL_BREAK_LINE]: floorLevelBreakLine,
}

import { BaseTexture, Color3, Mesh, PBRMaterial, Scene, Texture, VertexBuffer } from '@babylonjs/core'
import {
    IGeometryMaterialOptions,
    IModel,
    ModelType,
    MODEL_TYPES_TO_LOAD_IN_VIEWER,
    PBRMaterialNames,
    PBRMaterialOptions,
} from '../../../../../types'
import ModelUtils from '../../ModelUtils'
import PRBMaterialList from '../PBRMaterialOptions'
import { AbstractMaterialGenerator } from './AbstractMaterialGenerator'

type LoadedPBRTextureReturn = {
    material: PBRMaterial
    options: PBRMaterialOptions
    hasTextures: boolean
}
/**
 * Class that generate PBR type material
 *
 */
export class PBRMaterialGenerator extends AbstractMaterialGenerator {
    private static readonly REFLECTION_PROBE = 'ReflectionProbe'
    private static readonly TEXTURE_DEFAULT_RATIO: number = 1
    private materialOptions: IGeometryMaterialOptions[] = []

    private loadedMaterialTextures: Record<string, LoadedPBRTextureReturn> = {}
    private scene: Scene | null = null

    public initGenerator(scene: Scene, structure: IModel): Promise<void> {
        this.scene = scene
        // Create array of promises that wrap around babylon texture loading
        this.populateMaterialOptions(MODEL_TYPES_TO_LOAD_IN_VIEWER, structure.children)
        return Promise.all(
            PRBMaterialList.map((opt) => {
                const material = PBRMaterialGenerator.createPBRMaterial(opt, scene)
                return PBRMaterialGenerator.loadPBRMaterialTexture(material, opt, scene)
            })
        ).then((res) => {
            // Save the PBR material objects and options into local state
            this.loadedMaterialTextures = res.reduce((acc, item) => {
                const newAccumulator = { ...acc, [item.options.name]: item }
                return newAccumulator
            }, {})
        })
    }

    /**
     * Creates geometry material options for a given model type and adds the material to available
     * options if not already available.
     *
     * @param  {IModel[]} nodes
     * @param  {ModelType} type
     * @param  {string} materialOptionName
     * @returns void
     */
    private createGeometryMaterialOptions = (nodes: IModel[], type: ModelType, materialOptionName: string): void => {
        const ids = ModelUtils.findElementIdsByType(nodes, type)
        const option: IGeometryMaterialOptions = {
            ids,
            optionsName: materialOptionName,
        }
        this.materialOptions.push(option)
    }

    /**
     * For each model type, creates the appropriate geometry material options
     *
     * @param  {ModelType[]} nodeTypes
     * @param  {IModel[]} nodes
     * @returns void
     */
    private populateMaterialOptions = (nodeTypes: ModelType[], nodes: IModel[]): void => {
        nodeTypes.forEach((modelType) => {
            switch (modelType) {
                case ModelType.WALL:
                    this.createGeometryMaterialOptions(nodes, modelType, PBRMaterialNames.Siding)
                    break
                case ModelType.ROOF:
                    this.createGeometryMaterialOptions(nodes, modelType, PBRMaterialNames.Roof)
                    break
                case ModelType.DOOR_GLASS:
                case ModelType.WINDOW_GLASS:
                    this.createGeometryMaterialOptions(nodes, modelType, PBRMaterialNames.Glass)
                    break
                default:
                    break
            }
        })
    }

    /**
     * Apply a PBRMaterial with given option to meshes
     */
    public applyMaterial(): void {
        this.materialOptions.forEach((materialOptions) => {
            materialOptions.optionsName &&
                this.scene &&
                this.applyMaterialOptionsToMeshes(
                    materialOptions.optionsName,
                    this.findMeshes(materialOptions.ids, this.scene)
                )
        })
    }

    private applyMaterialOptionsToMeshes(optionsName: string, meshes: Mesh[]): void {
        const foundLoadedTexture = this.loadedMaterialTextures[optionsName]
        if (foundLoadedTexture) {
            const { material, options, hasTextures } = foundLoadedTexture
            if (hasTextures && Array.isArray(meshes)) {
                const ratioX = options?.textureWidth || PBRMaterialGenerator.TEXTURE_DEFAULT_RATIO
                const ratioY = options?.textureHeight || PBRMaterialGenerator.TEXTURE_DEFAULT_RATIO
                const uvs = new Map<string, number[]>()

                meshes.forEach((mesh) => {
                    const meshUVs = mesh.getVerticesData(VertexBuffer.UVKind)
                    if (meshUVs && meshUVs !== null) {
                        const uvNumbers = meshUVs.map((num) => num as number) as number[]
                        uvs!.set(mesh.id, uvNumbers)
                    }
                })
                this.applyTextureMaterialOnMeshes({
                    meshes,
                    material,
                    textureXRatio: ratioX,
                    textureYRatio: ratioY,
                    uvsMap: uvs,
                })
            } else {
                this.applyMaterialOnMeshes(meshes, material)
            }
        }
    }

    /**
     * Loads the material textures from remote URL if not present
     * @param material  The babylon PBR material object
     * @param options The PBR material options
     * @param scene the babylon scene to add the materials to
     * @returns
     */
    private static loadPBRMaterialTexture(
        material: PBRMaterial,
        options: PBRMaterialOptions,
        scene: Scene
    ): Promise<LoadedPBRTextureReturn> {
        let albedoTexture: Texture
        let bumpTexture: Texture
        let ambientTexture: Texture
        let reflectivityTexture: Texture
        let emissiveTexture: Texture
        let metallicTexture: Texture

        const allTextures: Texture[] = []

        if (options.textureUrl) {
            albedoTexture = new Texture(options.textureUrl, scene)
            allTextures.push(albedoTexture)
        }
        if (options.normalTextureUrl) {
            bumpTexture = new Texture(options.normalTextureUrl, scene)
            allTextures.push(bumpTexture)
        }
        if (options.ambientTextureUrl) {
            ambientTexture = new Texture(options.ambientTextureUrl, scene)
            allTextures.push(ambientTexture)
        }
        if (options.reflectivityTextureUrl) {
            reflectivityTexture = new Texture(options.reflectivityTextureUrl, scene)
            allTextures.push(reflectivityTexture)
        }
        if (options.emissiveTextureUrl) {
            emissiveTexture = new Texture(options.emissiveTextureUrl, scene)
            allTextures.push(emissiveTexture)
        }
        if (options.metallicTextureUrl) {
            metallicTexture = new Texture(options.metallicTextureUrl, scene)
            allTextures.push(metallicTexture)
        }

        return new Promise((resolve) => {
            BaseTexture.WhenAllReady(allTextures, () => {
                material.useParallax = true
                material.useParallaxOcclusion = true

                if (albedoTexture) {
                    material.albedoTexture = albedoTexture
                    material.albedoTexture.hasAlpha = !!options.textureUrlHasAlpha
                }
                if (bumpTexture) {
                    material.bumpTexture = bumpTexture
                }
                if (ambientTexture) {
                    material.ambientTexture = ambientTexture
                }
                if (reflectivityTexture) {
                    material.reflectivityTexture = reflectivityTexture
                }
                if (emissiveTexture) {
                    material.emissiveTexture = emissiveTexture
                }
                if (metallicTexture) {
                    material.metallicTexture = metallicTexture
                }

                if (options.refractionTexture) {
                    const probe =
                        Array.isArray(scene.reflectionProbes) &&
                        scene.reflectionProbes.find((p) => p.name === PBRMaterialGenerator.REFLECTION_PROBE)
                    if (probe) {
                        material.refractionTexture = probe.cubeTexture
                        material.reflectionTexture = probe.cubeTexture
                        material.invertRefractionY = true
                    }
                } else {
                    material.reflectionTexture = scene.environmentTexture
                }
                material.environmentIntensity = 0.75
                material.cameraExposure = 0.66
                material.cameraContrast = 1.66
                material.ambientTextureImpactOnAnalyticalLights = 1
                material.usePhysicalLightFalloff = false
                material.useParallax = true
                material.useParallaxOcclusion = true

                material.metallic = options.metallic
                material.roughness = options.roughness
                material.indexOfRefraction = options.indexOfRefraction
                material.microSurface = options.microSurface
                material.parallaxScaleBias = options.parallaxScaleBias
                material.linkRefractionWithTransparency = options.linkRefractionWithTransparency

                material.freeze()
                resolve({ options, material, hasTextures: allTextures.length > 0 })
            })
        })
    }

    private static createPBRMaterial(options: PBRMaterialOptions, scene: Scene): PBRMaterial {
        const material = new PBRMaterial(`PBR-material-${options.name}`, scene)

        if (options.alpha) {
            material.alpha = options.alpha
        }

        if (options.color && PBRMaterialGenerator.isHexColorValid(options.color)) {
            material.albedoColor = Color3.FromHexString(options.color)
        }
        if (options.ambientColor && PBRMaterialGenerator.isHexColorValid(options.ambientColor)) {
            material.ambientColor = Color3.FromHexString(options.ambientColor)
        } else {
            material.ambientColor = Color3.White()
        }
        if (options.reflectivityColor && PBRMaterialGenerator.isHexColorValid(options.reflectivityColor)) {
            material.reflectivityColor = Color3.FromHexString(options.reflectivityColor)
        } else {
            material.reflectivityColor = Color3.Black()
        }
        if (options.emissiveColor && PBRMaterialGenerator.isHexColorValid(options.emissiveColor)) {
            material.emissiveColor = Color3.FromHexString(options.emissiveColor)
        }
        if (options.reflectionColor && PBRMaterialGenerator.isHexColorValid(options.reflectionColor)) {
            material.reflectionColor = Color3.FromHexString(options.reflectionColor)
        }

        return material
    }
}

export default PBRMaterialGenerator

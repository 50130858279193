import { ActiveDrawable, DrawableLocation, OpeningGroup } from '../../models/activeDrawable'
import { IMUP_WINDOW_AND_DOOR_TYPES } from '../constants/drawable-types'
import { filterDrawableGroups } from '../../actions/drawable'

/** Variable is used in linking-unlinking logic , to keep track of the original location in the state (before saving) */
const linking_step = 'linking_step'

export const isLeGroupSuggestionAlreadyUsed = (le_group: OpeningGroup): boolean => {
    // Only le openings are 'suggestions'. Only openings with a single location are 'le suggestions'
    if (
        le_group.openings.find((opening) => opening.opening_locations.length > 1) ||
        !IMUP_WINDOW_AND_DOOR_TYPES.includes(le_group.type)
    ) {
        return false
    }

    let isUsed = true

    for (const opening of le_group.openings) {
        if (!opening.settings.rcm_linked_opening_id) {
            isUsed = false
            break
        }
    }

    return isUsed
}

/** Check if opening is already linked based on rcm_linked_opening_id of the opening*/
export const isOpeningLocationAlreadyLinked = (opening: ActiveDrawable): boolean => {
    return !!(IMUP_WINDOW_AND_DOOR_TYPES.includes(opening.type) && opening.settings.rcm_linked_opening_id)
}

export const isOpeningMatchedOnSeveralLocations = (opening: ActiveDrawable): boolean => {
    return opening.opening_locations.length > 1
}

/** Check if opening is already linked based on linked_openings_ids of other openings  */
export const findIfOpeningIsAlreadyLinked = (opening: ActiveDrawable, openings: ActiveDrawable[]): boolean => {
    return !!openings.find((o) => o.settings.linked_openings_ids && o.settings.linked_openings_ids.includes(opening.id))
}

/**
 * Link LE window/door suggestion-location to an opening.
 * @param drawableToLink drawable that you clicked on to link to the active drawable
 * @param activeDrawable
 * @param activeDrawableGroup
 * @param dispatch from Redux
 * @param updateDrawable pass updateDrawable reducer
 * @param updateActiveDrawableGroup
 * @param toggleLinkingForm pass toggleLinkingForm reducer
 * @param toggleDrawableOnHover
 * @param setActiveDrawable pass setActiveDrawable reducer
 */
export const linkDrawableLocation = (
    drawableToLink: ActiveDrawable,
    activeDrawable: ActiveDrawable,
    activeDrawableGroup: OpeningGroup,
    dispatch,
    updateDrawable: (newDrawable: ActiveDrawable) => void,
    updateActiveDrawableGroup: (newGroup: OpeningGroup) => void,
    toggleLinkingForm: (toggle: boolean) => void,
    toggleDrawableOnHover: (id: number) => void,
    setActiveDrawable: (newDrawable: ActiveDrawable | null, index: number) => void
): void => {
    // Only LE drawables can be linked together
    if (IMUP_WINDOW_AND_DOOR_TYPES.includes(drawableToLink.type)) {
        // Add new coordinates to the activeDrawable.
        const newDrawable: ActiveDrawable = JSON.parse(JSON.stringify(activeDrawable))
        const newLocation: DrawableLocation = JSON.parse(JSON.stringify(drawableToLink.opening_locations[0]))

        // Drawable can have max 2 locations, so we just assign a new location
        if (newDrawable.opening_locations.length === 1) {
            newLocation.updated_at = linking_step
            newDrawable.opening_locations[1] = newLocation
        }

        // Until we allow only 2 locations, array will always contain only one linked opening id.
        newDrawable.settings.linked_openings_ids = [drawableToLink.id]
        dispatch(updateDrawable(newDrawable))

        // Update ActiveDrawableGroup
        const newGroup = JSON.parse(JSON.stringify(activeDrawableGroup))
        newGroup.openings = []
        activeDrawableGroup.openings.forEach((opening) => {
            if (opening.id === newDrawable.id) {
                newGroup.openings.push(newDrawable)
            } else {
                newGroup.openings.push(opening)
            }
        })

        // Update manual dimensions of ActiveDrawableGroup (update of group openings is handled on BE)
        // Do not overwrite user's input, do not overwrite, if value is 0
        if (
            newGroup.settings.manual_width !== drawableToLink.settings.manual_width &&
            drawableToLink.settings.manual_width &&
            !newGroup.settings.isWidthManual
        ) {
            newGroup.settings.manual_width = drawableToLink.settings.manual_width
        }
        if (
            !newGroup.settings.manual_height !== drawableToLink.settings.manual_height &&
            drawableToLink.settings.manual_height &&
            !newGroup.settings.isHeightManual
        ) {
            newGroup.settings.manual_height = drawableToLink.settings.manual_height
        }

        dispatch(updateActiveDrawableGroup(newGroup))

        // Update UI & color of linking, unknown location
        const updatedDrawableToLink = JSON.parse(JSON.stringify(drawableToLink))
        updatedDrawableToLink.settings.rcm_linked_opening_id = activeDrawable.settings.rcm_id
        dispatch(updateDrawable(updatedDrawableToLink))

        // Update UI after linking
        dispatch(toggleLinkingForm(false))
        dispatch(setActiveDrawable(null, 0))
        dispatch(toggleDrawableOnHover(activeDrawable.id))
        // need to filter drawableGroups per tab in case when we link drawable on different tab
        dispatch(filterDrawableGroups())
    }
}

/** Unlink drawable locations
 * @param drawable opening that you clicked on to unlink locations
 * @param activeDrawableGroup
 * @param openings all openings from all tabs
 * @param dispatch from Redux
 * @param updateDrawable pass updateDrawable reducer
 * @param updateActiveDrawableGroup
 */
export const unlinkDrawableLocation = (
    drawable: ActiveDrawable,
    activeDrawableGroup,
    openings: ActiveDrawable[],
    dispatch,
    updateDrawable: (newDrawable: ActiveDrawable) => void,
    updateActiveDrawableGroup: (newGroup: OpeningGroup) => void
): void => {
    // Remove link from an opening that has been used as a 'suggestion'
    drawable.settings.linked_openings_ids.forEach((id: number) => {
        const openingsCopy = JSON.parse(JSON.stringify(openings))

        const unlinkedOpening = openingsCopy.find((opening) => opening.id === id)
        if (unlinkedOpening && unlinkedOpening?.settings?.rcm_linked_opening_id) {
            unlinkedOpening.settings.rcm_linked_opening_id = null
            dispatch(updateDrawable(unlinkedOpening))
        }
    })

    // Remove linkage pointing to suggestions from an opening
    const newDrawable: ActiveDrawable = JSON.parse(JSON.stringify(drawable))

    newDrawable.settings.linked_openings_ids = []
    // We have a limit of 2 locations. Delete the second one.
    // To decide, which location is the original one:
    // First we have to check if there is a location, that has been linked, but not saved, if not - we rely on id
    if (newDrawable.opening_locations[0].id && newDrawable.opening_locations[1].id) {
        let indexToDeKeep = newDrawable.opening_locations[0].id < newDrawable.opening_locations[1].id ? 0 : 1

        if (newDrawable.opening_locations[0].updated_at === linking_step) {
            indexToDeKeep = 1
        }

        if (newDrawable.opening_locations[1].updated_at === linking_step) {
            indexToDeKeep = 0
        }

        newDrawable.opening_locations = [newDrawable.opening_locations[indexToDeKeep]]
        dispatch(updateDrawable(newDrawable))
    }

    // Update activeDrawableGroup
    const newGroup = JSON.parse(JSON.stringify(activeDrawableGroup))
    newGroup.openings = []
    activeDrawableGroup.openings.forEach((opening) => {
        if (opening.id === newDrawable.id) {
            newGroup.openings.push(newDrawable)
        } else {
            newGroup.openings.push(opening)
        }
    })

    newGroup.drawables = []
    activeDrawableGroup.drawables.forEach((existingDrawable) => {
        if (existingDrawable.id === newDrawable.id) {
            newGroup.drawables.push(newDrawable)
        } else {
            newGroup.drawables.push(existingDrawable)
        }
    })

    dispatch(updateActiveDrawableGroup(newGroup))
}

import { Database, Engine, Scene } from '@babylonjs/core'
import Paper from 'paper'
import { IStateMediator } from '../../types'
import { BabylonManager } from './BabylonManager'
import { PaperManager } from './PaperManager'

export default new (class Managers {
    // 3D static config
    private static readonly ENABLE_ANTI_ALIAS = false
    private static readonly ENABLE_ADAPTING_TO_DEVICE_RATIO = false
    private static readonly ENGINE_OPTIONS = {
        // Webgl context parameters
        // https://developer.mozilla.org/en-US/docs/Web/API/WebGLRenderingContext/getContextAttributes
        stencil: true,
        preserveDrawingBuffer: true,
        // DO not let babylonjs handle webgl lost context
        doNotHandleContextLost: true,
    }

    // nullable manager instances
    private manager2D: PaperManager | null = null
    private manager3D: BabylonManager | null = null

    private createEngine = (canvas: HTMLCanvasElement): Engine =>
        new Engine(
            canvas,
            Managers.ENABLE_ANTI_ALIAS,
            Managers.ENGINE_OPTIONS,
            Managers.ENABLE_ADAPTING_TO_DEVICE_RATIO
        )

    private attachOfflineProviderToScene = (scene: Scene): Scene => {
        Database.IDBStorageEnabled = true

        scene.offlineProvider = new Database('/imup-3d.babylon', () => null, true)
        scene.blockMaterialDirtyMechanism = true

        return scene
    }

    private createScene = (canvas: HTMLCanvasElement): Scene => {
        const engine = this.createEngine(canvas)
        const scene = new Scene(engine)

        return this.attachOfflineProviderToScene(scene)
    }

    public get2DManager = (): PaperManager | null => this.manager2D

    public get3DManager = (): BabylonManager | null => this.manager3D

    public create2DManager = (canvas: HTMLCanvasElement, mediator: IStateMediator): PaperManager => {
        Paper.setup(canvas)

        this.manager2D = new PaperManager({ mediator, paper: Paper })

        return this.manager2D
    }

    public create3DManager = (canvas: HTMLCanvasElement, mediator: IStateMediator): BabylonManager => {
        const scene = this.createScene(canvas)

        this.manager3D = new BabylonManager({ mediator, scene })

        return this.manager3D
    }
})()

import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import { call, CallEffect, select, SelectEffect } from 'redux-saga/effects'
import { ActiveFloor } from '../../../../models/activeFloor'
import { DocumentMapping } from '../../../../models/documentMapping'
import { getActiveFloor } from '../../../../reducers/drawable'
import { DRAWING_TYPES } from '../../../../shared/constants/drawable-types'
import { PLAN_VIEWS_ENUM } from '../../../../shared/constants/plan-views'
import determineDrawingType from '../../../../shared/services/drawable-type-service'
import { preparedDrawablesFailure } from '../../../slices/2D'
import { selectDocumentMappings } from '../../../slices/documents'
import { IMUP2DDrawableLocation } from '../../../types'
import SagaActionEnabledError from '../../utils/sagaBaseError'

/**
 * associateTypeWithLocation
 * @param locationWithGroupId: A single location to add a drawing type to
 *
 * Attaches drawable type valud using a call to attachDrawableType
 */
export function* associateTypeWithLocation(
    locationWithGroupId
): Generator<
    SelectEffect | CallEffect<DRAWING_TYPES | null>,
    IMUP2DDrawableLocation,
    ActiveFloor & (DRAWING_TYPES | null) & (DocumentMapping[] | null)
> {
    // Get the active floor from the store
    const activeFloor: ActiveFloor = yield select(getActiveFloor)

    // If theres no activeFloor -- then default to using document mappings because we are in an IMUP only project
    let mapping: DocumentMapping | undefined = undefined
    if (isNull(activeFloor) || isUndefined(activeFloor)) {
        const allMappings: DocumentMapping[] | null = yield select(selectDocumentMappings)
        mapping = allMappings?.find((m) => m.document_chunk_id === locationWithGroupId.document_chunk_id)
    }

    // Throw an error if the active floor is null
    if (!activeFloor && !mapping) {
        throw new SagaActionEnabledError(preparedDrawablesFailure, 'Error: Both active floors and mappings are null')
    }

    // Determine plan type - floor or elevation
    let planType: PLAN_VIEWS_ENUM = PLAN_VIEWS_ENUM.ELEVATION

    if (activeFloor?.is_floor || mapping?.type === 'floor') {
        planType = PLAN_VIEWS_ENUM.FLOOR
    }

    // Get the drawing type for the location
    const shapeType: DRAWING_TYPES | null = yield call(determineDrawingType, {
        drawable: locationWithGroupId.drawable,
        planView: locationWithGroupId,
        planType: planType,
    })

    // Prepare the IMUP 2D drawable representation with drawing type
    const drawableLocation: IMUP2DDrawableLocation = {
        opening_location_id: locationWithGroupId.id,
        opening_group_id: locationWithGroupId.groupId,
        document_chunk_id: locationWithGroupId.document_chunk_id,
        drawable_id: locationWithGroupId.drawable.id,
        drawing_type: locationWithGroupId.drawable.type,
        coordinates: locationWithGroupId.coordinates,
        settings: locationWithGroupId.settings,
        floor_scale: activeFloor?.scale_factor ?? mapping?.scale_factor,
        shapeType,
        cutouts: locationWithGroupId.cutouts,
        three_d_identifier: locationWithGroupId.three_d_identifier,
        label: locationWithGroupId.label,
        additionalData: locationWithGroupId.additional_data,
        isActive: locationWithGroupId.isActive,
        region_id: locationWithGroupId.drawable.opening_locations[0].region_id,
        ai_suggestion_id: locationWithGroupId.ai_suggestion_id,
    }

    return drawableLocation
}

import { PROJECT_TYPES_ENUM } from './project-type.constants'
import { PROJECT_STATUS_NAME } from './projectStatusName'
import { USER_PERMISSIONS_NAMES } from './user-permissions'

interface actionVisibility {
    projectStatuses: PROJECT_STATUS_NAME[]
    permissions: USER_PERMISSIONS_NAMES[]
    projectTypes: PROJECT_TYPES_ENUM[]
}

export const PROJECT_ACTION_VISIBILITY: { [key: string]: actionVisibility | undefined } = {
    settings: {
        projectStatuses: [PROJECT_STATUS_NAME.NEW],
        permissions: [USER_PERMISSIONS_NAMES.UPDATE_WINDOW_DOOR_TAKEOFF],
        projectTypes: [PROJECT_TYPES_ENUM.WINDOW_TAKEOFF],
    },
    takeoff: {
        projectStatuses: [
            PROJECT_STATUS_NAME.READY_FOR_TAKEOFF,
            PROJECT_STATUS_NAME.IN_PROGRESS,
            PROJECT_STATUS_NAME.READY_FOR_REVIEW,
            PROJECT_STATUS_NAME.COMPLETED,
            PROJECT_STATUS_NAME.AI_PROCESSING,
        ],
        permissions: [USER_PERMISSIONS_NAMES.UPDATE_WINDOW_DOOR_TAKEOFF],
        projectTypes: [PROJECT_TYPES_ENUM.WINDOW_TAKEOFF],
    },
    windows_schedule: {
        projectStatuses: [PROJECT_STATUS_NAME.READY_FOR_REVIEW, PROJECT_STATUS_NAME.COMPLETED],
        permissions: [USER_PERMISSIONS_NAMES.UPDATE_WINDOW_DOOR_TAKEOFF],
        projectTypes: [PROJECT_TYPES_ENUM.WINDOW_TAKEOFF],
    },
    windows_schedule_lite: {
        projectStatuses: [PROJECT_STATUS_NAME.READY_FOR_REVIEW, PROJECT_STATUS_NAME.COMPLETED],
        permissions: [USER_PERMISSIONS_NAMES.UPDATE_WINDOW_DOOR_TAKEOFF],
        projectTypes: [PROJECT_TYPES_ENUM.WINDOW_TAKEOFF],
    },
    windows_schedule_gateway: {
        projectStatuses: [PROJECT_STATUS_NAME.READY_FOR_REVIEW, PROJECT_STATUS_NAME.COMPLETED],
        permissions: [USER_PERMISSIONS_NAMES.UPDATE_WINDOW_DOOR_TAKEOFF],
        projectTypes: [PROJECT_TYPES_ENUM.WINDOW_TAKEOFF],
    },
    windows_schedule_group: {
        projectStatuses: [PROJECT_STATUS_NAME.READY_FOR_REVIEW, PROJECT_STATUS_NAME.COMPLETED],
        permissions: [USER_PERMISSIONS_NAMES.UPDATE_WINDOW_DOOR_TAKEOFF],
        projectTypes: [PROJECT_TYPES_ENUM.WINDOW_TAKEOFF],
    },
    markup: {
        projectStatuses: [PROJECT_STATUS_NAME.IN_PROGRESS, PROJECT_STATUS_NAME.ERROR_PENDING_COMPLETION],
        permissions: [USER_PERMISSIONS_NAMES.UPDATE_INTERACTIVE_MARKUP],
        projectTypes: [PROJECT_TYPES_ENUM.INTERACTIVE_MARKUP, PROJECT_TYPES_ENUM.INTERACTIVE_MARKUP_ONLY],
    },
    update_data: {
        projectStatuses: [
            PROJECT_STATUS_NAME.ERROR,
            PROJECT_STATUS_NAME.IN_PROGRESS,
            PROJECT_STATUS_NAME.ERROR_PENDING_COMPLETION,
        ],
        permissions: [USER_PERMISSIONS_NAMES.UPDATE_DATA],
        projectTypes: [PROJECT_TYPES_ENUM.INTERACTIVE_MARKUP],
    },
    update_data_2d: {
        projectStatuses: [
            PROJECT_STATUS_NAME.ERROR,
            PROJECT_STATUS_NAME.IN_PROGRESS,
            PROJECT_STATUS_NAME.ERROR_PENDING_COMPLETION,
            PROJECT_STATUS_NAME.MATERIAL_CALCS_ERROR,
        ],
        permissions: [USER_PERMISSIONS_NAMES.UPDATE_DATA],
        projectTypes: [PROJECT_TYPES_ENUM.INTERACTIVE_MARKUP],
    },
    update_data_3d: {
        projectStatuses: [
            PROJECT_STATUS_NAME.ERROR,
            PROJECT_STATUS_NAME.IN_PROGRESS,
            PROJECT_STATUS_NAME.ERROR_PENDING_COMPLETION,
            PROJECT_STATUS_NAME.MATERIAL_CALCS_ERROR,
        ],
        permissions: [USER_PERMISSIONS_NAMES.UPDATE_DATA],
        projectTypes: [PROJECT_TYPES_ENUM.INTERACTIVE_MARKUP],
    },
    merge_data: {
        projectStatuses: [PROJECT_STATUS_NAME.ERROR, PROJECT_STATUS_NAME.IN_PROGRESS],
        permissions: [USER_PERMISSIONS_NAMES.MERGE_DATA],
        projectTypes: [PROJECT_TYPES_ENUM.INTERACTIVE_MARKUP],
    },
    clear_project_errors: {
        projectStatuses: [
            PROJECT_STATUS_NAME.ERROR,
            PROJECT_STATUS_NAME.ERROR_PENDING_COMPLETION,
            PROJECT_STATUS_NAME.MATERIAL_CALCS_ERROR,
        ],
        permissions: [USER_PERMISSIONS_NAMES.UPDATE_DATA],
        projectTypes: [PROJECT_TYPES_ENUM.INTERACTIVE_MARKUP, PROJECT_TYPES_ENUM.INTERACTIVE_MARKUP_ONLY],
    },
    re_create_markup_only_project: {
        projectStatuses: [PROJECT_STATUS_NAME.CREATION_IN_PROGRESS],
        permissions: [USER_PERMISSIONS_NAMES.UPDATE_DATA],
        projectTypes: [PROJECT_TYPES_ENUM.INTERACTIVE_MARKUP_ONLY],
    },
    view_window_takeoff_csv: {
        projectStatuses: [PROJECT_STATUS_NAME.READY_FOR_REVIEW, PROJECT_STATUS_NAME.COMPLETED],
        permissions: [USER_PERMISSIONS_NAMES.UPDATE_WINDOW_DOOR_TAKEOFF],
        projectTypes: [PROJECT_TYPES_ENUM.WINDOW_TAKEOFF],
    },
    assign: {
        projectStatuses: [],
        permissions: [],
        projectTypes: [],
    },
    recalculate_measurements: {
        projectStatuses: [PROJECT_STATUS_NAME.IN_PROGRESS],
        projectTypes: [PROJECT_TYPES_ENUM.INTERACTIVE_MARKUP_ONLY],
        permissions: [USER_PERMISSIONS_NAMES.UPDATE_INTERACTIVE_MARKUP],
    },
    generate_deliverables: {
        projectStatuses: [PROJECT_STATUS_NAME.IN_PROGRESS],
        projectTypes: [PROJECT_TYPES_ENUM.INTERACTIVE_MARKUP_ONLY],
        permissions: [USER_PERMISSIONS_NAMES.UPDATE_INTERACTIVE_MARKUP],
    },
}

import {
    CalculateFeetFromPxInput,
    CalculateFeetFromPxInputForPath,
    CalculateFeetFromPxInputOneFactor,
} from '../../../imup/types'
import { Coordinate } from '../../../models/activeDrawable'
import { SCALES } from '../../../shared/constants/scales'

export const SCALE_LABEL_ENUMS = [
    '1:32',
    '1:16',
    '3:32',
    '1:8',
    '3:16',
    '1:4',
    '3:8',
    '1:2',
    '3:4',
    '1:1',
    '1.5:1',
    '3:1',
]

export const SCALE_TITLES = [
    '1/32"',
    '1/16"',
    '3/32"',
    '1/8"',
    '3/16"',
    '1/4"',
    '3/8"',
    '1/2"',
    '3/4"',
    '1"',
    '1-1/2"',
    '3"',
]

export const convertScaleEnumToString = (scaleFactorLabelEnum: string) => {
    const enumIndex = SCALE_LABEL_ENUMS.indexOf(scaleFactorLabelEnum)
    return enumIndex !== -1 ? SCALE_TITLES[enumIndex] : scaleFactorLabelEnum
}

export const convertScaleFactorLabelEnumToDecimal = (scaleFactorLabelEnum: string): number => {
    let value = 0 // default to 0
    if (scaleFactorLabelEnum.includes(':')) {
        value = Number(scaleFactorLabelEnum.split(':')[1]) / Number(scaleFactorLabelEnum.split(':')[0])
    }
    return value
}

export const DPI_72 = 72 // NOTE: 72 is the dpi for the old image conversion

export const calculatePXPerFoot = (input: Omit<CalculateFeetFromPxInputOneFactor, 'pxValue'>) => {
    const documentDPI = input.dpi ? input.dpi : DPI_72
    const scaleFactor = input.scaleFactor

    if (!Number.isFinite(scaleFactor)) return Infinity

    const resultantPxPerFoot = (documentDPI * input.pdfScale) / (input.calibrationFactor * scaleFactor)

    if (!Number.isFinite(resultantPxPerFoot)) return Infinity

    return resultantPxPerFoot
}

export const calculatePXPerFootXandY = (input: Omit<CalculateFeetFromPxInput, 'pxValue'>): number[] => {
    const documentDPI = input.dpi ? input.dpi : DPI_72
    const scaleFactor = input.scaleFactor

    if (!Number.isFinite(scaleFactor)) return [Infinity, Infinity]

    const xCalibration = input.xCalibrationFactor ?? 1
    const yCalibration = input.yCalibrationFactor ?? 1

    const xResultantPxPerFoot = (documentDPI * input.pdfScale) / (xCalibration * scaleFactor)
    const yResultantPxPerFoot = (documentDPI * input.pdfScale) / (yCalibration * scaleFactor)

    if (!Number.isFinite(xResultantPxPerFoot) || !Number.isFinite(xResultantPxPerFoot)) return [Infinity, Infinity]

    return [xResultantPxPerFoot, yResultantPxPerFoot]
}

export const calculateAngleDegrees = (p1: Coordinate, p2: Coordinate): number => {
    const dx = p2[0] > p1[0] ? p2[0] - p1[0] : p1[0] - p2[0]
    const dy = p2[1] > p1[1] ? p2[1] - p1[1] : p1[1] - p2[1]
    const radians = Math.atan2(dy, dx)
    let degrees = radians * (180 / Math.PI)
    // Ensure degrees within the range of 0 to 360
    degrees = (degrees + 360) % 360
    // then normalize to under 90 (1st quadrant)
    const normalizedDegrees = degrees > 90 ? 180 - degrees : degrees
    return normalizedDegrees
}

export const factorToApplyBasedOnCoordinates = (
    p1: Coordinate,
    p2: Coordinate,
    pxPerFootX: number,
    pxPerFootY: number
): number => {
    const factorToApply = p1[0] === p2[0] ? pxPerFootY : pxPerFootX
    return factorToApply
}

/**
 * Applies scale factor to a line based on X and Y factors
 * and path degree angle
 * @param input CalculateFeetFromPxInput
 * @returns
 */
export const applyScaleFactorToPathLength = (input: CalculateFeetFromPxInputForPath): number => {
    let degrees = 0
    let pxPerFoot = 1

    if (input.coordinates && input.coordinates[0] && input.coordinates[1]) {
        const [pxPerFootX, pxPerFootY] = calculatePXPerFootXandY(input)

        // When both factors are applied: calculate degree or apply the correct one based on coordinates
        if (pxPerFootX !== pxPerFootY && input.xCalibrationFactor !== 1 && input.yCalibrationFactor !== 1) {
            degrees = calculateAngleDegrees(input.coordinates[0], input.coordinates[1])
            if (degrees !== 0) {
                const degreesFromXAxis = degrees
                const degreesFromYAxis = 90 - degrees
                const xFactor = degreesFromXAxis / 90
                const yFactor = degreesFromYAxis / 90
                pxPerFoot = pxPerFootX * yFactor + pxPerFootY * xFactor

                if (degreesFromXAxis < 1 || degreesFromYAxis < 1) {
                    pxPerFoot = degreesFromXAxis < 1 ? pxPerFootX : pxPerFootY
                }
            } else {
                pxPerFoot = factorToApplyBasedOnCoordinates(
                    input.coordinates[0],
                    input.coordinates[1],
                    pxPerFootX,
                    pxPerFootY
                )
            }
        } else {
            // If only one factor set or none set, apply it to both axies
            pxPerFoot = input.xCalibrationFactor === 1 ? pxPerFootY : pxPerFootX
        }
    }
    return input.pxValue / pxPerFoot
}

/**
 * Applies scale X & Y scale factors to Area evenly
 * @param input CalculateFeetFromPxInput
 * @returns
 */
export const applyScaleFactorToPathArea = (input: CalculateFeetFromPxInput): number => {
    if (input.xCalibrationFactor === 1 && input.yCalibrationFactor === 1) {
        return input.pxValue / Math.pow(calculatePXPerFoot({ ...input, calibrationFactor: 1 }), 2)
    }

    if (input.xCalibrationFactor === 1 || input.yCalibrationFactor === 1) {
        const calibrationFactor = input.xCalibrationFactor === 1 ? input.yCalibrationFactor : input.xCalibrationFactor
        return input.pxValue / Math.pow(calculatePXPerFoot({ ...input, calibrationFactor: calibrationFactor }), 2)
    }

    const [pxPerFootX, pxPerFootY] = calculatePXPerFootXandY(input)
    if (!pxPerFootX || !pxPerFootX) {
        return 0
    }

    const pxPerFoot = (pxPerFootX + pxPerFootY) / 2
    return input.pxValue / Math.pow(pxPerFoot, 2)
}

// Inverse of applyScaleFactorToPathLength
export const removeScaleFactorFromLength = (
    length: number,
    scaleFactor: number,
    calibrationRatio: number,
    dpi: number | null = 72
): number => {
    const documentDPI = dpi ? dpi : DPI_72
    const rawLength = (length / scaleFactor) * calibrationRatio
    return rawLength * documentDPI
}

/**
 * Returns scale label for Window&Door projects
 * @param scaleFactor ex: 1:96
 * @returns scale label ex: 1/8"
 */
export const getScaleLabel = (scaleFactor: string): string => {
    const scale = SCALES.find((scale) => scale.value === scaleFactor)

    if (scale) {
        return scale.label
    }

    // If scale is not found, page has been manually calibrated. Find the closest value.
    const targetValue = Number(scaleFactor.split(':')[1])

    const closestScale = SCALES.reduce((closest, current) => {
        const currentScaleValue = Number(current.value.split(':')[1])
        const currentDifference = Math.abs(targetValue - currentScaleValue)
        const closestDifference = Math.abs(targetValue - Number(closest.value.split(':')[1]))

        return currentDifference < closestDifference ? current : closest
    }, SCALES[0])

    return `${closestScale.label} *`
}

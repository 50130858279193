import { call, fork, ForkEffect, select, StrictEffect, takeEvery } from 'redux-saga/effects'
import {
    ACTIVATE_ONLY_CLICKED_DRAWABLE_GROUP_BY_TYPE,
    TOGGLE_DRAWABLE_GROUP,
    TOGGLE_DRAWABLE_GROUPS,
    TOGGLE_DRAWABLE_GROUPS_BY_TYPE,
    toggleDrawableGroup,
    toggleDrawableGroups,
    toggleDrawableGroupsByType,
} from '../../../actions/drawable'
import { selectActiveMode } from '../../slices/common'
import { VIEW_MODE } from '../../types'
import { handleToggleDrawableGroups2D } from '../2D/handleToggleDrawableGroups'
import { handleToggleDrawableGroups3D } from '../3D/handleToggleDrawableGroups'

export function* handleToggleDrawableGroups(
    action:
        | ReturnType<typeof toggleDrawableGroup>
        | ReturnType<typeof toggleDrawableGroupsByType>
        | ReturnType<typeof toggleDrawableGroups>
): Generator<StrictEffect, void, VIEW_MODE> {
    try {
        const activeMode: VIEW_MODE | null = yield select(selectActiveMode)

        switch (activeMode) {
            case VIEW_MODE.Markup2D:
                yield fork(handleToggleDrawableGroups2D)
                break
            case VIEW_MODE.Markup3D:
                yield fork(handleToggleDrawableGroups3D, action)
                break
            default:
                break
        }
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForToggleDrawableGroups(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(
        [
            TOGGLE_DRAWABLE_GROUP,
            TOGGLE_DRAWABLE_GROUPS,
            TOGGLE_DRAWABLE_GROUPS_BY_TYPE,
            ACTIVATE_ONLY_CLICKED_DRAWABLE_GROUP_BY_TYPE,
        ],
        handleToggleDrawableGroups
    )
}

import inRange from 'lodash/inRange'

/**
 * Hover animation that can be bound to a
 * 2D paper Path item and supplied as its onFrameHandler.
 *
 * Increments and decrements opacity by value
 * specified by paper item.
 */
export function flash2D(this: paper.Path) {
    if (inRange(this.data.opacityValue, 0, 1.01)) {
        this.data.opacityValue += this.data.opacityChangeVal

        if (this.strokeColor) {
            this.strokeColor.alpha = this.data.opacityValue
        }
        if (this.fillColor) {
            // Area types fade fill in addition to stroke
            this.fillColor.alpha = this.data.opacityValue
        }
    } else if (this.data.opacityValue < 0) {
        this.data.opacityChangeVal = -this.data.opacityChangeVal
        this.data.opacityValue = 0
    } else if (this.data.opacityValue > 1) {
        this.data.opacityChangeVal = -this.data.opacityChangeVal
        this.data.opacityValue = 1
    }
}

export default flash2D

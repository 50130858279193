import { Opening_location } from './../../../../models/activeDrawable'
import { GeometryGroup } from '../../../slices/geometry'
import { ThreeDToTwoDRecord } from '../../../types'

/**
 * Prepares the mappings between 3D meshes to 2D items
 * @returns object to map between 3D meshes and 2D items
 */
export function* prepare3DTo2DMapping(
    openings: Record<string, string> | null,
    drawableGroups: GeometryGroup[]
): Generator<ThreeDToTwoDRecord, any, unknown> {
    const mappings = yield drawableGroups
        .flatMap((drawableGroup) =>
            drawableGroup.openings.map((opening) => ({
                ...opening,
                groupId: drawableGroup.id,
                type: drawableGroup.type,
            }))
        )
        .flatMap((opening) =>
            opening.opening_locations.reduce(
                (filteredLocations, location) =>
                    !!location.three_d_identifier &&
                    (!location.additional_data || (location.additional_data && !location.additional_data.joist_length))
                        ? [
                              ...filteredLocations,
                              { ...location, openingId: opening.id, groupId: opening.groupId, type: opening.type },
                          ]
                        : filteredLocations,
                [] as Array<Opening_location & { openingId: number; groupId: number; type: string }>
            )
        )
        .reduce((threeDToTwoDMappings, { id, openingId, groupId, three_d_identifier, type }) => {
            const threeDid =
                openings && openings[three_d_identifier!] ? openings[three_d_identifier!] : three_d_identifier!
            !threeDToTwoDMappings[threeDid]
                ? (threeDToTwoDMappings[threeDid] = [
                      {
                          locationId: id!,
                          openingId,
                          groupId,
                          type,
                      },
                  ])
                : threeDToTwoDMappings[threeDid].push({
                      locationId: id!,
                      openingId,
                      groupId,
                      type,
                  })
            return threeDToTwoDMappings
        }, {} as ThreeDToTwoDRecord)
    return mappings
}

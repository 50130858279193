import { FormValidation, UiSchema } from '@rjsf/core'
import { FormProperties } from '../imup/types'
import { FORM_ERROR_MESSAGES } from '../shared/constants/error-messages'
import { deckOrPorch } from '../utils/formSchema/constants'

export const uiSchema: UiSchema = {
    usage_type: {
        'ui:title': 'Trayed Ceiling and Soffit Type',
        'ui:placeholder': 'Select Type',
    },
    floor_level: {
        'ui:title': 'Trayed Ceiling and Soffit Floor Level',
        'ui:placeholder': 'Select Floor Level',
    },
    floor_level_other: {
        'ui:title': 'Floor level other',
        'ui:placeholder': 'Floor level other',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
    bundle_location: {
        'ui:placeholder': 'Select Bundle Location',
    },
    bundle_floor_level: {
        'ui:placeholder': 'Select Bundle Floor Level',
    },
    bundle_other_floor_level: {
        'ui:placeholder': 'Enter Bundle Other Floor Level',
    },
}

const onValidate = (formData: any, errors: FormValidation) => {
    if (formData.floor_level_other) {
        const floorOtherValue = parseInt(formData.floor_level_other)
        if (!(floorOtherValue > 0)) {
            errors.floor_level_other.addError(FORM_ERROR_MESSAGES.FULL_NUMBER)
        }
    }
}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    newSettings.floor_level_other = formData.floor_level === 'other' ? formData.floor_level_other : ''

    if (!deckOrPorch.includes(formData.bundle_name)) {
        newSettings.bundle_floor_level = null
    }

    if (formData.bundle_floor_level !== 'other') {
        newSettings.bundle_other_floor_level = null
    }

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
